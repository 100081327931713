import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NotificationResponse, NotificationTemplate } from '@core/interfaces';
import { NotificationMenuItemComponent } from '../../notification-menu-item/notification-menu-item.component';

@Component({
  selector: 'app-market-global-system',
  templateUrl: './market-global-system.component.html',
  styleUrls: ['./market-global-system.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MarketGlobalSystemComponent extends NotificationMenuItemComponent {
  @Input() notification: NotificationResponse;
  readonly NotificationTemplate = NotificationTemplate;
}
