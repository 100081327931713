import { Injectable } from '@angular/core';
import { IValidationRequest, IValidationRequestKey, IValidationResponse } from '@core/interfaces';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';


@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  private validatePath = '/api/validate';

  constructor(private apiService: ApiService) { }

  public validateTemplate(templateToCheck: string): Observable<IValidationResponse> {
    const payload: IValidationRequest = {
      key: IValidationRequestKey.TemplateDisplayLabelUnique,
      value: templateToCheck
    };
    return this.apiService.post(this.validatePath, payload);
  }

  public checkAccessForDocuments(documents: string[]): Observable<IValidationResponse> {
    const payload: IValidationRequest = {
      key: IValidationRequestKey.HasAccessToDocumentUuids,
      value: documents
    };
    return this.apiService.post(this.validatePath, payload);
  }

  public checkAccessForDeviations(deviations: string[]): Observable<IValidationResponse> {
    const payload: IValidationRequest = {
      key: IValidationRequestKey.HasAccessToDeviationUuids,
      value: deviations
    };
    return this.apiService.post(this.validatePath, payload);
  }
}
