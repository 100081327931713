import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AclService, HelperService } from '@core/services';
import { UserSelectors } from '@store/user/user.selectors';
import { Store } from '@ngxs/store';
import { IWorkflowType, TemplateAccessRequestType, WorkflowAccessListResponse } from '@core/interfaces';
import { forkJoin } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Injectable()
export class ReviewAccessGuardService  {
  accessList: WorkflowAccessListResponse;

  constructor(
    private aclService: AclService,
    private store: Store,
    private helperService: HelperService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ) {
    return new Promise<boolean>((resolve, reject) => {
      return this.store.select(UserSelectors.getCurrentUser).pipe(
        filter(user => !!user),
        take(1)
      ).subscribe((currentUser) => {
          forkJoin([
            this.aclService.getAccessList(IWorkflowType.Runtime, route.params.originalVersionRootUuid, TemplateAccessRequestType.ACCESS),
            this.aclService.getCurrentUserGroup(),
          ]).subscribe(([accessList, userGroups]) => {
              this.accessList = accessList;
              const hasAccess = this.helperService.userIsInAccessList(accessList, currentUser.uuid, userGroups);
              resolve(hasAccess);

              if (!hasAccess) {
                const workflowUrl = this.helperService.getWorkflowUrl('SDOX', route.params.originalVersionRootUuid, route.params.originalUuid);
                this.router.navigate([workflowUrl]);
              }
          }, error => {
            resolve(false);
          });
      });
    });
  }
}
