import { AddActiveFilter, RemoveActiveFilter, SearchChanged } from '@store/filters/filters.actions';

export class GetFilters {
  static readonly type = '[Workspace] GetFilters';
}

export class AddActiveFilterWorkspace extends AddActiveFilter {
  static readonly type = '[Workspace] AddActiveFilterWorkspace';
}

export class RemoveActiveFilterWorkspace extends RemoveActiveFilter {
  static readonly type = '[Workspace] RemoveActiveFilterWorkspace';
}

export class SearchChangedWorkspace extends SearchChanged {
  static readonly type = '[Workspace] SearchChangedWorkspace';
}
