import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-type-to-confirm',
  templateUrl: './type-to-confirm.component.html',
  styleUrls: ['./type-to-confirm.component.scss']
})
export class TypeToConfirmComponent implements OnInit {
  @Input() text: string;
  @Output() status = new EventEmitter<boolean>();

  confirmFormControl: UntypedFormControl;


  ngOnInit() {
    this.confirmFormControl = new UntypedFormControl('', [Validators.required, Validators.pattern(this.text)]);
    this.confirmFormControl.valueChanges.subscribe(x => this.status.next(this.confirmFormControl.valid));
  }
}
