<div class="company-licenses-container section-container gray-text">
  <div class="flex-col flex-gap-30">
    <ng-container *appUserAccessLevel="[ AccessLevel.BTRAdmin ]">
      <mat-card appearance="outline" class="margin-bottom-25">
        <div class="table-container flex-100 flex-col">
          <ng-container>
            <table
              #compTable
              class="scrolling-table btr-table"
              matSort
              mat-table
              [dataSource]="licenseRequests"
              matSortDirection="asc"
              matSortDisableClear
              (matSortChange)="sortTable($event)">
              <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
              <tr mat-row *matRowDef="let licenseRequests; columns: columnsToDisplay"></tr>
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="id">ID</th>
                <td mat-cell
                    *matCellDef="let licenseRequest"> {{licenseRequest.id}} </td>
              </ng-container>
              <ng-container matColumnDef="company">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="company_name">Company</th>
                <td mat-cell
                    *matCellDef="let licenseRequest"> {{licenseRequest.company.name}} </td>
              </ng-container>
              <ng-container matColumnDef="company_admin">
                <th mat-header-cell *matHeaderCellDef>Company Admin</th>
                <td mat-cell *matCellDef="let licenseRequest"> {{licenseRequest.license_company_admin_count}} </td>
              </ng-container>
              <ng-container matColumnDef="admin">
                <th mat-header-cell *matHeaderCellDef>Admin</th>
                <td mat-cell *matCellDef="let licenseRequest"> {{licenseRequest.license_admin_count}} </td>
              </ng-container>
              <ng-container matColumnDef="editor">
                <th mat-header-cell *matHeaderCellDef>Editor</th>
                <td mat-cell *matCellDef="let licenseRequest"> {{licenseRequest.license_editor_count}} </td>
              </ng-container>
              <ng-container matColumnDef="client">
                <th mat-header-cell *matHeaderCellDef>Client</th>
                <td mat-cell *matCellDef="let licenseRequest"> {{licenseRequest.license_client_count}} </td>
              </ng-container>
              <ng-container matColumnDef="viewer">
                <th mat-header-cell *matHeaderCellDef>Viewer</th>
                <td mat-cell *matCellDef="let licenseRequest"> {{licenseRequest.license_viewer_count}} </td>
              </ng-container>
              <ng-container matColumnDef="initiator_message">
                <th mat-header-cell
                    *matHeaderCellDef
                    matTooltip="Request Details"
                    matTooltipClass="btr-basic small"
                    appTooltipWhenEllipsis="horizontal">
                  Request Details
                </th>
                <td mat-cell
                    matTooltip="{{licenseRequest.initiator_message }}"
                    matTooltipPosition="above"
                    matTooltipClass="license-tooltip"
                    *matCellDef="let licenseRequest">
                  {{licenseRequest.initiator_message }}</td>
              </ng-container>
              <ng-container matColumnDef="responder_message">
                <th mat-header-cell
                    *matHeaderCellDef
                    matTooltip="Response Details"
                    matTooltipClass="btr-basic small"
                    appTooltipWhenEllipsis="horizontal">
                  Response Details
                </th>
                <td mat-cell
                    matTooltip="{{licenseRequest.responder_message }}"
                    matTooltipPosition="above"
                    matTooltipClass="license-tooltip"
                    *matCellDef="let licenseRequest"> {{licenseRequest.responder_message }} </td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="status">Status</th>
                <td [class]="licenseRequest.status.toLowerCase()" mat-cell *matCellDef="let licenseRequest">
                <span>
                   {{LicenseRequestStatusLabels[licenseRequest.status] }}
                </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let licenseRequest">
                  <ng-container *appUserAccessLevel="[ AccessLevel.BTRAdmin ]">
                    <button mat-button class="approve-button" (click)="approveLicenseRequest(licenseRequest)"
                            *ngIf="licenseRequest.status === 'open'">
                      <mat-icon>thumb_up</mat-icon>
                    </button>
                    <button mat-button class="reject-button" (click)="rejectLicenseRequest(licenseRequest)"
                            *ngIf="licenseRequest.status === 'open'">
                      <mat-icon>thumb_down</mat-icon>
                    </button>
                  </ng-container>
                </td>
              </ng-container>
            </table>
          </ng-container>
          <app-table-loading-overlay *ngIf="isLoading" [targetTable]="compTable"></app-table-loading-overlay>
          <div class="flex-100">
            <app-page-selector
              class="flex-100"
              [pageIndex]="pageIndex"
              [pageSize]="pageSize"
              [length]="totalItems"
              (pageChange)="pageChange($event)">
            </app-page-selector>
          </div>
        </div>
      </mat-card>
    </ng-container>
  </div>
</div>
