import { Injectable } from '@angular/core';
import { ReportingCredentialsResponse, ReportingLockStatus, ReportingStatusReponse } from '@core/interfaces';
import { interval, Observable, Subject } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ReportingService {
  private lockStatus$: Subject<ReportingLockStatus>;
  private pollInterval = 3000;

  constructor(private apiService: ApiService) { }

  public getLockStatus(): Observable<ReportingStatusReponse> {
    return this.apiService.get('/api/reporting/status');
  }

  public getCredentials(): Observable<ReportingCredentialsResponse> {
    return this.apiService.get('/api/reporting/credentials');
  }

  public getLogs(offset: number, limit: number): Observable<any> {
    return this.apiService.get(`/api/reporting/logs/${offset}/${limit}`);
  }

  public createDatabase(): Observable<ReportingStatusReponse> {
    return this.apiService.post(`/api/reporting/enable`, {});
  }

  public deleteDatabase(): Observable<ReportingStatusReponse> {
    return this.apiService.post(`/api/reporting/disable`, {});
  }

  public syncDatabase(): Observable<ReportingStatusReponse> {
    return this.apiService.post(`/api/reporting/sync`, {});
  }

  public startReportingLockStatusCheck(): Observable<ReportingStatusReponse> {
    this.stopReportingLockStatusCheck();
    this.lockStatus$ = new Subject();
    return interval(this.pollInterval).pipe(
      startWith(0),
      switchMap(() => this.getLockStatus()),
      takeUntil(this.lockStatus$)
    );
  }

  public stopReportingLockStatusCheck(): void {
    if (this.lockStatus$) {
      this.lockStatus$.next(null);
      this.lockStatus$.complete();
    }
  }
}
