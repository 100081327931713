import { IUser } from '@core/models';

export class GetCurrentUser {
  static readonly type = '[User State] GetCurrentUser';
}

export class Logout {
  static readonly type = '[User State] Logout';
  constructor(public forceSignOut = true) { }
}

export class Login {
  static readonly type = '[User State] Login';
}

export class UpdateProfileUser {
  static readonly type = '[User State] UpdateProfileUser';
  constructor(public updatedUser: IUser) { }
}

export class UpdateProfileImage {
  static readonly type = '[User State] UpdateProfileImage';
  constructor(public profileImage: string, public updatedUser: IUser) { }
}

export class UpdateProfilePagination {
  static readonly type = '[User State] UpdateProfilePagination';
  constructor(public default_pagination: number) { }
}
