export enum IEnvironmentName {
  validation = 'val',
  development = 'dev',
  iq = 'iq',
  production = 'prod',
  qa = 'qa',
  staging = 'stg',
  stagingTest = 'stgtest',
  local = 'Local',
  sbx = 'sbx',
  sbxTest = 'sbxtest'
}

export enum IEnvironmentLabel {
  validation = 'Validation',
  development = 'Development',
  iq = 'IQ',
  production = 'Production',
  qa = 'QA',
  staging = 'Staging',
  stagingTest = 'StagingTest',
  local = 'Local',
  sbx = 'SBX',
  sbxTest = 'SBXTest'
}

export interface IEnvironment {
  production: boolean;
  environmentName: IEnvironmentName;
  environmentLabel: IEnvironmentLabel,
  apiUrl: string;
  microservices_ports: {
    printing: string | null;
    integrations?: string;
  };
  sentry_dsn: string;
  sample_rate: number;
  traces_sample_rate: number;
  honeypot: string;
  release: string;
  marketplace_master: boolean;
  releaseVersion: string;
}
