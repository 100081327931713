import { A11yModule } from '@angular/cdk/a11y';
import { BidiModule } from '@angular/cdk/bidi';
import { ObserversModule } from '@angular/cdk/observers';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { MaterialModule } from '@core/modules/material.module';
import { PassFailCellComponent } from './pass-fail-cell.component';
import {
  FailSettingModalComponent
} from '@shared/components/pass-fail-cell/fail-setting-modal/fail-setting-modal.component';
import { ButtonsModule } from '@shared/components/buttons/buttons.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MultiStepConfirmationModule } from '@shared/components/multi-step-confirmation/multi-step-confirmation.module';
import { LoadingAnimationModule } from '@shared/components/loading-animation/loading-animation.module';
import { SignStepModule } from '@shared/components/sign-step/sign-step.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    CoreModule,
    A11yModule,
    BidiModule,
    ObserversModule,
    OverlayModule,
    PortalModule,
    CdkStepperModule,
    CdkTableModule,
    ButtonsModule,
    ReactiveFormsModule,
    MultiStepConfirmationModule,
    SignStepModule,
    LoadingAnimationModule,
  ],
  declarations: [
    PassFailCellComponent,
    FailSettingModalComponent
  ],
  exports: [
    PassFailCellComponent
  ],
  providers: []
})
export class PassFailCellModule {
}
