import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IBulkSigning } from './../interfaces';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class SigningService {
  private runtimePath = '/api/runtime-user-workflow/';

  constructor(private apiService: ApiService) { }

  public bulkSign(
    workflowUuid: string,
    versionUuid: string,
    ts: string,
    payload: IBulkSigning,
  ): Observable<{ row_uuids: string[] }> {
    return this.apiService
      .post(`${this.runtimePath}${workflowUuid}/version/${versionUuid}/ts-execute/${ts}/bulk-sign`,
      payload);
  }

  public bulkReset(
    workflowUuid: string,
    versionUuid: string,
    stepUuid: string,
    body
  ): Observable<{ success: boolean }> {
    return this.apiService.postMultipartForm(`${this.runtimePath}${workflowUuid}/version/${versionUuid}/reset-bulk/${stepUuid}`, body);
  }
}
