import { MENU_SIZES } from '@core/constants/app-constants';
import { Selector } from '@ngxs/store';
import { AppState, AppStateModel } from './app.state';
import { UserSelectors } from './user/user.selectors';


export class AppSelectors {

  @Selector([AppState])
  static isMenuOpened(state: AppStateModel) {
    return state.isMenuOpen;
  }

  @Selector([AppState])
  static isFullScreen(state: AppStateModel) {
    return state.isFullScreen;
  }

  @Selector([AppState])
  static isLoginPage(state: AppStateModel) {
    return state.isLoginPage;
  }

  @Selector([
    AppSelectors.isLoginPage,
    AppSelectors.isFullScreen,
    UserSelectors.isLoggedIn,
    AppSelectors.isMenuOpened
  ])
  static getContentFlexSize(isLoginPage: boolean, isFullScreen: boolean, isLoggedIn: boolean, isMenuOpened: boolean) {
    const menuSize = isMenuOpened ? MENU_SIZES.expanded : MENU_SIZES.collapsed;
    if (isFullScreen) {
      return `calc(100% - ${menuSize})`;
    } else {
      if (isLoggedIn && !isLoginPage) {
        if (isMenuOpened) {
          return 'calc(100% - 210px)';
        } else {
          return '88';
        }
      } else {
        return '100';
      }
    }
  }

  @Selector([AppState])
  static getCurrentRoute(state: AppStateModel) {
    return state.currentRoute;
  }
}
