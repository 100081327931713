import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@core/modules/material.module';
import { PipesModule } from '@core/pipes/pipes.module';
import { ButtonsModule } from '@shared/components/buttons/buttons.module';
import { AdditionalQuestionModule } from '../additional-question/additional-question.module';
import { ElectronicSignatureModule } from '../electronic-signature/electronic-signature.module';
import { InfoBoxModule } from '../info-box/info-box.module';
import { LoadingAnimationModule } from '../loading-animation/loading-animation.module';
import { MultiStepConfirmationModule } from '../multi-step-confirmation/multi-step-confirmation.module';
import { WorkflowsListingModalModule } from '../workflows-listing-modal/workflows-listing-modal.module';
import { ImplementationEnvironmentCanvasComponent } from './implementation-canvas/implementation-environment/implementation-environment-canvas.component';
import { DeleteDeliverableModalComponent } from './implementation-runtime/implementation-environment/delete-deliverable-modal/delete-deliverable-modal.component';
import { ImplementationEnvironmentRuntimeComponent } from './implementation-runtime/implementation-environment/implementation-environment-runtime.component';
import { ImplementationLinkItemComponent } from './implementation-runtime/implementation-link-item/implementation-link-item.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingAnimationModule,
    ButtonsModule,
    AdditionalQuestionModule,
    WorkflowsListingModalModule,
    MatExpansionModule,
    InfoBoxModule,
    ElectronicSignatureModule,
    MultiStepConfirmationModule,
    PipesModule
  ],
  declarations: [
    ImplementationEnvironmentCanvasComponent,
    ImplementationEnvironmentRuntimeComponent,
    ImplementationLinkItemComponent,
    DeleteDeliverableModalComponent
  ],
  exports: [
    ImplementationEnvironmentCanvasComponent,
    ImplementationEnvironmentRuntimeComponent,
    ImplementationLinkItemComponent,
  ],
  providers: []
})
export class ImplementationModule {
}
