import { Routes } from '@angular/router';
import { AccessLevel } from '@core/constants';
import { Permissions } from '@core/constants/permissions.enum';
import {
  AccessLevelGuardService,
  AuthenticationGuardService,
  AuthorizationGuardService,
  SectionsVisibilityGuardService,
} from '@core/guards';
import { BlankPageComponent } from './admin/pages/blank/blank.component';
import { CmsComponent } from './admin/pages/cms/cms.component';
import { CompanyNotFoundComponent } from './admin/pages/company-not-found/company-not-found.component';
import { ForgotPasswordComponent } from './admin/pages/forgot-password/forgot-password.component';
import { HomeComponent } from './admin/pages/home/home.component';
import { LoginComponent } from './admin/pages/login/login.component';
import { MissingAccountLoginComponent } from './admin/pages/missing-account/missing-account-login.component';
import { MissingAccountComponent } from './admin/pages/missing-account/missing-account.component';
import { PageNotFoundComponent } from './admin/pages/page-not-found/page-not-found.component';
import { ResetPasswordComponent } from './admin/pages/reset-password/reset-password.component';
import { NotAuthenticatedGuardService } from './core/guards/not-authenticated.guard.service';

export const AppRoutes: Routes = [
  // If a route does not need authentication then add it in the NOT_LOGGED_ROUTES array from the interceptor
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NotAuthenticatedGuardService]
  },
  { path: 'login-callback', redirectTo: 'home' },
  { path: 'logout-callback', redirectTo: 'home' },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [NotAuthenticatedGuardService]
  },
  {
    path: 'forgot-password/routed-from-login',
    component: ForgotPasswordComponent,
    canActivate: [NotAuthenticatedGuardService]
  },
  {
    path: 'reset-password/:user',
    component: ResetPasswordComponent,
    canActivate: [NotAuthenticatedGuardService]
  },
  { path: 'missing-account', component: MissingAccountComponent },
  { path: 'missing-account:login', component: MissingAccountLoginComponent },
  {
    path: 'home',
    canActivate: [AuthenticationGuardService, SectionsVisibilityGuardService],
    component: HomeComponent
  },
  {
    path: 'profile',
    canActivate: [AuthenticationGuardService],
    loadChildren: () => import('./admin/pages/profile/profile.module').then(m => m.ProfileModule),
    data: {
      accessLevels: [AccessLevel.BTRAdmin, AccessLevel.User]
    }
  },
  {
    path: 'company',
    canActivate: [AuthenticationGuardService, AuthorizationGuardService],
    loadChildren: () => import('./admin/pages/company/company.module').then(m => m.CompanyModule),
    data: {
      requiredPermissions: [Permissions.companyAdminPanelAccess]
    }
  },
  {
    path: 'company-admin',
    canActivate: [AuthenticationGuardService, AuthorizationGuardService],
    loadChildren: () => import('./admin/company-admin/company-admin.module').then(m => m.CompanyAdminModule),
    data: {
      requiredPermissions: [Permissions.companyAdminPanelAccess]
    }
  },
  {
    path: 'companies',
    canActivate: [AuthenticationGuardService],
    loadChildren: () => import('./admin/pages/companies/companies.module').then(m => m.CompaniesModule),
    data: {
      accessLevels: [AccessLevel.BTRAdmin]
    }
  },
  {
    path: 'documents',
    canActivate: [AuthenticationGuardService],
    loadChildren: () => import('./documents/documents.module').then(m => m.DocumentsModule)
  },
  {
    path: 'workflows',
    canActivate: [AuthenticationGuardService, AuthorizationGuardService],
    loadChildren: () => import('./workflows/workflows.module').then(m => m.WorkflowsModule),
    data: {
      requiredPermissions: [Permissions.viewDocumentWorkflow]
    }
  },
  {
    path: 'reports',
    canActivate: [AuthenticationGuardService, AuthorizationGuardService],
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
    data: {
      requiredPermissions: [Permissions.viewReportsPage],
      btrAdminHasAccess: false
    }
  },
  {
    path: 'reports-admin',
    canActivate: [AuthenticationGuardService, AuthorizationGuardService],
    loadChildren: () => import('./admin/pages/reports/reports.module').then(m => m.ReportsModule),
    data: {
      accessLevels: [AccessLevel.BTRAdmin],
      btrAdminHasAccess: true
    }
  },
  {
    path: 'my-tasks',
    canActivate: [AuthenticationGuardService, AuthorizationGuardService],
    loadChildren: () => import('./my-tasks-and-assignments/my-tasks-and-assignments.module').then(m => m.MyTasksAndAssignmentsModule),
    data: {
      requiredPermissions: [Permissions.viewTasksPage]
    },
  },
  {
    path: 'platform-monitoring',
    canActivate: [AuthenticationGuardService, AccessLevelGuardService],
    loadChildren: () => import('./admin/pages/platform-monitoring/platform-monitoring.module').then(m => m.PlatformMonitoringModule),
    data: {
      accessLevels: [AccessLevel.BTRAdmin]
    }
  },
  {
    path: 'templates',
    canActivate: [AuthenticationGuardService],
    loadChildren: () => import('./canvas/canvas.module').then(m => m.CanvasModule)
  },
  {
    path: 'admin',
    canActivate: [AuthenticationGuardService, AccessLevelGuardService],
    loadChildren: () => import('./admin/pages/admin-users/admin-users.module').then(m => m.AdminUsersModule),
    data: {
      accessLevels: [AccessLevel.BTRAdmin]
    }
  },
  {
    path: 'cms',
    component: CmsComponent,
    canActivate: [
      AuthenticationGuardService,
      AccessLevelGuardService
    ],
    data: {
      accessLevels: [AccessLevel.BTRAdmin],
    }
  },
  {
    path: 'marketplace',
    canActivate: [AuthenticationGuardService],
    loadChildren: () => import('./marketplace/marketplace.module').then(m => m.MarketplaceModule),
  },
  {
    path: 'global-systems',
    loadChildren: () => import(`./marketplace/marketplace-systems/marketplace-systems.module`).then(m => m.MarketplaceSystemsModule),
    canActivate: [
      AuthenticationGuardService,
      AccessLevelGuardService
    ],
    data: {
      accessLevels: [AccessLevel.BTRAdmin],
    }
  },
  {
    path: 'audit-trail',
    canActivate: [AuthenticationGuardService, AuthorizationGuardService],
    loadChildren: () => import('./audit-trail/audit-trail.module').then(m => m.AuditTrailModule),
    data: {
      requiredPermissions: [Permissions.viewAuditTrail],
      btrAdminHasAccess: false
    },
  },
  {
    path: 'audit-trail-admin',
    canActivate: [AuthenticationGuardService, AuthorizationGuardService],
    loadChildren: () => import('./admin/pages/audit-trail/audit-trail.module').then(m => m.AuditTrailModule),
    data: {
      accessLevels: [AccessLevel.BTRAdmin],
      btrAdminHasAccess: true
    }
  },
  {
    path: 'request-access',
    canActivate: [AuthenticationGuardService, AccessLevelGuardService],
    loadChildren: () => import('./admin/pages/request-access/request-access.module').then(m => m.RequestAccessModule),
    data: {
      accessLevels: [AccessLevel.User]
    }
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'not-found', component: CompanyNotFoundComponent },
  { path: 'blank', component: BlankPageComponent },
  { path: '404', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/404' }
];
