import { CanvasWorkflowStatus, IAdditionalQuestionChangeEvent } from './canvas.interface';
import { SafeResourceUrl } from "@angular/platform-browser";
export interface IImplementationWorkflowData {
  status: CanvasWorkflowStatus;
  version: number;
  versionUuid: string;
  system: string;
  uuid: string;
}

export interface IImplementationDeliverable {
  name: string;
  file_uuid?: string;
  display_label?: string;
  document_title?: string;
  workflow_uuid?: string;
  version_uuid?: string;
  sdox_uuid?: string;
  file_url?: string;
  file?: any;
  execution_required?: boolean; // true | false for placeholders, null for the one added from runtime
  summary_required?: boolean; // true | false for placeholders, null for the one added from runtime
  isExternalFileResource?: boolean;
  deleted_at?: string;
  user?: string;
  reason?: string;
  mime_type?: string;
  sdox_document_category?: string;
  version?: string;
  extension?: string;
  thumbnail?: SafeResourceUrl;
  file_content?: string;
  is_new_upload?: boolean;
  original_attachment?: Blob;
  sequence_filename?: string;
}

export interface IEnvironmentChangeEvent {
  environment: string;
  question: IAdditionalQuestionChangeEvent;
}

export interface IUploadFile {
  uuid?: string;
  name: string;
  file?: FormData;
  file_url?: string;
  created_at?: string;
  updated_at?: string;
  mime_type?: string;
}

export interface ILinkChangeEvent {
  isLink: boolean;
  type: DeliverableType;
  deliverable?: IImplementationDeliverable;
}

export enum DeliverableType {
  Deliverables = 'deliverables',
  Executed_Deliverables = 'executed_deliverables',
  Summary_Reports = 'summary_reports'
}
