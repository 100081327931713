import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { MaterialModule } from '@core/modules/material.module';
import { ButtonsModule } from '../buttons/buttons.module';
import { MultiStepConfirmationComponent } from './multi-step-confirmation.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    CoreModule,
    MatExpansionModule,
    CommonModule,
    MaterialModule,
    ButtonsModule,
    ReactiveFormsModule
  ],
  declarations: [
    MultiStepConfirmationComponent
  ],
  exports: [
    MultiStepConfirmationComponent
  ],
  providers: []
})
export class MultiStepConfirmationModule {
}
