
import { Injectable } from '@angular/core';
import { FILTERS_LOCAL_STORAGE_KEY } from '@core/constants/app-constants';
import { ICompanyFilterTagWithObjectValues, IFilterDataValues, IFilterSelectValue, IPrefix } from '@core/interfaces';
import { CompanySearchService, FilterTagService, GroupsService, PrefixService } from '@core/services';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Filters } from '@store/filters/filters';
import { GetSystems } from '@store/system/system.actions';
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { AddActiveFilterWorkspace, GetFilters, RemoveActiveFilterWorkspace, SearchChangedWorkspace } from './workspace-filters.actions';
import { prefixSelectValues, WorkspaceFilters, workspaceFiltersDefaults, WorkspaceFiltersModel } from './workspace-filters.model';

@State<WorkspaceFiltersModel>({
  name: 'WorkspaceFiltersState',
  defaults: {
    loading: true,
    activeFilters: [],
    filterItems: workspaceFiltersDefaults
  }
})
@Injectable()
export class WorkspaceFiltersState extends Filters<WorkspaceFilters> {
  private companySearchUser: CompanySearchService;

  constructor(companySearchUser: CompanySearchService, private prefixService: PrefixService,
    private filterTagService: FilterTagService, private store: Store, private groupsService: GroupsService) {
    super(companySearchUser);
    this.companySearchUser = companySearchUser;
  }

  @Selector([WorkspaceFiltersState.getAllRequestFilters()])
  static getRequestFilters(requestFilters: IFilterDataValues) {
    return requestFilters;
  }

  @Selector([WorkspaceFiltersState])
  static getIsLoading(state: WorkspaceFiltersModel) {
    return state.loading;
  }

  @Action(SearchChangedWorkspace)
  searchChanged(ctx, action) {
    return super.searchChanged(ctx, action);
  }

  @Action(AddActiveFilterWorkspace)
  addActiveFilter(ctx, action) {
    super.addActiveFilter(ctx, action);
  }

  @Action(RemoveActiveFilterWorkspace)
  removeActiveFilter(ctx, action) {
    super.removeActiveFilter(ctx, action);
  }

  @Action(GetFilters)
  getFilters({ getState, patchState }: StateContext<WorkspaceFiltersModel>) {
    const state = getState();
    const workspaceFilters = JSON.parse(localStorage.getItem(FILTERS_LOCAL_STORAGE_KEY.RUNTIME));

    return forkJoin([
      this.store.dispatch(new GetSystems(
        0,
        Number.MAX_SAFE_INTEGER,
        {
          sortBy: 'name',
          sortDirection: 'asc'
        })
      ),
      this.companySearchUser.searchCompanyUser(0, 100, { sortBy: 'first_name,last_name', sortDirection: 'asc' }, { search: '' }),
      this.prefixService.getPrefixes(0, Number.MAX_SAFE_INTEGER),
      this.groupsService.getAccessGroupsForLoggedUser(),
      this.filterTagService.getFilterTags(0, Number.MAX_SAFE_INTEGER, { sortBy: 'name', sortDirection: 'asc' })
    ]).pipe(
      take(1),
      switchMap(([systems, users, prefixes, accessGroups, filterTags]) => {
        return this.filterTagService.getFilterTagWithValues(
          0,
          Number.MAX_SAFE_INTEGER,
          filterTags.data.filter(filter => filter.values.length).map(filter => filter.uuid)
        ).pipe(
          map(res => {
            return [
              systems.SystemState.systems.map(system => {
                return {
                  label: system.name,
                  value: system.uuid
                };
              }),
              users,
              prefixSelectValues.map(filter => {
                return {
                  key: `filters.prefix.data.${filter.value}`,
                  label: filter.label,
                  value: filter.value,
                };
              }),
              prefixes.data,
              accessGroups.sort((a, b) => a.name.localeCompare(b.name)),
              filterTags.data.filter(filter => filter.values.length).map(filter => {
                return {
                  key: `filters.filter_tag_value_uuid.data.${filter.uuid}`,
                  label: filter.name,
                  value: filter.uuid,
                };
              }),
              !!res ? res.data : [],
            ];
          })
        );
      }),
      tap(([systems, users, prefixSelectValues, allPrefixes, accessGroups, filterTags, filterTagsDetails]) => {
        const workspaceFiltersState: WorkspaceFiltersModel = _.cloneDeep(state);
        const activeFilters: any[] = [];
        (filterTagsDetails as ICompanyFilterTagWithObjectValues[]).forEach(filter => {
          workspaceFiltersState.filterItems.filters.filter_tag_value_uuid.data[filter.uuid] = {
            label: filter.name,
            selected: null,
            selectValues: filter.values.map(fil => {
              return {
                label: fil.value,
                value: fil.uuid
              };
            }),
            isMultiselect: true,
            mergeSelectedValues: true,
            parentKey: 'filter_tag_value_uuid'
          };
        });
        (prefixSelectValues as IFilterSelectValue[]).forEach(prefixSelectValue => {
          workspaceFiltersState.filterItems.filters.prefix.data[prefixSelectValue.value] = {
            label: prefixSelectValue.label,
            selected: null,
            selectValues: (allPrefixes as IPrefix[]).filter(prefix => prefix.type === prefixSelectValue.value).map(prefix => {
              return {
                label: prefix.name,
                value: prefix.uuid
              };
            }),
            isMultiselect: true,
            parentKey: 'prefix'
          };
        });

        if (workspaceFilters) {
          workspaceFilters.map(item => {
            const stateItem = _.get(workspaceFiltersState.filterItems, item.key);
            if (stateItem) {
              stateItem.selected = item.value;
              activeFilters.push({
                key: item.key,
                value: stateItem
              });
            }
          });
        }

        workspaceFiltersState.filterItems.filters.system_uuid.selectValues = systems;
        workspaceFiltersState.filterItems.filters.owner_user_uuid.selectValues = users;
        workspaceFiltersState.filterItems.filters.access_groups_uuids.selectValues = accessGroups;
        workspaceFiltersState.filterItems.filters.filter_tag_value_uuid.children = filterTags;
        workspaceFiltersState.filterItems.filters.prefix.children = prefixSelectValues;

        patchState({
          ...workspaceFiltersState,
          activeFilters: [
            ...activeFilters
          ],
          loading: false,
        });
      })
    );
  }
}
