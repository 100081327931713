import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingService } from '@core/services';
import { AutoUnsubscribe } from '@core/utilities/auto-unsub';
import { SignaturePasswordType } from 'src/app/admin/pages/profile/profile.interface';

@Component({
  selector: 'app-signature-password-banner',
  templateUrl: './signature-password-banner.component.html',
  styleUrls: ['./signature-password-banner.component.scss']
})
@AutoUnsubscribe()
export class SignaturePasswordBannerComponent {

  constructor(private router: Router,
              private routingService: RoutingService) { }

  goToSignaturePassword(): void {
    this.router.navigate([this.routingService.PROFILE_BASIC_INFORMATION.url()],
                         { queryParams: { passMode: SignaturePasswordType.Create } });
  }
}
