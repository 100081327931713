import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IForgotPasswordData } from '@core/interfaces';
import { RoutingService } from '@core/services';
import { Auth, I18n } from 'aws-amplify';
import { ToastrService } from 'ngx-toastr';
import { ResetPasswordService } from '../../../core/services/reset-password.service';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  emailSentTo: string;
  password: string;
  emailSent = false;
  hideAmplify = false;
  services;

  get showMfaForm() {
    return !!this.resetPasswordService.mfaUser;
  }

  get mfaFormGroup() {
    return this.resetPasswordService.mfaFormGroup;
  }

  get mfaLoginError() {
    return this.resetPasswordService.mfaLoginError;
  }

  constructor(
    private router: Router,
    private routingService: RoutingService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private resetPasswordService: ResetPasswordService,
    private authenticator: AuthenticatorService
  ) {
    this.services = {
      handleForgotPassword: this.handleForgotPassword.bind(this),
      handleForgotPasswordSubmit: this.handleForgotPasswordSubmit.bind(this)
    };
  }

  ngOnInit() {
    this.authenticator.toResetPassword(); 
    I18n.setLanguage('en');
    I18n.putVocabulariesForLanguage('en', {
      'Enter your username': 'Email address*',
      'Send Code': 'Send Code',
      Code: 'Reset Code*',
      'Confirm Password': 'Confirm your new password*',
      'Your passwords must match': 'Please make sure your passwords match',
      'Confirmation Code': 'Reset Code*',
      'Password': 'New password*',
      'New password': 'New password*'
    });
    if (this.router.url.includes('routed-from-login')) {
      this.router.navigate([this.routingService.FORGOT_PASSWORD.url()]);
      setTimeout(() => {
        window.location.reload();
      }, 500)
    }
  }

  async handleForgotPassword(email: string): Promise<void> {
    this.emailSentTo = email;
    email = email.trim().toLowerCase();

    return Auth.forgotPassword(email)
      .then(res => {
        this.emailSent = true;
        this.cdr.markForCheck();
      });
  }

  async handleForgotPasswordSubmit(formData: IForgotPasswordData): Promise<void> {
    const { username, code, password } = formData;
    this.password = password;

    return Auth.forgotPasswordSubmit(username, code, password)
      .then(res => {
        this.hideAmplify = true;
        this.toastr.success('Your password was changed successfully');
        this.resetPasswordService.loginAfterPasswordReset(this.emailSentTo, this.password)
      });
  }

  goToLoginPage() {
    this.router.navigate([this.routingService.LOGIN.url()]);
  }

  public loginMFA() {
    const code = this.mfaFormGroup.controls['mfaCode'].value;
    this.resetPasswordService.loginMFAUser(code);
  }
}
