import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { AccessLevel } from '@core/constants';
import { PAGINATION_LOCAL_STORAGE_KEY } from '@core/constants/app-constants';
import { ILicenseRequest, LicenseRequestStatus, LicenseRequestStatusLabels } from '@core/interfaces/licenses.interface';
import { ConfirmationModalComponent } from '@core/modals/confirmation-modal/confirmation-modal.component';
import { Company } from '@core/models/company.model';
import { HelperService, PaginatorService } from '@core/services';
import { LicensesService } from '@core/services/licenses.service';
import { AutoUnsubscribe } from '@core/utilities/auto-unsub';
import { Store } from '@ngxs/store';
import { UserSelectors } from '@store/user/user.selectors';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { filter, finalize, switchMap, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-company-licenses-widget',
  templateUrl: './company-licenses-widget.component.html',
  styleUrls: ['./company-licenses-widget.component.scss'],
  providers: [PaginatorService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class CompanyLicensesWidgetComponent implements OnInit {
  @Input() userAccessLevel: AccessLevel;
  @Input() company: Company;

  readonly AccessLevel = AccessLevel;
  readonly LicenseRequestStatusLabels = LicenseRequestStatusLabels;

  licenseRequests: ILicenseRequest[] = [];
  isLoading: boolean;

  //  Paginator
  pageIndex = 0;
  pageSize = JSON.parse(localStorage.getItem(PAGINATION_LOCAL_STORAGE_KEY.ADMIN_LICENSES_DASHBOARD)) 
    ? JSON.parse(localStorage.getItem(PAGINATION_LOCAL_STORAGE_KEY.ADMIN_LICENSES_DASHBOARD)) 
    : this.store.selectSnapshot(UserSelectors.getCurrentUserDefaultPagination)
      ? this.store.selectSnapshot(UserSelectors.getCurrentUserDefaultPagination)
      : 10;
  private sortBy: string;
  private sortDirection: string;
  totalItems: number;

  private paginationChanged$: Subscription;

  constructor(
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService, private dialog: MatDialog,
    private licensesService: LicensesService,
    private helperService: HelperService,
    private store: Store,
    private paginatorService: PaginatorService) { }

  get columnsToDisplay() {
    const licenseColumns = ['company_admin', 'admin', 'editor', 'client', 'viewer'];
    const messageColumns = ['initiator_message', 'responder_message']
    const actionsColumns = ['status', 'actions'];
    return ['id', 'company', ...licenseColumns, ...messageColumns, ...actionsColumns];
  }

  ngOnInit(): void {
    this.paginationChanged$ = this.paginatorService.getPaginationData().subscribe(res => {
      this.pageIndex = res.pageIndex;
      this.pageSize = res.pageSize;
      localStorage.setItem(PAGINATION_LOCAL_STORAGE_KEY.ADMIN_LICENSES_DASHBOARD, JSON.stringify(res.pageSize))
      this.sortBy = res.sortBy;
      this.sortDirection = res.sortDirection;
      this.getLicenseRequests();
    });
    this.getLicenseRequests();
  }

  private getLicenseRequests() {
    this.isLoading = true;
    this.licensesService.getLicenseRequests(
      this.pageIndex * this.pageSize,
      this.pageSize,
      {
        sortBy: this.sortBy,
        sortDirection: this.sortDirection
      },
      {
        companyUuid: this.company ? this.company.uuid : null,
        status: LicenseRequestStatus.Pending
      })
      .pipe(
        take(1),
        finalize(() => {
          this.isLoading = false;
          this.cdr.markForCheck();
        }))
      .subscribe(res => {
        this.licenseRequests = res.data;
        this.totalItems = res.total;
      }, err => {
        this.helperService.showErrorMessage(err);
      });
  }

  rejectLicenseRequest(licenseRequest: ILicenseRequest) {
    const oldStatus = licenseRequest.status;
    this.dialog.open(ConfirmationModalComponent,
      {
        data: {
          modalTitle: 'Reject License Request confirmation',
          modalContent: `Are you sure you want to reject this license request?`,
          feedbackTitle: `Please add a reason for rejecting this request.!`,
          feedbackRequired: true,
          maxFeedbackLength: 100
        }
      }).afterClosed().pipe(
        filter(result => !!result),
        tap(() => {
          licenseRequest.status = 'rejected';
          this.cdr.markForCheck();
        }),
        switchMap((feedback: string) => this.licensesService.rejectLicenseRequest(licenseRequest.uuid, feedback)),
        take(1)
      ).subscribe(() => {
        this.toastr.success('License Request rejected.');
        this.getLicenseRequests();
      }, err => {
        licenseRequest.status = oldStatus;
        this.cdr.markForCheck();
        this.helperService.showErrorMessage(err);
      });
  }

  approveLicenseRequest(licenseRequest: ILicenseRequest) {
    const oldStatus = licenseRequest.status;
    this.dialog.open(ConfirmationModalComponent,
      {
        data: {
          modalTitle: 'Accept License Request confirmation',
          modalContent: `Are you sure you want to accept this license request?`
        }
      }).afterClosed().pipe(
        filter(result => !!result),
        tap(() => {
          licenseRequest.status = 'Approved';
          this.cdr.markForCheck();
        }),
        switchMap((optionalFeedback: string) => this.licensesService.approveLicenseRequest(licenseRequest.uuid, optionalFeedback)),
        take(1)
      ).subscribe(res => {
        this.toastr.success('License Request Accepted successfully');
        this.getLicenseRequests();
      }, err => {
        licenseRequest.status = oldStatus;
        this.cdr.markForCheck();
        this.helperService.showErrorMessage(err);
      });
  }

  pageChange(ev: PageEvent): void {
    this.paginatorService.pageChange(ev);
  }

  sortTable(ev: Sort): void {
    this.paginatorService.sortTable(ev);
  }
}
