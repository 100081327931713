<app-login-container>
  <ng-template let-brandingData>
    <div class="flex-row justify-center items-center">
      <div class="section-container flex-col flex-fixed-380">
        <div class="missing-account-message">You do not have an account in Res_Q. Please contact your Company Admin</div>
        <app-styleguide-simple-button
          type="green-filled"
          label="Go back to Login"
          (onClick)="goToLogin()">
        </app-styleguide-simple-button>
      </div>
    </div>
  </ng-template>
</app-login-container>
