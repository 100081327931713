import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { MaterialModule } from '@core/modules/material.module';
import { NgxsModule } from '@ngxs/store';
import { ButtonsModule } from '@shared/components/buttons/buttons.module';
import { ImpactAssessmentEcmRuntimeComponent } from '@shared/components/impact-assessment/impact-assessment-ecm-runtime/impact-assessment-ecm-runtime.component';
import { ClassificationsModule } from '../classifications/classifications.module';
import { PageSelectorModule } from '../page-selector/page-selector.module';
import { SaveCancelButtonModule } from '../save-cancel-button/save-cancel-button.module';
import { ImpactAssessmentConfigurationItemComponent } from './impact-assessment-modal/impact-assessment-configuration-item/impact-assessment-configuration-item.component';
import { ImpactAssessmentConfigurationComponent } from './impact-assessment-modal/impact-assessment-configuration/impact-assessment-configuration.component';
import { ImpactAssessmentConfirmationComponent } from './impact-assessment-modal/impact-assessment-confirmation/impact-assessment-confirmation.component';
import { ImpactAssessmentEditorComponent } from './impact-assessment-modal/impact-assessment-editor/impact-assessment-editor.component';
import { ImpactAssessmentExecuteModalComponent } from './impact-assessment-execute-modal/impact-assessment-execute-modal.component';
import { ImpactAssessmentHeaderComponent } from './impact-assessment-modal/impact-assessment-header/impact-assessment-header.component';
import { ImpactAssessmentModalComponent } from './impact-assessment-modal/impact-assessment-modal.component';
import { ImpactAssessmentSelectorComponent } from './impact-assessment-modal/impact-assessment-selector/impact-assessment-selector.component';
import { QuestionComponent } from './impact-assessment-modal/impact-assessment-editor/questionnaire-builder/question/question.component';
import { QuestionnaireBuilderComponent } from './impact-assessment-modal/impact-assessment-editor/questionnaire-builder/questionnaire-builder.component';
import { QuestionsPlayerModule } from './questions-player/questions-player.module';
import { ImpactAssessmentState } from './store/impact-assessment.state';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SaveCancelButtonModule,
    ButtonsModule,
    MatExpansionModule,
    QuestionsPlayerModule,
    ClassificationsModule,
    PageSelectorModule,
    NgxsModule.forFeature([ImpactAssessmentState]),
  ],
  declarations: [
    ImpactAssessmentExecuteModalComponent,
    ImpactAssessmentModalComponent,
    ImpactAssessmentSelectorComponent,
    ImpactAssessmentConfirmationComponent,
    ImpactAssessmentConfigurationComponent,
    ImpactAssessmentConfigurationItemComponent,
    ImpactAssessmentEcmRuntimeComponent,
    QuestionnaireBuilderComponent,
    QuestionComponent,
    ImpactAssessmentEditorComponent,
    ImpactAssessmentHeaderComponent,
  ],
  exports: [
    ImpactAssessmentExecuteModalComponent,
    ImpactAssessmentModalComponent,
    ImpactAssessmentSelectorComponent,
    ImpactAssessmentConfirmationComponent,
    ImpactAssessmentConfigurationComponent,
    ImpactAssessmentConfigurationItemComponent,
    ImpactAssessmentEcmRuntimeComponent,
    QuestionnaireBuilderComponent,
    QuestionComponent,
    ImpactAssessmentEditorComponent,
    ImpactAssessmentHeaderComponent,
  ],
  providers: []
})
export class ImpactAssessmentModule {
}
