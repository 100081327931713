import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IApiPaginatedDataBase, IExtraQueryParameters, ICompanySystem, ISortQueryParameters, ISortQuerySystemParameters, ICompanySystemSettings } from '../interfaces';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  private path = '/api/system';

  constructor(private apiService: ApiService) {}

  public getSystems(offset: number, limit: number, sortQueryParams?: ISortQueryParameters, extraQueryParams?: IExtraQueryParameters)
  : Observable<IApiPaginatedDataBase<ICompanySystem>> {
    const queryParams = this.apiService.addSortQueryParams(sortQueryParams);
    return this.apiService.get(`${this.path}/${offset}/${limit}`, this.apiService.addFilterQueryParams(queryParams, extraQueryParams));
  }

  public addSystem(system: ICompanySystem) {
    return this.apiService.post(this.path, system);
  }

  public updateSystem(system: ICompanySystem) {
    return this.apiService.put(`${this.path}/${system.uuid}`, system);
  }

  public deleteSystem(system: ICompanySystem) {
    return this.apiService.delete(`${this.path}/${system.uuid}`);
  }

  public getDownloadedSystems(offset: number, limit: number, sortQueryParams?: ISortQuerySystemParameters)
  : Observable<IApiPaginatedDataBase<ICompanySystem>> {
    const queryParams = this.apiService.addSortQueryParams(sortQueryParams);
    return this.apiService.get(`${this.path}/downloaded/${offset}/${limit}`, this.apiService.addSortQueryParams(sortQueryParams));
  }

  public updateSystemPeriodicReviewSettings(system: ICompanySystemSettings) {
    return this.apiService.patch(`${this.path}/${system.uuid}/periodic-review-settings`, system);
  }
}
