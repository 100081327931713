import { IUserLite } from '@core/models';

export interface IImpactAssessmentLite {
  uuid: string;
  title: string;
  status: ImpactAssessmentStatus;
  author_uuid: string;
  author: IUserLite;
  created_at: string;
  updated_at: string;
}

export interface IImpactAssessmentQuestionLite {
  uuid: string;
  assessment_uuid: string;
  title: string;
  type: 'single' | 'multiple';
  order_index: number;
}

export interface IImpactAssessmentQuestionFull extends IImpactAssessmentQuestionLite {
  answers: IImpactAssessmentAnswerFull[];
}


export interface IImpactAssessmentQuestionFullWithProgress extends IImpactAssessmentQuestionLite {
  answers: IImpactAssessmentAnswerFullWithProgress[];
}

export interface IImpactAssessmentAnswerLite {
  uuid?: string;
  question_uuid: string;
  title: string;
  order_index?: number;
}

export interface IImpactAssessmentAnswerFullWithProgress extends IImpactAssessmentAnswerLite {
  score: number;
  is_checked: boolean;
  trigger: IImpactAssessmentTriggerFullWithProgress;
}

export interface IImpactAssessmentAnswerFull extends IImpactAssessmentAnswerLite {
  score: number;
  trigger: IImpactAssessmentTriggerFull;
}

export interface IImpactAssessmentClassification {
  uuid?: string;
  assessment_uuid?: string;
  title: string;
  color: string;
  min_score?: number;
  max_score: number;
}

export interface IImpactAssessmentTriggerFullWithProgress {
  uuid?: string;
  questions: IImpactAssessmentQuestionFullWithProgress[];
  created_at?: string;
  updated_at?: string;
}

export interface IImpactAssessmentTriggerFull {
  uuid?: string;
  questions: IImpactAssessmentQuestionFull[];
  created_at?: string;
  updated_at?: string;
}

export interface IImpactAssessmentFull extends IImpactAssessmentLite {
  questions: IImpactAssessmentQuestionFull[];
  classifications: IImpactAssessmentClassification[];
  data?: IImpactAssessmentFull;
  source_impact_assessment_uuid?: string; // used only for cloning
}

export interface IImpactAssessmentLiteWithClassifications extends IImpactAssessmentLite {
  classifications?: IImpactAssessmentClassification[];
  questions: IImpactAssessmentQuestionFullWithProgress[];
}

export interface IImpactAssessmentEcmCachedResult {
  change_descriptions: IImpactAssessmentEcmCachedClassificationCdResult[];
}

export interface IImpactAssessmentEcmCachedClassificationCdResult {
  cd_text: string;
  cd_gid: string;
  session_uuid: string;
  classification_name: string;
  classification_color: string;
  classification_uuid: string;
  completed_questions: number;
  total_questions: number;
  session?: IImpactAssessmentSessionWithProgress;
}

export interface IImpactAssessmentStepData {
  step?: ImpactAssessmentStep;
  cbOnConfirm?: () => void;
  cbOnCancel?: () => void;
  data?: any;
}

export interface IImpactAssessmentSessionWithProgress {
  change_description: string;
  session_uuid: string;
  impact_assessment: IImpactAssessmentFullWithProgress;
  cached_result: IImpactAssessmentCachedResult; // API returns [] (empty array) if the result is empty;
}

export interface IImpactAssessmentProgressUpdate {
  completedAssessmentCount?: number;
  totalAssessments?: number;
}

export interface IImpactAssessmentFullWithProgress extends IImpactAssessmentLite {
  questions: IImpactAssessmentQuestionFullWithProgress[];
}

export interface IImpactAssessmentSdoxLink {
  uuid: string;
  impact_assessment_uuid: string;
  impact_assessment: IImpactAssessmentLite;
  impact_assessment_session_uuid: string;
  cached_result: IImpactAssessmentCachedResult; // API returns [] (empty array) if the result is empty;
}

export interface IImpactAssessmentCachedResult {
  classification_title: string;
  classification_color: string;
  impact_assessment_name: string;
}

export interface ITableIALinkSourceData {
  fromRowID?: string;
  fromStepUuid: string;
  fromStepID: string;
  fromWorkflowID: string;
  fromWorkflowVersionUuid: string;
  fromRowUuid?: string;
  fromRowIndex?: number;
  link?: IImpactAssessmentSdoxLink;
}

export interface IImpactAssessmentSdoxLinkSyncBody {
  impact_assessment_uuid: string;
  impact_assessment_session_uuid?: string;
}

export interface IImpactAssessmentSimpleResponse {
  affected_row_uuids: string[];
}

export interface IImpactAssessmentDetailedResponse {
  affected_links: { [uuid: string]: IImpactAssessmentSdoxLink };
}

export enum ImpactAssessmentStatus {
  Draft = 'draft',
  Finalized = 'finalized',
}

export enum ImpactAssessmentStep {
  Selector = 'selector',
  CreateConfig = 'create_config',
  UseConfig = 'use_config',
  CloneConfig = 'clone_config',
  PreviewConfig = 'preview_config',
  EditConfig = 'edit_config',
  ConfirmationModal = 'confirmation_modal',
  ViewConfig = 'view_config'
}

export enum ImpactAssessmentAction {
  View = 'view',
  Clone = 'clone',
  Delete = 'delete',
  Edit = 'edit',
  Unlink = 'unlink',
  Preview = 'preview',
  Execute = 'execute'
}
