import { EventEmitter, Injectable } from '@angular/core';
import { ITestResultLinkChangeEvent } from '@core/interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { ITableIALinkSourceData } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  private iaLinkChangedEvent: EventEmitter<ITableIALinkSourceData> = new EventEmitter();
  private testResultLinkChangedEvent: EventEmitter<ITestResultLinkChangeEvent> = new EventEmitter();
  displayName: BehaviorSubject<string> = new BehaviorSubject(''); // used in template/document header eg. "New Workflow V1(Draft)"
  private resetIASessionsEvent: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private canvasLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public getResetIASessionsTriggered(): Observable<boolean> {
    return this.resetIASessionsEvent.asObservable();
  }

  public setResetIASessionsTriggered(state: boolean) {
    this.resetIASessionsEvent.next(state);
  }

  iaLinkChangedEmit(event: ITableIALinkSourceData) {
    this.iaLinkChangedEvent.emit(event);
  }

  getIaLinkChangedEventEmitter(): EventEmitter<ITableIALinkSourceData> {
    return this.iaLinkChangedEvent;
  }

  getTestResultLinkEventEmitter(): EventEmitter<ITestResultLinkChangeEvent> {
    return this.testResultLinkChangedEvent;
  }

  testResultLinkChangeEmit(event: ITestResultLinkChangeEvent) {
    this.testResultLinkChangedEvent.emit(event);
  }

  setCanvasLoading(isLoading = true) {
    this.canvasLoading.next(isLoading);
  }

  getCanvasLoading() {
    return this.canvasLoading;
  }

  setDisplayName(name: string) {
    this.displayName.next(name);
  }
}
