<div class="item-data">
  <app-icon-two-images bigImg="license" smallImg="check2" [isSuccess]="true">
  </app-icon-two-images>
  <div class="item-details" (click)="goToOriginalWorkflow(notification, true)">
      <div class="item-details-title">
        The process of importing data from <strong class="capitalize">{{notification.data?.import_type}}</strong> 
        into <strong>{{notification.data?.step_display_title}}</strong> table 
        from <strong>{{notification.data?.document_title}}</strong> was successful.
      </div>
      <div class="item-details-time" *ngIf="company$ | async as company">
          {{notification.created_at | dateFormat : null : company.config.timezone : company.config.timeformat}}
      </div>
  </div>
</div>
<app-notification-bullet [notification]="notification"></app-notification-bullet>