import { Injectable } from '@angular/core';
import { PLACEHOLDER_ROOT_ID } from '@core/constants/app-constants';
import {
  IApiPaginatedDataBase,
  IFilterDataValues,
  IGenerateTraceMatrixRequest,
  IGenerateTraceMatrixResponse,
  ISortQueryParameters,
  ILinksDeletedEntities,
  ILinksEntityType,
  IRuntimeLite,
  LinkEntityRequest,
  LinkRequestResource,
  LinksEntityResponse,
  LinksForRowsRequest,
  LinksForRowsResponse,
  LinksList,
  LinksListStepElementsResource,
  LinksUpdateRequestResource,
  WorkflowSectionStepsListResourceArray
} from '@core/interfaces';
import { ApiService } from '@core/services';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TraceMatrixLinksService {
  // TODO: Everything Related To TraceMatrixLinks should be renamed to Links (this includes all classes used).
  // TODO: Maybe move to /core/services.
  constructor(private apiService: ApiService) { }

  public generateMatrix(workflow: IGenerateTraceMatrixRequest, workflow_version: string): Observable<IGenerateTraceMatrixResponse> {
    return this.apiService.post(`/api/tm/${PLACEHOLDER_ROOT_ID}/version/${workflow_version}/generate`, workflow);
  }

  public getTraceMatrixLinksWorkflowsFiltered(workflowRootUuid: string, offset: number, limit: number, filters: IFilterDataValues): Observable<IApiPaginatedDataBase<IRuntimeLite>> {
    return this.apiService.post(`/api/links/workflows/${workflowRootUuid}/${offset}/${limit}`, filters);
  }

  public getTraceMatrixLinksSteps(workflowVersionUuid: string): Observable<WorkflowSectionStepsListResourceArray> {
    return this.apiService.get(`/api/links/workflow/${workflowVersionUuid}/steps`);
  }

  public getTraceMatrixLinksStepElements(offset: number, limit: number, sortQueryParams: ISortQueryParameters, stepUuid: string): Observable<IApiPaginatedDataBase<LinksListStepElementsResource>> {
    return this.apiService.get(`/api/links/step/${stepUuid}/elements/${offset}/${limit}`, this.apiService.addSortQueryParams(sortQueryParams));
  }

  public linkTraceMatrixEntity(workflowRootUuid: string, linksStoreObject: LinkEntityRequest<LinkRequestResource>): Observable<LinksEntityResponse> {
    return this.apiService.post(`/api/links/${workflowRootUuid}/store/`, linksStoreObject);
  }

  public getLinksForEntity(entityType: ILinksEntityType, entityId: string): Observable<LinksList[]> {
    return this.apiService.get(`/api/links/list/${entityType}/${entityId}`);
  }

  public updateLink(workflowRootUuid: string, linksUpdateObject: LinkEntityRequest<LinksUpdateRequestResource>): Observable<LinksEntityResponse> {
    return this.apiService.put(`/api/links/${workflowRootUuid}/update`, linksUpdateObject);
  }

  public removeLink(workflowRootUuid: string, linksUuids: string[]): Observable<LinksList[]> {
    return this.apiService.deleteWithBody(`/api/links/${workflowRootUuid}/delete`, { links_uuids: linksUuids });
  }

  public getLinksForRows(stepUuid: string, payload: LinksForRowsRequest): Observable<LinksForRowsResponse> {
    return this.apiService.post(`/api/links/${stepUuid}/rows-links`, payload);
  }

  public getDeletedEntities(workflowRootUuid: string, type: ILinksEntityType, uuids: string[]): Observable<ILinksDeletedEntities> {
    return this.apiService.post(`/api/links/${workflowRootUuid}/deleted-entities`, {
      type: type.toString(),
      uuids
    });
  }

  public sortLinks(data: LinksList[]) {
    const dataCopy = _.cloneDeep(data);
    //  filter SDOX and group them by sdox_document_category
    const sdox = dataCopy.filter(el => el.sdox_document_category);
    const sdoxGrouped = _.groupBy(sdox, item => item.sdox_document_category);
    //  filter non-SDOX and create the group manually
    const nonSdox = dataCopy.filter(el => !el.sdox_document_category);
    const nonSdoxGrouped = { workflows: nonSdox };
    //  merge the two grouped data
    const allData = { ...sdoxGrouped, ...nonSdoxGrouped };
    //  order links by document name
    for (const [key, value] of Object.entries(allData)) {
      //  move 'all_workflow' links at the beginning, then by sort name:
      (value as LinksList[]).sort((a, b) => a.all_workflow ? -1 : b.all_workflow ? 1 : 0);
      (value as LinksList[]).sort(this.sortByName);
      //  order links by section name
      for (const val of value as LinksList[]) {
        if (val.steps?.length) {
          // move the 'all_steps'/'whole section' links at the beginning, then sort by name:
          val.steps.sort((a, b) => a.all_step ? -1 : b.all_step ? 1 : 0);
          val.steps.sort(this.sortByName);
        }
      }
    }
    return allData;
  }

  private sortByName(a, b): number {
    return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0);
  }
}
