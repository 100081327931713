import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { MaterialModule } from '@core/modules/material.module';
import { InfoBoxComponent } from './info-box.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    MaterialModule,
  ],
  declarations: [
    InfoBoxComponent
  ],
  exports: [
    InfoBoxComponent
  ],
  providers: []
})
export class InfoBoxModule {
}
