import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IBranding, ICms } from '../interfaces';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class CmsService {
  private cmsPath = '/api/cms-page/';

  constructor(private apiService: ApiService) {
  }

  public getCmsLogin(): Observable<ICms> {
    return this.apiService.get(this.cmsPath + 'login');
  }

  public getBrandingLogin(): Observable<IBranding> {
    return this.apiService.get(`/api/branding/login`);
  }

  public getCms(): Observable<ICms> {
    return this.apiService.get(this.cmsPath);
  }

  public getCmsBySlug(slug: string): Observable<ICms> {
    return this.apiService.get(this.cmsPath + slug);
  }

  public updateCms(slug: string, formData: FormData) {
    return this.apiService.postMultipartForm(this.cmsPath + slug, formData);
  }

  public deletePod(cms: ICms) {
    return this.apiService.delete(this.cmsPath + cms.slug);
  }
}
