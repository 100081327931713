import { CanvasWorkflowStatus, ICompanyLite, ICompanySystem, IDateFiltersRange } from '.';
import { MarketplacePackageStatus } from '../../marketplace/store/marketplace.model';

export interface IPackage {
  uuid: string;
  version_root_uuid: string;
  is_private: boolean;
  title: string;
  description: string;
  version: number;
  image_url: string;  //  TODO: remove this
  system: ICompanySystem;
  company?: any;
  author_company_domain?: string;
  shared_with: ICompanyLite[];
  templates: WorkflowLink[];
  workflows: WorkflowLink[];
  files: IPackageFile[];
  has_been_downloaded: boolean;
  is_downloadable: boolean;
  downloaded_count: number;
  global_package_root_uuid: string;
  global_package_version_uuid: string;
  status: MarketplacePackageStatus;
  contact_name: string;
  contact_email: string;
  contact_phone: string;
  created_at: string;
  updated_at: string;
  published_at: string;
  downloaded_at: string;
  rejected_at: string;
}

export interface IPackageLite {
  uuid: string;
  title: string;
  description: string;
  system: ICompanySystem;
  company_name: string;
  company_logo: string;
  status: MarketplacePackageStatus;
  has_been_downloaded: boolean;
  is_downloadable: boolean;
  asset_count: number;
  version_root_uuid: string;
  version: number;
  published_at: string;
  updated_at: string;
  rejected_at: string;
  created_at: string;
  download_count: number;
}

export interface IPackageLocal extends IPackage {
  templates: WorkflowLinkLocal[];
}

export interface WorkflowLink {
  workflow_uuid: string;
  version_uuid: string;
  version: number;
  workflow_type: string;
  document_title: string;
  display_label: string;
  has_access: boolean;
}

export interface IPackageFile {
  file_uuid: string;
  name: string;
  file_url?: string;
  mime_type?: string;
  file_size?: number;
}

export interface WorkflowLinkLocal extends WorkflowLink {
  selected: boolean;
}

export interface WorkflowLinkImport extends WorkflowLink {
  system_name: string;
  status: CanvasWorkflowStatus;
  author: string;
  type: string;
  system_uuid: string;
  updated_at: string;
  type_label?: string;
}

export interface IPackageVersion {
  uuid: string;
  version: number;
}

export interface IPackageContactUser {
  contact_name: string;
  contact_email: string;
  contact_phone: string;
}

export interface IPackageListingRequest {
  search?: string;
  filters: {
    system_uuid?: string | string[];
    company_uuid?: string | string[];
    is_private?: boolean;
    is_downloaded?: boolean;
    status?: string[];
  };
  date_filters: {
    published_at?: IDateFiltersRange;
    downloaded_at?: IDateFiltersRange;
    created_at?: IDateFiltersRange;
  };
  sortBy?: string;
  sortDirection?: string;
}

export interface IPackageHistoryListingRequest {
  search?: string;
  filters: {
    system_name?: string;
    company_name?: string;
    is_private?: boolean;
    status?: string;
  };
  date_filters: {
    created_at?: IDateFiltersRange;
  };
  sortBy?: string;
  sortDirection?: string;
}

export interface IPackageHistoryListingResponse {
  uuid: string;
  name: string;
  is_private: boolean;
  version: number;
  system_name: string;
  company_name: string;
  created_at: string;
  requested_at: string;
  requested_by_name: string;
  decided_by_name: string;
  description: string;
  status: MarketplacePackageStatus;
  reason: string;
}

export interface IPackageInitial {
  is_private: boolean;
  system: ICompanySystem;
  title: string;
}

export interface IRequestPackageRequest {
  user_uuid: string;
  reason: string;
}

export interface IPackageFileResponse {
  uuid: string;
  name: string;
  created_at?: string;
  updated_at?: string;
  mime_type?: string;
  file_size?: number;
}

export enum PackageActionType {
  DELETE = 'DELETE',
  CANCEL_PUBLISHING = 'CANCEL_PUBLISHING',
  CREATE_NEW_VERSION = 'CREATE_NEW_VERSION',
  REMOVE_FROM_MARKETPLACE = 'REMOVE_FROM_MARKETPLACE',
  VIEW_ORIGINAL_PACKAGE = 'VIEW_ORIGINAL_PACKAGE',
  CHOOSE_VERSION = 'CHOOSE_VERSION'
}

export enum AssetAction {
  addWorkflows = 'addWorkflows',
  addTemplates = 'addTemplates',
  addFiles = 'addFiles',
}

export enum AssetListAction {
  previewWorkflow = 'previewWorkflow',
  previewTemplate = 'previewTemplate',
  previewFile = 'previewFile',
  removeAsset = 'removeAsset',
  downloadAsset = 'downloadAsset',
}

export enum AssetType {
  workflow = 'workflows',
  template = 'templates',
  file = 'files',
}

export enum SelectButtonAction {
  selectAll = 'selectAll',
  deselectAll = 'deselectAll',
}
