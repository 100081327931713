import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({
  name: 'timeZoneFormat'
})
export class TimeZoneFormat implements PipeTransform {

  private eliminateMinutes(timeZone: string): string {
    return timeZone.includes(':00') ? timeZone.replace(':00', '') : timeZone;
  }

  transform(timeZone: string, showLocation?: boolean): any | string {
    if (!timeZone) {
      return '-';
    }

    const zoneAbbr = moment().tz(timeZone).zoneAbbr();
    if (zoneAbbr.startsWith('-') || zoneAbbr.startsWith('+') || zoneAbbr.startsWith('GMT')) {
      const gmt = zoneAbbr !== 'GMT' ? `- (GMT${this.eliminateMinutes(moment().tz(timeZone).format('Z'))})` : '- GMT';
      timeZone = showLocation ? `${timeZone} ${gmt}` : `${gmt}`;
    } else {
      timeZone = showLocation
                  ? `${timeZone} - ${zoneAbbr} (GMT${this.eliminateMinutes(moment().tz(timeZone).format('Z'))})`
                  : `${zoneAbbr} (GMT${this.eliminateMinutes(moment().tz(timeZone).format('Z'))})`;
    }

    return timeZone;
  }
}
