<div class="item-data">
    <app-icon-two-images
        bigImg="license"
        smallImg="close2"
        [isSuccess]="false">
    </app-icon-two-images>
    <div class="item-details" (click)="goToCompanyUsers(notification)">
        <div class="item-details-title">
        <strong>{{notification.data?.action_user | displayName}}</strong> from <strong>{{notification.data?.company?.name}}</strong> has reached the maximum number of failed login attempts and their Res_Q account was deactivated.
        </div>
        <div class="item-details-time" *ngIf="company$ | async as company">
            {{notification.created_at | dateFormat : null : company.config.timezone : company.config.timeformat}}
        </div>
    </div>
</div>
<app-notification-bullet [notification]="notification"></app-notification-bullet>
