export interface ISignatureMeaning {
  uuid: string;
  name: string;
}

export interface ISignatureRole {
  uuid: string;
  name: string;
}

export enum SignatureRoleOptions {
  SignatureMeaning = 'signatureMeaning',
  SignatureRole = 'signatureRole'
}
