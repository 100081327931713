import { CanvasFunctionalElementType } from '../../canvas/canvas.enum';
import { SdoxDocumentCategory } from '../interfaces';

interface ISdoxDocumentCategory {
  label: string;
  value: SdoxDocumentCategory;
  feTypes: CanvasFunctionalElementType[]; // Functional element types. Eg: "Table"
  hidden: boolean; // Some categories cannot be manually created
}

export const SDOX_DOCUMENT_CATEGORIES: ISdoxDocumentCategory[] = [{
  label: 'Executed Test Script',
  value: SdoxDocumentCategory.Test_Script_Execution,
  feTypes: [CanvasFunctionalElementType.General_Table, CanvasFunctionalElementType.External, CanvasFunctionalElementType.Test_Script, CanvasFunctionalElementType.SectionTitle],
  hidden: true
}, {
  label: 'General',
  value: SdoxDocumentCategory.General,
  feTypes: [CanvasFunctionalElementType.General_Table, CanvasFunctionalElementType.External, CanvasFunctionalElementType.SectionTitle],
  hidden: false
}, {
  label: 'Requirements',
  value: SdoxDocumentCategory.Requirements,
  feTypes: [CanvasFunctionalElementType.General_Table, CanvasFunctionalElementType.External, CanvasFunctionalElementType.Table, CanvasFunctionalElementType.SectionTitle],
  hidden: false
}, {
  label: 'Test Scripts',
  value: SdoxDocumentCategory.Test_Script,
  feTypes: [CanvasFunctionalElementType.General_Table, CanvasFunctionalElementType.External, CanvasFunctionalElementType.Test_Script, CanvasFunctionalElementType.SectionTitle],
  hidden: false
}, {
  label: 'Trace Matrix Requirements',
  value: SdoxDocumentCategory.Trace_Matrix,
  feTypes: [CanvasFunctionalElementType.General_Table, CanvasFunctionalElementType.External, CanvasFunctionalElementType.SectionTitle],
  hidden: true
}];
