<div class="item-data">
    <ng-container *ngIf="notification.template === NotificationTemplate.PackageRemovedByCompany || notification.template === NotificationTemplate.PackageRemovedToParentCompany">
        <app-icon-two-images bigImg="package" smallImg="close2" [isSuccess]="false">
        </app-icon-two-images>
    </ng-container>
    <ng-container *ngIf="notification.template === NotificationTemplate.PackageChangedByCompany">
        <app-icon-two-images bigImg="package" smallImg="replace-doc" [isSuccess]="true">
        </app-icon-two-images>
    </ng-container>
    <div class="item-details" (click)="openNotification(notification, !!(notification.template !== NotificationTemplate.PackageChangedByCompany))">
        <ng-container *ngIf="notification.template !== NotificationTemplate.PackageChangedByCompany">
            <div  class="item-details-title">
                <ng-container
                *ngIf="notification.template === NotificationTemplate.PackageRemovedToParentCompany">
                    Your package <strong>{{notification.data?.link ? notification.data?.link?.title : notification.data?.package_title}}</strong> was <strong>removed</strong> from Marketplace by
                    <span>Res_Q Admin.</span>
                </ng-container>
                <ng-container *ngIf="notification.template === NotificationTemplate.PackageRemovedByCompany">
                    The package <strong>{{notification.data?.link ? notification.data?.link?.title : notification.data?.package_title}}</strong> was <strong>removed</strong> from Marketplace by
                    <strong>{{notification.data?.company_name}}</strong>
                </ng-container>
            </div>
            <div class="item-details-subtitle">With reason:</div>
            <div class="item-details-comment"
                 matTooltip="{{notification.data?.reason}}"
                 matTooltipClass="btr-basic"
                 appTooltipSize
                 matTooltipPosition="right"
                 appTooltipWhenEllipsis>
                 "{{notification.data?.reason}}"
            </div>
        </ng-container>
        <ng-container
            *ngIf="notification.template === NotificationTemplate.PackageChangedByCompany">
            <div  class="item-details-title">
                <strong>{{notification.data?.company_name}}</strong> has published a new version for <strong>{{notification.data?.link ? notification.data?.link?.title : notification.data?.package_title}}</strong> in  Marketplace.
            </div>
        </ng-container>
        <div class="item-details-time" *ngIf="company$ | async as company">
            {{notification.created_at | dateFormat : null : company.config.timezone : company.config.timeformat}}
        </div>
    </div>
</div>
<app-notification-bullet [notification]="notification"></app-notification-bullet>
