<div class="item-data">
    <app-icon-two-images  
        *ngIf="notification.template === NotificationTemplate.PackagePublishedPublicToBtrAdmin || notification.template === NotificationTemplate.PackagePublishedPrivateToBtrAdmin" 
        bigImg="package" [isSuccess]="true">
    </app-icon-two-images>
    <app-icon-two-images  
        *ngIf="notification.template === NotificationTemplate.PackagePublishedPublicToCompany || notification.template === NotificationTemplate.PackagePublishedPrivateToCompany" 
        bigImg="package" smallImg="check2" [isSuccess]="true">
    </app-icon-two-images>
    <div class="item-details" (click)="goToPackage(notification)">
        <ng-container
            *ngIf="notification.template === NotificationTemplate.PackagePublishedPublicToBtrAdmin ||
                   notification.template === NotificationTemplate.PackagePublishedPrivateToBtrAdmin">
            <div class="item-details-title">There is a <strong> publish request </strong>
                for: <strong>{{notification.data?.link?.title || notification.data?.package_title}} </strong>
                <ng-container *ngIf="notification.template === NotificationTemplate.PackagePublishedPublicToBtrAdmin">
                    <span>(public)</span>
                </ng-container>
                <ng-container *ngIf="notification.template === NotificationTemplate.PackagePublishedPrivateToBtrAdmin">
                    <span>(private)</span>
                </ng-container>
                from <strong>{{notification.data?.company_name}}</strong>
            </div>
        </ng-container>
        <ng-container
            *ngIf="notification.template === NotificationTemplate.PackagePublishedPublicToCompany ||
                   notification.template === NotificationTemplate.PackagePublishedPrivateToCompany">
            <div class="item-details-title">
                <strong>{{notification.data?.company_name}}</strong> has published the 
                <ng-container *ngIf="notification.template === NotificationTemplate.PackagePublishedPublicToCompany">
                    <span>public </span>
                </ng-container>
                <ng-container *ngIf="notification.template === NotificationTemplate.PackagePublishedPrivateToCompany">
                    <span>private </span>
                </ng-container>
                package <strong>{{notification.data?.link?.title || notification.data?.package_title}} </strong> in Marketplace.
            </div>
        </ng-container>
        <div class="item-details-time" *ngIf="company$ | async as company">
            {{notification.created_at | dateFormat : null : company.config.timezone : company.config.timeformat}}
        </div>
    </div>
</div>
<app-notification-bullet [notification]="notification"></app-notification-bullet>