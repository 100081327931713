<div class="item-data">
    <app-icon-two-images bigImg="general-fe-icon" smallImg="close2" [isSuccess]="false">
    </app-icon-two-images>
    <div class="item-details" (click)="goToSettingsPage(notification)">
        <div class="item-details-title" *ngIf="notification.template === NotificationTemplate.ReportingEnabledFailed">
            The Reports Gateway database creation and sync has failed.
        </div>
        <div class="item-details-title" *ngIf="notification.template === NotificationTemplate.ReportingSyncFailed">
            The Reports Gateway database re-syncing has failed.
        </div>
        <div class="item-details-time" *ngIf="company$ | async as company">
            {{notification.created_at | dateFormat : null : company.config.timezone : company.config.timeformat}}
        </div>
    </div>
</div>
<app-notification-bullet [notification]="notification"></app-notification-bullet>