import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { MaterialModule } from '@core/modules/material.module';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';
import { UserGroupDepartmentSearchModule } from '../user-group-department-search/user-group-department-search.module';
import { CompanyUserGroupsComponent } from './company-user-groups.component';
import { ButtonsModule } from '../buttons/buttons.module';
import { CompanyAddEditUserGroupsModalComponent } from './add-edit-group-modal/add-edit-group-modal.component';
import { CompanyUserGroupComponent } from './group/group.component';
import { UsersListModule } from '../users-list/users-list.module';
import { UsersSelectModule } from '../select/users-select/users-select.module';
import { PageSelectorModule } from '../page-selector/page-selector.module';
import { PipesModule } from '@core/pipes/pipes.module';
import { TableLoadingOverlayModule } from '../table-loading-overlay/table-loading-overlay.module';
import { LoadingAnimationModule } from '../loading-animation/loading-animation.module';
import { PageLoadingOverlayModule } from '../page-loading-overlay/page-loading-overlay.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    CoreModule,
    UserAvatarModule,
    UserGroupDepartmentSearchModule,
    ButtonsModule,
    UsersListModule,
    PipesModule,
    PageSelectorModule,
    UsersSelectModule,
    TableLoadingOverlayModule,
    LoadingAnimationModule,
    PageLoadingOverlayModule
  ],
  declarations: [
    CompanyUserGroupsComponent,
    CompanyUserGroupComponent,
    CompanyAddEditUserGroupsModalComponent
  ],
  exports: [
    CompanyUserGroupsComponent,
    CompanyUserGroupComponent,
    CompanyAddEditUserGroupsModalComponent
  ]
})
export class CompanyUsersGroupsModule {
}
