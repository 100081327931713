import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { MaterialModule } from '@core/modules/material.module';
import { DateFormatPipe } from '@core/pipes';
import { ChunkLoadingErrorHandler, CustomErrorHandler, ERROR_HANDLERS } from '@core/services';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import * as Sentry from '@sentry/angular-ivy';
import { LoadingAnimationModule } from '@shared/components/loading-animation/loading-animation.module';
import { SharedModule } from '@shared/shared.module';
import { SystemState } from '@store/system/system.state';
import { ToastrModule } from 'ngx-toastr';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { environment } from '../environments/environment';
import { AdminModule } from './admin/admin.module';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routes';
import { RequestInterceptor } from './core/interceptors';
import { CompanyDetailsResolver } from './core/resolvers';
import { FooterComponent } from './shared/components/layout/footer/footer.component';
import { AppState } from './store/app.state';
import { AsyncPdfState } from './store/async-pdf/async-pdf.state';
import { CompanyState } from './store/company/company.state';
import { ConfigState } from './store/config/config.state';
import { NotificationState } from './store/notification/notification.state';
import { TasksState } from './store/tasks/tasks.state';
import { UserState } from './store/user/user.state';
import { MatTooltipDefaultOptions, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { NgChartsModule } from 'ng2-charts';

export const MatTooltipCustomOptions: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 0,
  disableTooltipInteractivity: true, // Hide the tooltip immediately after mouse out
};

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 10000, // 10 seconds
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    SharedModule,
    AdminModule,
    LoadingAnimationModule,
    RouterModule.forRoot(AppRoutes, { onSameUrlNavigation: 'reload' }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
      name: 'RESQ'
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsResetPluginModule.forRoot(),
    NgxsModule.forRoot([AppState, NotificationState, UserState, CompanyState, SystemState, TasksState, AsyncPdfState, ConfigState], {
      developmentMode: !environment.production,
      selectorOptions: {
        injectContainerState: false,
        suppressErrors: false
      }
    }),
    NgChartsModule
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    CompanyDetailsResolver,
    {
      provide: ERROR_HANDLERS,
      useClass: ChunkLoadingErrorHandler,
      multi: true
    },
    {
      provide: ERROR_HANDLERS,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
      multi: true
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { //  Use the above ERROR_HANDLERS providers in order to to catch the errors
      //  in two ways: with the chunk error loading service and also with Sentry
      provide: ErrorHandler,
      useExisting: CustomErrorHandler
    },
    { provide: Window, useValue: window },
    DateFormatPipe, // Added here in order to be able to use this pipe in a service
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: MatTooltipCustomOptions }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
