import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DynamicModule } from 'ng-dynamic-component';
import { MaterialModule } from '@core/modules/material.module';
import { AutocompleteInputComponent } from './autocomplete-input/autocomplete-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '@core/pipes/pipes.module';
import { DisplayNamePipe } from '@core/pipes';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    DynamicModule,
    ReactiveFormsModule,
    PipesModule
  ],
  declarations: [
    AutocompleteInputComponent,
  ],
  exports: [
    AutocompleteInputComponent,
  ],
  providers: [ DisplayNamePipe ]
})
export class InputsModule {
}
