import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from '@core/services';
@Injectable({
  providedIn: 'root',
})
export class CustomValidationService {

  checkLength(length: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return !control.value || control.value.length < length ? { minlength: true } : null;
    };
  }

  checkLowerCase(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return control.value.toUpperCase() === control.value ? { lowerCase: true } : null;
    };
  }

  checkUpperCase(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return control.value.toLowerCase() === control.value ? { upperCase: true } : null;
    };
  }

  checkSpecialCharacter(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const checkSpecialChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      return control.value.match(checkSpecialChar) ? null : { special: true };
    };
  }

  /**
   * Will return error if a Control contains any special character besides _
   * @returns
   */
  checkNotSpecialCharacter(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const checkSpecialChar = /[ `!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/;
      return control.value.match(checkSpecialChar) ? { special: true } : null;
    };
  }

  checkNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const digit = /.*[0-9].*/;
      return control.value.match(digit) ? null : { number: true };
    };
  }

  notDuplicate(otherValues: string[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isDuplicate = otherValues && otherValues.length > 0 && otherValues.includes(control.value);
      return !isDuplicate ? null : { isDuplicate: true };
    };
  }

  noDuplicatesValidator(existingValues, isNewValue = false): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let occurrenceCount = 0;
      existingValues.forEach(val => {
        if (val === control.value) {
          occurrenceCount++;
        }
      });
      if (!isNewValue && control.value !== null && occurrenceCount > 1) {
        return { duplicate: true };
      } else if (isNewValue && control.value !== null && existingValues.includes(control.value)) {
        return { duplicate: true };
      }
      return null;
    };
  }

  /*
  * Checks if the input value belongs to a collection
  * */
  existsInCollection(collection: string[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const existsInCollection = !control.value ||
        typeof control.value !== 'string' ||
        (control.value && collection.includes(control.value));
      return existsInCollection ? null : { existsInCollection: true };
    };
  }

  checkPhoneNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const phone = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{5})$/;
      return control.value?.match(phone) ? null : { phone: true };
    };
  }

  checkPhoneNumberAsync(userService: UserService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      return userService.validatePhoneNumber(control.value).pipe(
        map((result: boolean) => {
          return result ? null : { invalidPhoneNumber: true }
        })
      );
    };
  }

  noWhitespace(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return control.value && control.value.trim().length === 0 ? { whitespace: true } : null;
    };
  }

  classificationsRangeValidator(classificationsRange: number[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const newValue = control.value;
      let minMaxError = false;
      classificationsRange.forEach(val => {
        if (newValue >= val - 1 && newValue <= val + 1) {
          minMaxError = true;
        }
      });
      return minMaxError ? { classificationsRangeOverlap: true } : null;
    };
  }

  alphanumericValidator(allowSpace = false): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const alphanumeric = allowSpace ? /^[a-z0-9\s]*$/i : /^[a-z0-9]*$/i;
      return control.value?.match(alphanumeric) ? null : { alphanumeric: true };
    };
  }

  minLengthWithoutWhitespaces(minLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return control.value && control.value.replace(/ /g, '').length < minLength? { minlength: true } : null;
    };
  }
}
