import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { IUser } from './core/models';

@Injectable({providedIn: 'root'})
export class MonitoringService {

  monitorUser(user: IUser): void {
    try {
      Sentry.configureScope(scope => {
        scope.setUser({
          id: user.uuid,
          username: user.first_name + ' ' + user.last_name,
          email: user.email
        });
      });
      Sentry.setContext('user-extra', {
        Role: user.role?.name,
        'Is Company Admin': user.role?.identifier === 'company_admin',
        Department: user.department?.name,
        Permissions: JSON.stringify(user.permissions, null, 2)
      });
    } catch (e) {
      throw new Error('Sentry Error');
    }
  }

  removeMonitorUser(): void {
    Sentry.configureScope(scope => scope.setUser(null));
  }

}
