import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { MaterialModule } from '@core/modules/material.module';
import { ButtonsModule } from '@shared/components/buttons/buttons.module';
import { GridsterModule } from 'angular-gridster2';
import { DynamicIoModule, DynamicModule } from 'ng-dynamic-component';
import { SharedCompanyModule } from '../company/shared-company.module';
import { DashboardWidgetComponent } from './dashboard-widget/dashboard-widget.component';
import { DashboardComponent } from './dashboard.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    CoreModule,
    SharedCompanyModule,
    FormsModule,
    GridsterModule,
    DynamicModule,
    DynamicIoModule,
    ButtonsModule,
  ],
  exports: [
    DashboardComponent
  ],
  declarations: [
    DashboardComponent,
    DashboardWidgetComponent
  ],
  providers: []
})
export class DashboardModule {
}
