<div class="item-data">
    <app-icon-two-images bigImg="package" smallImg="check2" [isSuccess]="true">
    </app-icon-two-images>
    <div class="item-details" (click)="goToPackage(notification)">
        <div class="item-details-title">Your package
            <strong>{{notification.data?.link ? notification.data?.link?.title : notification.data?.package_title}} </strong> was
            <strong> approved </strong> in Marketplace by Res_Q Admin.
        </div>
        <div class="item-details-time" *ngIf="company$ | async as company">
            {{notification.created_at | dateFormat : null : company.config.timezone : company.config.timeformat}}
        </div>
    </div>
</div>
<app-notification-bullet [notification]="notification"></app-notification-bullet>