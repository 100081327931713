import { MatPaginatorIntl } from '@angular/material/paginator';


export function CustomPaginator() {
  const customPaginatorIntl = new MatPaginatorIntl();

  customPaginatorIntl.itemsPerPageLabel = 'Items per page';

  customPaginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number): string => {
      const startIndex = page * pageSize;
      const endIndex = Math.min(startIndex + pageSize, length);
      return `${startIndex + 1}-${endIndex} of ${length} entries`;
  };

  return customPaginatorIntl;
}
