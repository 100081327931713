export interface IPrefix {
  uuid?: string;
  name: string;
  type: PrefixType;
  created_at?: string;
}
export enum PrefixType {
  Document = 'document',
  Workflow = 'workflow',
}
