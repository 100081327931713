import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CanvasFunctionalElementType } from '../../canvas/canvas.enum';
import {
  IApiPaginatedDataBase,
  ICanvasStep,
  IFilterDataValues,
  IImpactAssessmentCachedResult,
  IImpactAssessmentDetailedResponse,
  IImpactAssessmentFull,
  IImpactAssessmentFullWithProgress,
  IImpactAssessmentLite,
  IImpactAssessmentLiteWithClassifications,
  IImpactAssessmentSdoxLink,
  IImpactAssessmentSdoxLinkSyncBody,
  IImpactAssessmentSimpleResponse,
  IQuestionsResult
} from '../interfaces';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ImpactAssessmentService {
  private path = '/api/user-impact-assessment/';
  private listingPath = '/api/impact-assessments';
  private runtimePath = '/api/runtime-user-workflow';
  private canvasPath = '/api/canvas-user-workflow';
  private previewPath = '/api/preview-user-workflow';

  private affectedRowsAfterDelete: BehaviorSubject<IImpactAssessmentSimpleResponse> = new BehaviorSubject(null);
  private affectedRowsAfterFinalize: BehaviorSubject<IImpactAssessmentDetailedResponse> = new BehaviorSubject(null);

  constructor(private apiService: ApiService) { }

  public setAffectedRowsAfterDelete(data: IImpactAssessmentSimpleResponse) {
    this.affectedRowsAfterDelete.next(data);
  }

  public getAffectedRowsAfterDelete() {
    return this.affectedRowsAfterDelete;
  }

  public setAffectedRowsAfterFinalize(data: IImpactAssessmentDetailedResponse) {
    this.affectedRowsAfterFinalize.next(data);
  }

  public getAffectedRowsAfterFinalize() {
    return this.affectedRowsAfterFinalize;
  }

  public getIALiteListing(offset: number, limit: number, filters: IFilterDataValues): Observable<IApiPaginatedDataBase<IImpactAssessmentLite>> {
    return this.apiService.post(`${this.listingPath}/${offset}/${limit}`, filters);
  }

  public getIA(id: string): Observable<IImpactAssessmentFull> {
    return this.apiService.get(this.path + id);
  }

  public deleteIA(id: string): Observable<IImpactAssessmentSimpleResponse> {
    return this.apiService.delete(this.path + id);
  }

  public createIA(data: IImpactAssessmentFull): Observable<IImpactAssessmentFull> {
    return this.apiService.post(this.path, data);
  }

  public finalizeIA(data: IImpactAssessmentFull): Observable<IImpactAssessmentDetailedResponse> {
    return this.apiService.post(this.path + data.uuid + '/finalize', {});
  }

  public editIA(data: IImpactAssessmentFull): Observable<IImpactAssessmentFull> {
    return this.apiService.put(this.path + data.uuid, data);
  }

  public getRuntimeIAQuestions(
    workflowUuid: string,
    versionUuid: string,
    stepUuid: string,
    rowUuid: string,
    fe: CanvasFunctionalElementType,
  ): Observable<IImpactAssessmentFullWithProgress> {
    return this.apiService
      .get(`${this.runtimePath}/${workflowUuid}/version/${versionUuid}/${fe}/${stepUuid}/row/${rowUuid}/impact-assessment/play`);

  }

  public postRuntimeIAAnswers(
    workflowUuid: string,
    versionUuid: string,
    stepUuid: string,
    rowUuid: string,
    fe: CanvasFunctionalElementType,
    answers: IQuestionsResult[]
  ): Observable<IImpactAssessmentCachedResult> {
    return this.apiService
      .post(`${this.runtimePath}/${workflowUuid}/version/${versionUuid}/${fe}/${stepUuid}/row/${rowUuid}/ia/play/answer-complete`, answers);

  }

  /*
  * Synchronizes link from a row
  * (insert/update/delete)
  * */
  public syncIALinksFromRow(
    workflowUuid: string,
    versionUuid: string,
    stepUuid: string,
    rowUuid: string,
    isTemplate: boolean,
    fe: CanvasFunctionalElementType,
    body: IImpactAssessmentSdoxLinkSyncBody
  ): Observable<IImpactAssessmentSdoxLink> {
    return this.apiService
      .post(`${isTemplate ? this.canvasPath : this.runtimePath }/${workflowUuid}/version/${versionUuid}/${fe}/${stepUuid}/row/${rowUuid}/ia/sync`,
        body);
  }

  /*
  * Synchronizes all links from all rows in a table
  * */
  public syncIALinksFromStep(
    workflowUuid: string,
    versionUuid: string,
    stepUuid: string,
    isTemplate: boolean,
    fe: CanvasFunctionalElementType,
    body: { impact_assessment_uuid: string }
  ): Observable<ICanvasStep> {
    return this.apiService
      .post(`${isTemplate ? this.canvasPath : this.runtimePath }/${workflowUuid}/version/${versionUuid}/${fe}/${stepUuid}/ia/sync`,
        body);
  }

  public getRuntimeIAWithProgress(
    workflowUuid: string,
    versionUuid: string,
    stepUuid: string,
    sessionUuid: string,
    fe: CanvasFunctionalElementType,
    previewPath: boolean
  ): Observable<IImpactAssessmentFullWithProgress> {
    return this.apiService
      .get(`${previewPath ? this.previewPath : this.runtimePath}/${workflowUuid}/version/${versionUuid}/${fe}/${stepUuid}/session/${sessionUuid}/play`);
  }

  public getIASessionWithProgress(
    impactAssessmentUuid: string,
    sessionUuid: string,
  ): Observable<IImpactAssessmentFullWithProgress> {
    return this.apiService
      .get(`${this.path}${impactAssessmentUuid}/session/${sessionUuid}`);
  }

  public getIASessionsWithProgress(
    stepUuid,
    workflowUuid: string,
    versionUuid: string,
  ) {
    return this.apiService
      .get(`${this.runtimePath}/${workflowUuid}/version/${versionUuid}/Impact/${stepUuid}/impact-assessment/play-all`);
  }

  public saveRuntimeIAAnswers(
    workflowUuid: string,
    versionUuid: string,
    stepUuid: string,
    sessionUuid: string,
    fe: CanvasFunctionalElementType,
    answers: IQuestionsResult,
    previewPath: boolean
  ): Observable<IImpactAssessmentLiteWithClassifications> {
    return this.apiService
      .post(`${previewPath ? this.previewPath : this.runtimePath}/${workflowUuid}/version/${versionUuid}/${fe}/${stepUuid}/session/${sessionUuid}/play/answer`, answers);
  }
}
