import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PageLoadingOverlayComponent } from './page-loading-overlay.component';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    PageLoadingOverlayComponent
  ],
  exports: [
    PageLoadingOverlayComponent
  ],
  providers: []
})
export class PageLoadingOverlayModule {
}
