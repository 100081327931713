import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { MaterialModule } from '@core/modules/material.module';
import { ButtonsModule } from '@shared/components/buttons/buttons.module';
import { ClassificationsModule } from '@shared/components/classifications/classifications.module';
import { ComponentsModule } from '@shared/components/components.module';
import { ImageUploadModule } from '@shared/components/image-upload/image-upload.module';
import { QuestionsPlayerModule } from '@shared/components/impact-assessment/questions-player/questions-player.module';
import { CompanyLicensesWidgetComponent } from '../dashboard/dashboard-widget/company-licenses-widget/company-licenses-widget.component';
import { CompanySystemModalComponent } from './company-systems/company-system-modal/company-system-modal.component';
import { GlobalSystemsModalComponent } from './company-systems/global-systems-modal/global-systems-modal.component';
import { InputsModule } from '@shared/components/inputs/inputs.module';
import { PageSelectorModule } from '@shared/components/page-selector/page-selector.module';
import { CompanyUnderMaintenanceComponent } from './company-under-maintenance/company-under-maintenance.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    CoreModule,
    ImageUploadModule,
    ClassificationsModule,
    QuestionsPlayerModule,
    ButtonsModule,
    InputsModule,
    PageSelectorModule
  ],
  exports: [
    CompanyLicensesWidgetComponent,
    CompanyUnderMaintenanceComponent,
  ],
  declarations: [
    CompanyLicensesWidgetComponent,
    CompanySystemModalComponent,
    GlobalSystemsModalComponent,
    CompanyUnderMaintenanceComponent
  ],
  providers: []
})
export class SharedCompanyModule {
}
