import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'signWithLabel'
})
export class SignWithLabelPipe implements PipeTransform {
  transform(value: number, label: string): string {
    const sign = value < 0 ? '' : '+';
    let labelValue = '';
    if (label) {
      labelValue = value === 1 || value === -1 ? label : `${label}s`;
    }
    return `${sign}${value} ${labelValue}`;
  }
}
