import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { ButtonsModule } from '../buttons/buttons.module';
import { ConfirmPasswordResetModalComponent } from './confirm-password-reset-modal.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonsModule,
    MatDialogModule
  ],
  declarations: [
    ConfirmPasswordResetModalComponent
  ],
  exports: [

  ]
})
export class ConfirmPasswordResetModalModule {}
