import { Injectable } from '@angular/core';
import { ICanvasStep } from '@core/interfaces';
import { Observable } from 'rxjs';
import { CanvasFunctionalElementType } from '../../canvas/canvas.enum';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class LinksVisibilityService {
  private templatePath = '/api/canvas-user-workflow';
  private runtimePath = '/api/runtime-user-workflow';

  constructor(private apiService: ApiService) {
  }

  // TODO: remove this for template
  public hideLinksColumn(
    workflowUuid: string,
    versionUuid: string,
    feType: CanvasFunctionalElementType,
    stepUuid: string,
    isTemplate = false
  ): Observable<{ success: true }> {
    return this.apiService.post(`${isTemplate ? this.templatePath : this.runtimePath}/${workflowUuid}/version/${versionUuid}/${feType}/${stepUuid}/links/hide`, {});
  }

  // TODO: remove this for template
  public showLinksColumn(
    workflowUuid: string,
    versionUuid: string,
    feType: CanvasFunctionalElementType,
    stepUuid: string,
    isTemplate = false
  ): Observable<{ success: true }> {
    return this.apiService.post(`${isTemplate ? this.templatePath : this.runtimePath}/${workflowUuid}/version/${versionUuid}/${feType}/${stepUuid}/links/show`, {});
  }

  public disableIALinksColumn(
    workflowUuid: string,
    versionUuid: string,
    feType: CanvasFunctionalElementType,
    stepUuid: string,
    isTemplate = false
  ): Observable<ICanvasStep> {
    return this.apiService.post(`${isTemplate ? this.templatePath : this.runtimePath}/${workflowUuid}/version/${versionUuid}/${feType}/${stepUuid}/ia-links/disable`, {});
  }

  public enableIALinksColumn(
    workflowUuid: string,
    versionUuid: string,
    feType: CanvasFunctionalElementType,
    stepUuid: string,
    isTemplate = false
  ): Observable<ICanvasStep> {
    return this.apiService.post(`${isTemplate ? this.templatePath : this.runtimePath}/${workflowUuid}/version/${versionUuid}/${feType}/${stepUuid}/ia-links/enable`, {});
  }

  public setAttachmentColumn(
    workflowUuid: string,
    versionUuid: string,
    stepUuid: string,
    isTemplate = false,
    showAttachment: boolean
  ): Observable<{ success: boolean }> {
    return this.apiService.post(
      `${isTemplate ? this.templatePath : this.runtimePath}/${workflowUuid}/version/${versionUuid}/TestScript/${stepUuid}/attachment-column`,
      { attachment_column: showAttachment }
    );
  }

  public setUncheckingMandatoryPassword(
    workflowUuid: string,
    versionUuid: string,
    stepUuid: string,
    isTemplate = false,
    mandatoryPassword: boolean
  ): Observable<{ success: boolean }> {
    return this.apiService.post(
      `${isTemplate ? this.templatePath : this.runtimePath}/${workflowUuid}/version/${versionUuid}/TestScript/${stepUuid}/unchecking-mandatory-password`,
      { mandatory_password: mandatoryPassword }
    );
  }

  public setNAOption(
    workflowUuid: string,
    versionUuid: string,
    stepUuid: string,
    isTemplate = false,
    naOption: number
  ): Observable<{ success: boolean }> {
    return this.apiService.post(
      `${isTemplate ? this.templatePath : this.runtimePath}/${workflowUuid}/version/${versionUuid}/TestScript/${stepUuid}/na-option`,
      { na_option: naOption }
    );
  }

  public setNonSequentialExecution(
    workflowUuid: string,
    versionUuid: string,
    stepUuid: string,
    isTemplate = false,
    nonSequentialExecution: boolean
  ): Observable<{ success: boolean }> {
    return this.apiService.post(
      `${isTemplate ? this.templatePath : this.runtimePath}/${workflowUuid}/version/${versionUuid}/TestScript/${stepUuid}/non-sequential-execution`,
      { non_sequential_execution: nonSequentialExecution }
    );
  }

}
