import { Injectable } from '@angular/core';
import { IApiPaginatedDataBase, IExtraQueryParameters, ISortQueryParameters } from '@core/interfaces';
import { IUser, IUserLite } from '@core/models';
import { Observable, map } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private apiService: ApiService) { }

  public getUsers(offset: number, limit: number, sortQueryParams: ISortQueryParameters, extraQueryParams?: IExtraQueryParameters): Observable<IApiPaginatedDataBase<IUser>> {
    const queryParams = this.apiService.addSortQueryParams(sortQueryParams);
    return this.apiService.get<IUser[]>(`/api/admin/${offset}/${limit}`, this.apiService.addExtraQueryParams(queryParams, extraQueryParams));
  }

  public searchBTRAdminUsers(offset: number, limit: number, sortQueryParams: ISortQueryParameters, extraQueryParams?: IExtraQueryParameters): any {
    const queryParams = this.apiService.addSortQueryParams(sortQueryParams);
    return this.apiService.get(`/api/admin/${offset}/${limit}`, this.apiService.addExtraQueryParams(queryParams, extraQueryParams)).pipe(map((res: IApiPaginatedDataBase<IUserLite>) =>  res.data));
  }

  public addUser(user: Partial<IUser>) {
    return this.apiService.post(`/api/admin`, user);
  }

  public updateUser(user: Partial<IUser>) {
    return this.apiService.put(`/api/admin/${user.uuid}`, user);
  }

  public deleteUser(user) {
    return this.apiService.delete(`/api/admin/${user.uuid}`);
  }

  public resetMFASettings(userUuid: string) {
    return this.apiService.post(`/api/admin/${userUuid}/mfa/reset`, {});
  }

  public resendTemporaryPassword(userUuid: string) {
    return this.apiService.post(`/api/admin/password/resend/${userUuid}`, {});
  }
}
