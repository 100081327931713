import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { MaterialModule } from '@core/modules/material.module';
import { ButtonsModule } from '@shared/components/buttons/buttons.module';
import { SaveCancelButtonModule } from '../save-cancel-button/save-cancel-button.module';
import { ClassificationsComponent } from './classifications.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { RangeIndicatorComponent } from './range-indicator/range-indicator.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    SaveCancelButtonModule,
    ButtonsModule,
  ],
  declarations: [
    ClassificationsComponent,
    ColorPickerComponent,
    RangeIndicatorComponent
  ],
  exports: [
    ClassificationsComponent,
    ColorPickerComponent,
    RangeIndicatorComponent
  ],
  providers: []
})
export class ClassificationsModule {
}
