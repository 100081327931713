import {
  ChangeDetectionStrategy, Component,
  Input
} from '@angular/core';
import { Router } from '@angular/router';
import { FILTERS_LOCAL_STORAGE_KEY } from '@core/constants/app-constants';
import { ICompanySystem } from '@core/interfaces';
import {
  RoutingService
} from '@core/services';
import { Store } from '@ngxs/store';
import { StateReset } from 'ngxs-reset-plugin';
import { map, take } from 'rxjs/operators';
import { AddActiveFilterWorkspace } from 'src/app/documents/workspace/store/workspace-filters.actions';
import { WorkspaceFiltersState } from 'src/app/documents/workspace/store/workspace-filters.state';

@Component({
  selector: 'app-system-card',
  templateUrl: './system-card.component.html',
  styleUrls: ['./system-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SystemCardComponent {
  @Input() system: ICompanySystem;

  constructor(private router: Router, private routingService: RoutingService, private store: Store) { }

  goToWorkflows() {
    this.store.dispatch(
      new StateReset(WorkspaceFiltersState)
    ).pipe(
      take(1),
      map(() => {
        const key = "filters.system_uuid";
        const value = [this.system.uuid];
        localStorage.setItem(FILTERS_LOCAL_STORAGE_KEY.RUNTIME, JSON.stringify([{ key, value }]));
        return this.store.dispatch(new AddActiveFilterWorkspace(key, value))
      }),
      take(1)
    ).subscribe(() => this.router.navigate([this.routingService.DOCUMENTS.url()]));
  }
}
