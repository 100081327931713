import { IFilterDataValues } from '../../core/interfaces';

export class GetPackages {
  static readonly type = '[Marketplace Packages] Get';
  constructor(public offset: number, public limit: number, public filters?: IFilterDataValues) {}
}

export class GetGlobalPackages {
  static readonly type = '[Marketplace Packages] Get Global Packages';
  constructor(public offset: number, public limit: number, public filters?: IFilterDataValues) {}
}

export class RemovePackage {
    static readonly type = '[Marketplace Packages] Remove Package';
    constructor(public id: number, public reason: string, public isAdmin: boolean) {}
}

export class StartDownloadStatusCheck {
  static readonly type = '[Marketplace Packages] Start Download Status Check';
  constructor(public workflowUuid: string, public versionUuid: string) {}
}

export class StopDownloadStatusCheck {
  static readonly type = '[Marketplace Packages] Stop Download Status Check';
}

export class StopImportDataLockStatusCheck {
  static readonly type = '[Marketplace Packages] Stop Import Data Lock Status Check';
}
