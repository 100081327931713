<div class="not-found-container">

  <div class="section-container gray-text margin-top-15">

    <mat-card appearance="outline" class="details-subsection flex-col">
      <div class="logo flex-100 justify-center items-center">
        <img src="../../../../assets/img/favicon.ico"/>
      </div>
      <h3 class="flex-100 justify-center">The requested resource was not found!</h3>
      <p class="text-center flex-100 justify-center">
        <span>Go to <a [routerLink]="routingService.HOME.url()">HOME</a></span>
      </p>
    </mat-card>
  </div>

</div>
