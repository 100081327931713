// Unique identifiers for Save dropdown
import { SdoxDocumentCategory } from '@core/interfaces';

export enum CanvasSaveMenuItemsId {
  LoadPreviousSave = 'LoadPreviousSave',
}

// Unique identifiers for Actions menu
export enum CanvasActionsMenuItemsId {
  Publish = 'Publish',
  Unpublish = 'Unpublish',
  SaveNewVersion = 'SaveNewVersion',
  LoadAnotherVersion = 'LoadAnotherVersion',
  VersionsList = 'VersionsList',
  Clone = 'Clone',
  ShowSettings = 'ShowSettings',
  AuditTrail = 'AuditTrail',
  Delete = 'Delete',
  CopyLink = 'CopyLink'
}

export enum CanvasFunctionalElementType {
  Summary = 'Summary',
  DrawConnector = 'DrawConnector',
  Switch = 'Switch',
  Impact = 'Impact',
  Implementation = 'Implementation',
  Completer = 'Completer',
  Start = 'Start',
  Initiation = 'Initiation',
  Table = 'Table',
  General_Table = 'General',
  Test_Script = 'TestScript',
  External = 'External',
  TraceMatrixTable = 'TraceMatrix',
  SectionTitle = 'SectionTitle',
}

export const LinkAllowableTypes = [CanvasFunctionalElementType.Table, CanvasFunctionalElementType.Test_Script];
export const DocumentAllowable = [SdoxDocumentCategory.Requirements, SdoxDocumentCategory.Test_Script];


//  NRESQ-3010 - FE type labels used in Runtime, Runtime sections and Canvas workflow preview
export enum CanvasFunctionalElementTypeLabel {
  Summary = 'Impact Assessment Summary',
  DrawConnector = 'DrawConnector',
  Switch = 'Switch',
  Impact = 'Impact Assessment',
  Implementation = 'Implementation',
  Completer = 'Completer',
  Start = 'Start',
  Initiation = 'Initiation',
  Table = 'Table',
  General_Table = 'General',
  Test_Script = 'TestScript',
  External = 'External',
  TraceMatrix = 'Trace Matrix Table',
  SectionTitle = 'Section Title'
}

export enum HeaderAndFooterPresets {
  Preset1 = 'preset-1',
  Preset2 = 'preset-2'
}

export enum HeaderAndFooterPresetLabels {
  'preset-1' = 'Preset 1',
  'preset-2' = 'Preset 2'
}

export enum ExternalFEViewModes {
  NoPreview = 'no-preview',
  Webviewer = 'webviewer',
  Upload = 'upload',
  Video = 'video'
}

export enum SettingsTabs {
  Settings = 'Settings',
  Metadata = 'Metadata'
}

export enum UserDetailsActions {
  TransferOwnership = 'transfer',
  Approve = 'approve',
  Reject = 'reject',
  SeeMembersList = 'seeMembersList',
}
