import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IApiPaginatedDataBase, ISortQueryParameters, ISignatureMeaning, ISignatureRole } from '../interfaces';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class SignatureService {
  private pathSignatureMeaning = '/api/signature-meaning';
  private pathSignatureRole = '/api/signature-role';

  constructor(private apiService: ApiService) { }

  public getSignatureMeaningList(offset: number, limit: number, sortQueryParams?: ISortQueryParameters): Observable<IApiPaginatedDataBase<ISignatureMeaning>> {
    return this.apiService.get<ISignatureMeaning[]>(`${this.pathSignatureMeaning}/${offset}/${limit}`, this.apiService.addSortQueryParams(sortQueryParams));
  }

  public addSignatureMeaning(signatureMeaning: ISignatureMeaning) {
    return this.apiService.post(this.pathSignatureMeaning, signatureMeaning);
  }

  public updateSignatureMeaning(signatureMeaning: ISignatureMeaning) {
    return this.apiService.patch(`${this.pathSignatureMeaning}/${signatureMeaning.uuid}`, signatureMeaning);
  }

  public deleteSignatureMeaning(signatureMeaning: ISignatureMeaning) {
    return this.apiService.delete(`${this.pathSignatureMeaning}/${signatureMeaning.uuid}`);
  }

  public getSignatureRoleList(offset: number, limit: number, sortQueryParams?: ISortQueryParameters): Observable<IApiPaginatedDataBase<ISignatureRole>> {
    return this.apiService.get<ISignatureRole[]>(`${this.pathSignatureRole}/${offset}/${limit}`, this.apiService.addSortQueryParams(sortQueryParams));
  }

  public addSignatureRole(signatureRole: ISignatureRole) {
    return this.apiService.post(this.pathSignatureRole, signatureRole);
  }

  public updateSignatureRole(signatureRole: ISignatureRole) {
    return this.apiService.patch(`${this.pathSignatureRole}/${signatureRole.uuid}`, signatureRole);
  }

  public deleteSignatureRole(signatureRole: ISignatureRole) {
    return this.apiService.delete(`${this.pathSignatureRole}/${signatureRole.uuid}`);
  }
}
