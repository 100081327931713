import { Injectable } from '@angular/core';
import { IApiPaginatedDataBase, IExtraQueryParameters, ISortQueryParameters } from '@core/interfaces';
import { Observable } from 'rxjs';
import { ILicense, ILicenseRequest } from '../interfaces/licenses.interface';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class LicensesService {
  private pathLicenseRequest = '/api/license-request';
  private companyPathLicenseRequest = '/api/company-license-request';

  constructor(private apiService: ApiService) { }

  public getLicenses(): Observable<ILicense> {
    return this.apiService.get('/api/current-company/licenses');
  }

  public getLicensesForCompany(companyUuid: string): Observable<ILicense> {
    return this.apiService.get(`/api/company/${companyUuid}/licenses`);
  }

  public getLicenseRequests(offset: number, limit: number, sortQueryParams: ISortQueryParameters, extraQueryParams: IExtraQueryParameters): Observable<IApiPaginatedDataBase<ILicenseRequest>> {
    const queryParams = this.apiService.addSortQueryParams(sortQueryParams)
    return this.apiService.get<ILicenseRequest[]>(`${this.pathLicenseRequest}/${offset}/${limit}`, this.apiService.addExtraQueryParams(queryParams, extraQueryParams));
  }

  public getLicenseRequestsForCompany(offset: number, limit: number, sortQueryParams?: ISortQueryParameters): Observable<IApiPaginatedDataBase<ILicenseRequest>> {
    return this.apiService.get<ILicenseRequest[]>(`${this.companyPathLicenseRequest}/${offset}/${limit}`, this.apiService.addSortQueryParams(sortQueryParams));
  }

  public createLicenseRequest(licenseRequest: Partial<ILicense> & { initiator_message: string }) {
    return this.apiService.post(this.companyPathLicenseRequest, licenseRequest);
  }

  public closeLicenseRequest(licenseRequestUuid: string, comment: string) {
    return this.apiService.post(`${this.companyPathLicenseRequest}/${licenseRequestUuid}/close`, { acceptor_comment: comment });
  }

  public rejectLicenseRequest(licenseRequestUuid: string, message: string) {
    return this.apiService.post(`${this.pathLicenseRequest}/${licenseRequestUuid}/reject`, { responder_message: message });
  }

  public approveLicenseRequest(licenseRequestUuid: string, message?: string) {
    return this.apiService.post(`${this.pathLicenseRequest}/${licenseRequestUuid}/approve`, { responder_message: message });
  }

  public updateLicensesForCompany(companyUuid: string, licenseRequest: Partial<ILicense> & { initiator_message: string }) {
    return this.apiService.put(`/api/company/${companyUuid}/licenses`, licenseRequest);
  }
}
