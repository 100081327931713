import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { MaterialModule } from '@core/modules/material.module';
import { ButtonsModule } from '../buttons/buttons.module';
import { FileUploadModule } from '../file-upload/file-upload.module';
import { MultiStepUploadFromCsvComponent } from './multi-step-upload-from-csv.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    CoreModule,
    MatExpansionModule,
    CommonModule,
    MaterialModule,
    ButtonsModule,
    ReactiveFormsModule,
    FileUploadModule,
    FormsModule,
  ],
  declarations: [
    MultiStepUploadFromCsvComponent
  ],
  exports: [
    MultiStepUploadFromCsvComponent
  ],
  providers: []
})
export class MultiStepUploadFromCsvModule {}
