<div class="offline" *ngIf="!isOnline">
  <span>You are currently offline</span>
</div>

<app-signature-password-banner
  *ngIf="loggedIn && !(isBtrAdmin$ | async) && (user$ | async) && !(hasSignaturePassword$ | async) && isSignaturePasswordBannerVisible">
</app-signature-password-banner>

<app-async-pdf-banner id="top-pdf-banner" *ngIf="loggedIn && files.length" [files]="files"></app-async-pdf-banner>
<app-main-nav *ngIf="loggedIn && (!router.url.includes('/missing-account:login')) && isMenuDisplayed && (!router.url.includes('/login') || router.url.includes('/audit-trail/') || router.url.includes('/reports/'))"></app-main-nav>
<div class="app-container flex-row flex-wrap"
     [class.has-pdf-banner]="files.length">
  <div *ngIf="loggedIn && isMenuDisplayed && (!router.url.includes('/login') || router.url.includes('/audit-trail/') || router.url.includes('/reports/'))"
       [class]="(isFullScreen$ | async) ? 'flex-0' : (isMenuOpened$ | async) ? 'flex-fixed-210' : 'flex-8'">
  </div>
  <div *ngIf="contentFlexSize$ | async as contentFlexSize"
       class="app-content flex-col justify-between"
       [class.padding-right-10]="isMenuOpened$ | async"
       [class]="getContentClass(contentFlexSize)">
    <div class="router-container">
      <router-outlet></router-outlet>
    </div>
    <app-footer class="flex-row justify-between items-center"
                *ngIf="loggedIn && (!router.url.includes('/login') || router.url.includes('/audit-trail/') || router.url.includes('/reports/'))">
    </app-footer>
  </div>
  <a href="{{ environment.honeypot }}" rel="nofollow" style="display: none" aria-hidden="true">honeypot link</a>
</div>

<div id="hubspot-container" [class.menu-open]="isMenuOpened$ | async"></div>
