import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { MaterialModule } from '@core/modules/material.module';
import { PipesModule } from '@core/pipes/pipes.module';
import { AccessEntitiesListModule } from '../access-entities-list/access-entities-list.module';
import { ButtonsModule } from '../buttons/buttons.module';
import { FilterTagsModule } from '../filter-tags/filter-tags.module';
import { SaveCancelButtonModule } from '../save-cancel-button/save-cancel-button.module';
import { SelectPrefixModule } from '../select-prefix/select-prefix.module';
import { RequestedAccessPanelModule } from '../settings-accordion/requested-access-panel/requested-access-panel.module';
import { UserDetailsModule } from '../user-details/user-details.module';
import { UserGroupDepartmentSearchModule } from '../user-group-department-search/user-group-department-search.module';
import { DocumentNamingPatternComponent } from './document-naming-pattern/document-naming-pattern.component';
import { FeConfigurationComponent } from './fe-configuration/fe-configuration.component';
import { SettingsMetadataComponent } from './settings-metadata.component';
import { MetadataPanelModule } from './metadata-panel/metadata-panel.module';
import { LoadingAnimationModule } from '../loading-animation/loading-animation.module';

@NgModule({
  declarations: [
    SettingsMetadataComponent,
    DocumentNamingPatternComponent,
    FeConfigurationComponent,
  ],
  exports: [
    SettingsMetadataComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    MetadataPanelModule,
    SaveCancelButtonModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    SelectPrefixModule,
    RequestedAccessPanelModule,
    UserDetailsModule,
    UserGroupDepartmentSearchModule,
    FilterTagsModule,
    AccessEntitiesListModule,
    ButtonsModule,
    PipesModule,
    LoadingAnimationModule
  ],
})
export class SettingsMetadataModule { }
