export const IGNORE_TAGS = [
  'SCRIPT',
  'STYLE',
  'SELECT',
  'OPTION',
  'BUTTON',
  'OBJECT',
  'APPLET',
  'VIDEO',
  'AUDIO',
  'CANVAS',
  'EMBED',
  'PARAM',
  'METER',
  'PROGRESS'
];

export const HIGHLIGHT_CLASS = 'highlighted';
export const SELECTED_CLASS = 'highlight-selected';
export const PENDING_COMMENT_HIGHLIGHT_CLASS = 'pending-comment';
export const POSTED_COMMENT_HIGHLIGHT_CLASS = 'posted-comment';
export const GID_ATTR = 'data-highlight-gid';
export const STEP_UUID_ATTR = 'data-step-uuid';
export const ROW_UUID_ATTR = 'data-row-uuid';
export const COLUMN_KEY_ATTR = 'data-column-key';
export const CELL_HIGHLIGHT_SELECTED_TEXT = 'CELL_HIGHLIGHT';

export const NODE_TYPE = {
  ELEMENT_NODE: 1,
  TEXT_NODE: 3
};

export enum ReviewTargetType {
  TEXT_HIGHLIGHT = 'TEXT_HIGHLIGHT',
  GENERAL_FE = 'GENERAL_FE_HIGHLIGHT',
  ITEM_TITLE = 'SDOX_ITEM_TITLE',
  CELL_HIGHLIGHT = 'CELL_HIGHLIGHT'
}

export interface HighlightResult {
  createdHighlights: HTMLElement[];
  error: string;
}

export interface ParentReviewTarget {
  node: HTMLElement;
  type: ReviewTargetType;
}

export const IGNORE_HIGHLIGHT_TAGS = [
  'app-links',
  'app-table-impact-assessment-cell',
  'app-pass-fail-cell'
];
