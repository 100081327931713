import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IPermissionsUpdateRequest,
  IPermissionsUpdateSignature,
  IRolePermissions,
  IRolePermissionsPermission, ISignatureRequest
} from '../interfaces';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PermissionMatrixService {
  private path = '/api/permission-matrix/';
  private pathPermissionMatrixLite = '/api/permission-matrix-lite/';

  constructor(private apiService: ApiService) {
  }

  public getPermissionMatrix(): Observable<IRolePermissions[]> {
    return this.apiService.get(this.path).pipe(map(res => res.data));
  }

  public getPermissionMatrixLite(): Observable<IRolePermissions[]> {
    return this.apiService.get(this.pathPermissionMatrixLite).pipe(map(res => res.data));
  }

  // TO DO AT: Delete this method when integration is done, rename savePermissionMatrix to updatePermissionMatrix
  public updatePermissionMatrix(permission: IRolePermissionsPermission): Observable<IRolePermissionsPermission> {
    return this.apiService.put(this.path + permission.uuid, permission);
  }

  public savePermissionMatrix(permission: IPermissionsUpdateRequest): Observable<IRolePermissions[]> {
    return this.apiService.patch(this.path, permission).pipe(
      map(res => res.data)
    );
  }

  resetPermissionMatrix(credentials: ISignatureRequest): Observable<IRolePermissions[]> {
    return this.apiService.post(this.path + 'reset-to-default', credentials);
  }
}
