import { Injectable } from '@angular/core';
import {
  AttachGroupToAccessListRequest,
  AttachUserToAccessListRequest,
  BulkAssignRequest,
  BulkShareRequest,
  BulkTransferOwnershipRequest,
  DetachGroupFromAccessListRequest,
  DetachUserFromAccessListRequest,
  IAccessListRequests,
  IApiPaginatedDataBase,
  ISortQueryParameters,
  IWorkflowType,
  RuntimeAccessListRequest,
  TemplateAccessListRequest,
  TemplateAccessRequestType,
  TransferOwnershipRequest,
  UserGroupsResource,
  WorkflowAccessListForUserResponse,
  WorkflowAccessListResponse,
  WorkflowRequestAccessDetailsResponse
} from '@core/interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AclService {
  constructor(private apiService: ApiService) { }

  public requestDocumentAccess(payload: TemplateAccessListRequest | RuntimeAccessListRequest, type: IWorkflowType):
    Observable<{ success: boolean }> {
    return this.apiService.post(`/api/${type}/workflow-access-list/request`, payload);
  }

  public transferOwnership(payload: TransferOwnershipRequest, type: string): Observable<{ success: boolean }> {
    return this.apiService.post(`/api/${type}/workflow-access-list/transfer-ownership`, payload);
  }

  public bulkShare(payload: BulkShareRequest, type: string): Observable<{ success: boolean }> {
    return this.apiService.post(`/api/${type}/workflow-access-list/bulk-share`, payload);
  }

  public bulkTransferOwnership(payload: BulkTransferOwnershipRequest, type: string): Observable<{ success: boolean }> {
    return this.apiService.post(`/api/${type}/workflow-access-list/bulk-transfer-ownership`, payload);
  }

  public bulkAssign(payload: BulkAssignRequest): Observable<{ success: boolean }> {
    return this.apiService.post(`/api/document-assignments/bulk-assign`, payload);
  }

  public getAccessRequests(type: IWorkflowType, uuid: string): Observable<IAccessListRequests[]> {
    return this.apiService.get(`/api/${type}/workflow-access-list/requests/${uuid}`)
      .pipe(
        map(res => res.data)
      );
  }

  public approveRequestAccess(type: IWorkflowType, requestUuid: string): Observable<{ success: boolean }> {
    return this.apiService.post(`/api/${type}/workflow-access-list/approve/${requestUuid}`, {});
  }

  public rejectRequestAccess(type: IWorkflowType, requestUuid: string): Observable<{ success: boolean }> {
    return this.apiService.post(`/api/${type}/workflow-access-list/reject/${requestUuid}`, {});
  }

  public getAccessList(type: IWorkflowType, workflowUuid: string, accessType?: TemplateAccessRequestType): Observable<WorkflowAccessListResponse> {
    return this.apiService.get(`/api/${type}/workflow-access-list/list/${workflowUuid}${accessType ? ('?type=' + accessType) : ''}`)
      .pipe(map(res => res.data));
  }

  public attachUserToAccessList(type: string, payload: AttachUserToAccessListRequest, workflowRootUuid: string): Observable<{ success: boolean }> {
    return this.apiService.post(`/api/${type}/workflow-access-list/attach-user/${workflowRootUuid}`, payload);
  }

  public attachGroupToAccessList(type: string, payload: AttachGroupToAccessListRequest, workflowRootUuid: string): Observable<{ success: boolean }> {
    return this.apiService.post(`/api/${type}/workflow-access-list/attach-group/${workflowRootUuid}`, payload);
  }

  public detachUserFromAccessList(type: string, payload: DetachUserFromAccessListRequest, workflowRootUuid: string): Observable<{ success: boolean }> {
    return this.apiService.post(`/api/${type}/workflow-access-list/detach-user/${workflowRootUuid}`, payload);
  }

  public detachGroupFromAccessList(type: string, payload: DetachGroupFromAccessListRequest, workflowRootUuid: string): Observable<{ success: boolean }> {
    return this.apiService.post(`/api/${type}/workflow-access-list/detach-group/${workflowRootUuid}`, payload);
  }

  public getWorkflowAccessList(offset: number, limit: number, sortQueryParams: ISortQueryParameters, userId: string, type: string): Observable<IApiPaginatedDataBase<WorkflowAccessListForUserResponse>> {
    return this.apiService.get(`/api/workflow-access-list/user/${userId}/${offset}/${limit}?type=${type}`, this.apiService.addSortQueryParams(sortQueryParams))
  }

  public getUserGroups(userId: string): Observable<UserGroupsResource[]> {
    return this.apiService.get(`/api/groups/user/${userId}`)
      .pipe(map(res => res.data));
  }

  public getCurrentUserGroup(): Observable<UserGroupsResource[]> {
    return this.apiService.get(`/api/groups/current-user`)
      .pipe(map(res => res.data));
  }

  public getAccessRequestWorkflowInfo(type: string, versionUuid: string): Observable<WorkflowRequestAccessDetailsResponse> {
    return this.apiService.get(`/api/${type}/workflow-access-list/details/${versionUuid}`);
  }
}
