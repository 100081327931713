import { Injectable } from '@angular/core';
import { updateDatepickerFormat } from '@core/providers/custom-datepicker-format';


@Injectable({
  providedIn: 'root'
})
export class CompanyCustomDateFormatService {

  readonly COMPANY_DATE_FORMATS = [
    {
      value: 'MMM D, YYYY',
      label: 'Dec 11, 2000 - MMM DD, YYYY'
    },
    {
      value: 'D MMM, YYYY',
      label: '11 Dec, 2000 - DD MMM, YYYY'
    },
    {
      value: 'M/D/YYYY',
      label: '11/11/2000 - MM/DD/YYYY'
    },
    {
      value: 'D/M/YYYY',
      label: '11/11/2000 - DD/MM/YYYY'
    },
    {
      value: 'YYYY-MM-DD',
      label: '2000-11-11 - YYYY-MM-DD'
    }
  ]

  public updateDatepickerFormat(companyDateFormat: string) {
    if (this.COMPANY_DATE_FORMATS.map(dateFormat => dateFormat.value).find(format => format === companyDateFormat)) {
      updateDatepickerFormat(companyDateFormat);
    }
  }
}
