import { ICompanyNotificationSettings, ICompanySelectItem, NotificationType } from '../interfaces';

export class NotificationSettings {
  uuid: string;
  type: NotificationType;
  isRecurrent: boolean;
  intervalSeconds: number;
  notificationLimit: number;
  description: string;
  companyUuid: string;
  createdAt: string;
  updatedAt: string;

  constructor(settings: ICompanyNotificationSettings) {
    this.uuid = settings.uuid;
    this.type = settings.type;
    this.isRecurrent = settings.is_recurrent;
    this.intervalSeconds = settings.interval_seconds;
    this.notificationLimit = settings.notification_limit;
    this.description = settings.description;
    this.companyUuid = settings.company_uuid;
    this.createdAt = settings.created_at;
    this.updatedAt = settings.updated_at;
  }

  public toDTO(): ICompanyNotificationSettings {
    const dto = {
      uuid: this.uuid,
      type: this.type,
      is_recurrent: this.isRecurrent,
      interval_seconds: this.intervalSeconds,
      notification_limit: this.notificationLimit,
      description: this.description,
      company_uuid: this.companyUuid
    };

    return dto;
  }

  /*
  * Returns the time (hour/day) depending on interval seconds value
  * */
  public getTimeFromInterval(): ICompanySelectItem {
    const daySec = 86400; // seconds within a day

    if (this.intervalSeconds < daySec) {
      return {
        value: 3600,
        viewValue: 'hour(s)'
      };
    } else {
      const daySequence =  this.intervalSeconds / daySec;
      if (daySequence - Math.floor(daySequence) !== 0) {
        return { // if the result is decimal we are returning the hour time
          value: 3600,
          viewValue: 'hour(s)'
        };
      } else {
        return {
          value: 86400,
          viewValue: 'day(s)'
        };
      }
    }
  }

  /*
  * Returns the sequence depending on interval seconds value
  * Repeat every "x" hour(s)
  * x = 7200 / 3600 = 2;
  * Result: Repeat every 2 hour(s)
  *
  * x = 90 000 / 86400 = 1.041
  * Result: Repeat every 25 hour(s)
  * */
  public getSequenceFromInterval(): number {
    const hourSec = 3600; // seconds within an hour
    const daySec = 86400; // seconds within a day

    if (this.intervalSeconds < daySec) {
      return this.intervalSeconds / hourSec;
    } else {
      const daySequence =  this.intervalSeconds / daySec;
      if (daySequence - Math.floor(daySequence) !== 0) {
        return this.intervalSeconds / hourSec; // if the result is decimal we are returning the hour sequence
      } else {
        return daySequence;
      }
    }
  }
}
