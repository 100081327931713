export enum PodStatus {
  USED = 'used',
  FREE = 'free',
  PENDING = 'pending',
  RESERVED = 'reserved'
}

export enum PodTransferStatus {
  SCHEDULED = 'scheduled',
  IN_PROGRESS = 'in_progress',
  CANCELLED = 'cancelled',
  COMPLETED = 'completed',
  FAILED = 'failed'
}
