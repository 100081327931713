import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

// Usage:
// - when the text overflows vertically, use just 'appTooltipWhenEllipsis' (default)
// - when the text overflows horizontally, use 'appTooltipWhenEllipsis="horizontal"'
@Directive({
  selector: '[matTooltip][appTooltipWhenEllipsis]'
})
export class TooltipWhenEllipsisDirective {

  @Input() appTooltipWhenEllipsis: string;

  constructor(private matTooltip: MatTooltip,
              private elementRef: ElementRef<HTMLElement>
  ) { }

  @HostListener('mouseenter', ['$event'])
  setTooltipState(): void {
      const element = this.elementRef.nativeElement;
      if (this.appTooltipWhenEllipsis === 'horizontal') {
        this.matTooltip.disabled = element.scrollWidth <= element.clientWidth;
      } else {
        this.matTooltip.disabled = element.scrollHeight <= element.clientHeight;
      }
  }
}
