import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { Attachment, TableAttachmentsInitDefaults, UploadAttachmentType } from '@core/interfaces/attachment-type.interface';
import { CanvasService } from './canvas.service';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FILE_TYPES_WITH_THUMBNAIL } from '@core/constants/app-constants';
import { StartAsyncPdfCheck } from '@store/async-pdf/async-pdf.actions';
import { AsyncFileResponseStatus } from '@store/async-pdf/async-pdf.model';
import { AsyncPdfSelectors } from '@store/async-pdf/async-pdf.selectors';
import { CompanySelectors } from '@store/company/company.selectors';
import { UserSelectors } from '@store/user/user.selectors';
import { ToastrService } from 'ngx-toastr';
import { take, finalize, tap } from 'rxjs';
import { ApiService } from './api.service';
import { PrintingService } from './printing.service';
import { RuntimeService } from './runtime.service';
import { VideoService } from './video.service';
import * as _ from 'lodash';
import { Store } from '@ngxs/store';
import { WEBVIEWER_ALLOWED_FILE_TYPES, DOC_FILE_TYPES, XLS_FILE_TYPES, PPT_FILE_TYPES, CSV_FILE_TYPES } from '@core/constants/app-constants';

export enum ITableAttachmentsSources {
  Attachment = 'attachment',
  Pass = 'pass',
  Deviation = 'deviation'
}
@Injectable({
  providedIn: 'root'
})
export class TableAttachmentsService {
  readonly webviewerAllowedFileTypes = WEBVIEWER_ALLOWED_FILE_TYPES;
  readonly docFileTypes = DOC_FILE_TYPES;
  readonly xlsFileTypes = XLS_FILE_TYPES;
  readonly pptFileTypes = PPT_FILE_TYPES;
  readonly csvFileTypes = CSV_FILE_TYPES;

  isViewMode: boolean;
  filesCount: number;
  documentTitle: string;
  sectionTitle: string;
  stepTitle: string;
  versionUuid: string;
  rowUuid: string;
  workflowUuid: string;
  stepUuid: string;
  attachmentDataFromDetailsDeviationsModal: Attachment;
  attachmentDataFromPassFailRowThumbnails: boolean;
  hideSelectAttachmentSection: boolean;
  clickedThumbnailIndex: number;
  downloadRequestUrl: string;
  isSignStepOrSubmitDeviation: boolean;
  attachments: Attachment[]
  source: ITableAttachmentsSources;

  isLoading: boolean = false;
  isQueueLoading: boolean = false;
  isLoadingAttachment: boolean = false;
  originalAttachment$: Subscription;
  initialAttachmentsArray: Attachment[] = [];
  totalFileSize: number = 0;
  isLoadingWebviewer = false;
  selectedAttachmentIndex = null;
  selectedAttachmentData: File | Blob | SafeResourceUrl = null;
  videoUrl: string;

  selectedAttachmentName: string;
  selectedAttachmentIsWebviewerValid = false;
  selectedAttachmentIsNonWebviewerImage = false;
  selectedAttachmentIsCSV = false;
  selectedAttachmentIsVideo = false;
  selectedAttachmentIsScreenRecording = false;
  pageSize = 10;
  pageNumber = 0;
  apiUrl: string;
  isOpenedFromDetailsModal: boolean;
  canPreviewVideos = false;
  temporaryIdCounter = 0;

  fileNamingSequenceCounter = 1;
  initialFileNamingSequenceCounter = 1;
  isDeviation = false;

  get fileTypeCannotBePreviewed() {
    return !this.selectedAttachmentIsNonWebviewerImage &&
      !this.selectedAttachmentIsWebviewerValid && !this.selectedAttachmentIsCSV && !this.videoCanBePreviewed;
  }

  get videoCanBePreviewed() {
    return this.selectedAttachmentIsVideo && (this.canPreviewVideos || this.selectedAttachmentIsScreenRecording);
  }

  get isFakeWebviewer() {
    return this.selectedAttachmentData && this.attachments.length > 0 &&
      !this.selectedAttachmentIsWebviewerValid && !this.isLoading &&
      (this.selectedAttachmentIsNonWebviewerImage || this.selectedAttachmentIsCSV)
  }

  constructor(
    private store: Store,
    private canvasService: CanvasService,
    private _sanitizer: DomSanitizer,
    private printingService: PrintingService,
    private toastr: ToastrService,
    private apiService: ApiService,
    private runtimeService: RuntimeService,
    private dialog: MatDialog,
    private videoService: VideoService,
  ) { }

  /**
   * Should be called when component is initialized (Attachments Dialog is Open or Attachments Preview Page is Opened)
   */
  public init(defaults: TableAttachmentsInitDefaults) {
    this.isViewMode = defaults.isViewMode;
    this.filesCount = defaults.filesCount;
    this.documentTitle = defaults.documentTitle;
    this.sectionTitle = defaults.sectionTitle;
    this.stepTitle = defaults.stepTitle;
    this.versionUuid = defaults.versionUuid;
    this.rowUuid = defaults.rowUuid;
    this.workflowUuid = defaults.workflowUuid;
    this.stepUuid = defaults.stepUuid;
    this.attachmentDataFromDetailsDeviationsModal = defaults.attachmentDataFromDetailsDeviationsModal;
    this.attachmentDataFromPassFailRowThumbnails = defaults.attachmentDataFromPassFailRowThumbnails;
    this.hideSelectAttachmentSection = defaults.hideSelectAttachmentSection;
    this.clickedThumbnailIndex = defaults.clickedThumbnailIndex;
    this.downloadRequestUrl = defaults.downloadRequestUrl;
    this.isSignStepOrSubmitDeviation = defaults.isSignStepOrSubmitDeviation;
    this.attachments = defaults.attachments;
    this.source = defaults.source;
    this.isDeviation = defaults.isDeviation;
    this.canPreviewVideos = this.store.selectSnapshot(CompanySelectors.getCurrentCompany).config.videoStreaming;
    this.selectedAttachmentIndex = null;

    if (!this.isViewMode) {
      this.runtimeService.getFileSequenceNumber(this.workflowUuid, this.versionUuid).subscribe(res => {
        this.fileNamingSequenceCounter = (this.isDeviation ? res.execution_deviation : res.execution_attachment) + 1;
        this.initialFileNamingSequenceCounter = this.fileNamingSequenceCounter;
      })
    }

    if (this.filesCount) {
      this.isLoading = true;
      if (this.isSignStepOrSubmitDeviation) {
        if (this.attachments?.length) {
          this.attachments.map((attachment) => {
            this.totalFileSize += attachment?.original_attachment?.size;
          })
          this.selectAttachmentClick(this.attachments[this.clickedThumbnailIndex], this.clickedThumbnailIndex);
        }
      } else if (this.attachmentDataFromPassFailRowThumbnails) {
        this.runtimeService.getPassDetails(this.workflowUuid, this.versionUuid, this.stepUuid, this.rowUuid).pipe(
          take(1)
        ).subscribe(res => {
          this.attachments = res.attachments.map((attachment: any) => {
            const imgType = attachment?.mime_type === 'image/svg+xml' ? 'image/svg+xml' : 'image/jpg';
            return {
              name: attachment.name,
              file_uuid: attachment.file_uuid,
              thumbnail: FILE_TYPES_WITH_THUMBNAIL.includes(attachment?.mime_type) ? this._sanitizer.bypassSecurityTrustResourceUrl(`data:${imgType};base64,` + attachment.file_content) : false,
              content: attachment.file_content,
              file_size: attachment.file_size,
              extension: attachment.name?.split('.').pop(),
              full_path: attachment.full_path,
              mime_type: attachment.mime_type,
              sequence_filename: attachment.sequence_filename ? attachment.sequence_filename : attachment.name
            };
          });
          this.selectAttachmentClick(this.attachments[this.clickedThumbnailIndex], this.clickedThumbnailIndex);
        },
          (err) => {
            this.isLoading = false;
          });
      } else if (this.attachmentDataFromDetailsDeviationsModal) {
        this.attachments.push(this.attachmentDataFromDetailsDeviationsModal)
        this.selectAttachmentClick(this.attachments[this.clickedThumbnailIndex], this.clickedThumbnailIndex);
      } else {
        if (this.source === ITableAttachmentsSources.Attachment) {
          this.canvasService.getThumbnails(this.workflowUuid, this.versionUuid, this.stepUuid, this.rowUuid).pipe(
            take(1)
          ).subscribe(res => {
            this.attachments = res.data.map(attachment => {
              const imgType = attachment?.mime_type === 'image/svg+xml' ? 'image/svg+xml' : 'image/jpg';
              this.totalFileSize += attachment.file_size;
              return {
                name: attachment.original_filename,
                file_uuid: attachment.file_uuid,
                thumbnail: FILE_TYPES_WITH_THUMBNAIL.includes(attachment?.mime_type) ? this._sanitizer.bypassSecurityTrustResourceUrl(`data:${imgType};base64,` + attachment.file_content) : false,
                content: attachment.file_content,
                file_size: attachment.file_size,
                extension: attachment.original_filename?.split('.').pop(),
                full_path: attachment.full_path,
                mime_type: attachment.mime_type,
                sequence_filename: attachment.sequence_filename ? attachment.sequence_filename : attachment.name
              };
            });
            this.initialAttachmentsArray = this.attachments ? _.cloneDeep(this.attachments) : [];
            this.selectAttachmentClick(this.attachments[this.clickedThumbnailIndex], this.clickedThumbnailIndex);
            this.isLoading = false;
          },
            (err) => {
              this.isLoading = false;
              console.error(err);
            });
        } else {
          this.attachments = this.attachments.map(attachment => {
            const imgType = attachment?.mime_type === 'image/svg+xml' ? 'image/svg+xml' : 'image/jpg';
            this.totalFileSize += attachment.file_size;
            return {
              name: attachment.original_filename,
              file_uuid: attachment.file_uuid,
              thumbnail: FILE_TYPES_WITH_THUMBNAIL.includes(attachment?.mime_type) ? this._sanitizer.bypassSecurityTrustResourceUrl(`data:${imgType};base64,` + attachment.file_content) : false,
              content: attachment.file_content,
              file_size: attachment.file_size,
              extension: attachment.original_filename?.split('.').pop(),
              full_path: attachment.full_path,
              mime_type: attachment.mime_type,
              sequence_filename: attachment.sequence_filename ? attachment.sequence_filename : attachment.name
            };
          });
          this.initialAttachmentsArray = this.attachments ? _.cloneDeep(this.attachments) : [];
          this.selectAttachmentClick(this.attachments[this.clickedThumbnailIndex], this.clickedThumbnailIndex);
          this.isLoading = false;
        }
      }
    }
  }

  saveAttachmentData(data: FileList) {
    Array.from(data).forEach((item, index) => this.createAttachmentFromBlob(item, index, data.length));
  }

  downloadFile(attachment?: Attachment): void {
    const originalAttachment = attachment ? attachment : this.attachments[this.selectedAttachmentIndex];
    if (!this.selectedAttachmentData) {
      this.loadAttachmentData(originalAttachment, () => this.downloadFile());
    } else {
      if (!this.attachments[this.selectedAttachmentIndex].original_attachment) {
        (this.attachments[this.selectedAttachmentIndex].original_attachment as any) = this.selectedAttachmentData;
      }
      const a = document.createElement('a');
      a.href = URL.createObjectURL(originalAttachment.original_attachment);
      a.download = originalAttachment.sequence_filename ? originalAttachment.sequence_filename : originalAttachment.name;
      document.body.appendChild(a);
      a.click();
    }
  }

  removeAllAttachments() {
    this.attachments = [];
    this.totalFileSize = 0;
    this.fileNamingSequenceCounter = 1;
    this.selectAttachmentClick(null);
  }

  selectAttachmentClick(attachment: Attachment, index?: number) {
    if (this.selectedAttachmentIndex === index) {
      return;
    }
    if (this.originalAttachment$) {
      this.originalAttachment$.unsubscribe();
    }

    if (!attachment) {
      this.selectedAttachmentIndex = null;
      this.isLoadingAttachment = false;
      return;
    }

    this.selectedAttachmentData = null;
    this.videoUrl = null;
    this.selectedAttachmentIndex = index;
    this.isLoadingAttachment = true;
    this.selectedAttachmentName = this.attachments[index].sequence_filename ? this.attachments[index].sequence_filename : this.attachments[index].name;
    const extractedFileExtension = this.selectedAttachmentName?.split('.').pop();

    this.selectedAttachmentIsCSV = this.csvFileTypes.includes(extractedFileExtension);
    this.selectedAttachmentIsNonWebviewerImage = FILE_TYPES_WITH_THUMBNAIL.includes(attachment?.mime_type || attachment?.original_attachment?.type);
    this.selectedAttachmentIsVideo = this.isAttachmentVideoType(attachment) || attachment?.is_screen_recording;
    this.selectedAttachmentIsScreenRecording = attachment?.is_screen_recording;
    if (this.selectedAttachmentIsCSV) {
      this.isLoadingAttachment = true;
    }

    this.selectedAttachmentIsWebviewerValid = this.webviewerAllowedFileTypes.includes(extractedFileExtension?.toLowerCase());
    if (this.selectedAttachmentIsWebviewerValid) {
      this.isLoadingWebviewer = true;
    }

    if (this.isSignStepOrSubmitDeviation) {
      this.getSelectedAttachment(attachment, index);
      return;
    }

    if (this.attachmentDataFromPassFailRowThumbnails) {
      this.getFileForIndividualPassFailRowThumbnail(attachment, index);
    } else if (attachment.file_uuid) {
      // for videos a URL will be used to play the video instead of downloading the blob
      if (this.videoCanBePreviewed && this.selectedAttachmentIsVideo) {
        this.getVideoUrl(attachment);
        // only download file data if it can be previewed, otherwise data will be lazy loaded
      } else if (!this.fileTypeCannotBePreviewed) {
        this.loadAttachmentData(attachment);
      } else {
        // no data is needed to display the download option
        this.isLoading = false;
        this.isLoadingAttachment = false;
      }
    } else {
      this.getSelectedAttachment(attachment, index);
    }
  }

  private getVideoUrl(attachment: Attachment) {
    this.videoService.getStreamingLink(attachment.file_uuid)
      .subscribe(res => {
        this.videoUrl = res.file;
        this.isLoading = false;
        this.isLoadingAttachment = false;
      });
  }

  private loadAttachmentData(attachment, cb? /* used to perform action after loading the data - e.g.: download*/) {
    if (attachment?.original_attachment) {
      this.selectedAttachmentData = attachment.original_attachment;
      this.isLoading = false;
      this.isLoadingAttachment = false;
      if (cb) {
        cb();
      }
    } else {
      // if entered from pass fail row thumbnails the attachment route doesn't work
      if (this.attachmentDataFromPassFailRowThumbnails) {
        this.getFileForIndividualPassFailRowThumbnail(attachment, this.selectedAttachmentIndex, cb, true);
      } else {
        const deviationDownloadRequestUrl = `/api/runtime-user-workflow/${this.workflowUuid}/version/${this.workflowUuid}/ts-execute/${this.stepUuid}/${this.rowUuid}/deviation/file/${attachment.file_uuid}`;
        const passDownloadRequestUrl = `/api/runtime-user-workflow/${this.workflowUuid}/version/${this.workflowUuid}/ts-execute/${this.stepUuid}/${this.rowUuid}/pass/file/${attachment.file_uuid}`;
        const isFromDeviation = this.source === ITableAttachmentsSources.Deviation;
        const isFromPass = this.source === ITableAttachmentsSources.Pass;
        let request;

        if (this.attachmentDataFromDetailsDeviationsModal) {
          request = this.canvasService.getExternalDeliverable(this.downloadRequestUrl);
        } else if (isFromDeviation) {
          request = this.canvasService.getExternalDeliverable(deviationDownloadRequestUrl);
        } else if (isFromPass) {
          request = this.canvasService.getExternalDeliverable(passDownloadRequestUrl);
        } else {
          request = this.canvasService.getOriginalAttachment(this.workflowUuid, this.versionUuid, this.stepUuid, this.rowUuid, attachment.file_uuid)
        }

        this.originalAttachment$ = request.pipe(take(1)).subscribe(res => {
          if (this.attachmentDataFromDetailsDeviationsModal || isFromPass || isFromDeviation) {
            res = new Blob([res]);
          }

          if (this.selectedAttachmentIsWebviewerValid) {
            this.selectedAttachmentData = res;

            if (cb) {
              cb();
            }
          } else {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
              this.attachments[this.selectedAttachmentIndex] = {
                ...this.attachments[this.selectedAttachmentIndex],
                original_attachment: res
              };

              this.selectedAttachmentData = this.csvFileTypes.includes(attachment.extension)
                ? this.attachments[this.selectedAttachmentIndex].original_attachment
                : this._sanitizer.bypassSecurityTrustResourceUrl(reader.result as string)

              if (cb) {
                cb();
              }
            }, false);

            if (res) {
              reader.readAsDataURL(res);
            }
          }
          this.isLoading = false;
          this.isLoadingAttachment = false;
          this.selectedAttachmentIsNonWebviewerImage = FILE_TYPES_WITH_THUMBNAIL.includes(res.type) || FILE_TYPES_WITH_THUMBNAIL.includes(attachment.mime_type) ? true : false;
        },
          (err) => {
            this.isLoading = false;
          });
      }
    }
  }

  removeAttachment(event, index) {
    event.stopPropagation();
    this.totalFileSize -= this.attachments[index].file_size;
    // Business logic:
    // If a file is removed the sequence continues (eg: file_6 and the user got to file_7 the sequence continues without _6 - so the
    // user will have file_1, file_2,file_3,file_4,file_5,file_7,file_8 etc), but if the user removes all files until _6 so for example
    // the user added a total of 10 files and they remove _10, _9, _8, _7, _6 and then start to add files the numbering will continue from _6
    const deletedAttachmentSequence = Number(this.attachments[index]?.sequence_filename?.split('_').pop().split('.')[0]);
    const isLast = this.attachments.length === index + 1;
    if (deletedAttachmentSequence && deletedAttachmentSequence + 1 === this.fileNamingSequenceCounter) {
      if (isLast) {
        if (this.attachments.length > 1) {
          const previousElementAttachmentSequence = Number(this.attachments[index-1]?.sequence_filename?.split('_').pop().split('.')[0]);
          if (deletedAttachmentSequence - previousElementAttachmentSequence > 1) {
            const difference = deletedAttachmentSequence - previousElementAttachmentSequence;
            this.fileNamingSequenceCounter -= difference;
          } else {
            this.fileNamingSequenceCounter -= 1;
          }
        } else if (this.attachments.length === 1 ) {
          this.fileNamingSequenceCounter -= 1;
        } else {
          this.fileNamingSequenceCounter = 1;
        }
      }
    }
    this.attachments = this.attachments.filter((attachment, i) => i !== index);
    this.selectedAttachmentIndex = null;
    if (this.attachments.length) {
      this.selectAttachmentClick(this.attachments[0], 0);
    } else {
      this.selectAttachmentClick(null);
    }
  }

  downloadAllAttachments() {
    const generatedAttachments = this.store.selectSnapshot(AsyncPdfSelectors.getGeneratedFiles);
    const generatedAttachmentsInProgress = generatedAttachments.find(attachment => attachment.workflow_uuid === this.versionUuid
      && attachment.workflow_version_uuid === this.workflowUuid
      && attachment.status === AsyncFileResponseStatus.PENDING
      && attachment.row_uuid === this.rowUuid
    );
    // The above IDs are mismatched on purpose (workflow_uuid === version_uuid) due to an existing bug in the entire app where
    // we use the above code logic (we swap them in printing).
    // TODO: Fix bug and retest entire app where printing is used with the above selector.

    if (generatedAttachmentsInProgress) {
      this.toastr.error('There is already a file generation in progress for this step.');
    } else {
      this.isQueueLoading = true;
      this.toastr.info('Exporting files as a ZIP archive.');
      const mode = this.attachmentDataFromPassFailRowThumbnails ? 'details-modal' : 'attachment';

      const attachmentData = {
        mode,
        files: this.attachments,
        version_root_uuid: this.versionUuid,
        uuid: this.workflowUuid,
        row_uuid: this.rowUuid,
        document_title: this.documentTitle + '_' + this.sectionTitle + '_' + this.stepTitle + '_' + 'Attachments' + '_',
      };

      this.printingService.downloadAttachments(
        attachmentData,
        this.store.selectSnapshot(CompanySelectors.getCurrentCompany),
        this.store.selectSnapshot(UserSelectors.getCurrentUser),
      ).pipe(
        take(1),
        finalize(() => {
          // This is needed so the async queue has time to process the new DynamoDB 'In Progress' request.
          setTimeout(() => {
            this.isQueueLoading = false;
          }, 1000)
        }),
        tap(() => {
          const currentUserUuid = this.store.selectSnapshot(UserSelectors.getCurrentUserUuid);
          this.store.dispatch(new StartAsyncPdfCheck(currentUserUuid));
        })
      ).subscribe();
    }
  }

  createAttachmentFromBlob(file: File, index: number, noOfFiles: number, screenRecording = false) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const extension = file.name?.split('.').pop();
      this.attachments.push({
        original_attachment: file,
        name: file.name,
        thumbnail: FILE_TYPES_WITH_THUMBNAIL.includes(file.type)
          ? this._sanitizer.bypassSecurityTrustResourceUrl(reader.result as string)
          : false,
        file_size: file.size,
        extension,
        is_screen_recording: screenRecording,
        mime_type: file.type,
        dataUrl: URL.createObjectURL(file),
        is_new_upload: true,
        temporary_id_counter: this.temporaryIdCounter,
        sequence_filename: `${this.sectionTitle}_${this.stepTitle}_${this.isDeviation ? 'Deviation' : 'Attachment'}_${this.fileNamingSequenceCounter}.${extension}`,
      });
      this.fileNamingSequenceCounter += 1;
      this.temporaryIdCounter += 1;
      this.totalFileSize += file.size;
      this.initialAttachmentsArray = _.cloneDeep(this.attachments);
      if (index === noOfFiles - 1) {
        this.selectAttachmentClick(this.attachments[this.attachments.length - 1], this.attachments.length - 1);
      }
    }, false);

    reader.onloadend = () => {
      reader.removeAllListeners('load');
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  getFileForIndividualPassFailRowThumbnail(attachment: Attachment, index: number, cb?, isDownload?: boolean): void {
    const url = `/api/runtime-user-workflow/${this.workflowUuid}/version/${this.versionUuid}/ts-execute/${this.stepUuid}/${this.rowUuid}/pass/file/${attachment.file_uuid}`;
    if (this.selectedAttachmentIsVideo && !cb /* if cb is invoked full data is required to call the function */) {
      this.getVideoUrl(attachment);
    } else {
      let attachmentObj = {
        original_attachment: null,
        name: attachment.name,
        extension: attachment.name?.split('.').pop()
      };

      if (this.selectedAttachmentIsNonWebviewerImage || this.selectedAttachmentIsWebviewerValid || this.selectedAttachmentIsCSV || isDownload) {
        this.apiService.getImageFromFile(url).subscribe(res => {
          attachmentObj.original_attachment = new Blob([res], { type: attachment.mime_type }),
            this.attachments[index].original_attachment = attachmentObj.original_attachment;
          this.attachments[index].name = attachmentObj.name;
          this.attachments[index].extension = attachmentObj.extension;
          this.getSelectedAttachment(attachmentObj, index);
          if (cb) {
            cb();
          }
        },
          (err) => {
            this.isLoading = false;
          });
      } else {
        this.attachments[index].name = attachmentObj.name;
        this.attachments[index].extension = attachmentObj.extension;
        this.getSelectedAttachment(attachmentObj, index);
        if (cb) {
          cb();
        }
      }
    }
  }

  getSelectedAttachment(attachment: Attachment, index: number) {
    if (this.selectedAttachmentIsWebviewerValid) {
      const file = this.attachments[index].original_attachment
      file.arrayBuffer().then((arrayBuffer) => {
        const blob = new Blob([new Uint8Array(arrayBuffer)], { type: file.type });
        this.selectedAttachmentData = blob;
      });
    } else {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.attachments[this.selectedAttachmentIndex] = {
          ...this.attachments[this.selectedAttachmentIndex],
          original_attachment: this.attachments[index].original_attachment
        };

        this.selectedAttachmentData = this.csvFileTypes.includes(attachment.extension)
          ? this.attachments[index].original_attachment
          : this._sanitizer.bypassSecurityTrustResourceUrl(reader.result as string)

      }, false);

      if (this.attachments[index].original_attachment) {
        reader.readAsDataURL(this.attachments[index].original_attachment);
      }
    }
    this.isLoading = false;
    this.isLoadingAttachment = false;
    this.selectedAttachmentIsNonWebviewerImage = FILE_TYPES_WITH_THUMBNAIL.includes(attachment?.original_attachment?.type) ? true : false;
  }

  onVideoLoadingError() {
    this.selectedAttachmentIsVideo = false;
  }

  onWebviewerFileChanged(event): void {
    // TODO (if req is approved): Recalculate total file size when adding annotations
    this.selectedAttachmentData = event.file;
    const index = this.attachments.findIndex(attachment => {
      if (attachment.file_uuid && event.fileData.file_uuid) {
        return attachment.file_uuid === event.fileData.file_uuid;
      }

      return attachment.temporary_id_counter === event.fileData.temporary_id_counter;
    })
    this.attachments[index].original_attachment = new File([event.file], event.fileData.name);
    const objectURL = URL.createObjectURL(event.file);
    this.attachments[index].thumbnail = this._sanitizer.bypassSecurityTrustResourceUrl(objectURL);
  }

  isAttachmentVideoType(attachment: Attachment) {
    // TODO: check why screen recording has no mime type
    return this.videoService.canPlayType(attachment?.mime_type || attachment?.original_attachment?.type) || attachment?.is_screen_recording;
  }

  onWebviewerLoaded() {
    this.isLoadingWebviewer = false;
  }

  addAttachments(formData) {
    return this.canvasService.addAttachments(this.workflowUuid, this.versionUuid, this.stepUuid, this.rowUuid, formData)
      .pipe(
        take(1),
        finalize(() => this.isLoading = false)
      )
  }


}
