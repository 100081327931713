/* tslint:disable:no-string-literal */
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Company } from '@core/models';
import { AutoUnsubscribe } from '@core/utilities/auto-unsub';
import { Store } from '@ngxs/store';
import { RemoveGeneratedFile } from '@store/async-pdf/async-pdf.actions';
import { AsyncFileDynamoDBResponseLocal } from '@store/async-pdf/async-pdf.model';
import { AsyncPdfSelectors } from '@store/async-pdf/async-pdf.selectors';
import { CompanySelectors } from '@store/company/company.selectors';
import { PrintingService } from '@core/services';
import { ToastrService } from 'ngx-toastr';
import { cloneDeep } from 'lodash';
import { DateFormatPipe } from '@core/pipes';
import { EXECUTED_TEST_SCRIPT_DATA_TYPES } from '@core/constants/app-constants';

@Component({
  selector: 'app-async-pdf-files-modal',
  templateUrl: './async-pdf-files-modal.component.html',
  styleUrls: ['./async-pdf-files-modal.component.scss']
})
@AutoUnsubscribe()
export class AsyncPdfFilesModalComponent {
  company: Company;
  files: AsyncFileDynamoDBResponseLocal[] = [];
  loading = false;

  readonly ExecutedTestScriptDataTypes = EXECUTED_TEST_SCRIPT_DATA_TYPES;

  constructor(
    private dialogRef: MatDialogRef<AsyncPdfFilesModalComponent>,
    private store: Store,
    private printingService: PrintingService,
    private toastr: ToastrService
  ) {
    this.loading = true;
    this.company = this.store.selectSnapshot(CompanySelectors.getCurrentCompany);
    this.store.select(AsyncPdfSelectors.getGeneratedFiles).subscribe(res => {
      this.files = cloneDeep(res);
      this.files.forEach(file => {
        if (this.ExecutedTestScriptDataTypes.includes(file.document_type)) {
          file.document_title = this.convertTitleDate(file);
        }
      })
      this.loading = false;
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  downloadFile(file: AsyncFileDynamoDBResponseLocal): void {
    const currentDownloadingFile = this.files.find(f => f.file_url === file.file_url);
    currentDownloadingFile.isLoading = true;
    this.printingService.getImage(file.file_url).subscribe(blob => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = file.document_type === 'workflow'
        ? file.sdox_document_category && file.sdox_document_category === 'test_script_execution'
          ? file.display_label + `_R${file.version}` + `.${this.getUrlExtension(file.file_url)}`
          : file.display_label + `_V${file.version}` + `.${this.getUrlExtension(file.file_url)}`
        : file.document_title + `.${this.getUrlExtension(file.file_url)}`;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      currentDownloadingFile.isLoading = false;
    }, err => this.toastr.error(`The download process failed`));
  }

  getUrlExtension(url: string): string {
    return url.split(/[#?]/)[0].split('.').pop().trim();
  }

  removeFile(file: AsyncFileDynamoDBResponseLocal) {
    this.loading = true;
    this.store.dispatch(new RemoveGeneratedFile(file.batch_id));
  }

  convertTitleDate(file: AsyncFileDynamoDBResponseLocal) {
    // The last 24 characters will be a Zulu UTC Date from DynamoDB
    // We need to convert this date to match the company date config.
    const extractedDate = file.document_title.slice(-24);
    const extractedTitle = file.document_title.slice(0, -24);
    const dateFormatPipe = new DateFormatPipe();
    const formattedDate = dateFormatPipe.transform(extractedDate, this.company.config.dateformat, this.company.config.timezone, this.company.config.timeformat);

    return `${extractedTitle + formattedDate}`;
  }
}
