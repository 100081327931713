import { WorkflowBaseModel } from '@core/models/workflow-base.model';
import { IWorkflowTemplate } from '../interfaces';

export class WorkflowTemplateModel extends WorkflowBaseModel<IWorkflowTemplate> {
  protected readonly _workflow: IWorkflowTemplate;

  constructor(workflow: IWorkflowTemplate) {
    super(workflow);
  }
}
