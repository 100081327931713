import { PermissionList, Permissions } from '@core/constants/permissions.enum';
import { CommonFiltersItemsModel, CommonFiltersModel, IFilterDataValues } from '@core/interfaces';
import { Company } from '@core/models';
import { AdminService, CompanySearchService, HelperService } from '@core/services';
import { createSelector, Select, StateContext } from '@ngxs/store';
import { DatepickerRange } from '@shared/components/datepicker/datepicker.models';
import { CompanySelectors } from '@store/company/company.selectors';
import { UserSelectors } from '@store/user/user.selectors';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { AddActiveFilter, AddSortOption, RemoveActiveFilter, SearchChanged } from './filters.actions';
import moment from 'moment';

export class Filters<T> {
  static readonly specialFilters: string[] = ['is_delayed', 'created_by_me', 'is_private', 'is_downloaded'];
  static readonly filtersWithPermission: any[] = [{
    filter: 'owner_user_uuid',
    permission: Permissions.filteringOwner
  }];
  readonly filtersWithSearch: string[] = ['filters.owner_user_uuid', 'filters.approver_user_uuid', 'filters.reviewer_user_uuid', 'filters.user_uuid', 'filters.assigner_uuid'];
  private companySearchService: CompanySearchService;
  private adminSearchService: AdminService;
  companySubscription$: Subscription;
  permissionsSubscription$: Subscription;
  btrAdminSubscription$: Subscription;

  @Select(CompanySelectors.getCurrentCompany)
  company$: Observable<Company>;
  static companyData: Company;

  @Select(UserSelectors.getUserPermissions)
  permissions$: Observable<PermissionList>;
  static permissionsData: PermissionList;

  constructor(companySearchService: CompanySearchService, adminSearchService?: AdminService) {
    this.companySearchService = companySearchService;
    this.adminSearchService = adminSearchService;
    this.companySubscription$ = this.company$.subscribe(company => {
      Filters.companyData = cloneDeep(company);
    });
    this.permissionsSubscription$ = this.permissions$.subscribe(permissions => {
      Filters.permissionsData = cloneDeep(permissions);
    });
  }

  static getTypes<T>() {
    return createSelector([this], (state: CommonFiltersModel<T>) => {
      const filterItems = state.filterItems.filters as any;
      return filterItems.type ? filterItems.type.selectValues : null;
    });
  }

  static getSelectedType<T>() {
    return createSelector([this], (state: CommonFiltersModel<T>) => {
      const filterItems = state.filterItems.filters as any;
      return filterItems.type ? filterItems.type.selected : null;
    });
  }

  static getSearch<T>() {
    return createSelector([this], (state: CommonFiltersModel<T>) => {
      return state.filterItems.search.selected;
    });
  }

  static getAllRequestFilters<T>() {
    return createSelector([this], (state: CommonFiltersModel<T>) => {
      const filterItems: CommonFiltersItemsModel<T> = _.cloneDeep(state.filterItems);
      const isUTCDate = state.filterItems?.is_utc;
      const filterDataValues: IFilterDataValues = {
        filters: {},
      };
      if (filterItems.search) {
        filterDataValues.search = filterItems.search.selected || '';
      }

      if (filterItems.is_sso) {
        filterDataValues.is_sso = filterItems.is_sso.selected || '';
      }

      const filters = Object.keys(filterItems.filters).reduce((obj, key) => {
        let data = null;
        if (filterItems.filters[key].data) {
          data = {
            ...filterItems.filters[key].data
          };
        } else {
          data = {
            [key]: filterItems.filters[key]
          };
        }
        return Object.assign(obj, data);
      }, {});
      this.setFilterDataValues(filters, filterDataValues);
      Object.keys(filterDataValues.filters).forEach(key => {
        Object.keys(filterDataValues.filters[key]).forEach(filterKey => {
          if (filterKey === 'start') {
            filterDataValues.filters[key] = {
              ...filterDataValues.filters[key],
              from: isUTCDate
                ? filterDataValues.filters[key].start
                : HelperService.formatDateToCompanyTimezoneUTC(Filters.companyData, filterDataValues.filters[key].start, true)
            };
            delete filterDataValues.filters[key].start;
          } else if (filterKey === 'end') {
            filterDataValues.filters[key] = {
              ...filterDataValues.filters[key],
              to: isUTCDate
                ? moment(filterDataValues.filters[key].end).utc().set('hour', 23).set('minute', 59).set('seconds', 59).format()
                : HelperService.formatDateToCompanyTimezoneUTC(Filters.companyData, filterDataValues.filters[key].end, false)
            };
            delete filterDataValues.filters[key].end;
          }
        });
      });

      if (filterItems.date_filters) {
        filterDataValues.date_filters = {};
        Object.keys(filterItems.date_filters)
          .filter(key => !!filterItems.date_filters[key].selected)
          .filter(key => {
            return (filterItems.date_filters[key].selected as DatepickerRange).start !== null
              && (filterItems.date_filters[key].selected as DatepickerRange).end !== null;
          })
          .forEach(key => {
            filterDataValues.date_filters[key] = {
              from: isUTCDate
                ? (filterItems.date_filters[key].selected as DatepickerRange).start
                : HelperService.formatDateToCompanyTimezoneUTC(Filters.companyData, (filterItems.date_filters[key].selected as DatepickerRange).start, true),
              to: isUTCDate
                ? moment((filterItems.date_filters[key].selected as DatepickerRange).end).utc().set('hour', 23).set('minute', 59).set('seconds', 59).format()
                : HelperService.formatDateToCompanyTimezoneUTC(Filters.companyData, (filterItems.date_filters[key].selected as DatepickerRange).end, false),
            };
          });
      }

      if (filterItems.sortOptions?.selectValues) {
        if (filterItems.sortOptions.selected) {
          filterDataValues.sortBy = filterItems.sortOptions.selected;
        } else {
          filterDataValues.sortBy = `${filterItems.sortOptions?.selectValues[0].value}`;
        }
        filterDataValues.sortDirection = filterItems.sortOptions.selectValues
          .find(sortValue => sortValue.value === filterDataValues.sortBy).sortDirection;
      }

      return this.removeFiltersWithoutPermission(filterDataValues);
    });
  }

  static removeFiltersWithoutPermission(data) {
    for (const key of Object.keys(data.filters)) {
      if (!this.filterOnPermission(key)) {
        delete data.filters[key];
      }
    }
    return data;
  }

  static getFilterItems<T>() {
    return createSelector([this], (state: CommonFiltersModel<T>) => {
      return state.filterItems;
    });
  }

  static getActiveFilters<T>(specialFilters: string[] = Filters.specialFilters) {
    return createSelector([this], (state: CommonFiltersModel<T>) => {
      if (state.activeFilters) {
        const activeFilters = cloneDeep(state.activeFilters).filter(item => this.filterOnPermission(item, true));
        if (state.filterItems.search) {
          return [
            ...activeFilters
          ];
        } else if (state.filterItems.is_sso) {
          return [
            {
              key: 'is_sso',
              value: state.filterItems.is_sso
            },
            ...activeFilters
          ];
        } else {
          return [
            ...activeFilters
          ];
        }
      } else {
        const filterItems = state.filterItems;
        const filters = Object.keys(state.filterItems)
          .filter(key => key !== 'search' && key !== 'sortOptions' && key !== 'is_sso').map(key => {
            const filterItem = Object.keys(filterItems[key])
              .filter(filterItemKey => !specialFilters.includes(filterItemKey))
              .filter(item => this.filterOnPermission(item))
              .map(filterItemKey => {
                const dropdownItemKey = `${key}.${filterItemKey}`;
                return {
                  key: dropdownItemKey,
                  value: filterItems[key][filterItemKey]
                };
              });
            return filterItem;
          });
        return _.flatten(filters).filter(activeFilter => activeFilter.value.selected !== null);
      }
    });
  }

  static getDropdownFilterItems<T>(specialFilters: string[] = Filters.specialFilters) {
    return createSelector([this], (state: CommonFiltersModel<T>) => {
      const filterItems = state.filterItems;
      const activeFilters = this.getActiveFilters()(state);
      const filters = Object.keys(filterItems)
        .filter(key => key !== 'search' && key !== 'sortOptions' && key !== 'is_sso').map(key => {
          const filterItem = Object.keys(filterItems[key])
            .filter(filterItemKey => !specialFilters.includes(filterItemKey) && !filterItems[key][filterItemKey].hiddenFromView)
            .filter(item => this.filterOnPermission(item))
            .map(filterItemKey => {
              const dropdownItemKey = `${key}.${filterItemKey}`;
              return {
                key: dropdownItemKey,
                label: filterItems[key][filterItemKey].label,
                disabled: activeFilters.map(activeFilter => activeFilter.key).includes(dropdownItemKey),
                options: filterItems[key][filterItemKey].children
                  ? filterItems[key][filterItemKey].children.map(child => {
                    return {
                      ...child,
                      disabled: activeFilters.map(activeFilter => activeFilter.key).includes(child.key),
                    };
                  })
                  : null
              };
            });
          return filterItem;
        });
      return _.flatten(filters);
    });
  }

  static getVisibilityFilters<T>() {
    return createSelector([this], (state: CommonFiltersModel<T>) => {
      const filterItems = state.filterItems;
      const filters = Object.keys(filterItems)
        .filter(key => key === 'filters').map(key => {
          const filterItem = Object.keys(filterItems[key])
            .filter(filterItemKey => filterItemKey === 'is_private')
            .map(filterItemKey => {
              const dropdownItemKey = `${key}.${filterItemKey}`;

              return {
                key: dropdownItemKey,
                value: filterItems[key][filterItemKey],
              };
            });
          return filterItem;
        });
      return _.flatten(filters);
    });
  }

  static getSortOptions<T>() {
    return createSelector([this], (state: CommonFiltersModel<T>) => {
      return state.filterItems.sortOptions.selectValues;
    });
  }

  static getSelectedSortOption<T>() {
    return createSelector([this], (state: CommonFiltersModel<T>) => {
      return state.filterItems.sortOptions.selected;
    });
  }

  addSortOption({ getState, patchState }: StateContext<CommonFiltersModel<T>>, { sortByKey }: AddSortOption) {
    const state = getState();
    const filterItems: CommonFiltersItemsModel<T> = _.cloneDeep(state.filterItems);
    if (!filterItems.sortOptions) {
      return;
    }

    const updatedFilters = _.set(filterItems, 'sortOptions.selected', sortByKey);
    patchState({
      filterItems: {
        ...updatedFilters
      }
    });
  }

  addActiveFilter({ getState, patchState }: StateContext<CommonFiltersModel<T>>, { key, value }: AddActiveFilter) {
    const state = getState();
    const filterItems = _.cloneDeep(state.filterItems);
    let updatedFilters = _.set(filterItems, `${key}.selected`, value);

    // NRESQ-13260 remove search filter from active filters if the value is empty (cleared by user).
    // NRESQ-13260 remove access filter from active filters if the value is ACCESS (default).
    if ((key === 'search' && value === '') || (key === 'filters.access_type' && value === 'ACCESS')) {
      this.clearActiveFilter(state, getState, patchState, updatedFilters, key);
    } else {
      let activeFilters = state.activeFilters;
      if (!state.activeFilters) {
        activeFilters = Filters.getActiveFilters()(state);
      }

      const filterItem = _.get(filterItems, key);
      const existingChildrenFilter = activeFilters.find(f => f.key.includes(filterItem.parentKey));
      if (existingChildrenFilter) {
        const parentFilter = _.get(filterItems, `filters.${existingChildrenFilter.value.parentKey}`);
        if (parentFilter.isMultiselect) {
          activeFilters = this.upsertActiveFilter(activeFilters, key, _.get(filterItems, key));
        } else {
          if (key !== existingChildrenFilter.key) {
            updatedFilters = _.set(filterItems, `${existingChildrenFilter.key}.selected`, null);
            activeFilters = this.upsertActiveFilter(
              activeFilters.filter(activeF => activeF.key !== existingChildrenFilter.key),
              key,
              _.get(filterItems, key)
            );
          } else {
            activeFilters = this.upsertActiveFilter(activeFilters, key, _.get(filterItems, key));
          }
        }
      } else {
        activeFilters = this.upsertActiveFilter(activeFilters, key, _.get(filterItems, key));
      }

      if (activeFilters) {
        patchState({
          filterItems: {
            ...updatedFilters
          },
          activeFilters
        });
      } else {
        patchState({
          filterItems: {
            ...updatedFilters
          }
        });
      }
    }
  }

  removeActiveFilter({ getState, patchState }: StateContext<CommonFiltersModel<T>>, { key }: RemoveActiveFilter) {
    const state = getState();
    const filterItems = _.cloneDeep(getState().filterItems);
    const updatedFilters = _.set(filterItems, `${key}.selected`, null);
    if (state.activeFilters) {
      patchState({
        filterItems: {
          ...updatedFilters
        },
        activeFilters: getState().activeFilters.filter(activeF => activeF.key !== key)
      });
    } else {
      patchState({
        filterItems: {
          ...updatedFilters
        }
      });
    }

  }

  searchChanged({ getState, patchState }: StateContext<CommonFiltersModel<T>>, { key, value }: SearchChanged) {
    if (this.filtersWithSearch.includes(key)) {
      return this.companySearchService.searchCompanyUser(0, 100, { sortBy: 'first_name,last_name,email', sortDirection: 'asc' }, { search: value })
        .pipe(
          filter(result => !!result),
          tap(users => {
            this.searchChange(getState, patchState, key, users);
          })
        );
    }
  }

  searchChangedBTRAdmin({ getState, patchState }: StateContext<CommonFiltersModel<T>>, { key, value }: SearchChanged) {
    if (this.filtersWithSearch.includes(key)) {
      return this.adminSearchService.searchBTRAdminUsers(0, Number.MAX_SAFE_INTEGER, { sortBy: 'first_name', sortDirection: 'asc' }, { search: value })
        .pipe(
          filter(result => !!result),
          tap(users => {
            this.searchChange(getState, patchState, key, users);
          })
        );
    }
  }

  private searchChange(getState, patchState, key, users) {
    const state = getState();
    const reportFilterItems = _.cloneDeep(state.filterItems);
    const updatedFilters = _.set(reportFilterItems, key + '.selectValues', users);

    if (state.activeFilters) {
      const activeFilters = _.cloneDeep(state.activeFilters);
      _.set(activeFilters.find(activeFilter => activeFilter.key === key), 'value.selectValues', users);
      patchState({
        filterItems: {
          ...updatedFilters
        },
        activeFilters
      });
    } else {
      patchState({
        filterItems: {
          ...updatedFilters
        }
      });
    }
  }

  private upsertActiveFilter(cf: any[], key, value) {
    let currentFilters = _.cloneDeep(cf);
    const filterExistsIndex = currentFilters.findIndex(f => f.key === key);
    if (filterExistsIndex > -1) {
      currentFilters[filterExistsIndex] = {
        ...currentFilters[filterExistsIndex],
        value: {
          ...currentFilters[filterExistsIndex].value,
          selected: value.selected
        }
      };
    } else {
      currentFilters = [...currentFilters, { key, value }];
    }
    return currentFilters;
  }

  static setFilterDataValues(filters: any, filterDataValues: IFilterDataValues) {
    Object.keys(filters)
      .filter(key =>
        (
          !filters[key].isMultiselect
          && filters[key].selected !== null
          && filters[key].selected !== 'All'
          && filters[key].selected !== ''
        ) || (
          filters[key].isMultiselect
          && filters[key].selected !== null
          && filters[key].selected !== 'All'
          && filters[key].selected !== ''
          && filters[key].selected.length !== 0
        )
      )
      .forEach(key => {
        const keyName = filters[key].parentKey || key;
        if (filters[key].shouldGetSelectedValueFromArray || filters[key].isMultiselect) {
          if (filters[key].mergeSelectedValues && filterDataValues.filters[keyName]) {
            filterDataValues.filters[keyName] = [...filterDataValues.filters[keyName], ...filters[key].selected];
          } else {
            filterDataValues.filters[keyName] = filters[key].selected;
          }
        } else {
          if (filters[key].parentKey) {
            filterDataValues.filters[keyName] = this.getFilterChildrenValues(filters, filters[key].parentKey);
          } else {
            filterDataValues.filters[keyName] = [filters[key].selected];
          }
        }
        if (['true', 'false'].includes(filterDataValues.filters[key])) {
          filterDataValues.filters[keyName] = filterDataValues.filters[keyName] === 'true';
        }
      });
  }

  static getFilterChildrenValues(filters: any, parentKey: string): string[] {
    return Object.keys(filters)
      .filter(key => filters[key].parentKey === parentKey
        && filters[key].selected !== null
        && filters[key].selected !== 'All'
        && filters[key].selected !== '')
      .map(key => filters[key].selected);
  }

  // Remove the filters which require a permission to be used and don't have it
  static filterOnPermission(filterItem, hasFilterLabel = false): boolean {
    const filterFunc = hasFilterLabel
      ? (f) => `filters.${f.filter}` === filterItem.key
      : (f) => f.filter === filterItem;
    const filterWithPermission = Filters.filtersWithPermission.find(filterFunc);

    return filterWithPermission ? Filters.permissionsData[filterWithPermission.permission] : true;
  }

  private clearActiveFilter(state, getState, patchState, updatedFilters, key) {
    if (state.activeFilters) {
      patchState({
        filterItems: {
          ...updatedFilters
        },
        activeFilters: getState().activeFilters.filter(activeF => activeF.key !== key)
      });
    } else {
      patchState({
        filterItems: {
          ...updatedFilters
        }
      });
    }
  }
}
