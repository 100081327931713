import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class RteImageUploadService {
  public uploadPath = '/api/syncfusion-image-upload/';
  public marketplaceUploadPath = '/api/marketplace/syncfusion-image-upload/';

  constructor(private apiService: ApiService) {}

  public getImage(image: string) {
    return this.apiService.get(`${this.uploadPath}${image}`);
  }
  public getMarketplaceImage(image: string) {
    return this.apiService.get(`${this.marketplaceUploadPath}${image}`);
  }
}
