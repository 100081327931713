import { Company } from "@core/models";

export interface ILicense {
  license_admin_count: number;
  license_admin_used: number;
  license_client_count: number;
  license_client_used: number;
  license_company_admin_count: number;
  license_company_admin_used: number;
  license_editor_count: number;
  license_editor_used: number;
  license_viewer_count: number;
  license_viewer_used: number;
}

export const getLicensesCount = (licenses: ILicense) => {
  if (!licenses) {
    return 0;
  }
  return licenses.license_company_admin_count
    + licenses.license_admin_count
    + licenses.license_editor_count
    + licenses.license_viewer_count
    + licenses.license_client_count;
};

export interface ILicenseRequest {
  company: Company;
  created_at: string;
  id: number;
  initiator_message: string;
  license_admin_count: number;
  license_client_count: number;
  license_company_admin_count: number;
  license_editor_count: number;
  license_viewer_count: number;
  responder_message: string;
  status: string;
  updated_at: string;
  uuid: string;
}

export enum LicenseRequestStatus {
  Pending = 'pending',
  Cancelled = 'cancelled',
  Approved = 'approved',
  Rejected = 'rejected',
  ModifiedByBTRA = 'directly_edited_by_resq_admin'
}

export enum LicenseRequestStatusLabels {
  pending = 'Pending',
  cancelled = 'Cancelled',
  approved = 'Approved',
  rejected = 'Rejected',
  directly_edited_by_resq_admin = 'Directly Edited by Res_Q Admin'
}
