import { Injectable } from '@angular/core';
import { IUserGroup } from '@core/interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class GroupsService {
  private readonly groupsPath = '/api/groups';

  constructor(private apiService: ApiService) { }

  getAccessGroupsForLoggedUser(): Observable<IUserGroup[]> {
    return this.apiService.get(`${this.groupsPath}/filter-list`).pipe(map(res => res.data));
  }
}
