import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomStepperComponent } from './custom-stepper.component';
import {CdkStepperModule} from '@angular/cdk/stepper';
import { ButtonsModule } from '../buttons/buttons.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY } from '@angular/material/autocomplete';
import { CloseScrollStrategy, Overlay } from '@angular/cdk/overlay';

export function scrollFactory(overlay: Overlay): () => CloseScrollStrategy {
  return () => overlay.scrollStrategies.close();
}

@NgModule({
  imports: [
    CommonModule,
    CdkStepperModule,
    ButtonsModule,
    MatIconModule,
    MatProgressBarModule,
    ScrollingModule
  ],
  declarations: [
    CustomStepperComponent
  ],
  exports: [
    CustomStepperComponent
  ],
  providers: [
    { provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay] }
  ]
})
export class CustomStepperModule {
}
