import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NotificationResponse } from '@core/interfaces';
import { NotificationMenuItemComponent } from '../../notification-menu-item/notification-menu-item.component';

@Component({
  selector: 'app-misc-initiator-sent-message',
  templateUrl: './misc-initiator-sent-message.component.html',
  styleUrls: ['./misc-initiator-sent-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MiscInitiatorSentMessageComponent extends NotificationMenuItemComponent {
  @Input() notification: NotificationResponse;
}
