<div class="item-data">
    <ng-container *ngIf="notification.template === NotificationTemplate.GlobalSystemDeleted">
        <app-icon-two-images bigImg="globe" smallImg="close2" [isSuccess]="false">
        </app-icon-two-images>
    </ng-container>
    <ng-container *ngIf="notification.template === NotificationTemplate.GlobalSystemActivatedToCompany">
        <app-icon-two-images bigImg="globe" smallImg="check2" [isSuccess]="true">
        </app-icon-two-images>
    </ng-container>
    <div class="item-details" (click)="goToCompanySystems(notification)">
        <div class="item-details-title" *ngIf="notification.template === NotificationTemplate.GlobalSystemDeleted">
            The Global system <strong>{{notification.data?.system_name}}</strong> was deleted by Res_Q Admin. 
            The system will still be available in your company as a local system.
        </div>
        <div class="item-details-title" *ngIf="notification.template === NotificationTemplate.GlobalSystemActivatedToCompany">
            Your local system <strong>{{notification.data?.system_name}}</strong> has become a global system.
            The system is still available in your company as a global system.
        </div>
        <div class="item-details-time" *ngIf="company$ | async as company">
            {{notification.created_at | dateFormat : null : company.config.timezone : company.config.timeformat}}
        </div>
    </div>
</div>
<app-notification-bullet [notification]="notification"></app-notification-bullet>