import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IUser } from '@core/models';
import { AuthenticationService, HelperService, RoutingService, UserService } from '@core/services';
import { Action, State, StateContext } from '@ngxs/store';
import { pipe, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { MonitoringService } from 'src/app/monitoring.service';
import { GetCurrentCompany } from '../company/company.actions';
import {
  GetCurrentUser,
  Login,
  Logout, UpdateProfileImage, UpdateProfilePagination, UpdateProfileUser
} from './user.actions';

export interface UserStateModel {
  loading: boolean;
  user: IUser;
  loggedIn: boolean;
  logoutInProgress: boolean;
}

@State<UserStateModel>({
  name: 'UserState',
  defaults: {
    loading: false,
    loggedIn: localStorage.getItem('loggedIn') === 'true' || false,
    user: null,
    logoutInProgress: false,
  }
})
@Injectable()
export class UserState {
  constructor(
    private authenticationService: AuthenticationService,
    private monitoringService: MonitoringService,
    private userService: UserService,
    private routingService: RoutingService,
    public helperService: HelperService,
    private router: Router
  ) { }

  @Action(GetCurrentUser, { cancelUncompleted: true })
  getCurrentUser({ patchState, dispatch }: StateContext<UserStateModel>) {
    patchState({
      loading: true
    });
    return this.userService.getUser().pipe(
      map(user => {
        patchState({
          loading: false,
          user
        });
        if (!user.is_btr_admin) {
          dispatch(new GetCurrentCompany());
        }
        return this.monitoringService.monitorUser(user);
      }),
      catchError(err => {
        if (err.status === HttpStatusCode.NotAcceptable) {
          localStorage.removeItem('loggedIn');
          this.router.navigate([this.routingService.MISSING_ACCOUNT.url()]);
        } else {
          this.helperService.showErrorMessage(err);
        }
        return throwError(err);
      })
    );
  }

  @Action(UpdateProfileUser, { cancelUncompleted: true })
  updateProfileUser({ patchState }: StateContext<UserStateModel>, { updatedUser }: UpdateProfileUser) {
    patchState({
      loading: true
    });
    return this.userService.updateProfileUser(updatedUser).pipe(
      take(1),
      tap(user => {
        patchState({
          loading: false,
          user
        });
      }),
      catchError(err => {
        patchState({
          loading: false
        });
        return throwError(err);
      })
    );
  }

  @Action(UpdateProfileImage, { cancelUncompleted: true })
  updateProfileImage({ patchState, dispatch }: StateContext<UserStateModel>, { profileImage, updatedUser }: UpdateProfileImage) {
    patchState({
      loading: true
    });
    return this.userService.updateProfileImage(profileImage).pipe(
      catchError(err => {
        patchState({
          loading: false,
        });
        return throwError(err);
      }),
      take(1),
      switchMap(() => dispatch(new UpdateProfileUser(updatedUser)))
    )
  }

  @Action(UpdateProfilePagination, { cancelUncompleted: true })
  updateProfilePagination({ patchState, getState }: StateContext<UserStateModel>, { default_pagination }: UpdateProfilePagination) {
    patchState({
      loading: true
    });
    return this.userService.updateProfilePagination(default_pagination).pipe(
      take(1),
      tap(_ => {
        patchState({
          user: {
            ...getState().user,
            default_pagination
          }
        });
      }),
      finalize(() => {
        patchState({
          loading: false
        });
      }),
      catchError(err => {
        return throwError(err);
      })
    )
  }


  @Action(Login, { cancelUncompleted: true })
  login({ patchState }: StateContext<UserStateModel>) {
    localStorage.setItem('loggedIn', 'true');
    patchState({
      loggedIn: true
    });
  }

  @Action(Logout, { cancelUncompleted: true })
  logout({ patchState }: StateContext<UserStateModel>, { forceSignOut }) {
    patchState({
      loading: true,
      logoutInProgress: true
    });
    // TODO !important - if a document is locked by the user that's logging out, set lock status to null in backend
    return this.authenticationService.logout(forceSignOut).pipe(
      tap((res) => {
        if (res !== false) {
          patchState({
            loggedIn: false,
            user: null,
            loading: false,
            logoutInProgress: false
          });
        }
      })
    );
  }
}
