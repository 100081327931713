import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { IFilterDataValues, KPITypes } from '@core/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private reportsPath = '/api/reports';
  private dashboardsPath = '/api/dashboard';
  private reportsDataSetPath = '/api/report-data-set';
  private adminReportsPath = '/api/admin-reports';
  private adminReportsDataSetPath = '/api/admin-report-data-set';

  constructor(private apiService: ApiService) { }

  public getReportByReportType(category: string) {
    return this.apiService.get(`${this.reportsPath}/${category}`);
  }

  public updateReportByReportType(category: string) {
    return this.apiService.post(`${this.reportsPath}/${category}`, {});
  }

  public getReportDataSet(offset: number, limit: number, filters: IFilterDataValues) {
    return this.apiService.post(`${this.reportsDataSetPath}/list/${offset}/${limit}`, filters);
  }

  public printReportAsync(exportFormat: string, reportData: any) {
    return this.apiService.postToBackendPdfService(`${this.reportsDataSetPath}/export/${exportFormat}`, reportData);
  }

  public getReportByReportTypeAdmin(category: string) {
    return this.apiService.get(`${this.adminReportsPath}/${category}`);
  }

  public updateReportByReportTypeAdmin(category: string) {
    return this.apiService.post(`${this.adminReportsPath}/${category}`, {});
  }

  public getReportDataSetAdmin(offset: number, limit: number, filters: IFilterDataValues) {
    return this.apiService.post(`${this.adminReportsDataSetPath}/list/${offset}/${limit}`, filters);
  }

  public printReportAsyncAdmin(exportFormat: string, reportData: any) {
    return this.apiService.postToBackendPdfService(`${this.adminReportsDataSetPath}/export/${exportFormat}`, reportData);
  }

  public getKPI(kpiType: KPITypes, filters: IFilterDataValues) {
    return this.apiService.post(`/api/kpi/${kpiType}`, filters);
  }

  public getReportDashboardCards(filters: IFilterDataValues) {
    return this.apiService.post(`${this.dashboardsPath}/cards`, filters);
  }

  public updateReportDashboardCards() {
    return this.apiService.post(`${this.dashboardsPath}/update`, {});
  }
}
