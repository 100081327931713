<div class="not-found-container">

  <div class="section-container gray-text margin-top-15">

    <mat-card appearance="outline" class="details-subsection flex-100 flex-wrap">
      <div class="logo flex-100 justify-center items-center">
        <img src="../../../../assets/img/favicon.ico"/>
      </div>
      <h3 class="flex-100 justify-center">Company domain does not exist!</h3>
    </mat-card>
  </div>

</div>
