import {
  ChangeDetectorRef, Directive, Input, OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { AutoUnsubscribe } from '@core/utilities/auto-unsub';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { UserSelectors } from 'src/app/store/user/user.selectors';
import { AccessLevel } from '../constants';

@Directive({ selector: '[appUserAccessLevel]' })
@AutoUnsubscribe()
export class UserAccessLevelDirective implements OnInit {
  private _userAccessLevels: AccessLevel[];
  set userAccessLevels(value: AccessLevel[]) {
    this._userAccessLevels = value;
    this.componentChecked = false;
    this.unsubscribeRoleChange();
  }

  get userAccessLevels(): AccessLevel[] {
    return this._userAccessLevels;
  }

  userAccessLevel: string;
  private userAccessLevel$: Subscription;
  componentChecked = false;

  @Input()
  set appUserAccessLevel(accessLevels: AccessLevel[]) {
    if (!accessLevels || !accessLevels.length) {
      throw new Error('Access Levels value is empty or missed');
    }
    this.userAccessLevels = accessLevels;
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    private store: Store
  ) {
  }

  ngOnInit() {
    this.userAccessLevel$ = this.store.select(UserSelectors.getUserAccessLevel).subscribe(userAccessLevel => {
      this.userAccessLevel = userAccessLevel;
      this.checkRole();
    });
  }

  private checkRole() {
    const isAuthenticated = this.store.selectSnapshot(UserSelectors.isLoggedIn);
    if (!isAuthenticated) {
      this.viewContainer.clear();
      return;
    }
    const hasAccess = this.userAccessLevels.some(r => this.userAccessLevel === r);
    if (hasAccess) {
      this.showComponent();
    } else {
      this.hideComponent();
    }
    this.cdr.markForCheck();
  }

  private showComponent() {
    if (!this.componentChecked) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.componentChecked = true;
    }
  }

  private hideComponent() {
    if (!this.componentChecked) {
      this.viewContainer.clear();
    } else {
      this.componentChecked = true;
    }
  }

  private unsubscribeRoleChange() {
    if (this.userAccessLevel$) {
      this.userAccessLevel$.unsubscribe();
    }
  }
}
