import { CELL_HIGHLIGHT_SELECTED_TEXT } from '@core/constants';
import { IUserLite } from '@core/models';
import { IRuntimeLite, RuntimeWorkflowStatus, TCActionStatus } from './canvas.interface';

export interface IReviewSession {
  version_root_uuid: string;
  uuid: string;
  version: number;
  created_at: string;
  status: string;
  comments_count: number;
  request_comment?: string;
}

export interface IReviewSessionComment {
  uuid?: string;
  gid?: string;
  composed_highlights?: ISerializedHighlight[];
  text: string;
  step_uuid?: string;
  row_uuid?: string;
  column_key?: string;
  order_index?: number;
  for_title: boolean;
  created_at?: string;
  updated_at?: string;
  is_solved?: boolean;
  replies?: IReviewSessionCommentLite[];
  user?: IUserLite;
  selected_text?: string;
}

export interface IUpdateCommentsRequest {
  uuid: string;
  order_index: number;
  composed_highlights: ISerializedHighlightBulk[];
}

export interface IReviewSessionCommentLite {
  uuid?: string;
  text: string;
  user: IUserLite;
  created_at?: string;
}

export interface ISerializedHighlight {
  textContent: string;
  path: number[];
  offset: number;
  gid: string;
}

export interface ISerializedHighlightBulk {
  gid: string;
  path: number[];
}

export interface IReviewSessionCancelResponse {
  document_status: string;
  task_status: RuntimeWorkflowStatus;
}

export interface IReviewSessionResponseList {
  original_workflow: IRuntimeLite;
  sessions: IReviewSession[];
}

export const isCellHighlight = (comment: IReviewSessionComment) => {
  if (comment) {
    // another condition would be comment.composed_highlights[0].textContent.indexOf('cell on') > -1
    return (comment.selected_text === null || comment.selected_text === undefined || comment.selected_text === CELL_HIGHLIGHT_SELECTED_TEXT)
      && comment.composed_highlights.length === 1
      && comment.composed_highlights[0].offset === 0;
  }
  return false;
};

export interface IWorkflowReviewLockResponse {
  uuid: string;
  workflow_version_uuid: string;
  user: IUserLite,
  expires_at: string;
}


export interface ISingleCommentRequest {
  workflow_review_lock_uuid: string;
  comment: IReviewSessionComment;
  updates: IUpdateCommentsRequest[];
}
export interface IDeleteCommentRequest {
  workflow_review_lock_uuid: string;
  updates: IUpdateCommentsRequest[];
}

export interface IUpdateCommentRequest {
  text: string;
}

export interface IToggleSolveCommentRequest {
  solved: boolean;
}

export interface ICreateReplyRequest {
  comment_uuid: string;
  text: string;
}

export interface IUpdateReplyRequest {
  text: string;
}