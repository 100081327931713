<div class="item-data">
    <app-icon-two-images
        bigImg="license">
    </app-icon-two-images>
    <div class="item-details" (click)="goToLicence(notification)">
        <div class="item-details-title">The license request <strong>#{{notification.data?.id}}</strong> for <strong>{{notification.data?.company?.name}}</strong> was submitted by <strong>{{notification.data?.action_user | displayName}}</strong>.</div>
        <div>The following licenses were requested:</div>
        <div *ngIf="notification.data?.license_company_admin_count">
            Company Admin: <strong>{{notification.data?.license_company_admin_count | signWithLabel: 'license'}}</strong>
        </div>
        <div *ngIf="notification.data?.license_admin_count">
            Admin: <strong>{{notification.data?.license_admin_count | signWithLabel: 'license'}}</strong>
        </div>
        <div *ngIf="notification.data?.license_client_count">
            Client: <strong>{{notification.data?.license_client_count | signWithLabel: 'license'}}</strong>
        </div>
        <div *ngIf="notification.data?.license_editor_count">
            Editor: <strong>{{notification.data?.license_editor_count | signWithLabel: 'license'}}</strong>
        </div>
        <div *ngIf="notification.data?.license_viewer_count">
            Viewer: <strong>{{notification.data?.license_viewer_count | signWithLabel: 'license'}}</strong>
        </div>
        <ng-container *ngIf="notification.data?.initiator_message">
            <div class="item-details-subtitle margin-top-10">With comment:</div>
            <div class="item-details-comment"
                 matTooltip="{{notification.data?.initiator_message}}"
                 matTooltipClass="btr-basic"
                 appTooltipSize
                 matTooltipPosition="right"
                 appTooltipWhenEllipsis>
            "{{notification.data?.initiator_message}}"
        </div>
        </ng-container>
        <div class="item-details-time" *ngIf="company$ | async as company">
            {{notification.created_at | dateFormat : null : company.config.timezone : 'HH:mm A'}}
        </div>
    </div>
</div>
<app-notification-bullet [notification]="notification"></app-notification-bullet>
