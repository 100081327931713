import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { GID_ATTR, HIGHLIGHT_CLASS, SELECTED_CLASS } from '@core/constants';
import { ISerializedHighlight } from '@core/interfaces/review.interface';
import { Util } from '@core/utilities/Util';

@Injectable({
  providedIn: 'root',
})
export class HighlightDomService {
  private renderer2: Renderer2;

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer2 = this.rendererFactory.createRenderer(null, null);
  }

  /**
   * Removes SELECTED_CLASS from highlight elements
   * @param containerElement 
   */
  public clearElements(containerElement): void {
    const highlightedElements: HTMLElement[] = Array.from(containerElement.querySelectorAll(`.${HIGHLIGHT_CLASS}`));
    highlightedElements.forEach((element: HTMLElement) => {
      this.renderer2.removeClass(element, SELECTED_CLASS);
    });
  }

  /**
   * Selects or deselects highlights
   * @param containerElement 
   * @param serializedChunks 
   * @param className 
   */
  public selectElements(containerElement, serializedChunks: ISerializedHighlight[], className: string): void {
    const highlightedElements: HTMLElement[] = Array.from(containerElement.querySelectorAll(`.${HIGHLIGHT_CLASS}`));
    highlightedElements.forEach((element: HTMLElement) => {
      const elementGID = element.getAttribute(GID_ATTR);
      if (serializedChunks.map(span => span.gid).indexOf(elementGID) > -1) {
        this.renderer2.addClass(element, SELECTED_CLASS);
        this.renderer2.addClass(element, className);
      } else {
        this.renderer2.removeClass(element, SELECTED_CLASS);
      }
    });
  }

  /**
   * Adds SELECTED_CLASS to element 
   * @param element 
   */
  public selectCommentBox(element: HTMLElement) {
    this.renderer2.addClass(element, SELECTED_CLASS);
  }

  /**
   * Removes SELECTED_CLASS from specific html element
   * @param element 
   */
  public clearCommentBox(element: HTMLElement) {
    this.renderer2.removeClass(element, SELECTED_CLASS);
  }

  /**
   * Returns user selection range
   * @returns 
   */
  public getRange(): Range {
    const selection = window.getSelection();
    return selection && selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
  }

  /**
   * Remove any user text select
   * Called when user either selects a new text or clicks on a general/table 
   */
  public removeAllRanges(): void {
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
    }
  }

  /**
   * Called to check if child is part of parentElement
   * @param parentElement 
   * @param child 
   * @returns 
   */
  public contains(parentElement: Node | HTMLElement | null | undefined, child): boolean {
    return parentElement && parentElement !== child && parentElement.contains(child);
  }

  /**
   * Called to wrap some text in a span tag (either for new comment of existing ones)
   * @param element 
   * @param gid 
   * @returns 
   */
  public wrap(element: Node | HTMLElement | null | undefined, gid?: string): HTMLElement {
    const parentNode = element.parentNode as HTMLElement;
    const isAnchorTag = this.isAnchorTag(element.parentNode);

    if (parentNode.hasAttribute(GID_ATTR) && parentNode.textContent === element.textContent) {
      return parentNode;
    }

    const wrapper = this.createWrapper(gid);
    const wrapperClone: HTMLElement = wrapper.cloneNode(true) as HTMLElement;
    if (element) {
      if (element.parentNode) {
        this.renderer2.insertBefore(element.parentNode, wrapperClone, element);
      }
      this.renderer2.appendChild(wrapperClone, element);
      if (isAnchorTag) {
        this.renderer2.setStyle(element.parentNode, 'pointer-events', 'none');
      }
    }
    return wrapperClone;
  }

  /**
   * Creates the actual span element with specific gid attribute
   * @param gid 
   * @returns 
   */
  public createWrapper(gid?: string): HTMLElement {
    const span: HTMLElement = this.renderer2.createElement('span');
    this.renderer2.setAttribute(span, GID_ATTR, gid ? gid : Util.prefixedNanoid());
    this.renderer2.addClass(span, HIGHLIGHT_CLASS);
    return span;
  }

  /**
   * Sets gid attribute to a table cell and adds css class
   * @param element 
   * @param gid 
   * @returns 
   */
  public wrapCell(element: Node | HTMLElement | null | undefined, gid?: string): HTMLElement {
    this.renderer2.setAttribute(element, GID_ATTR, gid ? gid : Util.prefixedNanoid());
    this.renderer2.addClass(element, HIGHLIGHT_CLASS);
    return element as HTMLElement;
  }

  /**
   * Utils function used when user selects text to add comment
   * @param node 
   * @returns 
   */
  public isAnchorTag(element): boolean {
    return (element as HTMLElement)?.tagName === 'A';
  }

  /**
   * Checks if an html element (or its children) contains img tag
   * @param element 
   * @returns 
   */
  public hasImage(element: HTMLElement | ParentNode | ChildNode): boolean {
    if (element.childNodes && element.childNodes.length === 1) {
      return this.hasImage(element.childNodes[0]);
    }
    return (element as HTMLElement)?.tagName?.toLowerCase() === 'img';
  }
}
