import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NotificationResponse } from '@core/interfaces';
import { NotificationMenuItemComponent } from '../../notification-menu-item/notification-menu-item.component';

@Component({
  selector: 'app-acl-request-granted',
  templateUrl: './acl-request-granted.component.html',
  styleUrls: ['./acl-request-granted.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AclRequestGrantedComponent extends NotificationMenuItemComponent {
  @Input() notification: NotificationResponse;

  goToAction(): void {
    if (this.notification.data?.access_level === 'workspace_access') {
      this.router.navigate([this.routingService.DOCUMENTS.url()]);
    } else {
      this.goToOriginalWorkflow(this.notification);
    }
  }
}
