import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { ICompanyNotificationSettings } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class NotificationSettingsService {
  private notificationSettingsPath = '/api/notification-setting/';

  constructor(private apiService: ApiService) {
  }

  public getNotificationSettings(): Observable<ICompanyNotificationSettings[]> {
    return this.apiService.get(this.notificationSettingsPath);
  }

  public getNotificationSetting(id: string): Observable<ICompanyNotificationSettings> {
    return this.apiService.get(this.notificationSettingsPath + id);
  }

  public updateNotificationSettings(settings: ICompanyNotificationSettings) {
    return this.apiService.put(this.notificationSettingsPath + settings.uuid, settings);
  }
}
