<div class="dashboard-container flex-100 flex-wrap" *ngIf="currentUser$ | async">
  <div id="dashboard-actions" class="flex-100">
    <div class="toggle-edit">
      <mat-slide-toggle (change)="editToggle($event)" [(ngModel)]="editDashboard">Edit Dashboard</mat-slide-toggle>
      <span id='save-layout' *ngIf="editDashboard">
        <app-styleguide-simple-button
          type="green-filled"
          label="Save Layout"
          icon="icon-n-save"
          [disabled]="widgetsSaved"
          (onClick)="saveLayout()">
        </app-styleguide-simple-button>
      </span>
    </div>
    <div class="add-widget-container flex-50 justify-end items-center" *ngIf="editDashboard && supportedWidgets.length > 0">
      <span class="add-widget-label margin-right-10">Add Additional Widget:</span>
      <div class="select-container flex-35">
        <mat-select [(ngModel)]="widgetType"
                    (selectionChange)="widgetTypeChanged($event)">
          <mat-option *ngFor="let widgetName of supportedWidgets"
                      [value]="widgetName.type">
            {{widgetName.name}}
          </mat-option>
        </mat-select>
      </div>
        <div class="button-container">
          <span id='add' class="e-control e-btn e-lib e-primary">
            <app-styleguide-simple-button
              type="green-outline"
              label="Add Widget"
              (onClick)="onAdd()">
            </app-styleguide-simple-button>
          </span>
        </div>
    </div>
  </div>
  <div id="home-dashboard" class="flex-100 flex-wrap">
    <div class="no-widgets flex-100" *ngIf="dashboard.length === 0">You don’t have any widgets selected, please click on Edit Dashboard to start adding one.</div>

    <gridster [options]="options" class="flex-100">
      <gridster-item [ngStyle]="{'z-index': 0}" [item]="item" *ngFor="let item of dashboard">
        <div class="header-border flex-100"></div>
        <div class="header-title">{{ getItemName(item.type) }}</div>
        <button
                *ngIf="editDashboard"
                class="dashboard-delete-button"
                mat-mini-fab
                (mousedown)="removeItem($event, item)">
          <span class="icon-n-delete"></span>
        </button>
        <app-dashboard-widget [widget]="item" [resizeEvent]="resizeEvent"></app-dashboard-widget>
      </gridster-item>
    </gridster>
  </div>

</div>
