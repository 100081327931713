import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ISortQueryParameters } from '@core/interfaces';
import { Observable, Subject, from, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Auth } from 'aws-amplify';
import { AUTOLOGOUT_SESSION_ID } from '@core/constants/app-constants';

@Injectable()
export class ApiService {
  apiUrl: string;
  printingServiceUrl: string;

  constructor(private http: HttpClient) {
    const getUrl = window.location;
    const url = new URL(getUrl.protocol + '/' + getUrl.host);
    url.port = '';
    this.apiUrl = url.toString().substring(0, url.toString().length - 1) + '/latest';
    url.port = environment.microservices_ports.printing || '';
    this.printingServiceUrl = url.toString().substring(0, url.toString().length - 1) + '/printing-api';
    url.port = environment.microservices_ports.integrations || '';
  }
  private setHeaders(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };

    return new HttpHeaders(headersConfig);
  }

  private formatErrors(error: any) {
    return throwError(error);
  }

  private formatBufferErrors(error) {
    const decodedString = String.fromCharCode.apply(null, new Uint8Array(error?.error));
    const objResponse = JSON.parse(decodedString);

    return throwError(objResponse);
  }

  get<T>(path: string, params: HttpParams = new HttpParams()): Observable<T | any> {
    return this.http.get<T>(`${this.apiUrl}${path}`, {
      params,
      headers: this.setHeaders(),
      withCredentials: true
    })
      .pipe(catchError(this.formatErrors));
  }

  getBuffer(path: string, params: HttpParams = new HttpParams(), observe?): Observable<any> {
    return this.http.get(`${this.apiUrl}${path}`, {
      params,
      headers: this.setHeaders(),
      responseType: 'arraybuffer',
      withCredentials: true,
      observe: observe || 'body'
    })
      .pipe(catchError(this.formatBufferErrors));
  }

  getBufferForPublicFile(path: string, observe?): Observable<any> {
    return this.http.get(path, {
      responseType: 'arraybuffer',
      observe: observe || 'body'
    }).pipe(catchError(this.formatBufferErrors));
  }

  getImageFromFile(path: string, params: HttpParams = new HttpParams(), observe?): Observable<any> {
    return this.http.get(`${this.apiUrl}${path}`, {
      params,
      headers: this.setHeaders(),
      responseType: 'blob',
      withCredentials: true,
      observe: observe || 'body'
    })
      .pipe(catchError(this.formatErrors));
  }

  put(path: string, body: object): Observable<any> {
    return this.http.put(
      `${this.apiUrl}${path}`,
      body,
      { headers: this.setHeaders() }
    ).pipe(catchError(this.formatErrors));
  }

  patch(path: string, body: object): Observable<any> {
    return this.http.patch(
      `${this.apiUrl}${path}`,
      body,
      { headers: this.setHeaders() }
    ).pipe(catchError(this.formatErrors));
  }

  post(path: string, body: object): Observable<any> {
    return this.http.post(
      `${this.apiUrl}${path}`,
      body,
      { headers: this.setHeaders(), withCredentials: true }
    ).pipe(catchError(this.formatErrors));
  }

  postBase64Image(path: string, body: object): Observable<string> {
    return this.http.post(
      `${this.apiUrl}${path}`,
      body,
      { headers: this.setHeaders(), withCredentials: true, responseType: 'text' }
    ).pipe(catchError(this.formatErrors));
  }

  postToPdfService(path: string, body: object, responseType?): Observable<any> {
    return this.http.post(
      `${this.printingServiceUrl}${path}`,
      body,
      { headers: this.setHeaders(), responseType: responseType || 'arraybuffer' }
    ).pipe(catchError(this.formatErrors));
  }

  postToBackendPdfService(path: string, body: object, responseType?): Observable<any> {
    return this.http.post(
      `${this.apiUrl}${path}`,
      body,
      { headers: this.setHeaders(), responseType: responseType || 'arraybuffer' }
    ).pipe(catchError(this.formatErrors));
  }

  delete(path: string): Observable<any> {
    return this.http.delete(
      `${this.apiUrl}${path}`,
      { headers: this.setHeaders() }
    ).pipe(catchError(this.formatErrors));
  }

  deleteWithBody(path: string, body: any): Observable<any> {
    return this.http.delete(`${this.apiUrl}${path}`,
      {
        headers: this.setHeaders(),
        body
      }
    ).pipe(catchError(this.formatErrors));
  }

  deleteWithKeepAlive(path: string): Observable<any> {
    const url = `${this.apiUrl}${path}`;
    const returnSbj = new Subject();
    Auth.currentSession().then((session) => {
      const token = `Bearer ${session.getAccessToken().getJwtToken()}`;
      const autoLogoutSessionId = localStorage.getItem(AUTOLOGOUT_SESSION_ID);
      fetch(url, {
        method: 'delete',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token,
          'X-Id-Token': session.getIdToken().getJwtToken(),
          'X-Id-Session': autoLogoutSessionId

        },
        keepalive: true
      }).then(x => {
        returnSbj.next(x);
      })
    })
    return returnSbj.asObservable();
  }

  postMultipartForm(path: string, body: FormData): Observable<any> {
    return this.http.post(
      `${this.apiUrl}${path}`,
      body,
      { headers: {} }
    ).pipe(catchError(this.formatErrors));
  }

  putMultipartForm(path: string, body: FormData): Observable<any> {
    body.append('_method', 'PUT');
    return this.http.post(
      `${this.apiUrl}${path}`,
      body,
      { headers: {}, withCredentials: true }
    ).pipe(catchError(this.formatErrors));
  }

  addSortQueryParams(sortQueryParams: ISortQueryParameters) {
    let params = new HttpParams();
    if (!sortQueryParams) {
      return params;
    }
    Object.entries(sortQueryParams)
      .filter(([key, value]) => typeof value !== 'undefined' && value !== null)
      .forEach(([key, value]) => params = params.set(key, value.toString()));
    return params;
  }

  addFilterQueryParams(queryParams: HttpParams, filters) {
    if (!filters) {
      return queryParams;
    }
    Object.keys(filters)
      .filter(key => typeof filters[key] !== 'undefined')
      .forEach(key => {
        if (Array.isArray(filters[key])) {
          (filters[key] as string[]).forEach((x, i) => {
            queryParams = queryParams.set('filters[' + key + '][' + i + ']', x);
          });
        } else {
          queryParams = queryParams.set('filters[' + key + ']', filters[key]);
        }
      });
    return queryParams;
  }

  addExtraQueryParams(queryParams: HttpParams, extraQueryParams) {
    let params = queryParams || new HttpParams();
    if (extraQueryParams) {
      Object.keys(extraQueryParams)
        .filter(key => !!extraQueryParams[key])
        .forEach(key => {
          if (Array.isArray(extraQueryParams[key])) {
            (extraQueryParams[key] as string[]).forEach((x, i) => {
              params = params.set(`${key}[${i}]`, x);
            });
          } else {
            if (key === 'search') {
              params = params.set(key, encodeURIComponent(extraQueryParams[key]));
            } else {
              params = params.set(key, extraQueryParams[key]);
            }
          }
        });
    }
    return params;
  }
}
