import { Directive, Input, OnInit } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { SMALL_TOOLTIP_CHARACTER_LENGTH } from '@core/constants/app-constants';

@Directive({
  selector: '[matTooltip][appTooltipSize]'
})
export class TooltipSizeDirective implements OnInit {
  constructor(private matTooltip: MatTooltip,
  ) { }

  ngOnInit(): void {
    const isTooltipLarge = this.matTooltip.message.length > SMALL_TOOLTIP_CHARACTER_LENGTH;
    this.matTooltip.tooltipClass = `${this.matTooltip.tooltipClass} ${isTooltipLarge ? ' large' : 'small'}`;
  }
}
