<div class="btr-modal-container">
  <span mat-dialog-title>File Export</span>
  <mat-dialog-content>
    <div class="flex-col">
      <ng-container *ngIf="files.length > 0">
        <div class="generated-file flex-100 justify-between items-center"
             [ngClass]="{'loading': loading}"
             [class.overlay]="file.isLoading" 
             *ngFor="let file of files">
          <div class="title flex-col flex-100">
            <div>
              <span *ngIf="!ExecutedTestScriptDataTypes.includes(file.document_type)"> 
                <span *ngIf="file.document_type === 'package'"> Files from </span>  
                <span *ngIf="file.document_type !== 'package'"> {{file.export_type === 'word' ? '[Word]' : '[' + file.export_type.toUpperCase() +']'}} </span> 
              </span>
              {{file.display_label ? file.display_label : file.document_title}}{{file.document_type === 'workflow' && file.version ? file.sdox_document_category && file.sdox_document_category === 'test_script_execution' ? '_R' + file.version : '_V' + file.version : ''}}
            </div>
            <div class="subtitle" *ngIf="file.status === 'Pending'">
              Started on {{file.batch_timestamp | dateFormat: company.config.dateformat : company.config.timezone : company.config.timeformat}}
            </div>
            <div class="subtitle" *ngIf="file.status !== 'Pending'">
              Finished on {{file.batch_end | dateFormat: company.config.dateformat : company.config.timezone : company.config.timeformat}}
            </div>
            <div *ngIf="file.is_archive_or_printing && file.status === 'Failed' && file.document_type !== 'package'" class="subtitle flex-100">
              <span class="failed-text flex-100">Downloading with attachments failed, please try the 'Without attachments' option</span>
            </div>
          </div>
          <div class="status flex-row justify-center items-center">

            <div *ngIf="file.status === 'Pending'" class="flex-100 justify-end items-center flex-gap-18">
              <span class="flex-100">(In Progress)</span>
              <span 
              matTooltipClass="btr-basic small"
              matTooltip="Remove from list"
              class="icon-n-close delete-icon" (click)="removeFile(file)"></span>
            </div>
            <div *ngIf="file.status === 'Done'" class="flex-100 justify-end items-center flex-gap-18">
              <span
              matTooltipClass="btr-basic small"
              matTooltip="Download File"
              class="icon-n-download download-icon" (click)="downloadFile(file)"></span>
              <span
              matTooltipClass="btr-basic small"
              matTooltip="Remove from list"
              class="icon-n-close delete-icon" (click)="removeFile(file)"></span>
            </div>
            <div *ngIf="file.status === 'Failed'" class="flex-100 justify-end items-center flex-gap-18">
              <span class="failed flex-100">(Failed)</span>
              <span 
              matTooltipClass="btr-basic small"
              matTooltip="Remove from list"
              class="icon-n-close delete-icon" (click)="removeFile(file)"></span>
            </div>
          </div>
          <app-loading-animation *ngIf="file.isLoading"
                                [withLogo]="false"
                                [size]="40"
                                [isLocal]="true">
          </app-loading-animation>
        </div>
      </ng-container>
      <ng-container *ngIf="files.length === 0">
        <span class="no-data-text">There are no PDFs or Word Documents in the Queue</span>
      </ng-container>
    </div>
  </mat-dialog-content>
  <mat-progress-bar *ngIf="loading" color="accent" mode="query"></mat-progress-bar>

  <mat-dialog-actions>
    <app-styleguide-simple-button
      type="green-link"
      label="Close"
      (onClick)="close()">
    </app-styleguide-simple-button>
  </mat-dialog-actions>
</div>
