import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileThumbnailOrIconComponent } from './file-thumbnail-or-icon.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule
  ],
  declarations: [
    FileThumbnailOrIconComponent
  ],
  exports: [
    FileThumbnailOrIconComponent
  ]
})
export class FileThumbnailOrIconModule {
}
