import { Injectable } from '@angular/core';
import { ICanvasStep } from '@core/interfaces';
import { CanvasService } from '@core/services/canvas.service';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { RuntimeService } from './runtime.service';

interface IStepData {
  step: ICanvasStep;
  cb?: () => void;
  updateErrors?: any;
  submitErrors?: any;
}

@Injectable({
  providedIn: 'root',
})
export class StepsRuntimeService {
  handledStep: ICanvasStep;
  private submittedStepSubject: BehaviorSubject<IStepData> = new BehaviorSubject(null);

  constructor(private runtimeService: RuntimeService) { }

  setSubmittedStepSubject(data: IStepData) {
    this.submittedStepSubject.next(data);
  }

  getSubmittedStepSubject() {
    return this.submittedStepSubject;
  }

  stepSubmitted(
    workflowUuid: string,
    versionUuid: string,
    step: ICanvasStep,
    cb?: () => void
  ) {
    return this.runtimeService.updateRuntimeStep(
      workflowUuid,
      versionUuid,
      step.fe_type,
      step.uuid,
      step
    ).pipe(
      catchError(err => {
        return throwError({
          step,
          cb,
          updateErrors: err
        });
      }),
      switchMap(res => {
        return this.runtimeService.submitRuntimeStep(
          workflowUuid,
          versionUuid,
          step.fe_type,
          step.uuid
        ).pipe(
          catchError(err => {
            return throwError({
              step,
              cb,
              submitErrors: err
            });
          }),
        );
      }),
      take(1),
    ).subscribe(_res => {
      this.setSubmittedStepSubject({
        step: _res.data,
        cb,
      });
    },
      err => {
        this.setSubmittedStepSubject(err);
      });
  }
}
