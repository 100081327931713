import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NotificationResponse } from '@core/interfaces';
import { NotificationMenuItemComponent } from '../../notification-menu-item/notification-menu-item.component';

@Component({
  selector: 'app-misc-user-reached-failed-logins-limit',
  templateUrl: './misc-user-reached-failed-logins-limit.component.html',
  styleUrls: ['./misc-user-reached-failed-logins-limit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserReachedFailedLoginsLimitComponent extends NotificationMenuItemComponent {
  @Input() notification: NotificationResponse;
}
