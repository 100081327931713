import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { MaterialModule } from '@core/modules/material.module';
import { ButtonsModule } from '@shared/components/buttons/buttons.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MultiStepConfirmationModule } from '@shared/components/multi-step-confirmation/multi-step-confirmation.module';
import { SignStepComponent } from './sign-step.component';
import { ElectronicSignatureModule } from '../electronic-signature/electronic-signature.module';
import { FileUploadModule } from '../file-upload/file-upload.module';
import { FileThumbnailOrIconModule } from '../file-thumbnail-or-icon/file-thumbnail-or-icon.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    CoreModule,
    ButtonsModule,
    ReactiveFormsModule,
    MultiStepConfirmationModule,
    ElectronicSignatureModule,
    FileUploadModule,
    FileThumbnailOrIconModule
  ],
  declarations: [
    SignStepComponent,
  ],
  exports: [
    SignStepComponent
  ],
  providers: []
})
export class SignStepModule {
}
