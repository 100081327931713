import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { INotificationPaginatedData, NotificationResponse } from '../interfaces';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private notificationsPath = '/api/notification';

  private pollInterval = 10000;
  private notifications$: Subject<INotificationPaginatedData<NotificationResponse>>;

  constructor(
    private apiService: ApiService,
    private store: Store
  ) { }

  getNotifications(offset: number, limit: number): Observable<INotificationPaginatedData<NotificationResponse>> {
    return this.getNotificationsRequest(offset, limit);
  }

  markAsRead(id: string): Observable<{ success: true }> {
    return (this.apiService.post(`${this.notificationsPath}/${id}/toggle-read`, {}));
  }

  resetCounter() {
    return (this.apiService.post(`${this.notificationsPath}/reset-counter`, {}));
  }

  private getNotificationsRequest = (offset: number, limit: number): Observable<INotificationPaginatedData<NotificationResponse>> => {
    return this.apiService.get(`${this.notificationsPath}/${offset}/${limit}`).pipe(
      filter(res => !!res),
      catchError(err => {
        return throwError(err);
      })
    );
  }
}
