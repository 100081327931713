export class StartAsyncPdfCheck {
  static readonly type = '[Async PDF Generator] StartAsyncPdfCheck';
  constructor(public userUuid: string) {}
}

export class StopAsyncPdfCheck {
  static readonly type = '[Async PDF Generator] StopAsyncPdfCheck';
}

export class GetGeneratedFiles {
  static readonly type = '[Async PDF Generator] GetGeneratedFiles';
  constructor(public userUuid: string) {}
}

export class RemoveGeneratedFile {
  static readonly type = '[Async PDF Generator] RemoveGeneratedFile';
  constructor(public batchId: string) {}
}
