import { AccessLevel } from '@core/constants';
import { PermissionList, Permissions } from '@core/constants/permissions.enum';
import { TCActionStatus } from '@core/interfaces';
import { IUser } from '@core/models';
import { Selector } from '@ngxs/store';
import { DocumentSelectors } from 'src/app/documents/store/document.selectors';
import { UserState, UserStateModel } from './user.state';

export class UserSelectors {
  @Selector([UserState])
  static getCurrentUser(state: UserStateModel) {
    return state.user;
  }

  @Selector([UserState])
  static isLoading(state: UserStateModel) {
    return state.loading;
  }

  @Selector([UserState])
  static getLogoutInProgress(state: UserStateModel) {
    return state.logoutInProgress;
  }

  @Selector([UserSelectors.getCurrentUser])
  static hasSignaturePassword(user: IUser) {
    return user?.has_signature_password;
  }

  @Selector([UserSelectors.getCurrentUser])
  static hasPinCode(user: IUser) {
    return user?.has_pin_code;
  }

  @Selector([UserSelectors.getCurrentUser])
  static getCurrentUserUuid(user: IUser) {
    return user?.uuid;
  }

  @Selector([UserSelectors.getCurrentUser])
  static getCurrentUserDefaultPagination(user: IUser) {
    return user?.default_pagination;
  }

  @Selector([UserSelectors.getCurrentUser])
  static getUserAccessLevel(user: IUser) {
    if (!user) {
      return null;
    } else if (user.is_btr_admin) {
      return AccessLevel.BTRAdmin;
    }
    return AccessLevel.User;
  }

  @Selector([UserSelectors.getCurrentUser])
  static getUserPermissions(user: IUser) {
    const permissionList = {} as PermissionList;
    if (user && !user.is_btr_admin) {
      Object.keys(Permissions).forEach(key => {
        permissionList[key] = !!user.permissions.includes(Permissions[key]);
      });
    }
    return permissionList;
  }

  @Selector([UserState])
  static isLoggedIn(state: UserStateModel) {
    return state.loggedIn;
  }

  @Selector([UserSelectors.getUserAccessLevel])
  static isBTRAdmin(accessLevel: AccessLevel) {
    // sau verificam pe user user.is_btr_admin?
    return accessLevel === AccessLevel.BTRAdmin;
  }

  @Selector([DocumentSelectors.getReviewRequest, UserSelectors.getCurrentUserUuid])
  static isPartOfReviewSeries(reviewRequest: any, currentUserUuid: string) {
    return reviewRequest?.series[0].some(s => s.uuid === currentUserUuid);
  }

  @Selector([DocumentSelectors.getReviewRequest, UserSelectors.getCurrentUserUuid])
  static canCurrentUserFinalizeReview(reviewRequest: any, currentUserUuid: string) {
    return reviewRequest?.series[0].some(s => s.uuid === currentUserUuid && s.action_status === TCActionStatus.Pending);
  }

  @Selector([UserSelectors.getCurrentUserUuid, DocumentSelectors.getLockedByUserUuid])
  static isReviewLockedByCurrentUser(currentUserUuid: string, lockedByUserUuid: string) {
    return currentUserUuid === lockedByUserUuid;
  }

  @Selector([DocumentSelectors.getExecutionRequest, UserSelectors.getCurrentUserUuid])
  static isPartOfExecutionSeries(executionRequest: any, currentUserUuid: string) {
    return executionRequest?.series[0]?.some(s => s.uuid === currentUserUuid);
  }
}
