import { Injectable } from '@angular/core';

interface Link {
  url(args?: string[]): string;
}

/*
 * Defines the internal links that one can navigate to.
 * Usage:
 * this.RoutingService.HOME.url();
 * this.RoutingService.PASSWORD_RESET.url(['someToken']);
 */
@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  readonly HOME: Link = { url: () => `/home` };
  readonly ADMIN: Link = { url: () => `/admin` };
  readonly LOGIN: Link = { url: () => `/login` };
  readonly FORGOT_PASSWORD: Link = { url: () => `/forgot-password` };
  readonly FORGOT_PASSWORD_ROUTED_FROM_LOGIN: Link = { url: () => `/forgot-password/routed-from-login` };
  readonly CHANGE_PASSWORD: Link = { url: () => `/change-password` };
  readonly MISSING_ACCOUNT: Link = { url: () => `/missing-account` };
  readonly MISSING_ACCOUNT_LOGIN: Link = { url: () => `/missing-account:login` };
  readonly TASKS: Link = { url: () => `/tasks` };
  readonly CMS: Link = { url: () => `/cms` };
  readonly PROFILE_BASIC_INFORMATION: Link = { url: () => `/profile/basic-information` };
  readonly PROFILE_MY_GROUPS: Link = { url: () => `/profile/my-groups` };
  readonly PROFILE_MY_ACCESS_GROUPS: Link = { url: () => `/profile/my-access-groups` };
  readonly PROFILE_USER_SETTINGS: Link = { url: () => `/profile/user-notification-settings` };
  readonly PROFILE_API_KEYS: Link = { url: () => `/profile/api-keys` };
  readonly COMPANY: Link = { url: () => `/company` };
  readonly COMPANY_ADMIN: Link = { url: () => `/company-admin` };
  readonly COMPANY_INFO: Link = { url: () => `/company-admin/information/company-details` };
  readonly COMPANY_LICENSES: Link = { url: () => `/company-admin/licenses-users/licenses` };
  readonly COMPANY_USERS: Link = { url: () => `/company-admin/licenses-users/company-users` };
  readonly COMPANY_CONFIGURATION: Link = { url: () => `/company/configuration` };
  readonly COMPANY_UNDER_MAINTENANCE: Link = { url: () => `/company/under-maintenance` };
  readonly COMPANIES: Link = { url: () => `/companies` };
  readonly COMPANIES_COMPANY_DETAILS: Link = { url: ([id]) => `/companies/${id}/company-details` };
  readonly COMPANIES_PARENT_CHILD: Link = { url: ([id]) => `/companies/${id}/parent-child-companies` };
  readonly COMPANIES_COMPANY_CONFIGURATION: Link = { url: ([id]) => `/companies/${id}/settings` };
  readonly COMPANIES_COMPANY_MODULES: Link = { url: ([id]) => `/companies/${id}/modules` };
  readonly COMPANIES_COMPANY_LICENSES: Link = { url: ([id]) => `/companies/${id}/licenses` };
  readonly COMPANIES_COMPANY_USERS: Link = { url: ([id]) => `/companies/${id}/company-users` };
  readonly COMPANIES_COMPANY_PENDING_SSO_USERS: Link = { url: ([id]) => `/companies/${id}/pending-sso-users` };
  readonly DOCUMENTS: Link = { url: () => `/documents` };
  readonly DOCUMENTS_DOCUMENT_INFO: Link = {
    url: ([workflowId, versionId, stepId, rowId]) => {
      let url = `/documents/${workflowId}/version/${versionId}`;
      if (stepId) {
        url += `/step/${stepId}`;
      }

      if (rowId) {
        url += `/row/${rowId}`;
      }

      return url;
    }
  };
  readonly DOCUMENTS_REVIEW_INFO: Link = {
    url: ([workflowId, versionId, originalVersionRootUuid, originalUuid]) => {
      return `/documents/${workflowId}/version/${versionId}/review-session/${originalVersionRootUuid}/version/${originalUuid}`;
    }
  };
  readonly DOCUMENTS_PREVIEW_ATTCHMENTS: Link = {
    url: ([workflowId, versionId, stepUuid, rowUuid, selectedAttachmentIndex, source, cloneParentUuid]) => {
      return `/documents/${workflowId}/version/${versionId}/attachments/${stepUuid}/${rowUuid}/${selectedAttachmentIndex}/${source}${(cloneParentUuid ? ('/' + cloneParentUuid) : '')}`;
    }
  };
  readonly WORKFLOWS: Link = { url: () => `/documents` };
  readonly WORKFLOWS_WORKFLOW_INFO: Link = {
    url: ([workflowId, versionId, stepId, rowId]) => {
      let url = `/workflows/${workflowId}/version/${versionId}`;
      if (stepId) {
        url += `/step/${stepId}`;
      }

      if (rowId) {
        url += `/row/${rowId}`;
      }

      return url;
    }
  };
  readonly TEMPLATES: Link = { url: () => `/templates` };
  readonly TEMPLATES_TEMPLATE_INFO: Link = { url: ([workflowId, versionId]) => `/templates/${workflowId}/version/${versionId}` };
  readonly MY_TASKS: Link = { url: () => `/my-tasks` };
  readonly PLATFORM_MONITORING_POD: Link = { url: () => `/platform-monitoring/pod` };
  readonly MARKETPLACE: Link = { url: () => `/marketplace` };
  readonly MARKETPLACE_MY_PACKAGES: Link = { url: () => `/marketplace/my-packages` };
  readonly MARKETPLACE_MY_PACKAGES_UPLOADS: Link = { url: () => `/marketplace/my-packages/uploads` };
  readonly MARKETPLACE_MY_PACKAGES_DOWNLOADS: Link = { url: () => `/marketplace/my-packages/downloads` };
  readonly MARKETPLACE_PACKAGE_APPROVAL_REQUESTS_HISTORY: Link = { url: () => `/marketplace/package-approval-requests-history` };
  readonly MARKETPLACE_PACKAGE_APPROVAL_REQUESTS: Link = { url: () => `/marketplace/package-approval-requests` };
  readonly MARKETPLACE_INFO: Link = { url: ([packageId, versionId]) => `marketplace/edit/${packageId}/version/${versionId}` };
  readonly MARKETPLACE_PREVIEW_RESOURCE: Link = { url: ([packageId, packageVersionRootUuid, workflowId, versionId, docType]) => `/marketplace/package/${packageVersionRootUuid}/version/${packageId}/${docType}/${workflowId}/version/${versionId}` };
  readonly MARKETPLACE_PRIVATE: Link = { url: () => '/marketplace/home/private' };

  readonly COMPANY_ADMIN_INFORMATION_DETAILS: Link = { url: () => `/company-admin/information/company-details` };
  readonly COMPANY_ADMIN_INFORMATION_CONTACTS: Link = { url: () => `/company-admin/information/company-contacts` };
  readonly COMPANY_ADMIN_INFORMATION_DEPARTMENTS_SITES: Link = { url: () => `/company-admin/information/departments-sites` };
  readonly COMPANY_ADMIN_INFORMATION_PARENT_CHILD_COMPANIES: Link = { url: () => `/company-admin/information/parent-child-companies` };
  readonly COMPANY_ADMIN_LICENSES_USERS_PERMISSIONS: Link = { url: () => `/company-admin/licenses-users/users-permissions` };
  readonly COMPANY_ADMIN_LICENSES: Link = { url: () => `/company-admin/licenses-users/licenses` };
  readonly COMPANY_ADMIN_USERS: Link = { url: () => `/company-admin/licenses-users/company-users` };
  readonly COMPANY_PENDING_SSO_USERS: Link = { url: () => `/company-admin/licenses-users/pending-sso-users` };
  readonly COMPANY_ADMIN_ACCESS_GROUPS: Link = { url: () => `/company-admin/licenses-users/access-groups` };

  readonly COMPANY_ADMIN_GENERAL_SETTINGS: Link = { url: () => `/company-admin/settings/general-settings` };
  readonly COMPANY_ADMIN_FILTER_TAGS_SETTINGS: Link = { url: () => `/company-admin/settings/filter-tags-settings` };
  readonly COMPANY_ADMIN_PREFIX_SETTINGS: Link = { url: () => `/company-admin/settings/prefix` };
  readonly COMPANY_ADMIN_SIGNATURE_SETTINGS: Link = { url: () => `/company-admin/settings/signature` };


  readonly COMPANY_ADMIN_SYSTEMS: Link = { url: () => `/company-admin/systems` };
  readonly GLOBAL_SYSTEMS: Link = { url: () => `/global-systems` };

  readonly NOT_FOUND: Link = { url: () => `/404` };
  readonly COMPANY_NOT_FOUND: Link = { url: () => `/not-found` };
  readonly BLANK: Link = { url: () => `/blank` };

  readonly AUDIT_TRAIL_ADMIN: Link = { url: () => `/audit-trail-admin` };
  readonly AUDIT_TRAIL_ADMIN_FULL: Link = { url: () => `/audit-trail-admin/full-audit-trail` };
  readonly AUDIT_TRAIL_ADMIN_ADMIN: Link = { url: () => `/audit-trail-admin/admin` };
  readonly AUDIT_TRAIL_ADMIN_COMPANY_DETAILS: Link = { url: () => `/audit-trail-admin/company-details` };
  readonly AUDIT_TRAIL_ADMIN_COMPANY_USERS: Link = { url: () => `/audit-trail-admin/company-users` };
  readonly AUDIT_TRAIL_ADMIN_COMPANY_SETTINGS: Link = { url: () => `/audit-trail-admin/company-settings` };
  readonly AUDIT_TRAIL_ADMIN_POD_MANAGEMENT: Link = { url: () => `/audit-trail-admin/pod-management` };
  readonly AUDIT_TRAIL_ADMIN_MARKETPLACE: Link = { url: () => `/audit-trail-admin/marketplace` };
  readonly AUDIT_TRAIL_ADMIN_GLOBAL_SYSTEMS: Link = { url: () => `/audit-trail-admin/global-systems` };
  readonly AUDIT_TRAIL_ADMIN_CMS_DASHBOARD: Link = { url: () => `/audit-trail-admin/cms-dashboard` };

  readonly AUDIT_TRAIL: Link = { url: () => `/audit-trail` };
  readonly AUDIT_TRAIL_FULL: Link = { url: () => `/audit-trail/system-audit-trail` };
  readonly AUDIT_TRAIL_TEMPLATES: Link = { url: () => `/audit-trail/templates` };
  readonly AUDIT_TRAIL_DOCUMENTS: Link = { url: () => `/audit-trail/documents` };
  readonly AUDIT_TRAIL_WORKFLOWS: Link = { url: () => `/audit-trail/workflows` };
  readonly AUDIT_TRAIL_COMPANY_USERS: Link = { url: () => `/audit-trail/company-users` };
  readonly AUDIT_TRAIL_PERMISSIONS: Link = { url: () => `/audit-trail/user-permissions` };
  readonly AUDIT_TRAIL_COMPANY_SETTINGS: Link = { url: () => `/audit-trail/company-settings` };
  readonly AUDIT_TRAIL_LOGIN: Link = { url: () => `/audit-trail/login` };
  readonly AUDIT_TRAIL_ACCESS_GROUPS: Link = { url: () => `/audit-trail/access-groups` };
  readonly AUDIT_TRAIL_FILTER_TAGS: Link = { url: () => `/audit-trail/filter-tags` };
  readonly AUDIT_TRAIL_IA: Link = { url: () => `/audit-trail/impact-assessments` };
  readonly AUDIT_TRAIL_COMPANY_DETAILS: Link = { url: () => `/audit-trail/company-details` };
  readonly AUDIT_TRAIL_COMPANY_CONTACTS: Link = { url: () => `/audit-trail/company-contacts` };
  readonly AUDIT_TRAIL_DEPARTMENTS_SITES: Link = { url: () => `/audit-trail/departments-sites` };
  readonly AUDIT_TRAIL_PARENT_CHILD: Link = { url: () => `/audit-trail/parent-child-companies` };
  readonly AUDIT_TRAIL_COMPANY_SYSTEMS: Link = { url: () => `/audit-trail/company-systems` };
  readonly AUDIT_TRAIL_LICENSES: Link = { url: () => `/audit-trail/licenses` };
  readonly AUDIT_TRAIL_PREFIXES: Link = { url: () => `/audit-trail/prefixes` };
  readonly AUDIT_TRAIL_SIGNATURE_SETTINGS: Link = { url: () => `/audit-trail/signature-settings` };
  readonly AUDIT_TRAIL_REPORTS_EXPORTS: Link = { url: () => `/audit-trail/reports-exports` };

  readonly REQEURST_ACCESS: Link = { url: () => `/request-access` };

  readonly REPORTS: Link = { url: () => `/reports` };
  readonly REPORTS_ADMIN: Link = { url: () => `/reports-admin` };
  readonly REPORTS_LICENSES_ADMIN: Link = { url: () => `/reports-admin/licenses` };
  readonly REPORTS_USAGES_ADMIN: Link = { url: () => `/reports-admin/usages` };
  readonly REPORTS_USERS_ADMIN: Link = { url: () => `/reports-admin/users` };
  readonly REPORTS_DASHBOARD: Link = { url: () => `/reports/dashboard` };
  readonly REPORTS_SYSTEMS: Link = { url: () => `/reports/systems` };
  readonly REPORTS_WORKFLOWS: Link = { url: () => `/reports/workflows` };
  readonly REPORTS_WORKFLOW_DELIVERABLES: Link = { url: () => `/reports/workflow-deliverables` };
  readonly REPORTS_TEST_SCRIPTS: Link = { url: () => `/reports/test-scripts` };
  readonly REPORTS_DEVIATIONS: Link = { url: () => `/reports/deviations` };
  readonly REPORTS_TASKS: Link = { url: () => `/reports/tasks` };
  readonly REPORTS_REVIEWS: Link = { url: () => `/reports/reviews` };
  readonly REPORTS_PERIODIC_REVIEWS: Link = { url: () => `/reports/periodic-reviews` };
  readonly REPORTS_PROJECT_MANAGEMENT: Link = { url: () => `/reports/project-management` };
  readonly REPORTS_KEY: Link = { url: ([key]) => `/reports/${key}` };

  _errorHandlingOverwriteRoutes = [];

  get errorHandlingOverwriteRoutes() {
    return this._errorHandlingOverwriteRoutes;
  }

  addErrorHandlingOverwriteRoutes(route: string) {
    if (!this.errorHandlingOverwriteRoutes.includes(route)) {
      this.errorHandlingOverwriteRoutes.push(route);
    }
  }

  removeErrorHandlingOverwriteRoutes(route) {
    if (this.errorHandlingOverwriteRoutes.includes(route)) {
      this.errorHandlingOverwriteRoutes.splice(this.errorHandlingOverwriteRoutes.indexOf(route), 1);
    }
  }
}
