import { Permissions } from '@core/constants/permissions.enum';

export interface ICompanyContact {
  uuid: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  created_at: string;
  updated_at: string;
  company_uuid: string;
}

export interface IUserLite {
  uuid: string;
  first_name: string;
  last_name: string;
  image_url: string;
  email: string;
  phone_number: string;
  last_login: string;
  is_sso: boolean;
  has_mfa: boolean;
  is_active: boolean;
  role: IUserRole;
  department: IDepartment;
  has_access?: boolean;
  requestedAccessUuid?: string;
}

export interface IUserRole {
  id: number;
  identifier: 'company_admin' | 'admin' | 'editor' | 'viewer' | 'client';
  name: string;
  order_index: number;
}

export interface IDepartment {
  uuid: string;
  name: string;
  company_uuid: string;
  users: IUserLite[];
  created_at: string;
  updated_at: string;
}

export interface IApiKey {
  uuid: string;
  name: string;
  key?: string;
  created_at: string;
}

export interface IUser extends IUserLite {
  company_uuid: string;
  is_btr_admin: boolean; // True for BTR admins. False for company users. BTRAdmin will not have role & department & permissions array
  has_pin_code: boolean;
  has_signature_password: boolean;
  sso_convert_status: string;
  sso_status: string;
  cognito_status: string;
  permissions: Permissions;
  created_at: string;
  updated_at: string;
  default_pagination: number;
}

export enum RoleType {
  COMPANY_ADMIN = 'company_admin',
  ADMIN = 'admin',
  EDITOR = 'editor',
  VIEWER = 'viewer',
  CLIENT = 'client'
}

export enum RoleTypeLabel {
  company_admin = 'Company Admin',
  admin = 'Admin',
  editor = 'Editor',
  viewer = 'Viewer',
  client = 'Client'
}

export enum RoleTypeMapping {
  'Company Admin' = 'company_admin',
  'Admin' = 'admin',
  'Editor' = 'editor',
  'Viewer' = 'viewer',
  'Client' = 'client'
}
