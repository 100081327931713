import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@core/modules/material.module';
import { SearchInputComponent } from './search-input.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule
  ],
  declarations: [
    SearchInputComponent,
  ],
  exports: [
    SearchInputComponent,
  ],
})
export class SearchInputModule { }
