<div class="item-data">
    <app-icon-two-images
        bigImg="comment2"
        smallImg="replace-doc"
        [isSuccess]="true">
    </app-icon-two-images>
    <div class="item-details" (click)="goToOriginalWorkflow(notification)">
        <div class="item-details-title">
            The review request on <strong>{{notification.data?.link?.title ? notification.data?.link?.title : '[deleted workflow]'}}</strong> was updated.
        </div>
        <div class="item-details-time" *ngIf="company$ | async as company">
            {{notification.created_at | dateFormat : null : company.config.timezone : company.config.timeformat}}
        </div>
    </div>
</div>
<app-notification-bullet [notification]="notification"></app-notification-bullet>
