<div class="qb-container">
  <mat-accordion
    cdkDropList
    #unassignedList="cdkDropList"
    [cdkDropListData]="questions"
    (cdkDropListDropped)="dropQuestion($event)"
    multi="true">
    <mat-expansion-panel
      cdkDrag
      *ngFor="let question of questions; let i = index" [expanded]="question.expanded || mode === ImpactAssessmentMode.Preview">
      <mat-expansion-panel-header class="panel-header align-center">
        <mat-panel-title class="align-center">
          <mat-icon class="pointer green-text large-icon" cdkDragHandle>height</mat-icon>
          {{level > 0 ? 'Sub-Question' : 'Question'}} {{i + 1}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-question [question]="question"
                    [level]="level"
                    [mode]="mode"
                    (questionChanged)="questionChanged()"
                    (questionDeleted)="deleteQuestion(i)"></app-question>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="flex-row items-center">
    <app-styleguide-simple-button
      type="green-link"
      icon="icon-n-plus"
      [label]="level > 0 ? 'Add Sub-Question' : 'Add Question'"
      (onClick)="addQuestion()">
    </app-styleguide-simple-button>
  </div>
</div>
