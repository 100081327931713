<mat-card appearance="outline" class="workflow-create-card flex-100" (click)="goToWorkflows()">
  <div class="card-header flex-100 flex-wrap justify-between items-center">
    <div class="new-workflow-wrapper flex-100 justify-center">
      <div class="new-workflow-container headline-5 flex-row justify-center">
        <div class="plus-sign">+</div>
      </div>
    </div>
    <div class="create-new-text headline-5 flex-100">Create a New Workflow</div>
    <div class="new-workflow-description caption flex-100">You have no open workflows. Click to create one from the Workspace</div>
  </div>
</mat-card>
