import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { HelperService, RoutingService } from '@core/services';
import { UserSelectors } from '@store/user/user.selectors';
import { filter, map, switchMap } from 'rxjs/operators';
import { AccessLevel } from '@core/constants';
import { Permissions } from '@core/constants/permissions.enum';
import { of } from 'rxjs';
import { CompanySelectors } from '@store/company/company.selectors';
import { INavMenuItem } from '@core/interfaces';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class SectionsVisibilityGuardService  {
  constructor(
    private store: Store,
    private router: Router,
    private routingService: RoutingService,
    private helperService: HelperService,
    private toastr: ToastrService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot) {
    return this.store.select(UserSelectors.getCurrentUser)
      .pipe(
        switchMap(user => {
          if (this.store.selectSnapshot(UserSelectors.isBTRAdmin)) {
            return of(null);
          } else {
            return this.store.select(CompanySelectors.getCurrentCompany)
              .pipe(
                filter(x => !!x?.uuid)
              );
          }
        }),
        map(() => {
          const userAccessLevel = this.store.selectSnapshot(UserSelectors.getUserAccessLevel);
          if ((userAccessLevel === AccessLevel.BTRAdmin) ||
            this.store.selectSnapshot(UserSelectors.getCurrentUser).permissions.includes(Permissions.viewHomepage)
          ) {
            return true;
          } else {
            // redirect to the page with the lowest priority
            const navMenuItems: INavMenuItem[] = this.helperService.generateNavMenu(
              this.store.selectSnapshot(UserSelectors.getUserPermissions),
              this.store.selectSnapshot(UserSelectors.isBTRAdmin),
              this.store.selectSnapshot(CompanySelectors.getCurrentCompany),
            ).filter(item => item.isVisible &&
                             item.accessLevels.some(r => this.store.selectSnapshot(UserSelectors.getUserAccessLevel) === r))
              .sort((a, b) => a.priority < b.priority ? -1 : 1);

            if (!navMenuItems.length) {
              this.toastr.warning('You are not allowed to access this page');
              this.router.navigate([this.routingService.BLANK.url()]);
            } else {
              this.router.navigate([navMenuItems[0].url]);
            }

            return false;
          }
        })
      );
  }
}
