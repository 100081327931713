import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NotificationResponse } from '@core/interfaces';
import { NotificationMenuItemComponent } from '../../notification-menu-item/notification-menu-item.component';

@Component({
  selector: 'app-execution-request-cancelled-to-remaining-tester',
  templateUrl: './execution-request-cancelled-to-remaining-tester.component.html',
  styleUrls: ['./execution-request-cancelled-to-remaining-tester.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExecutionRequestCancelledToRemainingTesterComponent extends NotificationMenuItemComponent {
  @Input() notification: NotificationResponse;
}
