import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class HubSpotService {
  get hubSpotConversations() {
    return (window as any).HubSpotConversations;
  }
  get hubspotMessagesIframeContainer(): HTMLElement {
    return document.getElementById('hubspot-container');
  }
  loadScript() {
    if (!this.hubSpotConversations?.widget) {
      (window as any).hsConversationsSettings = {
        loadImmediately: true,
        inlineEmbedSelector: '#hubspot-container',
      };
      let node = document.createElement('script');
      node.src = '//js.hs-scripts.com/8383502.js';
      node.type = 'text/javascript';
      node.async = true;
      node.charset = 'utf-8';
      node.id = "HUB SPOT";
      document.getElementsByTagName('head')[0].appendChild(node);
      (window as any).hsConversationsOnReady = [this.onConversationsAPIReady];
    }
  }
  removeScript() {
    this.hubSpotConversations?.widget?.remove();
    this.hubspotMessagesIframeContainer.style.visibility = 'hidden';
  }
  toggleWidgetVisibility() {
    if (this.hubspotMessagesIframeContainer?.style.visibility === 'visible') {
      this.hideWidget();
    } else {
      this.showWidget();
    }
  }
  showWidget() {
    this.onConversationsAPIReady();
    this.hubspotMessagesIframeContainer.style.visibility = 'visible';
  }
  hideWidget() {
    this.hubspotMessagesIframeContainer.style.visibility = 'hidden';
  }
  private onConversationsAPIReady() {
    const iframe = document.querySelector('#hubspot-conversations-inline-iframe') as HTMLElement;
    if (iframe) {
      iframe.style.height = '500px';
      iframe.style.border = 'none';
    }
  }
}
