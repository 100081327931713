import { Injectable } from '@angular/core';
import { IExtraQueryParameters, IFilterDataValues, ISortQueryParameters } from '../interfaces';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AdminAuditTrailService {
  auditTrailPath = () => `/api/admin-audit-trail/all`;
  auditTrailTypes = (category) => `/api/admin-audit-trail/types/${category}`;

  constructor(private apiService: ApiService) {}

  public getAuditTrailTypes(category: string, sortQueryParams?: ISortQueryParameters, extraQueryParams?: IExtraQueryParameters) {
    const queryParams = this.apiService.addSortQueryParams(sortQueryParams);

    return this.apiService.get(this.auditTrailTypes(category), this.apiService.addExtraQueryParams(queryParams, extraQueryParams));
  }

  public getAuditTrail(offset: number, limit: number, filters: IFilterDataValues) {
    return this.apiService.post(`${this.auditTrailPath()}/${offset}/${limit}`, filters);
  }

}
