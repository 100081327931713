import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { COGNITO_NEW_PASSWORD_REQUIRED } from '@core/constants/app-constants';
import { IBranding } from '@core/interfaces';
import { CognitoHostedIdentityProviderLabel, ILoginData } from '@core/interfaces/company-cognito.interface';
import { ApiService, AuthenticationService, RoutingService } from '@core/services';
import { AutoUnsubscribe } from '@core/utilities/auto-unsub';
import { Auth, I18n } from 'aws-amplify';
import { filter, take } from 'rxjs/operators';
import {
  IdentityProviderSelectionModalComponent
} from '../../shared/login/identity-provider-selection-modal/identity-provider-selection-modal.component';
import { AuthenticatorService } from '@aws-amplify/ui-angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
@AutoUnsubscribe()
export class LoginComponent implements OnInit {
  email = new UntypedFormControl('', [Validators.required, Validators.email]);
  password = new UntypedFormControl('', [Validators.required]);
  loading = true;
  amplifyServices = {
    handleSignIn: (formData: ILoginData) => this.handleAmplifyLogin(formData),
    handleConfirmSignIn: async ({ user, code, mfaType }) => {
      const signInPromise = Auth.confirmSignIn(user, code, mfaType);
      signInPromise.then(res => {
        this.router.navigate([this.routingService.HOME.url()]);
      }).catch(err => {
        // Angular does not trigger the request in case of Promise.catch
        // FetchAPI is being used to trigger the login/failed-auth request
        fetch(`${this.apiService.apiUrl}/api/login/failed-auth`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          credentials: 'include',
          body: JSON.stringify({ email: this.userEmail }),
        });
      })
      return signInPromise;
    }
  };
  temporaryPasswordUsed = false;
  isLoggedIn = false;

  private userEmail = '';

  constructor(
    private router: Router,
    private routingService: RoutingService,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private apiService: ApiService,
    private authenticator: AuthenticatorService
  ) { }

  ngOnInit() {
    this.authenticator.signOut();
    this.authenticator.toSignIn(); 
    I18n.setLanguage('en');
    I18n.putVocabulariesForLanguage('en', {
      'Sign in': 'Log in', // Button label
      Email: 'Email address*', // Username label
      Password: 'Password*', // Password label
      'Enter your Email': 'Email address*',
      'Enter your Password': 'Password*',
      'Change Password': 'Create Password',
      Code: '2FA Code',
    });
    this.authenticationService.clearLocalStorageFilters();
    this.authenticationService.clearLocalStoragePagination();
  }

  goToForgetPasswordPage() {
    this.router.navigate([this.routingService.FORGOT_PASSWORD_ROUTED_FROM_LOGIN.url()]);
  }

  private handleAmplifyLogin(formData: ILoginData) {
    const signInPromise = Auth.signIn(formData.username, formData.password);

    signInPromise.then(res => {
      this.userEmail = formData.username;
      if (res.challengeName === COGNITO_NEW_PASSWORD_REQUIRED) {
        this.temporaryPasswordUsed = true;
        localStorage.setItem(COGNITO_NEW_PASSWORD_REQUIRED, JSON.stringify(true));
      } else {
        this.isLoggedIn = true;
      }
    }).catch(() => {
      // Angular does not trigger the request in case of Promise.catch
      // FetchAPI is being used to trigger the login/failed-auth request
      fetch(`${this.apiService.apiUrl}/api/login/failed-auth`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ email: formData.username }),
      });
    });
    return signInPromise;
  }

  goToSignInWithSSO(brandingData: IBranding) {
    if (brandingData.company.pool_config.idp_data.length > 1) {
      this.dialog.open(IdentityProviderSelectionModalComponent,
        {
          data: { idpData: brandingData.company.pool_config.idp_data },
          width: '500px'
        }
      ).afterClosed().pipe(
        filter(result => !!result)
      ).subscribe(result => {
        const options: any = CognitoHostedIdentityProviderLabel[result.name]
          ? { provider: result.name }
          : { customProvider: result.name };

        Auth.federatedSignIn(options);
      });
    } else {
      const options: any = CognitoHostedIdentityProviderLabel[brandingData.company.pool_config.idp_data[0].name]
        ? { provider: brandingData.company.pool_config.idp_data[0].name }
        : { customProvider: brandingData.company.pool_config.idp_data[0].name };

      Auth.federatedSignIn(options);
    }
  }
}
