<div class="item-data">
    <ng-container>
        <app-icon-two-images bigImg="package" smallImg="close2" [isSuccess]="false">
        </app-icon-two-images>
    </ng-container>
    <div class="item-details" (click)="goToPackage(notification, true)">
        <ng-container>
            <div class="item-details-title">
                Your package <strong>{{notification.data?.link ? notification.data?.link?.title : notification.data?.package_title}}</strong> was rejected by
                <span>Res_Q Admin.</span>
            </div>

            <div class="item-details-subtitle">With reason:</div>
            <div class="item-details-comment"
                 matTooltip="{{notification.data?.reason}}"
                 matTooltipClass="btr-basic"
                 appTooltipSize
                 matTooltipPosition="right"
                 appTooltipWhenEllipsis>
                 "{{notification.data?.reason}}"
            </div>
        </ng-container>

        <div class="item-details-time" *ngIf="company$ | async as company">
            {{notification.created_at | dateFormat : null : company.config.timezone : company.config.timeformat}}
        </div>
    </div>
</div>
<app-notification-bullet [notification]="notification"></app-notification-bullet>
