import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IBulkConvertResponse, IMfaRequest, IMfaResponse, ISortQueryParameters, ResetUserPinCode, StoreUserPinCode, UpdateUserPinCode } from '../interfaces';
import { IUser, IUserLite, IUserRole } from '../models';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  constructor(private apiService: ApiService) { }

  public updateProfileUser(user: IUser): Observable<IUser> {
    return this.apiService.put(`/api/user`, user);
  }

  public updateProfileImage(imageBase64: string) {
    return this.apiService.postBase64Image('/api/user/image', { image: imageBase64 });
  }

  public updateLoginPasswordChanged() {
    return this.apiService.post('/api/company-user/password-changed', {});
  }

  public getUserRoles(sortQueryParams?: ISortQueryParameters): Observable<IUserRole[]> {
    return this.apiService.get(`/api/roles`, this.apiService.addSortQueryParams(sortQueryParams)).pipe(
      map(res => res.data)
    );
  }

  public deleteCompanyUser(uuid) {
    return this.apiService.delete(`/api/company-user/${uuid}`);
  }

  public createCompanyUser(user: Partial<IUserLite>, companyId: string) {
    const apiUrl = companyId ? `/api/company-user/store/${companyId}` : `/api/company-user`;
    return this.apiService.post(apiUrl, user);
  }

  public getUser(): Observable<IUser> {
    return this.apiService.get('/api/user');
  }

  public convertUserToSso(userId: string): Observable<IUser> {
    return this.apiService.post(`/api/user/${userId}/sso/enable`, {});
  }

  public convertUserToNonSso(userId: string): Observable<IUser> {
    return this.apiService.post(`/api/user/${userId}/sso/disable`, {});
  }

  public enableBulkSso(payload: string[]): Observable<IBulkConvertResponse> {
    return this.apiService.post(`/api/user/bulk/sso/enable`, payload)
      .pipe(map(res => res.data));
  }

  public disableBulkSso(payload: string[]): Observable<IBulkConvertResponse> {
    return this.apiService.post(`/api/user/bulk/sso/disable`, payload)
      .pipe(map(res => res.data));
  }

  public validatePhoneNumber(phone_number: string) {
    return this.apiService.get(`/api/validate-phone?phone=${encodeURIComponent(phone_number)}`)
      .pipe(map(res => res.success));
  }

  public getMfa(): Observable<IMfaResponse> {
    return this.apiService.get(`/api/user/mfa`);
  }

  public setMfa(payload: IMfaRequest): Observable<IMfaResponse> {
    return this.apiService.post(`/api/user/mfa`, payload);
  }

  public getSignatureImage(signatureUrl: string): Observable<Blob> {
    return this.apiService.getImageFromFile(`/api/file/${signatureUrl}`);
  }

  public setUserPinCode(payload: StoreUserPinCode) {
    return this.apiService.post(`/api/user-pin-code`, payload).pipe(map(res => res.success));
  }

  public updateUserPinCode(payload: UpdateUserPinCode) {
    return this.apiService.put(`/api/user-pin-code`, payload).pipe(map(res => res.success));
  }

  public resetUserPinCode(payload: ResetUserPinCode) {
    return this.apiService.post(`/api/user-pin-code/token`, payload).pipe(map(res => res.success));
  }

  public validateUserPinCode(token: string): Observable<boolean> {
    return this.apiService.get(`/api/user-pin-code/validate-token?token=${token}`).pipe(map( res => res.is_valid));
  }

  public forgotPin(): Observable<{ success: boolean }> {
    return this.apiService.post(`/api/user-pin-code/forgot`, {});
  }

  public updateProfilePagination(default_pagination: number) {
    return this.apiService.put('/api/user/pagination', { default_pagination });
  }
}
