import { Component, ComponentRef, ElementRef, Input, Renderer2 } from '@angular/core';
import { MatTable } from '@angular/material/table';

@Component({
  selector: 'app-table-loading-overlay',
  templateUrl: './table-loading-overlay.component.html',
  styleUrls: ['./table-loading-overlay.component.scss']
})
export class TableLoadingOverlayComponent {

  @Input() targetTable: any;

  constructor(
    private el: ElementRef,
    private renderer2: Renderer2) { }


  ngAfterViewInit(): void {
    if (!this.targetTable) {
      return;
    }

    const childNodes = Array.from((this.targetTable._elementRef.nativeElement.childNodes as NodeList));
    const tableBody = childNodes.find(x => x.nodeName == 'TBODY');
    if (!tableBody) {
      return;
    }

    this.renderer2.addClass(this.targetTable._elementRef.nativeElement, 'table-loading');
    this.renderer2.appendChild(tableBody, this.el.nativeElement);
  }

  ngOnDestroy(): void {
    if(this.targetTable){
      this.renderer2.removeClass(this.targetTable._elementRef.nativeElement, 'table-loading');
    } 
  }
}
