<div class="loading-animation" [class.local]="isLocal">
  <div class="logo-wrapper">
    <div *ngIf="isLocal" class="logo">
      <img *ngIf="!withLogo && !whiteCircle" class="img-logo" [style.height]="size + 'px'" src="../../../../assets/img/loading.gif" />
      <img *ngIf="!withLogo && whiteCircle" class="img-logo" [style.height]="size + 'px'" src="../../../../assets/img/loading-white.gif" />
      <img *ngIf="withLogo" class="img-logo" [style.height]="size + 'px'" src="../../../../assets/img/loading.gif" />
    </div>
    <div *ngIf="!isLocal" class="logo" [style.margin-top]="(-1 * size / 2) + 'px'"
      [style.margin-left]="(-1 * (size / 2)) + 'px'">
      <img *ngIf="!withLogo && !whiteCircle" class="img-logo" [style.height]="size + 'px'" src="../../../../assets/img/loading.gif" />
      <img *ngIf="!withLogo && whiteCircle" class="img-logo" [style.height]="size + 'px'" src="../../../../assets/img/loading-white.gif" />
      <img *ngIf="withLogo" class="img-logo" [style.height]="size + 'px'" src="../../../../assets/img/loading.gif" />
    </div>

    <div *ngIf="message" class="loading-message">{{message}}</div>
  </div>
</div>