import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateFormatPipe, DateGMTFormatPipe, FilterBySelectedOptionsPipe, HighlightPipe, OrderByPipe, RandomNamePipe, TimeZoneFormat } from '../pipes';
import { DisplayNamePipe } from './display-name.pipe';
import { RespondedApprovalRequestsPipe } from './responded-approval-requests.pipe';
import { LatestActionDatePipe } from './latest-action-date-pipe';
import { IsBeforePipe } from './date-is-before.pipe';
import { TcActionStatusConvert } from '@core/pipes/tc-action-status-convert.pipe';
import { DateToDayPipe } from '@core/pipes/date-to-day.pipe';
import { SignWithLabelPipe } from '@core/pipes/sign-with-label.pipe';
import { InitialsPipe } from '@core/pipes/initials.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    DateFormatPipe,
    HighlightPipe,
    DisplayNamePipe,
    RespondedApprovalRequestsPipe,
    LatestActionDatePipe,
    IsBeforePipe,
    TcActionStatusConvert,
    DateToDayPipe,
    SignWithLabelPipe,
    InitialsPipe,
    OrderByPipe,
    RandomNamePipe,
    FilterBySelectedOptionsPipe,
    DateGMTFormatPipe,
    TimeZoneFormat,
  ],
  declarations: [
    DateFormatPipe,
    HighlightPipe,
    DisplayNamePipe,
    RespondedApprovalRequestsPipe,
    LatestActionDatePipe,
    IsBeforePipe,
    TcActionStatusConvert,
    DateToDayPipe,
    SignWithLabelPipe,
    InitialsPipe,
    OrderByPipe,
    RandomNamePipe,
    FilterBySelectedOptionsPipe,
    DateGMTFormatPipe,
    TimeZoneFormat,
  ]
})
export class PipesModule {
}
