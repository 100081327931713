/* tslint:disable:no-string-literal */
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AutoUnsubscribe } from '@core/utilities/auto-unsub';
import { AsyncFileDynamoDBResponse, AsyncFileResponseStatus } from '@store/async-pdf/async-pdf.model';
import { AsyncPdfFilesModalComponent } from './async-pdf-files-modal/async-pdf-files-modal.component';
@Component({
  selector: 'app-async-pdf-banner',
  templateUrl: './async-pdf-banner.component.html',
  styleUrls: ['./async-pdf-banner.component.scss']
})
@AutoUnsubscribe()
export class AsyncPdfBannerComponent {

  queueBarText = '';
  hasInProgress = false;

  @Input() set files(_files: AsyncFileDynamoDBResponse[]) {
    const inProgress = _files.filter(file => file.status === AsyncFileResponseStatus.PENDING).length;
    const done = _files.filter(file => file.status === AsyncFileResponseStatus.DONE).length;
    const failed = _files.filter(file => file.status === AsyncFileResponseStatus.FAILED).length;
    this.queueBarText = '';
    this.hasInProgress = inProgress > 0;
    if (inProgress > 0) {
      this.queueBarText = `${inProgress} In Progress`;
    }
    if (done > 0) {
      if (inProgress > 0) {
        this.queueBarText += ' - ';
      }
      this.queueBarText += `${done} Done`;
    }

    if (failed > 0) {
      if (done > 0 || inProgress > 0) {
        this.queueBarText += ' - ';
      }
      this.queueBarText += `${failed} Failed`;
    }
  }
  constructor(
    private dialog: MatDialog
  ) { }

  openAsyncPdfFilesModal() {
    this.dialog.open(AsyncPdfFilesModalComponent)
      .afterClosed()
      .subscribe();
  }
}
