import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '@core/pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { MaterialModule } from '@core/modules/material.module';
import { ButtonsModule } from '../buttons/buttons.module';
import { UserDetailsComponent } from './user-details.component';
import { UserAvatarModule } from '@shared/components/user-avatar/user-avatar.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    CoreModule,
    ButtonsModule,
    PipesModule,
    UserAvatarModule
  ],
  declarations: [
    UserDetailsComponent
  ],
  exports: [
    UserDetailsComponent
  ],
  providers: []
})
export class UserDetailsModule {
}
