import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { INavMenuItem } from '@core/interfaces';
import { HelperService, RoutingService } from '@core/services';
import { Store } from '@ngxs/store';
import { CompanySelectors } from '@store/company/company.selectors';
import { ToastrService } from 'ngx-toastr';
import { combineLatest } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { UserSelectors } from 'src/app/store/user/user.selectors';
import { Permissions } from '../constants/permissions.enum';

@Injectable()
export class AuthorizationGuardService  {
  constructor(
    private toastr: ToastrService,
    private store: Store,
    private routingService: RoutingService,
    private router: Router,
    private helperService: HelperService
  ) { }


  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const requiredPermissions: Permissions[] = route.data.requiredPermissions;
    const btrAdminHasAccess: boolean = route.data.btrAdminHasAccess;
    return new Promise<boolean>((resolve, reject) => {
      combineLatest([
        this.store.select(UserSelectors.getCurrentUser),
        this.store.select(CompanySelectors.getPermissionMatrixLite)
      ]).pipe(
        filter(([user, permissionsLite]) => !!user && !!permissionsLite),
        take(1)
      ).subscribe(([user, permissionsLite]) => {
        const userAccessLevel = this.store.selectSnapshot(UserSelectors.getUserAccessLevel);
        const isBTRAdmin = this.store.selectSnapshot(UserSelectors.isBTRAdmin);

        if ((isBTRAdmin && btrAdminHasAccess) ||
          (requiredPermissions && requiredPermissions.length &&
            requiredPermissions.every(permission => user.permissions?.includes(permission))) ||
          (route.queryParams?.isPackagePreviewMode)
        ) {
          resolve(true);
        } else {
          resolve(false);

          // redirect to the page with the lowest priority
          const navMenuItems: INavMenuItem[] = this.helperService.generateNavMenu(
            this.store.selectSnapshot(UserSelectors.getUserPermissions),
            isBTRAdmin,
            this.store.selectSnapshot(CompanySelectors.getCurrentCompany),
          ).filter(item => item.isVisible && item.accessLevels.some(r => userAccessLevel === r))
            .sort((a, b) => a.priority < b.priority ? -1 : 1);

          if (!navMenuItems.length) {
            this.router.navigate([this.routingService.BLANK.url()]);
          } else {
            this.router.navigate([navMenuItems[0].url]);
          }

          const missingRequiredPermissions = requiredPermissions?.length
            ? requiredPermissions.filter(permission => !user.permissions?.includes(permission)).map(p => permissionsLite[p])
            : [];

          if (missingRequiredPermissions.length) {
            this.toastr.warning(`You do not have the ${missingRequiredPermissions.join(' or ')} ${missingRequiredPermissions.length === 1 ? 'permission' : 'permissions'} for this action.`)
          } else {
            this.toastr.warning('You are not allowed to access this page');
          }
        }
      }, error => {
        reject();
      });
    });
  }
}
