import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output
} from '@angular/core';
import { ImpactAssessmentMode } from '@core/constants';
import { IImpactAssessmentQuestionFull } from '@core/interfaces';

@Component({
  selector: 'app-questionnaire-builder',
  templateUrl: './questionnaire-builder.component.html',
  styleUrls: ['./questionnaire-builder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionnaireBuilderComponent {
  @Input() level = 0;
  @Input() mode: string;
  _questions: IImpactAssessmentQuestionFull[] = [];
  @Input() set questions(val: IImpactAssessmentQuestionFull[]) {
    this._questions = val;
    if (!this._questions.length) {
      this.addQuestion();
    }
  }

  get questions() {
    return this._questions;
  }

  @Output() valuesChanged = new EventEmitter<IImpactAssessmentQuestionFull[]>();
  readonly ImpactAssessmentMode = ImpactAssessmentMode;

  constructor(private cdr: ChangeDetectorRef) {}

  dropQuestion(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.questions, event.previousIndex, event.currentIndex);
    this.valuesChanged.emit(this.questions);
    this.cdr.markForCheck();
  }

  addQuestion(expanded = true) {
    this.questions.push({
      title: '',
      type: 'single',
      answers: [],
      expanded
    } as any);
    this.valuesChanged.emit(this.questions);
    this.cdr.markForCheck();
  }

  questionChanged() {
    this.valuesChanged.emit(this.questions);
  }

  deleteQuestion(index: number) {
    this.questions.splice(index, 1);
    this.valuesChanged.emit(this.questions);
    this.cdr.markForCheck();
  }
}

