import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NotificationResponse, NotificationTemplate } from '@core/interfaces';
import { NotificationMenuItemComponent } from '../../notification-menu-item/notification-menu-item.component';

@Component({
  selector: 'app-reporting-action-fail',
  templateUrl: './reporting-action-fail.component.html',
  styleUrls: ['./reporting-action-fail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportingActionFailComponent extends NotificationMenuItemComponent {
  @Input() notification: NotificationResponse;
  readonly NotificationTemplate = NotificationTemplate;
}
