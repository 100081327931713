<div class="item-data">
  <app-icon-two-images bigImg="user-gear"></app-icon-two-images>
  <div class="item-details" (click)="goToProfilePage(notification)">
    <div class="item-details-title">
      <strong>{{notification.data?.action_user | displayName}}</strong> has changed your user role for <strong>{{notification.data?.company?.name}}</strong>,
        from <strong>{{notification.data?.initial_user_role}}</strong> to <strong>{{notification.data?.new_user_role}}</strong>
    </div>
    <div class="item-details-time" *ngIf="company$ | async as company">
      {{notification.created_at | dateFormat : null : company.config.timezone : company.config.timeformat}}
    </div>
  </div>
</div>
<app-notification-bullet [notification]="notification"></app-notification-bullet>
