<div class="item-data">
    <app-icon-two-images bigImg="package" [isSuccess]="true">
    </app-icon-two-images>
    <div class="item-details" (click)="goToPackage(notification)">
        <div class="item-details-title">
            <strong>{{notification.data?.initiator_first_name}} {{notification.data?.initiator_last_name}} </strong>
            would like to download the package <strong>{{notification.data?.link?.title}}</strong>.
        </div>
        <div class="item-details-subtitle">With comment:</div>
        <div class="item-details-comment"
             matTooltip="{{notification.data?.initiator_comment}}"
             matTooltipClass="btr-basic"
             appTooltipSize
             matTooltipPosition="right"
             appTooltipWhenEllipsis>
             "{{notification.data?.initiator_comment}}"
        </div>
        <div class="item-details-time" *ngIf="company$ | async as company">
            {{notification.created_at | dateFormat : null : company.config.timezone : company.config.timeformat}}
        </div>
    </div>
</div>
<app-notification-bullet [notification]="notification"></app-notification-bullet>
