export class AddActiveFilter {
  constructor(public key: string, public value: any) { }
}

export class RemoveActiveFilter {
  constructor(public key: string) { }
}

export class SearchChanged {
  constructor(public key: string, public value: string) { }
}

export class AddSortOption {
  constructor(public sortByKey: string) { }
}
