import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NotificationResponse } from '@core/interfaces';
import { NotificationMenuItemComponent } from '../../notification-menu-item/notification-menu-item.component';

@Component({
  selector: 'app-import-step-data-completed',
  templateUrl: './import-step-data-completed.component.html',
  styleUrls: ['./import-step-data-completed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImportStepDataCompletedComponent extends NotificationMenuItemComponent {
  @Input() notification: NotificationResponse;
}
