import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { MaterialModule } from '@core/modules/material.module';
import { ButtonsModule } from '@shared/components/buttons/buttons.module';
import { RowAttachmentsComponent } from './row-attachments.component';
import { FileUploadModule } from '../file-upload/file-upload.module';
import { LoadingAnimationModule } from '../loading-animation/loading-animation.module';
import { WebViewerModule } from 'src/app/webviewer/webviewer.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    CoreModule,
    ButtonsModule,
    FileUploadModule,
    LoadingAnimationModule,
    WebViewerModule
  ],
  declarations: [
    RowAttachmentsComponent
  ],
  exports: [
    RowAttachmentsComponent
  ],
  providers: []
})
export class RowAttachmentsModule {
}
