import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { EXPIRED_CODE_EXCEPTION, LIMIT_EXCEEDED_ERROR, RESET_PASSWORD_ERROR } from '@core/constants/app-constants';
import { RoutingService } from '@core/services';
import { ResetPasswordService } from '@core/services/reset-password.service';
import { AutoUnsubscribe } from '@core/utilities/auto-unsub';
import { Auth } from 'aws-amplify';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class ResetPasswordComponent {
  user: string;
  resetForm: UntypedFormGroup;
  resetError: string;
  resetFormSub$: Subscription;
  passMatchError = 'Please make sure your passwords match';

  get showMfaForm() {
    return !!this.resetPasswordService.mfaUser;
  }

  get mfaFormGroup() {
    return this.resetPasswordService.mfaFormGroup;
  }

  get mfaLoginError() {
    return this.resetPasswordService.mfaLoginError;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routingService: RoutingService,
    private toastr: ToastrService,
    private resetPasswordService: ResetPasswordService,
    private cdr: ChangeDetectorRef
  ) {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.user = params.get('user');
      this.initForm();
    });
  }

  onResetPasswordSubmit() {
    const { username, code, password } = this.resetForm.value;

    Auth.forgotPasswordSubmit(username, code, password).then(x => {
      this.toastr.success('Your password was changed successfully');
      this.resetPasswordService.loginAfterPasswordReset(username, password)
    }).catch(err => {
      if (err.toString().indexOf(RESET_PASSWORD_ERROR) > -1 || err.toString().indexOf(LIMIT_EXCEEDED_ERROR) > -1) {
        this.resetError = err.toString().indexOf(RESET_PASSWORD_ERROR) > -1 ? RESET_PASSWORD_ERROR : LIMIT_EXCEEDED_ERROR;
      } else if (err.code === EXPIRED_CODE_EXCEPTION) {
        this.resetError = err.message;
      }
      this.cdr.markForCheck();
    });
  }

  goToLoginPage() {
    this.router.navigate([this.routingService.LOGIN.url()]);
  }

  initForm(): void {
    this.resetForm = new UntypedFormGroup({
      username: new UntypedFormControl(this.user),
      code: new UntypedFormControl(null, [Validators.required]),
      password: new UntypedFormControl(null, [Validators.required]),
      confirmPassword: new UntypedFormControl(null, [Validators.required])
    }, this.passwordConfirming.bind(this));
  }

  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('password').value !== c.get('confirmPassword').value) {
      c.get('confirmPassword').setErrors({ noMatch: true });
      return { invalid: true };
    }
    return { invalid: false };
  }

  public loginMFA() {
    const code = this.mfaFormGroup.controls['mfaCode'].value;
    this.resetPasswordService.loginMFAUser(code);
  }
}
