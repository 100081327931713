import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@core/modules/material.module';
import { TypeToConfirmComponent } from './type-to-confirm.component';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    CommonModule
  ],
  exports: [
    TypeToConfirmComponent
  ],
  declarations: [
    TypeToConfirmComponent
  ],
  providers: []
})
export class TypeToConfirmModule { }
