<div class="btr-modal-container">
  <span mat-dialog-title>Choose your Identity Provider</span>
  <mat-dialog-content>
    <div class="modal-content">
      <div class="button-wrapper flex-col flex-100 flex-gap-32 justify-center items-center">
        <app-styleguide-simple-button
          *ngFor="let provider of idpData"
          type="green-outline"
          [label]="'Sign in with ' + (CognitoHostedIdentityProviderLabel[provider.name] || provider.name)"
          (onClick)="selectIdp(provider)">
        </app-styleguide-simple-button>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <app-styleguide-simple-button
      type="dark-link"
      label="Back to Log in"
      (onClick)="close()">
    </app-styleguide-simple-button>
  </mat-dialog-actions>
</div>
