import { Injectable } from '@angular/core';
import {
  COLUMN_KEY_ATTR, GID_ATTR, HighlightResult, IGNORE_TAGS, NODE_TYPE, PENDING_COMMENT_HIGHLIGHT_CLASS,
  POSTED_COMMENT_HIGHLIGHT_CLASS, ROW_UUID_ATTR, STEP_UUID_ATTR
} from '@core/constants';
import { IApprovalOrReviewRequestSeriesUser, TCActionStatus } from '@core/interfaces';
import { IReviewSessionComment, isCellHighlight, ISerializedHighlight } from '@core/interfaces/review.interface';
import { Util } from '@core/utilities/Util';
import { Store } from '@ngxs/store';
import * as _ from 'lodash';
import { filter, map, take, tap } from 'rxjs/operators';
import { AddComment, Lock, SelectCommentChunk, SetLoadingDocument, SetLoadingHighlights } from 'src/app/documents/store/document.actions';
import { DocumentSelectors } from 'src/app/documents/store/document.selectors';
import { UserSelectors } from 'src/app/store/user/user.selectors';
import { HighlightDomService } from './highlight-dom.service';
import { HighlightSerializeService } from './highlight-serialize.service';
import { Permissions } from '@core/constants/permissions.enum';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class HighlightService {

  constructor(private highlightDomService: HighlightDomService,
    private store: Store,
    private toastr: ToastrService,
    private highlightSerializeService: HighlightSerializeService) { }

  /**
   * Called to check if user is in reviews list and did not finished review
   * @returns 
   */
  public isUserInReviewRequestSeries(): boolean {
    const loadedWorkflow = this.store.selectSnapshot(DocumentSelectors.getDocument);
    const currentUserUuid = this.store.selectSnapshot(UserSelectors.getCurrentUserUuid);
    const reviewRequest = loadedWorkflow?.steps[loadedWorkflow.steps.length - 1].step_data?.review_request;
    if (reviewRequest?.series) {
      const isUserInReviewRequestSeries = reviewRequest.series[0].some((seriesUser: IApprovalOrReviewRequestSeriesUser): boolean =>
        seriesUser.uuid === currentUserUuid && seriesUser.action_status === TCActionStatus.Pending);
      return isUserInReviewRequestSeries;
    }
    return false;
  }

  /**
 * Called on page init to enable content highlight and listeners for user activity 
 * @returns 
 */
  public canHighlight(): boolean {
    const currentUserUuid = this.store.selectSnapshot(UserSelectors.getCurrentUserUuid);
    const lockedByUserUuid = this.store.selectSnapshot(DocumentSelectors.getLockedByUserUuid);
    const isDocumentLockedFromThisTab = this.store.selectSnapshot(DocumentSelectors.isDocumentLockedFromThisTab);
    return this.isUserInReviewRequestSeries() && (!lockedByUserUuid || (lockedByUserUuid === currentUserUuid && isDocumentLockedFromThisTab));
  }


  /**
   * Called when user selects some text 
   * @param containerElement 
   * @param ignoreTags 
   * @returns 
   */
  public doHighlight(containerElement: HTMLElement, ignoreTags?: string[]): HighlightResult {
    const range = this.highlightDomService.getRange();
    if (!range) {
      return { createdHighlights: [], error: 'No selection made.' };
    }
    const createdHighlights = this.highlightRange(containerElement, range, ignoreTags);

    this.highlightDomService.removeAllRanges();
    return createdHighlights;
  }

  private getCreatedHighlightIndex(reviewTarget: HTMLElement, gid: string[]): number {
    return this.highlightSerializeService.getHighlights(reviewTarget).findIndex(hl => gid.some(x => x === hl.getAttribute(GID_ATTR)));
  }

  /**
   * Called when a comment is clicked (or any other part of a general)
   * @param element 
   */
  public selectCommentChunk(element: HTMLElement) {
    const commentId = element.getAttribute(GID_ATTR);
    if (commentId) {
      this.store.dispatch(new SelectCommentChunk(commentId));
    }
  }

  public scrollToSelectedComment(selectedComment: IReviewSessionComment) {
    setTimeout(() => {
      let highlight = this.highlightSerializeService.getHighlightByGid
        (this.getReviewTargetFn(selectedComment), selectedComment.composed_highlights[0].gid);
      if (highlight) {
        if (highlight.classList?.contains('cell-highlight')) {
          highlight = highlight.parentElement.parentElement;
        }

        // if not in view, scroll
        if (highlight.getBoundingClientRect().top < 0 || highlight.getBoundingClientRect().bottom > 0) {
          highlight.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
      }
    }, 10); // when editing a comment, if timeout is 0 it does not scroll
  }


  public highlightSelectedCommentChunks(comment: IReviewSessionComment) {
    this.highlightDomService.selectElements(
      this.getReviewTargetFn(comment),
      comment.composed_highlights,
      !!comment.uuid ? POSTED_COMMENT_HIGHLIGHT_CLASS : PENDING_COMMENT_HIGHLIGHT_CLASS
    );
  }

  /**
   * Called when new highlight (text or cell selection) is made
   * Will Lock Document and dispatch AddComment action
   */
  public addComment(newComment: IReviewSessionComment) {
    const currentUser = this.store.selectSnapshot(UserSelectors.getCurrentUser);

    if (!currentUser.permissions.includes(Permissions.reviewerAuthority)) {
      this.toastr.error('You need the "Review a document" permission in order to leave or edit comments');
      this.store.dispatch(new SetLoadingDocument(false));
      this.store.dispatch(new SetLoadingHighlights(false));
      return;
    }
    newComment.user = {
      ...currentUser,
      uuid: currentUser.uuid,
      first_name: currentUser.first_name,
      last_name: currentUser.last_name,
      email: currentUser.email,
      image_url: currentUser.image_url
    };
    this.store.dispatch(new Lock(true));
    this.store.dispatch(new AddComment(newComment));
  }

  /**
   * Called each time a comment is posted or deleted will update other comments order and offset
   * @param postedOrRemovedComment 
   * @returns 
   */
  public getBulkUpdateComments(postedOrRemovedComment?: IReviewSessionComment): IReviewSessionComment[] {
    let updatedComments = this.store.selectSnapshot(DocumentSelectors.getReviewSessionsCommentsForReviewTarget(postedOrRemovedComment))
      .map(comment => {
        const target = this.getReviewTargetFn(comment);
        if (!target) {
          return null;
        }
        let createdHighlights = this.highlightSerializeService.serializeChunks(target, comment.composed_highlights.map(chunk => chunk.gid));
        if (isCellHighlight(comment)) {
          createdHighlights = [{
            ...createdHighlights[0],
            textContent: comment.composed_highlights[0].textContent
          }];
        }
        return {
          ...comment,
          composed_highlights: createdHighlights,
          order_index: this.getCreatedHighlightIndex(target, comment.composed_highlights.map(chunk => chunk.gid))
        };
      });
    updatedComments = updatedComments.filter(c => !!c);
    return updatedComments;
  }

  /**
   * Called to remove highlights, based on comments
   * Will group comments and call this.deserializeChunks
   */
  public deserializeHighlights() {
    this.store.dispatch(new SetLoadingHighlights());
    this.store.select(DocumentSelectors.getLoadingCommentsStatus).pipe(
      filter(status => status === false),
      tap(() => {
        this.groupByReviewTarget(this.store.selectSnapshot(DocumentSelectors.getReviewSessionsComments))
          .map(comments => comments[0])
          .map((comment: IReviewSessionComment) => this.getReviewTargetFn(comment))
          .filter(reviewTarget => !!reviewTarget)
          .map((reviewTarget: HTMLElement) => {
            const highlightsForReviewTarget = this.highlightSerializeService.getHighlights(reviewTarget);
            highlightsForReviewTarget.forEach(hl => {
              if (hl.classList?.contains('cell-highlight')) {
                this.highlightSerializeService.removeCellHighlight(hl);
              } else {
                this.highlightSerializeService.removeTextHighlight(hl);
              }
            });
          });
      }),
      take(1),
      map(() => this.store.selectSnapshot(DocumentSelectors.getReviewSessionsComments)),
      map((allComments: IReviewSessionComment[]) => this.groupByReviewTarget(allComments))
    ).subscribe(groupedComments => {
      this.deserializeChunks(groupedComments);
      this.store.dispatch(new SetLoadingHighlights(false));
    });
  }

  /**
   * Removes highlights when table page changes
   * @param stepUuid 
   * @param pageNumber 
   */
  public deserializeTableHighlightsOnPageChange(stepUuid: string, pageNumber: number) {
    this.store.select(DocumentSelectors.getLoadingCommentsStatus).pipe(
      filter(status => status === false),
      take(1),
      map(() => this.getCommentsOnStep(stepUuid, pageNumber)),
      map((commentsOnPage: IReviewSessionComment[]) => this.groupByReviewTarget(commentsOnPage))
    ).subscribe(groupedComments => {
      this.deserializeChunks(groupedComments);
      this.store.dispatch(new SetLoadingHighlights(false));
    });
  }

  /**
   * Will remove highlights from a single step? based on comments from that specific step
   * @param groupedComments 
   */
  private deserializeChunks(groupedComments: IReviewSessionComment[][]) {
    groupedComments.forEach((comments: IReviewSessionComment[]) => {
      const comment = comments[0];
      const canDeserializeTableHighlight = this.isTableComment(comment)
        && this.store.selectSnapshot(DocumentSelectors.getTablePageNumber(comment.step_uuid))
        === this.store.selectSnapshot(DocumentSelectors.getCommentPageNumber(comment));

      if (canDeserializeTableHighlight || (!this.isTableComment(comment))) {
        const reviewTargetChunks: ISerializedHighlight[] = _.uniqBy(
          comments.reduce((a, b) => [...a, ...b.composed_highlights], []),
          'gid'
        );
        reviewTargetChunks.sort((a, b) => {
          if (a.path.length !== b.path.length) {
            return a.path.length - b.path.length;
          }
          const length = a.path.length;
          let index = 0;
          while (index < length) {
            const delta = a.path[index] - b.path[index];
            if (delta !== 0) {
              return delta;
            }
            index++;
          }
        });

        reviewTargetChunks
          .map(chunk => this.highlightSerializeService.deserialize(this.getReviewTargetFn(comment), chunk, isCellHighlight(comment)))
          .filter(hl => !!hl)
          .forEach(hl => {
            this.highlightSerializeService.addClass(hl, comment.uuid ? POSTED_COMMENT_HIGHLIGHT_CLASS : PENDING_COMMENT_HIGHLIGHT_CLASS);
          });
      }
    });
  }

  /**
   * Will group comments based on their target (step?) (html element)
   * @param comments 
   * @returns 
   */
  private groupByReviewTarget(comments: IReviewSessionComment[]) {
    const groupFn = (comment: IReviewSessionComment) => [comment.step_uuid, comment.row_uuid, comment.column_key, comment.for_title];
    // step_uuid, row_uuid, column_key, for_title => '[<step uuid>, <row uuid>, <column key>, false]' => table highlight
    // step_uuid, row_uuid, column_key, for_title => '[<step uuid>, null, null, true]' => title highlight
    // step_uuid, row_uuid, column_key, for_title => '[<step uuid>, null, null, false]' => general highlight

    const groups = {};
    comments.forEach(item => {
      const group = JSON.stringify(groupFn(item));
      groups[group] = groups[group] || [];
      groups[group].push(item);
    });
    return Object.keys(groups).map(group => groups[group]);
  }

  /**
   * Will remove specific comment from target (general rte?)
   * @param reviewTarget 
   * @param comment 
   */
  public removeTextComment(reviewTarget: HTMLElement, comment: IReviewSessionComment) {
    const allCommentsChunks = this.store.selectSnapshot(DocumentSelectors.getReviewSessionsComments)
      .filter(c => c.gid !== comment.gid)
      .reduce((a, b) => [...a, ...b.composed_highlights.map(chunk => chunk.gid)], []);

    comment.composed_highlights
      .map(chunk => chunk.gid)
      .filter(chunkGid => allCommentsChunks.indexOf(chunkGid) === -1)
      .map(chunkGid => this.highlightSerializeService.getHighlightByGid(reviewTarget, chunkGid))
      .forEach(highlight => {
        this.highlightSerializeService.removeTextHighlight(highlight)
      });
  }

  /**
   * Will remove comment from table cell
   * @param reviewTarget 
   * @param comment 
   */
  public removeCellComment(reviewTarget: HTMLElement, comment: IReviewSessionComment) {
    const highlight = this.highlightSerializeService.getHighlightByGid(reviewTarget, comment.composed_highlights[0].gid);
    this.highlightSerializeService.removeCellHighlight(highlight);
  }

  /**
   * highlights comments (adds css classes)
   * @param comments 
   */
  public highlightNewComments(comments: IReviewSessionComment[]) {
    comments.forEach(comment => {
      comment.composed_highlights?.filter(chunk => chunk.path?.length > 0)
        .map(chunk => this.highlightSerializeService.getHighlightByGid(this.getReviewTargetFn(comment), chunk.gid))
        .filter(hl => !!hl)
        .forEach(hl => {
          this.highlightSerializeService.addClass(hl, comment.uuid ? POSTED_COMMENT_HIGHLIGHT_CLASS : PENDING_COMMENT_HIGHLIGHT_CLASS)
        });
    });
  }


  /**
   * 
   * @param reviewTarget 
   * @param createdHighlights 
   * @param stepUuid 
   * @param rowUuid 
   * @param columnKey 
   * @returns 
   */
  public getNewComment(
    reviewTarget: HTMLElement,
    createdHighlights: HTMLElement[],
    stepUuid: string,
    rowUuid?: string,
    columnKey?: string
  ) {
    const composedSpanIds = createdHighlights
      .map((element: HTMLElement) => element.getAttribute(GID_ATTR))
      .filter(gid => !!gid);
    const chunks = this.highlightSerializeService.serializeChunks(reviewTarget, composedSpanIds);

    const currentUser = this.store.selectSnapshot(UserSelectors.getCurrentUser);
    const comment: IReviewSessionComment = {
      composed_highlights: chunks,
      gid: Util.prefixedNanoid(),
      text: '',
      selected_text: createdHighlights.map(el => el.textContent).join(''),
      for_title: false,
      step_uuid: stepUuid,
      row_uuid: rowUuid || null,
      column_key: columnKey || null,
      order_index: this.getCreatedHighlightIndex(reviewTarget, chunks.map(chunk => chunk.gid)),
      user: currentUser
    };
    return comment;
  }

  /**
   * Called when a new mouse selection is performed. Will start the highlight process.
   */
  private highlightRange(containerElement: HTMLElement, range: Range, ignoreTags?: string[]): HighlightResult {
    let result: HighlightResult = null;
    const startContainerReviewTarget = this.getParentTextHighlight(range.startContainer);
    const endContainerReviewTarget = this.getParentTextHighlight(range.endContainer);

    if (startContainerReviewTarget !== endContainerReviewTarget) {
      return { createdHighlights: [], error: 'Selection could not be made, please try a smaller selection inside a single component' };
    }

    const sameContainer = range.startContainer === range.endContainer;
    const startContainsGID_ATTR = this.checkNodeParentForGIDAttr((range.startContainer.parentNode as HTMLElement));
    const endContainsGID_ATTR = this.checkNodeParentForGIDAttr((range.endContainer.parentNode as HTMLElement));

    if (sameContainer && startContainsGID_ATTR && endContainsGID_ATTR) {
      return { createdHighlights: [], error: 'Comment already exists' };
    }

    if (ignoreTags && ignoreTags.length > 0) {
      const isStartContainerIgnored = this.isElementIgnored(range.startContainer, ignoreTags);
      const isEndContainerIgnored = this.isElementIgnored(range.endContainer, ignoreTags);
      if (isStartContainerIgnored || isEndContainerIgnored) {
        return { createdHighlights: [], error: 'Selection could not be made over selected elements.' };
      }
    }

    if (range.startContainer === range.endContainer
      && this.highlightDomService.isAnchorTag(range.startContainer.parentNode)
      && (this.highlightDomService.contains(containerElement, range.startContainer)
        || range.startContainer.parentNode === containerElement)) {
      return { createdHighlights: [this.highlightDomService.wrap(range.startContainer)], error: null };
    }

    range = this.trimRangeBoundaries(range);
    const rangeBoundaries = this.refineRangeBoundaries(range);
    const startContainer = rangeBoundaries.startContainer;
    const endContainer = rangeBoundaries.endContainer;
    const highlights = [];

    let goDeeper = rangeBoundaries.goDeeper;
    let done = false;
    let node = startContainer;

    do {
      if (node && goDeeper && node.nodeType === NODE_TYPE.TEXT_NODE) {
        if (node.parentNode instanceof HTMLElement
          && node.parentNode.tagName
          && node.nodeValue
          && !this.isNodeIgnored(node, ignoreTags)
          && IGNORE_TAGS.indexOf(node.parentNode.tagName) === -1
          && node.nodeValue.trim() !== '') {
          // highlight if a node is inside the container element
          if (this.highlightDomService.contains(containerElement, node.parentNode) || node.parentNode === containerElement) {
            const isAnchorTag = this.highlightDomService.isAnchorTag(node.parentNode);
            if (isAnchorTag) {
              const existingAnchorTagHighlight = this.highlightSerializeService
                .getHighlightByGid(containerElement, node.parentNode.parentElement.getAttribute(GID_ATTR));
              if (existingAnchorTagHighlight) {
                highlights.push(existingAnchorTagHighlight);
              } else {
                highlights.push(this.highlightDomService.wrap(node));
              }
            } else {
              const highlight = this.highlightDomService.wrap(node);
              highlights.push(highlight);
            }
          }
        }
        goDeeper = false;
      }
      if (node === endContainer && endContainer && !(endContainer.hasChildNodes() && goDeeper)) {
        done = true;
      }

      if (node instanceof HTMLElement && node.tagName && IGNORE_TAGS.indexOf(node.tagName) > -1) {
        if (endContainer instanceof HTMLElement && endContainer.parentNode === node) {
          done = true;
        }
        goDeeper = false;
      }
      if (goDeeper && (node instanceof Text || node instanceof HTMLElement) && node.hasChildNodes()) {
        node = node.firstChild;
      } else if (node && node.nextSibling) {
        node = node.nextSibling;
        goDeeper = true;
      } else if (node) {
        node = node.parentNode;
        goDeeper = false;
      }
    } while (!done);
    result = { createdHighlights: highlights, error: null };
    return result;
  }

  /**
   * Utils function used when user selects text to add comment
   * @param node 
   * @returns 
   */
  private checkNodeParentForGIDAttr(element: HTMLElement) {
    // If element's parent node is anchor, check it's grandparent also as our commentary span will wrap the anchor:
    return !!(element as HTMLElement).getAttribute(GID_ATTR)
      || !!(element.parentNode as HTMLElement).getAttribute(GID_ATTR)
      || !!(element.parentNode.parentNode as HTMLElement).getAttribute(GID_ATTR);
  }

  /**
   * Fixing: NRESQ-14260 - When user selects text to add new comment, make sure to trim spaces, 
   * to avoid multiple comments for the same text
   * @param range 
   * @returns 
   */
  private trimRangeBoundaries(range: Range) {
    let firstChar = range.startContainer.textContent?.charAt(range.startOffset);
    while (firstChar === ' ' && range.startOffset < range.startContainer.textContent.length) {
      range.setStart(range.startContainer, range.startOffset + 1);
      firstChar = range.startContainer.textContent?.charAt(range.startOffset);
    }

    let lastChar = range.endContainer.textContent?.charAt(range.endOffset);
    while (lastChar === ' ' && range.endOffset > 0) {
      range.setEnd(range.endContainer, range.endOffset - 1);
      lastChar = range.endContainer.textContent?.charAt(range.endOffset);
    }
    return range;
  }

  /**
   * Utils function called when user selects text to add new comment
   * @param range 
   * @returns 
   */
  private refineRangeBoundaries(range: Range) {
    let startContainer: | Node | (Node & ParentNode) | null = range.startContainer as HTMLElement;
    let endContainer: Node | (Node & ParentNode) | null = range.endContainer;
    let goDeeper = true;
    const ancestor = range.commonAncestorContainer;

    if (range.endOffset === 0) {
      while (endContainer && !endContainer.previousSibling && endContainer.parentNode !== ancestor) {
        endContainer = endContainer.parentNode;
      }
      if (endContainer) {
        endContainer = endContainer.previousSibling;
      }
    } else if (endContainer.nodeType === NODE_TYPE.TEXT_NODE) {
      if (endContainer && endContainer.nodeValue && range.endOffset < endContainer.nodeValue.length
        && !this.highlightDomService.isAnchorTag(endContainer.parentNode)) {
        const endContainerClone = endContainer.cloneNode(true);
        if ((endContainerClone as Text).splitText(range.endOffset).textContent !== ' ') {
          (endContainer as Text).splitText(range.endOffset);
        } else {
          (endContainer as Text).splitText(range.endOffset - 1);
        }
      }
    } else if (range.endOffset > 0) {
      endContainer = endContainer.childNodes.item(range.endOffset - 1);
    }

    if (startContainer.nodeType === NODE_TYPE.TEXT_NODE) {
      if (startContainer && startContainer.nodeValue && range.startOffset === startContainer.nodeValue.length) {
        goDeeper = false;
      } else if (startContainer instanceof Node && range.startOffset > 0) {
        if (!this.highlightDomService.isAnchorTag(startContainer.parentNode)) {
          const startContainerClone = startContainer.cloneNode(true);
          if ((startContainerClone as Text).splitText(range.startOffset).textContent !== ' ') {
            startContainer = (startContainer as Text).splitText(range.startOffset);
          } else {
            startContainer = (startContainer as Text).splitText(range.startOffset + 1);

          }
        }
        if (startContainer && endContainer === startContainer.previousSibling) {
          endContainer = startContainer;
        }
      }
    } else if (range.startOffset < startContainer.childNodes.length) {
      startContainer = startContainer.childNodes.item(range.startOffset);
    } else {
      startContainer = startContainer.nextSibling;
    }

    return {
      startContainer,
      endContainer,
      goDeeper
    };
  }

  /**
   * Utils function used when user selects text to add comment
   * @param node 
   * @returns 
   */
  private isElementIgnored(element: Node, ignoreTags: string[]): boolean {
    let isIgnored = false;
    let parent;
    if (element) {
      // tslint:disable-next-line: no-conditional-assignment
      while ((parent = element.parentNode) && !isIgnored) {
        if (ignoreTags.indexOf(parent?.tagName?.toString().toLowerCase()) > -1) {
          isIgnored = true;
        }
        element = parent;
      }
    }
    return isIgnored;
  }

  /**
   * Utils function called when user selects text to add new comment
   * @param range 
   * @returns 
   */
  private isNodeIgnored(node: Node, ignoreTags: string[]): boolean {
    let isIgnored = false;
    let parent;
    if (node && ignoreTags && ignoreTags.length) {
      // tslint:disable-next-line: no-conditional-assignment
      while ((parent = node.parentNode) && !isIgnored) {
        if (parent instanceof HTMLElement) {
          if (ignoreTags.indexOf(parent?.tagName?.toString().toLowerCase()) > -1) {
            isIgnored = true;
          }
        }
        node = parent;
      }
    }
    return isIgnored;
  }

  /**
   * Utils function used when user selects text to add comment
   * @param node 
   * @returns 
   */
  private getParentTextHighlight(node: Node): HTMLElement {
    let found = false;
    let parent;
    if (node) {
      // tslint:disable-next-line: no-conditional-assignment
      while ((parent = node.parentNode) && !found) {
        if (parent instanceof HTMLElement) {
          if (parent?.tagName?.toString().toLowerCase() === 'app-text-highlight' || parent.getAttribute(STEP_UUID_ATTR) !== null) {
            found = true;
          }
        }
        node = parent;
      }
    }
    return node as HTMLElement;
  }

  /**
   * Returns comments for a specific table page no
   * @param stepUuid 
   * @param pageNumber 
   * @returns 
   */
  private getCommentsOnStep(stepUuid: string, pageNumber: number) {
    const document = this.store.selectSnapshot(DocumentSelectors.getDocument);
    const rows = document.steps.find(step => step.uuid === stepUuid).step_data?.rows;
    const rowsPerPageInTable = this.store.selectSnapshot(DocumentSelectors.getRowsPerPage);
    const minIndex = pageNumber * rowsPerPageInTable;
    const maxIndex = (pageNumber * rowsPerPageInTable) + rowsPerPageInTable - 1;
    const filteredRows = rows.filter(row => row.order_index >= minIndex && row.order_index <= maxIndex);
    return this.store.selectSnapshot(DocumentSelectors.getReviewSessionsComments)
      .filter(comment => this.isTableComment(comment) && comment.step_uuid === stepUuid)
      .filter(comment => filteredRows.findIndex(row => row.uuid === comment.row_uuid) > -1).filter(c => !!c);
  }

  /**
   * @param comment 
   * @returns 
   */
  private isTableComment(comment: IReviewSessionComment) {
    // If the comment does not have a page number, it means it's not part of a table
    // TODO - check what happens when you highlight a table header cell
    return this.store.selectSnapshot(DocumentSelectors.getCommentPageNumber(comment)) !== null || !!comment.row_uuid;
  }

  /**
   * Called to return all html elements that can be highlighted 
   * @returns 
   */
  getReviewTargets() {
    return Array.from(
      document.querySelectorAll(`[${ROW_UUID_ATTR}],[${COLUMN_KEY_ATTR}],[${STEP_UUID_ATTR}]`)
    ) as HTMLElement[];
  }

  /**
   * For a specific comment or stepUuid, returns the step (html element) 
   * Used both when finding comments positions (offset) and when selecting them back in html
   * @param comment 
   * @param stepUuid 
   * @returns 
   */
  getReviewTargetFn = (comment?: IReviewSessionComment, stepUuid?: string): HTMLElement => {
    return this.getReviewTargets().find((nativeElement: HTMLElement) => {
      if (comment) {
        const columnKeyAttr = nativeElement.getAttribute(COLUMN_KEY_ATTR);
        const rowUuidAttr = nativeElement.getAttribute(ROW_UUID_ATTR);
        const stepUuidAttr = nativeElement.getAttribute(STEP_UUID_ATTR);

        if (comment.for_title) {
          // FE title
          return nativeElement.getAttribute(STEP_UUID_ATTR) === comment.step_uuid;
        } else if (columnKeyAttr
          && stepUuidAttr
          && !rowUuidAttr
          && !comment.row_uuid
          && nativeElement.classList.contains('table-preview-header-cell')) {
          // Table header cell
          return columnKeyAttr === comment.column_key && nativeElement.getAttribute(STEP_UUID_ATTR) === comment.step_uuid;
        } else if (columnKeyAttr && rowUuidAttr && stepUuidAttr) {
          // Table cell
          return columnKeyAttr === comment.column_key
            && rowUuidAttr === comment.row_uuid
            && stepUuidAttr === comment.step_uuid;
        } else if (!columnKeyAttr && !rowUuidAttr && !comment.column_key && !comment.row_uuid && !comment.for_title &&
          nativeElement.classList.contains('general-review-target')) {
          // TODO - find a better option for General FE
          return nativeElement.getAttribute(STEP_UUID_ATTR) === comment.step_uuid;
        }
      } else if (stepUuid) {
        return nativeElement.getAttribute(STEP_UUID_ATTR) === stepUuid;
      }
    });
  }
}
