import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Login } from '@store/user/user.actions';
import { Auth } from 'aws-amplify';
import { AuthenticationService } from '@core/services';

@Injectable()
export class AuthenticationGuardService  {
  constructor(private store: Store,
              private router: Router,
              private authenticationService: AuthenticationService,
              ) { }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return Auth.currentAuthenticatedUser().then(user => {
      // NRESQ-5556 If the user comes from the 'missing-account' page, it means he doesn't have access to the app
      if (this.router.url === '/missing-account') {
        return false;
      }
      this.store.dispatch(new Login());
      return true;
    }).catch(err => {
      this.authenticationService.logout(false);
      return false;
    });
  }
}
