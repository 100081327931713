import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, EventEmitter,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { AccessLevel, WidgetType } from '@core/constants';
import { IDashboardData, IDashboardWidget } from '@core/interfaces';
import { IUser } from '@core/models';
import { AutoUnsubscribe } from '@core/utilities/auto-unsub';
import { Select, Store } from '@ngxs/store';
import {
  CompactType,
  DisplayGrid,
  GridType,
  GridsterConfig,
  GridsterItem
} from 'angular-gridster2';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UserSelectors } from 'src/app/store/user/user.selectors';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@AutoUnsubscribe()
export class DashboardComponent implements OnInit, AfterViewInit {
  @Select(UserSelectors.getCurrentUser)
  public currentUser$: Observable<IUser>;

  @ViewChild('supportRequest') supportRequest: TemplateRef<any>;
  readonly WidgetType = WidgetType;
  widgetsSaved = true;
  widgetType: string;
  options: GridsterConfig;
  dashboard: Array<GridsterItem>;
  private userAccessLevel: AccessLevel;
  resizeEvent: EventEmitter<GridsterItem> = new EventEmitter<GridsterItem>();
  editDashboard = false;
  allWidgets: IDashboardWidget[] = [
    {
      name: 'License Requests',
      type: WidgetType.LicenseRequest,
      visible: false,
      accessLevel: AccessLevel.BTRAdmin
    }
  ];

  get supportedWidgets() {
    return this.allWidgets.filter(widget => {
      return !widget.visible && widget.accessLevel === this.userAccessLevel;
    });
  }

  private userAccessLevel$: Subscription;

  constructor(
    private cdr: ChangeDetectorRef,
    public router: Router,
    private toastr: ToastrService,
    private store: Store
  ) { }

  ngOnInit() {
    this.options = {
      gridType: GridType.VerticalFixed,
      displayGrid: DisplayGrid.OnDragAndResize,
      minCols: 4,
      disableWindowResize: false,
      scrollToNewItems: false,
      disableWarnings: false,
      fixedRowHeight: 190,
      ignoreMarginInRow: false,
      itemChangeCallback: () => {
        this.widgetsSaved = false;
      },
      compactType: CompactType.CompactLeftAndUp,
      pushItems: true,
      draggable: {
        enabled: false
      },
      resizable: {
        enabled: false
      },
      itemResizeCallback: (item) => {
        // update DB with new size
        // send the update to widgets
        this.resizeEvent.emit(item);
      }
    };

    this.dashboard = [];
  }

  ngAfterViewInit() {
    this.userAccessLevel$ = this.store.select(UserSelectors.getUserAccessLevel).pipe(
      filter(level => !!level)
    ).subscribe(userAccessLevel => {
      this.userAccessLevel = userAccessLevel;
      this.storageCleanup();
      this.getStorageData();
    });
  }

  getStorageData() {
    const dashboardDataString = localStorage.getItem('dashboard_' + this.userAccessLevel);
    if (dashboardDataString) {
      const dashboardDataObject = JSON.parse(dashboardDataString);
      this.dashboard = dashboardDataObject.dashboard;
      this.allWidgets = dashboardDataObject.widgets;
      this.changedOptions();
    } else {
      this.generateAllWidgets();
    }
    if (this.supportedWidgets.length > 0) {
      this.widgetType = this.supportedWidgets[0].type;
    }
    this.cdr.markForCheck();
  }

  generateAllWidgets() {
    this.supportedWidgets.map(widget => {
      this.dashboard.push({ x: 0, y: 0, cols: 2, rows: 2, type: widget.type });
      this.allWidgets[this.allWidgets.findIndex(wdg => wdg.type === widget.type)].visible = true;
    });
    this.saveLayout(true);
    this.cdr.markForCheck();

  }

  changedOptions(): void {
    if (this.options.api && this.options.api.optionsChanged) {
      this.options.api.optionsChanged();
    }
  }

  widgetTypeChanged(event) {
    this.cdr.markForCheck();
  }

  editToggle(event?) {
    this.options.resizable.enabled = this.editDashboard;
    this.options.draggable.enabled = this.editDashboard;
    this.changedOptions();
    this.cdr.markForCheck();
  }

  saveLayout(firstLoad?: boolean) {
    const saveObject: IDashboardData = {
      dashboard: this.dashboard,
      widgets: this.allWidgets
    };
    const dashboardData = JSON.stringify(saveObject);
    localStorage.setItem('dashboard_' + this.userAccessLevel, dashboardData);
    if (!firstLoad) {
      this.toastr.success('Dashboard Layout Saved Successfully!');
    }
    this.widgetsSaved = true;
  }

  goToPage(page: string) {
    this.router.navigate([page]);
  }

  onAdd() {
    this.dashboard.push({ x: 0, y: 0, cols: 2, rows: 2, type: this.widgetType });
    this.allWidgets[this.allWidgets.findIndex(widget => widget.type === this.widgetType)].visible = true;
    if (this.supportedWidgets.length > 0) {
      this.widgetType = this.supportedWidgets[0].type;
    }
    this.widgetsSaved = false;
    this.cdr.markForCheck();
  }

  removeItem(event, item): void {
    event.preventDefault();
    event.stopPropagation();
    this.allWidgets[this.allWidgets.findIndex(widget => widget.type === item.type)].visible = false;
    this.dashboard.splice(this.dashboard.indexOf(item), 1);
    if (this.supportedWidgets.length > 0) {
      this.widgetType = this.supportedWidgets[0].type;
    }
    this.widgetsSaved = false;
    this.cdr.markForCheck();
  }

  getItemName(widgetType: string) {
    return this.allWidgets[this.allWidgets.findIndex(widget => widget.type === widgetType)].name;
  }

  /*
  * Removes legacy widget types
  * */
  private storageCleanup() {
    if (localStorage.getItem('dashboard_' + this.userAccessLevel)) {
      const availableWidgets: string[] = Object.values(WidgetType);
      const dashboardDataObject: IDashboardData = JSON.parse(localStorage.getItem('dashboard_' + this.userAccessLevel));
      if (dashboardDataObject.dashboard) {
        dashboardDataObject.dashboard = dashboardDataObject.dashboard.filter(board => {
          return availableWidgets.includes(board.type);
        });
      }

      if (dashboardDataObject.widgets) {
        dashboardDataObject.widgets = dashboardDataObject.widgets.filter(widget => {
          return availableWidgets.includes(widget.type);
        });
      }

      localStorage.setItem('dashboard_' + this.userAccessLevel, JSON.stringify(dashboardDataObject));
    }
  }
}

