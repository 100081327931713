export type PermissionList = {
  [key in Permissions]: boolean;
};

export enum Permissions {
  // sections
  viewHomepage = 'viewHomepage',
  viewTasksPage = 'viewTasksPage',
  viewAuditTrail = 'viewAuditTrail',
  viewMyPackages = 'viewMyPackages',
  viewReportsPage = 'viewReportsPage',

  // templates
  viewTemplateListing = 'viewTemplateListing',
  viewTemplate = 'viewTemplate',
  createTemplate = 'createTemplate',
  editTemplate = 'editTemplate',
  deleteTemplate = 'deleteTemplate',
  cloneTemplate = 'cloneTemplate',
  versionTemplate = 'versionTemplate',
  publishTemplate = 'publishTemplate',
  viewTemplateAuditTrail = 'viewTemplateAuditTrail',

  // runtimes
  viewWorkspaceRuntimeListing = 'viewWorkspaceRuntimeListing',
  viewDocumentWorkflow = 'viewDocumentWorkflow',
  createDocumentWorkflow = 'createDocumentWorkflow',
  editDocumentWorkflow = 'editDocumentWorkflow',
  versionDocumentWorkflow = 'versionDocumentWorkflow',
  deleteDocumentWorkflow = 'deleteDocumentWorkflow',
  cloneDocumentWorkflow = 'cloneDocumentWorkflow',
  cancelDocumentWorkflow = 'cancelDocumentWorkflow',
  importData = 'importData',
  generateTraceMatrix = 'generateTraceMatrix',
  executeTestScript = 'executeTestScript',
  manageLinks = 'manageLinks',
  manageSignatureSession = 'manageSignatureSession',
  signatureAuthority = 'signatureAuthority',
  manageReviewSession = 'manageReviewSession',
  manageExecutionSession = 'manageExecutionSession',
  reviewerAuthority = 'reviewerAuthority',
  viewWorkspaceAuditTrail = 'viewWorkspaceAuditTrail',

  // acl
  giveOrRemoveAccessOnAllObjects = 'giveOrRemoveAccessOnAllObjects',
  transferOwnershipOnAllObjects = 'transferOwnershipOnAllObjects',
  bulkShare = 'bulkShare',
  viewNoAccessListing = 'viewNoAccessListing',

  // fe configuration
  viewConfigFe = 'viewConfigFe',
  manageConfigFe = 'manageConfigFe',

  // impact assessment
  viewImpactAssessment = 'viewImpactAssessment',
  manageImpactAssessment = 'manageImpactAssessment',
  executeImpactAssessment = 'executeImpactAssessment',

  // company admin
  companyAdminPanelAccess = 'companyAdminPanelAccess',

  // marketplace
  managePackage = 'managePackage',
  viewMarketplace = 'viewMarketplace',
  importTemplate = 'importTemplate',

  // filtering
  filteringUser = 'filteringUser',
  filteringOwner = 'filteringOwner',

  // my profile
  viewMyGroups = 'viewMyGroups',
  viewApiKeys = 'viewApiKeys',

  // System
  viewAllCompanySystems = 'viewAllCompanySystems',

  // reports
  viewAllReportData = 'viewAllReportData'
}
