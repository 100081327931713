import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { MaterialModule } from '@core/modules/material.module';
import { ButtonsModule } from '@shared/components/buttons/buttons.module';
import { DatepickerHeaderComponent } from './datepicker-header/datepicker-header.component';
import { DatepickerComponent } from './datepicker.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    ButtonsModule,
    MatDatepickerModule,
  ],
  declarations: [
    DatepickerComponent,
    DatepickerHeaderComponent
  ],
  exports: [
    DatepickerComponent
  ],
  providers: []
})
export class DatepickerModule { }
