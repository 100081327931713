import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AUTOLOGOUT_SESSION_ID } from '@core/constants/app-constants';
import { Store } from '@ngxs/store';
import * as Sentry from '@sentry/browser';
import { Auth } from 'aws-amplify';
import { ToastrService } from 'ngx-toastr';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { GetCurrentUser, Logout } from 'src/app/store/user/user.actions';
import { AuthenticationService, HelperService, RoutingService } from '../services';
import { nanoid } from 'nanoid';
import { CompanySelectors } from '@store/company/company.selectors';
import { UserSelectors } from '@store/user/user.selectors';
import { HttpCustomStatuses } from '@core/constants';
import { environment } from 'src/environments/environment';
import { IEnvironmentName } from 'src/environments/environment.interface';
import { ReviewAccessGuardService } from '@core/guards';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  // Add here any route that does not need authentication header
  readonly KNOWN_HTTP_STATUSES = [
    HttpStatusCode.PaymentRequired,
    HttpStatusCode.Forbidden,
    HttpStatusCode.NotAcceptable,
    HttpStatusCode.Conflict,
    HttpStatusCode.Gone,
    HttpStatusCode.UnprocessableEntity,
    HttpStatusCode.TooManyRequests,
  ]; // statuses that are handled by API
  readonly NOT_LOGGED_ROUTES = [/*'password-reset', */'forgot-password', 'page-not-found', 'branding/login'];
  readonly COGNITO_REFRESH_TOKEN_EXPIRE = 'NotAuthorizedException: Refresh Token has expired';
  readonly COGNITO_NO_CURRENT_USER = 'No current user';
  readonly COGNITO_PASSWORD_RESET_REQUIRED = 'Password reset required for the user';
  readonly NO_SSO_ACCOUNT = 'You do not have an account in Res_Q yet. Please contact your Company Admin.';
  readonly PRINTING_STATUS_406 = 'Request failed with status code 406';
  readonly OLD_PASSWORD_INCORRECT = 'Your old password is incorrect.';
  readonly OLD_PINCODE_INCORRECT = 'Your old PIN Code is incorrect.';
  readonly TM_INVALID_PROVIDED_DATA = 'The provided data was invalid.';
  readonly INACTIVE_ACCOUNT = 'Your account is inactive. Please contact an Admin.';
  readonly LOGIN_WITH_SSO = 'Your Admin has changed your authentication method to SSO. Please login with your SSO credentials';
  readonly MUST_LOCK_REVIEW_VERSION = 'You must lock the review version';
  readonly NO_SIGNATURE_REQUEST = 'There is no signature request in progress.';
  readonly SIGN_OWN_CREDENTIALS = 'You may only sign using your own credentials';
  readonly INCORRECT_SIGNATURE = 'Incorrect signature provided';
  readonly ACTION_UNAUTHORIZED = 'You do not have permission to perform this action.';
  readonly ORIGINAL_PACKAGE_REMOVED = 'The original package was removed from Marketplace';

  constructor(
    private router: Router,
    private routingService: RoutingService,
    private helperService: HelperService,
    private dialog: MatDialog,
    private store: Store,
    private toastr: ToastrService,
    private zone: NgZone,
    private authenticationService: AuthenticationService,
    private reviewAccessGuardService: ReviewAccessGuardService
  ) {
  }

  private async addAuthHeader(request: HttpRequest<any>, addRefreshToken = false): Promise<HttpRequest<any>> {
    let session = null;
    try {
      session = await Auth.currentSession();
    } catch (e) {
      if (environment.environmentName === IEnvironmentName.qa) {
        return throwError(e).toPromise();
      }
    }

    // If session is almost expired, force new one
    const expirationTime = session?.getAccessToken().getExpiration() || Math.ceil(Date.now() / 1000);
    const nowTime = Math.ceil(Date.now() / 1000) // cut milliseconds
    const diffInSeconds = expirationTime - nowTime;
    if (diffInSeconds < 5) {
      session = await this.authenticationService.forceCognitoNewSession().catch(e => {
        if (environment.environmentName === IEnvironmentName.qa) {
        }
        return null;
      });
    }

    if (!localStorage.getItem(AUTOLOGOUT_SESSION_ID)) {
      localStorage.setItem(AUTOLOGOUT_SESSION_ID, nanoid());
    }
    const autoLogoutSessionId = localStorage.getItem(AUTOLOGOUT_SESSION_ID);
    const token = `Bearer ${session.getAccessToken().getJwtToken()}`;
    const headers = {
      setHeaders: {
        Authorization: token,
        'X-Id-Token': session.getIdToken().getJwtToken(),
        'X-Id-Session': autoLogoutSessionId
      }
    };

    if (addRefreshToken) {
      headers.setHeaders['Refresh-Token'] = session.getRefreshToken().getToken();
    }

    return request.clone(headers);
  }

  private logout(forceLogout = true) {
    this.dialog.closeAll();
    this.store.dispatch(new Logout(forceLogout));
  }

  private routesExistInURL(url, routes) {
    return !!routes.filter(element => url.indexOf(element) > -1).length;
  }

  private showErrors(error, request?) {
    if (error?.error.errors?.error?.[0] === this.NO_SSO_ACCOUNT || error?.error.message === this.PRINTING_STATUS_406 ||
      error?.error.errors?.error?.[0] === this.OLD_PASSWORD_INCORRECT || error?.error.errors?.error?.[0] === this.OLD_PINCODE_INCORRECT
      || !navigator.onLine
    ) {
      return;
    }
    if (request && this.routingService.errorHandlingOverwriteRoutes.length &&
      this.routesExistInURL(request.url, this.routingService.errorHandlingOverwriteRoutes)) {
      return;
    }

    if (error && error.url && error.url.includes('/links/store/') && error.message &&
      error.error.message !== this.TM_INVALID_PROVIDED_DATA) {
      return;
    }

    //  NRESQ-6902 - Supress the error message for validate-publish - we will display a custom error from the component
    if (error && error.url && error.url.includes('/validate-publish') && error.status === HttpStatusCode.UnprocessableEntity) {
      return;
    }

    //  Supress the error message for custom error returned from the Integration API
    if (error && error.url?.includes('/integration') && error.status === HttpStatusCode.UnprocessableEntity) {
      return;
    }
    // NRESQ-9308 - Supress the error message - we will display a custom error from the component
    if (error && error.url && error.status === HttpStatusCode.NotFound &&
      (error.url.includes('/workflow-access-list/list') || error.url.includes('/links/step') || error.url.includes('/links/workflow'))
    ) {
      if (error.url.includes('/links/step') && error.url.includes('elements')) {
        this.toastr.error('The section you have selected has been deleted and cannot be linked.');
      }

      return;
    }

    // Sometimes the toastr message is not triggered, we force a change detection
    this.zone.run(() => {
      this.helperService.showErrorMessage(error);
    });
  }

  private handleResponseError(error, request?, next?) {
    if (error === this.COGNITO_REFRESH_TOKEN_EXPIRE ||
      error === this.COGNITO_NO_CURRENT_USER ||
      (error && error.message === this.COGNITO_PASSWORD_RESET_REQUIRED) ||
      error.status === HttpCustomStatuses.LoginTimeOut) {
      this.logout(false);
      return of({}) as Observable<HttpEvent<any>>;
    }
    if (error.status === HttpStatusCode.Unauthorized) {
      if (error.error?.errors?.error[0] === 'These credentials do not match our records.') {
        this.showErrors({ error: { message: 'These credentials do not match our records.', errors: [] } }, request);
      }
      this.logout();
    }
    // TODO: refactor condition
    else if ((request.url.includes('api/syncfusion-image-upload') && error.status === HttpStatusCode.NotFound) ||
      (error.status === HttpStatusCode.RequestTimeout && error.url.endsWith('print-sdox') ||
        (error.status === HttpStatusCode.InternalServerError && error.url.includes('print-sdox')))) {
      // do nothing
    } else if (error.status === 417 && (error?.error.errors?.error?.[0] === this.INACTIVE_ACCOUNT ||
      error?.error.errors?.error?.[0] === this.LOGIN_WITH_SSO)) {
      this.showErrors(error, request);
      this.router.navigate([this.routingService.MISSING_ACCOUNT.url()]);
    } else if (error.status === 302 || error.status === 417) {
      this.showErrors(error, request);
      this.logout();
    } else if (error.status === HttpStatusCode.Locked) {
      localStorage.removeItem('loggedIn');
      return this.router.navigate([this.routingService.MISSING_ACCOUNT_LOGIN.url()]);
    } else if (error.status === HttpStatusCode.NotFound) {
      if (request.url.includes('lock-status') && request.url.includes('review-session')) {
        // NRESQ-4121: needed when a review session is canceled by another user while the current user is reviewing
        this.toastr.warning('The current review session might have been canceled by another user.');
        this.router.navigate([this.routingService.HOME.url()]);
        return;
      }
      if (error.message.toLowerCase() === 'domain not found') {
        this.router.navigate([this.routingService.COMPANY_NOT_FOUND.url()]);
      } else {
        if (error && error.url
          && (
            error.url.includes('/links/step')
            || error.url.includes('/links/workflow')
            || (error.url.includes('/links') && error.url.includes('/update'))
            || (error.url.includes('/links') && error.url.includes('/store'))
            || error.url.includes('/workflow-access-list/list')
          )
        ) {
          this.showErrors(error, request);
        } else if (error?.url &&
          (error.url.includes('/runtime-user-workflow') || error.url.includes('/canvas-user-workflow')) &&
          error?.error?.errors?.error[0].startsWith('No query results for model')) {
          // NRESQ-9988 Show custom error if the document is deleted while a user is still on that document and attempts to make an action
          this.toastr.error('This action is no longer available.');
          return this.router.navigate([this.routingService.NOT_FOUND.url()]);
        } else if (this.routeIsReviewComments(error.url)) {
          // NRESQ-14359: Do nothing as these errors are handled in the respective components
        } else {
          this.router.navigate([this.routingService.NOT_FOUND.url()]);
        }
      }
      // Handle known error codes from backend without overriding the message
    } else if (error.status === HttpStatusCode.Forbidden) {
      if (request.url.includes('lock-status')) {
        return throwError(error);
      } else if (request.url.includes('extend') && error?.error.errors?.error?.[0] === this.MUST_LOCK_REVIEW_VERSION) {
        return of({}) as Observable<HttpEvent<any>>;
      } else if (error.error?.errors?.error?.[0] === this.ORIGINAL_PACKAGE_REMOVED) {
        this.showErrors(error, request);
      } else if (error && error.url && (error.url.includes('/links/workflow'))) {
        return throwError(error);
      } else if (error.error?.errors?.error?.[0] !== this.SIGN_OWN_CREDENTIALS &&
        error.error?.errors?.error?.[0] !== this.INCORRECT_SIGNATURE) {
        // NRESQ-8217 / NRESQ-9216 - Redirect users if they don't have permissions based on the error message from the backend.
        this.store.dispatch(new GetCurrentUser()).subscribe(
          () => {
            this.dialog.closeAll();
            this.router.navigate([this.routingService.HOME.url()]).then(() => {
              if (error.error?.errors?.error?.[0] === this.ACTION_UNAUTHORIZED) {
                if (!this.helperService.userHasTemporaryAccess(this.reviewAccessGuardService.accessList, this.store.selectSnapshot(UserSelectors.getCurrentUser).uuid)) {
                  this.toastr.warning('You are not allowed to access this page');
                }
              } else {
                this.showErrors(error, request);
              }
            });
          }
        );
      }
    } else if (this.KNOWN_HTTP_STATUSES.includes(error.status)) {
      if (error.message === 'Maintenance is being performed on this company') {
        this.router.navigate([this.routingService.COMPANY_UNDER_MAINTENANCE.url()]).then(() => {
          this.showErrors(error, request);
        });
      } else {
        this.showErrors(error, request);
      }
    }// [NRESQ-4147] Hide a specific error which is returned from the API even when the email is sent
    else if (error.status === HttpStatusCode.InternalServerError && error.error.message?.includes('Protocol error (IO.close)')) {
      return next.handle(request);
    } else if (error.status === HttpStatusCode.BadRequest && error?.error.errors?.error?.[0] === this.NO_SIGNATURE_REQUEST) {
      //  Do nothing here
    } else if ((error.status === HttpStatusCode.GatewayTimeout || error.status === HttpStatusCode.RequestTimeout) && request.url.includes('printing-api/print-sdox') && !request.body.async) {
      //  Do nothing here (error message will be managed in PrintingHelperService)
    } else {
      // generate unique transactionId and set as Sentry tag
      const transactionId = Math.random()
        .toString(36)
        .substr(2, 9);
      Sentry.configureScope(scope => {
        scope.setTag('transaction_id', transactionId);
      });

      // capture Exception
      Sentry.captureException(error);

      // clear transaction_id
      Sentry.configureScope(scope => {
        scope.setTag('transaction_id', undefined);
      });
      this.showErrors({
        error: {
          message: 'An error occurred! Please contact support. Error id: ' + transactionId, errors: []
        }
      }, request);
    }
    return throwError(error);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // Do not add authentication header to not logged-in routes
    if (this.NOT_LOGGED_ROUTES.some(item => request.url.toLowerCase().indexOf(item) >= 0)) {
      return next.handle(request);
    }

    //  NRESQ-6617 - Skip adding headers for the calls to download the FE validation package files
    if (request.url.includes('amazonaws.com/functional_element/') || request.url.includes('amazonaws.com/async-download')) {
      return next.handle(request);
    }

    if (request.url.includes('/printing-api') && !request.url.includes('/status')) {
      this.authenticationService.forceCognitoNewSession().catch(e => null)
    }

    const addRefreshToken = request.url.endsWith('/user/logout');
    return from(this.addAuthHeader(request, addRefreshToken)).pipe(
      switchMap(request => next.handle(request).toPromise()),
      catchError(e => {
        return this.handleResponseError(e, request, next)
      })
    );
  }

  private routeIsReviewComments(url: string) {
    return url.includes('review-session/') &&
      (
        url.endsWith('/comment') || // POST comment
        url.includes('/comment/') && url.endsWith('delete/') || // DELETE comment
        url.includes('/unlock/') || // unlock 
        url.includes('/extend/') || // extend 
        url.includes('/scroll-from/') // scroll 
      )
  }
}
