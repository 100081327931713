<div class="item-data">
  <ng-container *ngIf="notification.template === NotificationTemplate.SignatureRequestApproved">
    <app-icon-two-images
      bigImg="approval-pen"
      smallImg="thumbs-up"
      [isSuccess]="true">
    </app-icon-two-images>
  </ng-container>
  <ng-container *ngIf="notification.template === NotificationTemplate.SignatureRequestCancelled">
    <app-icon-two-images
      bigImg="approval-pen"
      smallImg="close2"
      [isSuccess]="false">
    </app-icon-two-images>
  </ng-container>
  <div class="item-details" (click)="openNotification(notification)">
    <div class="item-details-title" *ngIf="notification.template === NotificationTemplate.SignatureRequestApproved">
      <strong>{{notification.data?.link?.title}}</strong> was signed by <strong>{{notification.data?.action_user | displayName}}</strong>.
    </div>
    <div class="item-details-title" *ngIf="notification.template === NotificationTemplate.SignatureRequestCancelled">
      <strong>{{notification.data?.action_user | displayName}}</strong> canceled the signature request on <strong>{{notification.data?.link?.title}}</strong>.
    </div>
    <ng-container *ngIf="notification.template === NotificationTemplate.SignatureRequestApproved && notification.data?.steps">
      <div class="item-details-subtitle">Steps</div>
      <div class="item-details-comment margin-bottom-5"
            matTooltip="{{notification.data?.steps}}"
            matTooltipClass="btr-basic small"
            matTooltipPosition="right"
            appTooltipWhenEllipsis>
        {{notification.data?.steps}}
      </div>
    </ng-container>
    <ng-container *ngIf="notification.data?.comment">
      <div class="item-details-subtitle">With comment:</div>
      <div class="item-details-comment"
            matTooltip="{{notification.data?.comment}}"
            matTooltipClass="btr-basic"
            appTooltipSize
            matTooltipPosition="right"
            appTooltipWhenEllipsis>
          "{{notification.data?.comment}}"
        </div>
    </ng-container>
    <div class="item-details-time" *ngIf="company$ | async as company">
        {{notification.created_at | dateFormat : null : company.config.timezone : company.config.timeformat}}
    </div>
  </div>
</div>
<app-notification-bullet [notification]="notification"></app-notification-bullet>
