import { NotificationResponse } from "@core/interfaces";

export class GetNotifications {
  static readonly type = '[Notifications] GetNotifications';
}

export class MarkNotificationAsRead {
  static readonly type = '[Notifications] MarkNotificationAsRead';
  constructor(public uuid: string) {}
}

export class ResetNotificationsCounter {
  static readonly type = '[Notifications] ResetCounter';
}

export class UpdateNotificationsCounter {
  static readonly type = '[Notifications] UpdateCounter';
  constructor(public counter: number) {}
}

export class PushNotification {
  static readonly type = '[Notifications] PushNotification'
  constructor(public notification: NotificationResponse) {}
}
