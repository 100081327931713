import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HelperService, RoutingService, UserService } from '@core/services';
import { Auth } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {

  MFA_TYPES = ['SMS_MFA', 'SOFTWARE_TOKEN_MFA'];
  mfaFormGroup = new UntypedFormGroup({
    mfaCode: new UntypedFormControl('', [Validators.required]),
  });
  mfaUser: any;
  mfaLoginError: string;

  constructor(
    public helperService: HelperService,
    private userService: UserService,
    private router: Router,
    private routingService: RoutingService) {
  }

  public async loginAfterPasswordReset(username: string, password: string) {
    this.cleanData();
    const signInUser = await Auth.signIn(username, password).catch(e => e);
    // Handle Error:
    if (signInUser instanceof Error) {
      this.helperService.showErrorMessage(signInUser);
      return;
    }

    // User Has MFA:
    if (this.MFA_TYPES.indexOf(signInUser.challengeName) > -1) {
      this.mfaUser = signInUser;
      return;
    }
    // User doesn't have MFA, continue login process:
    this.updatePasswordChange();
  }

  public async loginMFAUser(code: string) {
    const confirmResult = await Auth.confirmSignIn(this.mfaUser, code, this.mfaUser.challengeName).catch(e => e);
    // Handle Error:
    if (confirmResult instanceof Error) {
      this.mfaLoginError = confirmResult.message;
      this.helperService.showErrorMessage(confirmResult);
      return;
    }

    // Continue login process:
    this.updatePasswordChange();
  }

  private updatePasswordChange() {
    this.userService.updateLoginPasswordChanged()
      .subscribe(
        success => {
          this.router.navigate([this.routingService.HOME.url()]).then(x => this.cleanData());
        },
        error => this.helperService.showErrorMessage(error));
  }

  private cleanData() {
    this.mfaUser = null;
    this.mfaLoginError = null;
    this.mfaFormGroup.reset();
  }
}
