import { Injectable } from '@angular/core';
import { ImpactAssessmentStep } from '@core/interfaces';
import { Action, State, StateContext } from '@ngxs/store';
import { ChangeEditorMode, ChangeStep, ChangeToPreviousStep, SelectIAConfig, SelectIALink } from './impact-assessment.actions';
import { impactAssessmentStateDefaults, ImpactAssessmentStateModel } from './impact-assessment.model';
@State<ImpactAssessmentStateModel>({
  name: 'ImpactAssessmentState',
  defaults: impactAssessmentStateDefaults
})
@Injectable()
export class ImpactAssessmentState {

  @Action(ChangeStep, { cancelUncompleted: true })
  changeStep({ getState, patchState }: StateContext<ImpactAssessmentStateModel>, { step }: ChangeStep) {
    const state = getState();
    patchState({
      currentStep: step,
      stepHistory: step.step && step.step !== ImpactAssessmentStep.ConfirmationModal ?
        [...state.stepHistory, step.step] : state.stepHistory
    });
  }

  @Action(ChangeToPreviousStep, { cancelUncompleted: true })
  changeToPreviousStep({ getState, dispatch }: StateContext<ImpactAssessmentStateModel>) {
    const state = getState();
    dispatch(new ChangeStep({
      step: state.stepHistory[state.stepHistory.length - 2]
    }));
  }

  @Action(ChangeEditorMode, { cancelUncompleted: true })
  changeEditorMode({ patchState }: StateContext<ImpactAssessmentStateModel>, { mode }: ChangeEditorMode) {
    patchState({
      editorMode: mode
    });
  }

  @Action(SelectIAConfig, { cancelUncompleted: true })
  selectIAConfig({ getState, patchState }: StateContext<ImpactAssessmentStateModel>, { iaConfig }: SelectIAConfig) {
    const state = getState();
    patchState({
      iaConfig
    });
  }

  @Action(SelectIALink, { cancelUncompleted: true })
  selectIALink({ patchState }: StateContext<ImpactAssessmentStateModel>, { iaLink }: SelectIALink) {
    patchState({
      iaLink
    });
  }
}
