<div class="item-data">
    <app-icon-two-images bigImg="user-gear" [isSuccess]="true">
    </app-icon-two-images>
    <div class="item-details" (click)="goToCompanyUsers(notification)">
        <div class="item-details-title">
            <strong>{{notification.data?.assigned_user | displayName}}</strong> was assigned as a {{notification.data?.assigned_user?.role?.name}}
            <span *ngIf="notification.data?.assigned_user?.is_company_admin">(and CA) </span>
            in your company by <strong>{{notification.data?.company_name}}</strong>.
        </div>
        <div class="item-details-time" *ngIf="company$ | async as company">
            {{notification.created_at | dateFormat : null : company.config.timezone : company.config.timeformat}}
        </div>
    </div>
</div>
<app-notification-bullet [notification]="notification"></app-notification-bullet>