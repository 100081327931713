<div class="item-data">
    <ng-container>
        <app-icon-two-images bigImg="parents" smallImg="check2" [isSuccess]="true">
        </app-icon-two-images>
    </ng-container>
    <div class="item-details" (click)="goToParentChildCompanies(notification)">
        <div class="item-details-title">
            <strong>{{notification.data?.company_name}}</strong> was assigned as a
            <ng-container *ngIf="notification.template === NotificationTemplate.RelationCreatedToChildCompany">
                <strong> Parent </strong>
            </ng-container>
            <ng-container *ngIf="notification.template === NotificationTemplate.RelationCreatedToParentCompany">
                <strong> Child </strong>
            </ng-container>
            of your company by Res_Q Admin.
        </div>
        <div class="item-details-time" *ngIf="company$ | async as company">
            {{notification.created_at | dateFormat : null : company.config.timezone : company.config.timeformat}}
        </div>
    </div>
</div>
<app-notification-bullet [notification]="notification"></app-notification-bullet>