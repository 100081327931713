import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib-esm/types/Auth';
import { Validators } from '@angular/forms';

export interface ICompanyPoolIdp {
  name: string;
  type: CognitoHostedUIIdentityProvider;
  created_at: string;
  end_session_endpoint?: string;
}

export interface ICompanyPoolConfig {
    user_pool_id: string;
    user_pool_client_id: string;
    idp_data: ICompanyPoolIdp[];
    urls: {
        domain: string;
        login: string;
        logout: string;
    };
}

export interface IForgotPasswordData {
    code: string;
    password: string;
    username: string;
}

export interface ILoginData {
    password: string;
    username: string;
}

export enum CognitoHostedIdentityProviderLabel {
  COGNITO = 'Cognito',
  Google = 'Google',
  Facebook = 'Facebook',
  LoginWithAmazon = 'Amazon',
  SignInWithApple = 'Apple',
}

export interface ISSOIdentityProvider {
  name: string;
  type: string;
  created_at: string;
}

export interface ISSOIdentityProviderSetup {
  [key: string]: {
    entity_id?: string;
    url: string;
  };
}
