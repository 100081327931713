import { Injectable } from '@angular/core';
import { CancelTsExecutionResponse, IApiPaginatedDataBase, ICanvasStep, IFilterDataValues, IRuntimeVersionLite,
        ISignedDetails, IUpdateCellContentRequest, IWorkflowRuntime, IWorkflowRuntimeMetadata, IUpdateFilterTagRequest, IWorkflowRuntimeFilterTag, CancelWorkflowRuntimeRequest, 
        IExecutionData} from '@core/interfaces';
import { WorkflowRuntimeModel } from '@core/models';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CanvasFunctionalElementType } from 'src/app/canvas/canvas.enum';
import { ApiService } from './api.service';
import { GeneralFeService } from './general-fe.service';
import { PLACEHOLDER_ROOT_ID } from '@core/constants/app-constants';

@Injectable({
  providedIn: 'root',
})
export class RuntimeService {

  private runtimePath = '/api/runtime-user-workflow';

  constructor(private apiService: ApiService, private generalService: GeneralFeService) {
  }

  public getRuntimeWorkflows(offset: number, limit: number, filters: IFilterDataValues): Observable<IApiPaginatedDataBase<IWorkflowRuntime>> {
    return this.apiService.post(`${this.runtimePath}/listing/${offset}/${limit}`, filters);
  }

  public deleteRuntimeWorkflow(id: string): Observable<any> {
    return this.apiService.delete(`${this.runtimePath}/${id}`);
  }

  public deleteRuntimeWorkflowVersion(id: string, versionId: string): Observable<any> {
    return this.apiService.delete(`${this.runtimePath}/${id}/version/${versionId}`);
  }

  public saveRuntimeWorkflow(workflow: IWorkflowRuntime): Observable<{ data: IWorkflowRuntime }> {
    workflow.steps = this.generalService.prepareStepsForSave(workflow.steps);
    return this.apiService.post(this.runtimePath, workflow);
  }

  public updateRuntimeWorkflow(workflowId: string, versionId: string, workflow: IWorkflowRuntime): Observable<{ data: IWorkflowRuntime }> {
    workflow.steps = this.generalService.prepareStepsForSave(workflow.steps);
    return this.apiService.put(`${this.runtimePath}/${workflowId}/version/${versionId}`, workflow)
      .pipe(
        map((res) => {
          res.data = this.generalService.setDefaultGeneralImages(res.data);
          return res;
        }));
  }

  public updateRuntimeWorkflowTitle(workflowId: string, versionId: string,
    data: { document_title: string }): Observable<{ data: IWorkflowRuntime }> {
    return this.apiService.put(`${this.runtimePath}/${workflowId}/version/${versionId}/document-title`, data);
  }
  public cloneRuntimeWorkflow(workflow: IWorkflowRuntime, workflow_version: string): Observable<{ data: IWorkflowRuntime }> {
    return this.apiService.post(`${this.runtimePath}/${PLACEHOLDER_ROOT_ID}/version/${workflow_version}/clone`, workflow);
  }
  public saveNewRuntimeVersion(workflowUuid: string, workflow: IWorkflowRuntime): Observable<{ data: IWorkflowRuntime }> {
    workflow.steps = this.generalService.prepareStepsForSave(workflow.steps);
    return this.apiService.post(`${this.runtimePath}/${workflowUuid}/new-version`, workflow);
  }

  public getRuntimeWorkflowVersions(id: string): Observable<IApiPaginatedDataBase<IRuntimeVersionLite>> {
    return this.apiService.get(`${this.runtimePath}/${id}/versions`);
  }

  public getRuntimeWorkflowVersion(id: string, versionId: string): Observable<IWorkflowRuntime> {
    return this.apiService.get(`${this.runtimePath}/${id}/version/${versionId}`)
      .pipe(map((res) => this.generalService.setDefaultGeneralImages(res) as IWorkflowRuntime));
  }

  public getRuntimeWorkflowMetadata(id: string, versionId: string): Observable<IWorkflowRuntimeMetadata> {
    return this.apiService.get(`${this.runtimePath}/${id}/version/${versionId}/metadata`);
  }

  public updateRuntimeAdditionalQuestion(
    workflowId: string,
    versionId: string,
    feType: CanvasFunctionalElementType,
    stepUuid: string,
    answer: 'Yes' | 'No'
  ): Observable<{ data: ICanvasStep }> {
    return this.apiService.put(`${this.runtimePath}/${workflowId}/version/${versionId}/${feType}/${stepUuid}/question-answer`, {
      question_selected_answer: answer
    });
  }

  public preSubmitRuntimeStep(
    workflowUuid: string,
    versionUuid: string,
    feType: CanvasFunctionalElementType,
    stepUuid: string
  ): Observable<{ data: ICanvasStep }> {
    return this.apiService.post(`${this.runtimePath}/${workflowUuid}/version/${versionUuid}/${feType}/${stepUuid}/presubmit`, {});
  }

  public submitRuntimeStep(
    workflowUuid: string,
    versionUuid: string,
    feType: CanvasFunctionalElementType,
    stepUuid: string
  ): Observable<{ data: ICanvasStep }> {
    return this.apiService.post(`${this.runtimePath}/${workflowUuid}/version/${versionUuid}/${feType}/${stepUuid}/submit`, {});
  }

  public unlockRuntimeStep(
    workflowUuid: string,
    versionUuid: string,
    feType: CanvasFunctionalElementType,
    stepUuid: string
  ): Observable<{ data: ICanvasStep }> {
    return this.apiService.post(`${this.runtimePath}/${workflowUuid}/version/${versionUuid}/${feType}/${stepUuid}/unlock`, {});
  }

  public updateRuntimeStep(
    workflowUuid: string,
    versionUuid: string,
    feType: CanvasFunctionalElementType,
    stepUuid: string,
    step: ICanvasStep
  ): Observable<{ data: ICanvasStep }> {
    // tslint:disable-next-line: max-line-length
    return this.apiService.put(`${this.runtimePath}/${workflowUuid}/version/${versionUuid}/${feType}/${stepUuid}`, WorkflowRuntimeModel.stripStep(cloneDeep(step)));
  }
  public requireSignatureForEachStep(
    workflowUuid: string,
    versionUuid: string,
    requireSignature: boolean
  ) {
    return this.apiService.post(`${this.runtimePath}/${workflowUuid}/version/${versionUuid}/ts-execute/require-signature-on-step`, {
      require_signature_after_each_step: requireSignature
    });
  }

  public executeTestScript(
    workflowUuid: string,
    versionUuid: string,
    workflowStepUuid: string,
    data: IExecutionData,
  ) {
    return this.apiService.post(`${this.runtimePath}/${workflowUuid}/version/${versionUuid}/Completer/${workflowStepUuid}/send-for-execution`, data);
  }

  public updateTestScriptExecution(
    workflowUuid: string,
    versionUuid: string,
    executedVersionUuid: string,
    data: IExecutionData,
  ) {
    return this.apiService.put(`${this.runtimePath}/${workflowUuid}/version/${versionUuid}/execution-session/${executedVersionUuid}`, data);
  }

  public reExecuteTestScript(
    workflowUuid: string,
    versionUuid: string,
    executedVersionUuid: string,
    data: IExecutionData,
  ) {
    return this.apiService.post(`${this.runtimePath}/${workflowUuid}/version/${versionUuid}/execution-session/${executedVersionUuid}/re-execute`, data);
  }

  public markRowAsPassed(
    workflowUuid: string,
    versionUuid: string,
    stepUuid: string,
    rowUuid: string,
    body
  ) {
    return this.apiService.postMultipartForm(`${this.runtimePath}/${workflowUuid}/version/${versionUuid}/ts-execute/${stepUuid}/${rowUuid}/pass`, body);
  }

  public markRowAsNA(
    workflowUuid: string,
    versionUuid: string,
    stepUuid: string,
    rowUuid: string,
    body
  ) {
    return this.apiService.postMultipartForm(`${this.runtimePath}/${workflowUuid}/version/${versionUuid}/ts-execute/${stepUuid}/${rowUuid}/na`, body);
  }

  public createDeviation(
    workflowUuid: string,
    versionUuid: string,
    stepUuid,
    rowUuid: string,
    body
  ) {
    return this.apiService.postMultipartForm(`${this.runtimePath}/${workflowUuid}/version/${versionUuid}/ts-execute/${stepUuid}/${rowUuid}/deviation`, body);
  }

  public createDeviationWithProceed(
    workflowUuid: string,
    versionUuid: string,
    stepUuid,
    rowUuid: string,
    body
  ) {
    return this.apiService.postMultipartForm(`${this.runtimePath}/${workflowUuid}/version/${versionUuid}/ts-execute/${stepUuid}/${rowUuid}/deviation-with-proceed`, body);
  }

  public getPassDetails(
    workflowUuid: string,
    versionUuid: string,
    ts: string,
    row: any
  ): Observable<ISignedDetails> {
    return this.apiService
      .get(`${this.runtimePath}/${workflowUuid}/version/${versionUuid}/ts-execute/${ts}/${row}/pass`);
  }

  public uncheckRow(
    workflowUuid: string,
    versionUuid: string,
    stepUuid: string,
    rowUuid: string,
    body
  ) {
    return this.apiService.postMultipartForm(`${this.runtimePath}/${workflowUuid}/version/${versionUuid}/ts-execute/${stepUuid}/${rowUuid}/reset`, body);
  }

  public cancelExecution(
    workflowUuid: string,
    cloneParentUuid: string,
    executionSessionUuid: string,
    body
  ): Observable<CancelTsExecutionResponse> {
    return this.apiService.post(`${this.runtimePath}/${workflowUuid}/version/${cloneParentUuid}/execution-session/${executionSessionUuid}/cancel`, body);
  }

  public getDeviationDetails(workflowUuid: string, versionUuid: string, stepUuid: string, rowUuid: string) {
    return this.apiService.get(`${this.runtimePath}/${workflowUuid}/version/${versionUuid}/ts-execute/${stepUuid}/${rowUuid}/deviation`);
  }

  public getPassBulkSignatureData(workflowUuid: string, versionUuid: string) {
    return this.apiService.get(`${this.runtimePath}/${workflowUuid}/version/${versionUuid}/ts-execute/pass-bulk`);
  }

  public getDeviationBulkSignatureData(workflowUuid: string, versionUuid: string) {
    return this.apiService.get(`${this.runtimePath}/${workflowUuid}/version/${versionUuid}/ts-execute/deviation-bulk`);
  }

  public getFileSequenceNumber(workflowUuid: string, versionUuid: string) {
    return this.apiService.get(`${this.runtimePath}/${workflowUuid}/version/${versionUuid}/ts-execute/file-sequence`);
  }

  public updateCellContent(
    workflowUuid: string,
    versionUuid: string,
    payload: IUpdateCellContentRequest,
  ) {
    return this.apiService.put(`${this.runtimePath}/${workflowUuid}/version/${versionUuid}/ts-execute/update-content`, payload);
  }

  public updateRuntimeFooterDiscalimer(workflowId: string, versionId: string,
                                       data: { disclaimer: string }): Observable<{ data: IWorkflowRuntime }> {
    return this.apiService.put(`${this.runtimePath}/${workflowId}/version/${versionId}/footer-disclaimer`, data);
  }

  public updateRuntimeFilterTag(workflowId: string, versionId: string,
                                data: IUpdateFilterTagRequest): Observable<{success: boolean}> {
    return this.apiService.put(`${this.runtimePath}/${workflowId}/version/${versionId}/filter-tag`, data);
  }

  public updateRuntimeSystem(workflowId: string, versionId: string,
                             data: { system_uuid: string }): Observable<{success: boolean}> {
    return this.apiService.put(`${this.runtimePath}/${workflowId}/version/${versionId}/system`, data);
  }

  public getFilterTags(workflowUuid: string, versionUuid: string): Observable<{ data: IWorkflowRuntimeFilterTag[] }> {
    return this.apiService.get(`${this.runtimePath}/${workflowUuid}/version/${versionUuid}/filter-tags`);
  }

  public cancelWorkflow(workflowUuid: string, versionUuid: string, body: CancelWorkflowRuntimeRequest): Observable<IWorkflowRuntime> {
    return this.apiService.post(`${this.runtimePath}/${workflowUuid}/version/${versionUuid}/cancel`, body);
  }
}
