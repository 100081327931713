import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonsModule } from '@shared/components/buttons/buttons.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageUploadComponent } from './image-upload.component';
import { MaterialModule } from '@core/modules/material.module';
import { ImageUploadModalComponent } from './image-upload-modal/image-upload-modal.component';
import { CoreModule } from '@core/core.module';

@NgModule({
  imports: [
    CommonModule,
    ImageCropperModule,
    MaterialModule,
    ButtonsModule,
    CoreModule
  ],
  declarations: [
    ImageUploadComponent,
    ImageUploadModalComponent
  ],
  exports: [
    ImageUploadComponent
  ]
})
export class ImageUploadModule {}
