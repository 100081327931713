import { Injectable } from '@angular/core';
import { interval, Observable, of, Subject } from 'rxjs';
import { UpdateTraceMatrixLinksRequest } from '@core/interfaces';
import { ApiService } from './api.service';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TraceMatrixService {
  private path = '/api/tm';
  private linksStatus$: Subject<boolean>;
  private pollInterval = 5000;

  constructor(private apiService: ApiService) { }

  public updateLinksForTable(data: UpdateTraceMatrixLinksRequest): Observable<{ success: true }> {
    return this.apiService.put(this.path + `/${data.version_root_uuid}/version/${data.uuid}/update`, data);
  }

  public getLinksStatus(workflowUuid: string, versionUuid: string): Observable<boolean> {
    return this.apiService.get(`${this.path}/${workflowUuid}/${versionUuid}/links-finished`)
      .pipe(
        map(res => {
          return res.success;
        })
      );
  }

  public startLinksStatusCheck(workflowUuid: string, versionUuid: string): Observable<boolean> {
    this.stopLinksStatusCheck();
    this.linksStatus$ = new Subject();
    return interval(this.pollInterval).pipe(
      startWith(0),
      switchMap(() => this.getLinksStatus(workflowUuid, versionUuid)),
      takeUntil(this.linksStatus$)
    );
  }

  public stopLinksStatusCheck() {
    if (this.linksStatus$) {
      this.linksStatus$.next(null);
      this.linksStatus$.complete();
    }
  }
}
