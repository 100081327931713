import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@core/modules/material.module';
import { LoadingAnimationComponent } from './loading-animation.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule
  ],
  declarations: [
    LoadingAnimationComponent
  ],
  exports: [
    LoadingAnimationComponent
  ],
  providers: []
})
export class LoadingAnimationModule {
}
