import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CoreModule } from '@core/core.module';
import { MaterialModule } from '@core/modules/material.module';
import { ButtonsModule } from '@shared/components/buttons/buttons.module';
import { FilterTagsComponent } from './filter-tags.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    MaterialModule,
    MatCheckboxModule,
    FormsModule,
    ButtonsModule,
  ],
  declarations: [
    FilterTagsComponent,
  ],
  exports: [
    FilterTagsComponent,
  ],
})
export class FilterTagsModule {}
