import { Injectable } from '@angular/core';
import { AsyncPdfService } from '@core/services';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { UserSelectors } from '@store/user/user.selectors';
import { tap } from 'rxjs/operators';
import { GetGeneratedFiles, RemoveGeneratedFile, StartAsyncPdfCheck, StopAsyncPdfCheck } from './async-pdf.actions';
import { AsyncFileDynamoDBResponse, AsyncFileResponseStatus } from './async-pdf.model';

export interface AsyncPdfStateModel {
  files: AsyncFileDynamoDBResponse[];
}

@State<AsyncPdfStateModel>({
  name: 'asyncPdfState',
  defaults: {
    files: []
  }
})
@Injectable()
export class AsyncPdfState {

  constructor(private asyncPdfService: AsyncPdfService, private store: Store) { }

  @Action(StartAsyncPdfCheck, { cancelUncompleted: true })
  startAsyncPdfCheck({ patchState, dispatch }: StateContext<AsyncPdfStateModel>, { userUuid }: StartAsyncPdfCheck) {
    return this.asyncPdfService.startAsyncPdfCheck(userUuid).pipe(tap(
      (asyncPdfStatus: AsyncFileDynamoDBResponse[]) => {
        patchState({
          files: asyncPdfStatus
        });
        const fileInProgress = asyncPdfStatus.find(file => file.status !== AsyncFileResponseStatus.DONE);
        if (!fileInProgress) {
          dispatch(new StopAsyncPdfCheck());
        }
      })
    );
  }

  @Action(StopAsyncPdfCheck, { cancelUncompleted: true })
  stopAsyncPdfCheck() {
    return this.asyncPdfService.stopAsyncPdfCheck();
  }

  @Action(RemoveGeneratedFile, { cancelUncompleted: true })
  removeGeneratedFile({ dispatch }: StateContext<AsyncPdfStateModel>, { batchId }: RemoveGeneratedFile) {
    return this.asyncPdfService.removeGeneratedFile(batchId).pipe(
      tap(() => dispatch(new GetGeneratedFiles(this.store.selectSnapshot(UserSelectors.getCurrentUserUuid))))
    );
  }

  @Action(GetGeneratedFiles, { cancelUncompleted: true })
  getGeneratedFiles({ patchState }: StateContext<AsyncPdfStateModel>, { userUuid }: GetGeneratedFiles) {
    return this.asyncPdfService.getGeneratedFiles(userUuid).pipe(
      tap(result => patchState({ files: result }))
    );
  }
}
