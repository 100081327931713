import { Component, Input } from '@angular/core';
import { NotificationResponse } from '@core/interfaces';
import { NotificationsService } from '@core/services';
import { Store } from '@ngxs/store';
import { filter } from 'rxjs/operators';
import { MarkNotificationAsRead } from 'src/app/store/notification/notification.actions';

@Component({
  selector: 'app-notification-bullet',
  templateUrl: './notification-bullet.component.html',
  styleUrls: ['./notification-bullet.component.scss']
})
export class NotificationBulletComponent {
  @Input() notification: NotificationResponse

  constructor(private notificationService: NotificationsService,
    private store: Store) { }

  markAsRead(ev: Event): void {
    ev.stopPropagation();
    this.notificationService.markAsRead(this.notification.uuid)
      .pipe(filter(result => !!result))
      .subscribe(result => {
        this.store.dispatch(new MarkNotificationAsRead(this.notification.uuid));
      });
  }
}
