import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';

if (environment.production) {
  enableProdMode();
  enableSentry();
}

function enableSentry() {
  Sentry.init({
    dsn: environment.sentry_dsn,
    environment: environment.environmentName,
    ignoreErrors: [
      'Non-Error exception captured'
    ],
    integrations: [
      new Sentry.BrowserTracing({
        tracingOrigins: [],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    release: environment.release,
    sampleRate: environment.sample_rate,
    tracesSampleRate: environment.traces_sample_rate,
    beforeSend(event, hint) {
      // in an attempt to disable the Sentry user form
      return event;
    }
  });
}
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
