import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, ElementRef,
  EventEmitter,
  Input,
  Output, QueryList,
  ViewChildren
} from '@angular/core';
import { ImpactAssessmentMode } from '@core/constants';
import { IImpactAssessmentAnswerFull, IImpactAssessmentQuestionFull } from '@core/interfaces';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionComponent {
  @ViewChildren('answerInput') answerInputs: QueryList<ElementRef>;
  _question: IImpactAssessmentQuestionFull;
  @Input() set question(val) {
    this._question = val;
    if (!this._question.answers.length) {
      this.addAnswer(this._question.answers);
      this.addAnswer(this._question.answers);
    }
  }
  get question() {
    return this._question;
  }
  @Input() level: number;
  @Input() mode: string;
  @Output() questionChanged = new EventEmitter<IImpactAssessmentQuestionFull>();
  @Output() questionDeleted = new EventEmitter();
  readonly ImpactAssessmentMode = ImpactAssessmentMode;

  constructor(private cdr: ChangeDetectorRef) {}

  addAnswer(answers) {
    answers.push({title: '', is_checked: false, score: 0});
    this.questionChanged.emit(this.question);
    this.cdr.markForCheck();
    setTimeout(() => {
      this.focusLastAddedInput();
    }, 0);
  }

  private focusLastAddedInput() {
    const lastInput: HTMLInputElement = this.answerInputs.last.nativeElement;
    if (lastInput) {
      lastInput.focus();
    }
  }

  dropAnswer(event: CdkDragDrop<string[]>, answers?: IImpactAssessmentAnswerFull[]) {
    moveItemInArray(answers, event.previousIndex, event.currentIndex);
    this.cdr.markForCheck();
  }

  deleteAnswer(answers: IImpactAssessmentAnswerFull[], index: number) {
    answers.splice(index, 1);
    this.cdr.markForCheck();
  }

  removeSubquestions(answer: IImpactAssessmentAnswerFull) {
    if (answer.trigger) {
      delete answer.trigger;
    }
    this.questionChanged.emit(this.question);
    this.cdr.markForCheck();
  }

  addSubQuestion(answer: IImpactAssessmentAnswerFull) {
    if (!answer.trigger) {
      answer.trigger = {questions: []};
    } else if (!answer.trigger.questions) {
      answer.trigger.questions = [];
    }
    answer.trigger.questions.push({
      title: '',
      type: 'single',
      answers: [],
      expanded: true
    } as any);
    this.questionChanged.emit(this.question);
    this.cdr.markForCheck();
  }

  deleteQuestion() {
    this.questionDeleted.emit();
  }

  onQuestionChange() {
    this.questionChanged.emit(this.question);
  }
}
