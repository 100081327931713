import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ICms } from '@core/interfaces';
import { CmsService } from '@core/services/cms.service';
import { AutoUnsubscribe } from '@core/utilities/auto-unsub';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { UserSelectors } from 'src/app/store/user/user.selectors';

@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class CmsComponent implements OnInit {
  cmsForm: UntypedFormGroup;
  loginPageData: ICms;
  imageSrc: string;
  currentUser;
  formData: FormData;
  uploadedFile: File;

  private currentUser$: Subscription;

  constructor(
    private cdr: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private cmsService: CmsService,
    private store: Store) { }

  ngOnInit(): void {
    this.currentUser$ = this.store.select(UserSelectors.getCurrentUser).subscribe(user => {
      this.currentUser = user;
      this.loadCMSData();
      this.cdr.markForCheck();
    });
  }

  loadCMSData() {
    this.cmsService.getCmsLogin().subscribe(res => {
      this.loginPageData = res;
      this.imageSrc = this.loginPageData.image_url;
      this.initForm();
      this.cdr.markForCheck();
    },
      err => {
        this.loginPageData = {
          slug: 'login',
          heading: '',
          title: '',
          subtitle: '',
          text_lines: ['']
        };
        this.initForm();
        this.cdr.markForCheck();
      });
  }

  initForm(): void {
    this.cmsForm = this.fb.group({
      heading: [
        this.loginPageData.heading ? this.loginPageData.heading : '',
        [Validators.required,
        Validators.minLength(3),
        Validators.maxLength(80)]
      ],
      title: [
        this.loginPageData.title ? this.loginPageData.title : '',
        [Validators.maxLength(80)]
      ],
      image: [''],
      subtitle: [
        this.loginPageData.subtitle ? this.loginPageData.subtitle : '',
        [Validators.maxLength(80)]
      ],
      textLines: new UntypedFormArray([])
    });
    if (this.loginPageData && this.loginPageData.text_lines && this.loginPageData.text_lines.length > 0) {
      for (const line of this.loginPageData.text_lines) {
        const newLine = new UntypedFormControl(line, [Validators.required]);
        this.textLines.push(newLine);
      }
    }
  }

  get textLines() {
    return this.cmsForm.get('textLines') as UntypedFormArray;
  }

  addTextLine() {
    const newLine = new UntypedFormControl('', [Validators.required]);
    this.textLines.push(newLine);
  }

  removeTextLine(index: number) {
    this.textLines.removeAt(index);
    this.cmsForm.markAsDirty();
  }

  clear() {
    this.textLines.clear();
  }

  clearEmptyLines() {
    for (let i = this.textLines.length - 1; i >= 0; i--) {
      if (!this.textLines.at(i).value) {
        this.textLines.removeAt(i);
      }
    }
  }

  saveCMSData() {
    this.clearEmptyLines();
    const textLines = [];
    for (let i = 0; i < this.textLines.length; i++) {
      if (this.textLines.at(i).value) {
        textLines.push(this.textLines.at(i).value);
      }
    }
    this.formData = new FormData();
    if (this.uploadedFile) {
      this.formData.set('image', this.uploadedFile);
    }
    this.formData.set('heading', this.cmsForm.get('heading')?.value);
    this.formData.set('title', this.cmsForm.get('title')?.value);
    this.formData.set('subtitle', this.cmsForm.get('subtitle')?.value);
    textLines.forEach((line, index) => {
      this.formData.set(`text_lines[${index}]`, line);
    });
    this.cmsService.updateCms('login', this.formData).subscribe(res => {
      this.loginPageData = res;
      this.toastr.success('CMS data updated!');
      this.cmsForm.markAsPristine();
      this.cdr.markForCheck();
    });
  }

  onFileChange(e) {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.toastr.error('Invalid file format!');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
    this.uploadedFile = file;
  }

  _handleReaderLoaded(e) {
    const reader = e.target;
    this.imageSrc = reader.result;
    this.cdr.markForCheck();
  }
}
