<div class="btr-modal-container">
  <span mat-dialog-title>{{ modalTitle || 'Confirmation' }}</span>
  <mat-dialog-content>
    <div class="flex-col">
      <div class="modal-message flex-100 flex-col" [ngStyle]="{'font-size':modalContentMessageFontSize}" [innerHTML]="modalContent || 'Are you sure?'"></div>
      <div *ngIf="modalNote" class="modal-note flex-100 flex-col" [innerHTML]="modalNote"></div>
      <div *ngIf="warningNote" class="warning-note flex-100 flex-col" [innerHTML]="warningNote"></div>
      <div *ngIf="modalList" class="modal-list flex-100 flex-col">
        <ul>
          <li *ngFor="let note of modalList">{{note}}</li>
        </ul>
      </div>

      <div class="feedback-input flex-row flex-wrap" [formGroup]="form" *ngIf="feedbackRequired || optionalFeedback">
        <mat-form-field appearance="outline" class="btr-form-field no-margin-bottom flex-100">
          <mat-label>{{feedbackTitle}}</mat-label>
          <textarea *ngIf="!maxFeedbackLength"
                    [class.hideTextareaResize] = "!displayTextareaResize"
                    rows="4"
                    matInput
                    [maxlength]="maxHintLength"
                    formControlName="feedback">
          </textarea>
          <mat-hint *ngIf="maxHintLength" align="end">{{form.controls['feedback'].value?.length || 0}}/{{maxHintLength}}</mat-hint>

          <input *ngIf="maxFeedbackLength" [maxlength]="maxFeedbackLength" matInput formControlName="feedback">
          <mat-hint *ngIf="maxFeedbackLength" align="end">{{form.controls['feedback'].value?.length || 0}}/{{maxFeedbackLength}}</mat-hint>

          <mat-error *ngIf="form.controls.feedback.errors?.required || form.controls.feedback.errors?.whitespace">Field cannot be empty</mat-error>
        </mat-form-field>
      </div>

      <ng-container *ngIf="typeToConfirmText">
        <div class="dialog-delimiter"></div>
        <div class="type-to-confirm">
          <app-type-to-confirm
            [text]="typeToConfirmText"
            (status)="onStatusChange($event)">
          </app-type-to-confirm>
        </div>
      </ng-container>

    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <app-styleguide-simple-button
      type="dark-link"
      [label]="cancelButtonLabel"
      (onClick)="close()">
    </app-styleguide-simple-button>
    <app-styleguide-simple-button
      [type]="confirmButtonType"
      [label]="confirmButtonLabel"
      [disabled]="(feedbackRequired && form && !form.valid) || (!isTextCorrect && hasTypeToConfirmText)"
      (onClick)="save()">
    </app-styleguide-simple-button>
  </mat-dialog-actions>
</div>
