<app-login-container [disableLoadingAnimation]="true">
  <ng-template let-brandingData>
    <div class="flex-row justify-center items-center" id="forgot-password">
      <!-- Title for Forgot Password State: -->
      <div class="section-container">
        <div *ngIf="!showMfaForm" class="login-header" [style.margin-bottom]="emailSent ? '32px' : '24px'">
          <div class="welcome-message bold-text">
            <ng-container *ngIf="!emailSent">
              <p class="text-center bold-text header">Forgot Your Password?</p>
              <p class="body-2 forgot-password-info">Enter your email address and we'll send an email with instructions to reset password</p>
            </ng-container>
            <span *ngIf="emailSent">We have sent a password reset code by email to {{emailSentTo}}. Enter it below to reset your password</span>
          </div>
        </div>

        <!-- Header For MFA Step (after Password Reset):  -->
        <app-login-header *ngIf="showMfaForm" [companyName]="brandingData?.company?.name"></app-login-header>

        <!-- Forgot Password Form (amplify) -->
        <div *ngIf="!showMfaForm && !hideAmplify" class="forgot-password-form">
          <amplify-authenticator [services]="services" initialState="resetPassword">
            <ng-template 
              amplifySlot="authenticated"
              let-user="user"
              let-signOut="signOut"
            ></ng-template>
          </amplify-authenticator>

          <app-styleguide-simple-button
            type="green-link"
            fluid="true"
            class="forgot-password-btn"
            label="Cancel"
            (onClick)="goToLoginPage()">
          </app-styleguide-simple-button>
        </div>

        <!-- MFA Form -->
        <form *ngIf="showMfaForm" [formGroup]="mfaFormGroup">
          <div class="reset-password-code">
            <mat-form-field appearance="outline" class="btr-form-field form-field-wrapper">
              <input type="text" matInput formControlName="mfaCode" placeholder="2FA Code" autocomplete="off" required>
              <mat-error *ngIf="mfaLoginError">
                {{mfaLoginError}}
              </mat-error>
            </mat-form-field>
          </div>

          <app-styleguide-simple-button 
            type="green-filled" 
            fluid="true" 
            class="reset-password-btn" 
            label="Confirm"
            (onClick)="loginMFA()">
          </app-styleguide-simple-button>
        </form>
      </div>
    </div>
  </ng-template>
</app-login-container>
