import { IWorkflowBase } from '@core/interfaces';
import { cloneDeep } from 'lodash';

export class WorkflowBaseModel<T extends IWorkflowBase> {
  protected readonly _workflow: T;

  constructor(workflow: T) {
    this._workflow = cloneDeep(workflow);
  }

  get data() {
    return this._workflow;
  }

  public toDTO(): T {
    this.stripSteps();

    return this._workflow;
  }

  /*
  * Remove the config keys unused by API
  * */
  protected stripSteps() {
    this._workflow.steps.forEach(step => {
      if (step.config_data) {
        delete step.config_data.show_links;
        delete step.config_data.enable_ia;
      }

      if (step.step_data) {
        delete step.step_data.executionRequiredMessage;
        delete step.step_data.summaryRequiredMessage;
      }
    });
  }
}
