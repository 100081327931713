import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { MaterialModule } from '@core/modules/material.module';
import { ButtonsModule } from '@shared/components/buttons/buttons.module';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';
import { UserGroupDepartmentSearchModule } from '../user-group-department-search/user-group-department-search.module';
import { ElectronicSignatureComponent } from './electronic-signature.component';
import { PinCodeInputModule } from '../pin-code-input/pin-code-input.module';
import { PipesModule } from '@core/pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    CoreModule,
    UserAvatarModule,
    UserGroupDepartmentSearchModule,
    ButtonsModule,
    PinCodeInputModule,
    PipesModule
  ],
  declarations: [
    ElectronicSignatureComponent
  ],
  exports: [
    ElectronicSignatureComponent
  ],
  providers: []
})
export class ElectronicSignatureModule {
}
