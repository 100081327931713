import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaginatorService {
  private pageIndex = 0;
  private pageSize = 10;
  private sortBy = null;
  private sortDirection = 'asc';
  private pagination$: Subject<{
    pageSize: number;
    pageIndex: number;
    sortBy: string;
    sortDirection: string;
  }> = new Subject();


  setInitialParameters(params) {
    this.sortBy = params?.sortBy ? params.sortBy : null;
    this.sortDirection = params?.sortDirection;
    this.pageIndex = params?.pageIndex;
    this.pageSize = params?.pageSize;
  }

  pageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.pagination$.next({
      pageSize: this.pageSize,
      pageIndex: this.pageIndex,
      sortBy: this.sortBy,
      sortDirection: this.sortDirection
    });
  }

  sortTable(event: Sort): void {
    this.sortBy = event.active;
    this.sortDirection = event.direction;
    this.pageIndex = 0;
    this.pagination$.next({
      pageSize: this.pageSize,
      pageIndex: this.pageIndex,
      sortBy: this.sortBy,
      sortDirection: this.sortDirection
    });
  }

  getPaginationData(): Observable<any> {
    return this.pagination$.asObservable();
  }

}
