import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NotificationResponse, NotificationTemplate } from '@core/interfaces';
import { NotificationMenuItemComponent } from '../../notification-menu-item/notification-menu-item.component';

@Component({
  selector: 'app-trace-matrix-notification-generated',
  templateUrl: './trace-matrix-generated.component.html',
  styleUrls: ['./trace-matrix-generated.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TraceMatrixGeneratedNotificationComponent extends NotificationMenuItemComponent {
  NotificationTemplate = NotificationTemplate;
  @Input() notification: NotificationResponse;
}
