import { Company, IUserLite } from '@core/models';

export enum NotificationType {
  WorkflowApproval = 'WorkflowApproval',
  WorkflowReview = 'WorkflowReview',
  WorkflowExecution = 'WorkflowExecution',
}

export enum NotificationTemplate {
  SignatureRequestApproved = 'SignatureRequestApproved',
  SignatureRequestCancelled = 'SignatureRequestCancelled',
  SignatureRequestChanged = 'SignatureRequestChanged',
  SignatureRequestCompleted = 'SignatureRequestCompleted',
  SignatureRequestRejected = 'SignatureRequestRejected',
  SignatureRequestRejectedToRemainingApprover = 'SignatureRequestRejectedToRemainingApprover',
  SignatureRequestUserRemoved = 'SignatureRequestUserRemoved',
  SignatureRequestSentForSignature = 'SignatureRequestSentForSignature',
  ReviewRequestCancelledToOwner = 'ReviewRequestCancelledToOwner',
  ReviewRequestCancelledToRemainingReviewer = 'ReviewRequestCancelledToRemainingReviewer',
  ReviewRequestCompleted = 'ReviewRequestCompleted',
  ReviewRequestSentForReviewToReviewer = 'ReviewRequestSentForReviewToReviewer',
  ReviewRequestChanged = 'ReviewRequestChanged',
  ReviewRequestChangedCancelled = 'ReviewRequestChangedCancelled',
  PackagePublishedPublicToBtrAdmin = 'PackagePublishedPublicToBtrAdmin',
  PackagePublishedPrivateToBtrAdmin = 'PackagePublishedPrivateToBtrAdmin',
  PackagePublishedPublicToCompany = 'PackagePublishedPublicToCompany',
  PackagePublishedPrivateToCompany = 'PackagePublishedPrivateToCompany',
  PackageRemovedToParentCompany = 'PackageRemovedToParentCompany',
  PackageRemovedByCompany = 'PackageRemovedByCompany',
  PackageChangedByCompany = 'PackageChangedByCompany',
  RelationCreatedToParentCompany = 'RelationCreatedToParentCompany',
  RelationCreatedToChildCompany = 'RelationCreatedToChildCompany',
  RelationRemovedToParentCompany = 'RelationRemovedToParentCompany',
  RelationRemovedToChildCompany = 'RelationRemovedToChildCompany',
  GlobalSystemDeleted = 'GlobalSystemDeleted',
  GlobalSystemActivatedToCompany = 'GlobalSystemActivatedToCompany',
  CompletedTraceMatrixGeneration = 'CompletedTraceMatrixGeneration',
  CompletedTraceMatrixUpdate = 'CompletedTraceMatrixUpdate',
  PackageDownloadedToCompany = 'PackageDownloadedToCompany',
  ReportingEnabledSuccessful = 'ReportingEnabledSuccessful',
  ReportingEnabledFailed = 'ReportingEnabledFailed',
  ReportingSyncSuccessful = 'ReportingSyncSuccessful',
  ReportingSyncFailed = 'ReportingSyncFailed',
  WorkflowAccessListRequested = 'WorkflowAccessListRequested',
  WorkflowAccessListApproved = 'WorkflowAccessListApproved',
  ReportCompleted = 'ReportCompleted',
  SystemPeriodicReview = 'SystemPeriodicReview'
}

export interface ICompanyNotificationSettings {
  uuid: string;
  type: NotificationType;
  is_recurrent: boolean;
  interval_seconds: number;
  notification_limit: number;
  description: string;
  company_uuid: string;
  created_at?: string;
  updated_at?: string;
}

export interface ICompanySelectItem {
  value: number;
  viewValue: string;
}

export interface NotificationResponse {
  uuid: string;
  type: string;
  template: NotificationTemplate;
  is_seen: boolean;
  is_read: boolean;
  data: NotificationData;
  created_at: string;
  id?: number;
  initiator_message?: string;
  responder_message?: string;
}

export interface NotificationData {
  title?: string;
  action_user?: IUserLite;
  comment?: string;
  link?: NotificationLink;
  steps?: string;
  id?: string;
  company?: Company;
  initial_user_role?: string;
  new_user_role?: string;
  initiator_comment?: string;
  acceptor_comment?: string;
  license_company_admin_count?: number;
  license_admin_count?: number;
  license_client_count?: number;
  license_editor_count?: number;
  license_viewer_count?: number;
  company_name?: string;
  reason?: string;
  initiator_first_name?: string;
  initiator_last_name?: string;
  initiator_message?: string;
  assigned_user?: IUserLite;
  system_name?: string;
  package_title?: string;
  responder_message?: string;
  access_level?: string;
  integration_type?: string;
  notification_type?: string;
  step_uuid?: string;
  import_type?: string;
  step_display_title?: string;
  document_title?: string;
  report_title?: string;
  report_link?: string;
  system_title?: string;
  periodic_review_date?: string;
}

export interface NotificationLink {
  title: string;
  workflow_type: string;
  parameters: NotificationLinkParameters;
  is_template?: boolean;
}

export interface NotificationLinkParameters {
  review_workflow_uuid: string;
  review_version_uuid: string;
  original_workflow_uuid: string;
  original_version_uuid: string;
  package_uuid: string;
  package_version_uuid: string;
}
