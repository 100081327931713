import {
  ChangeDetectionStrategy, Component,
  Inject, OnInit
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomValidationService } from '@core/validators/customvalidation';
import { ToastrService } from 'ngx-toastr';

export interface IConfirmationModalData {
  feedbackRequired: boolean;
  feedbackInitialValue: string;
  feedbackTitle: string;
  modalTitle: string;
  modalContent: string;
  minFeedbackLength: number;
  maxFeedbackLength: number;

  modalNote: string;
  modalList: string[];
  warningNote: string;
  cancelButtonLabel: string;
  confirmButtonLabel: string;
  confirmButtonType: string;
  maxHintLength: number;
  optionalFeedback: boolean;

  typeToConfirmText: string;
  modalContentMessageFontSize: string;
}

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationModalComponent implements OnInit {
  feedbackRequired: boolean;
  feedbackTitle: string;
  modalTitle: string;
  modalContent: string;
  maxFeedbackLength: number;
  minFeedbackLength: number;
  modalNote: string;
  modalList: string[];
  warningNote: string;
  cancelButtonLabel = 'No';
  confirmButtonLabel = 'Yes';
  confirmButtonType = 'green-link';
  maxHintLength: number;
  optionalFeedback: boolean;
  displayTextareaResize = true;
  typeToConfirmText = '';
  modalContentMessageFontSize = '16px';
  form: UntypedFormGroup;
  isTextCorrect = false;
  hasTypeToConfirmText = false;

  private feedbackInitialValue: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private customValidation: CustomValidationService,
    @Inject(MAT_DIALOG_DATA) private data: IConfirmationModalData
  ) {
    this.feedbackRequired = this.data.feedbackRequired;
    this.feedbackInitialValue = this.data.feedbackInitialValue;
    this.feedbackTitle = this.data.feedbackTitle;
    this.modalTitle = this.data.modalTitle;
    this.modalContent = this.data.modalContent;
    this.minFeedbackLength = this.data.minFeedbackLength || 1;
    this.maxFeedbackLength = this.data.maxFeedbackLength;

    this.modalNote = this.data.modalNote;
    this.modalList = this.data.modalList;
    this.warningNote = this.data.warningNote;
    this.cancelButtonLabel = this.data.cancelButtonLabel || 'No';
    this.confirmButtonLabel = this.data.confirmButtonLabel || 'Yes';
    this.confirmButtonType = this.data.confirmButtonType || 'green-link';
    this.maxHintLength = this.data.maxHintLength;
    this.optionalFeedback = this.data.optionalFeedback;
    this.typeToConfirmText = this.data.typeToConfirmText;
    this.modalContentMessageFontSize = this.data.modalContentMessageFontSize;
  }

  ngOnInit() {
    if (this.feedbackRequired || this.optionalFeedback) {
      this.form = this.fb.group({
        feedback: [
          this.feedbackInitialValue ? this.feedbackInitialValue : '',
          [
            Validators.required,
            Validators.minLength(this.minFeedbackLength),
            Validators.maxLength(this.maxHintLength ? this.maxHintLength : this.maxFeedbackLength ? this.maxFeedbackLength : 256),
            this.customValidation.noWhitespace()
          ]
        ]
      });
      if (this.optionalFeedback) {
        this.form.controls.feedback.removeValidators(Validators.required);
      }
    }
    this.hasTypeToConfirmText = !!this.typeToConfirmText;
  }

  onStatusChange(status: boolean): void {
    this.isTextCorrect = status;
  }

  close(): void {
    this.dialogRef.close(false);
  }

  save(): void {
    const feedback = this.form?.get('feedback').value;
    if (this.form && this.optionalFeedback) {
      this.dialogRef.close(feedback ? feedback : null);
    } else if (!this.feedbackRequired) {
      this.dialogRef.close(true);
    } else if (!this.form.valid) {
      this.toastr.warning('Please complete all required fields.');
    } else {
      this.dialogRef.close(feedback);
    }
  }

}
