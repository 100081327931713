<div class="align-center margin-top-15 flex-row flex-wrap">
  <mat-form-field class="no-label-wrapper btr-form-field no-margin-bottom flex-80" appearance="outline">
    <input matInput placeholder="Question..." [(ngModel)]="question.title" #questionTitleInput="ngModel" (focusout)="onQuestionChange()"/>
    <mat-hint class="hint-error" *ngIf="questionTitleInput.dirty && !question.title">Question title required</mat-hint>
  </mat-form-field>
  <mat-icon class="red delete-icon" (click)="deleteQuestion()">delete</mat-icon>
</div>
<div cdkDropList (cdkDropListDropped)="dropAnswer($event, question.answers)">
  <div *ngFor="let answer of question.answers; let j = index"
       cdkDrag
       class="align-center margin-top-10 question flex-row flex-wrap"
       [ngClass]="{'error':scoreInput.errors?.pattern}">
    <mat-icon class="right-arrow-icon flex-3">arrow_right_alt</mat-icon>
    <div class="flex-50">
      <mat-form-field class="no-label-wrapper btr-form-field margin-left" floatLabel="never" appearance="outline">
        <input placeholder="Answer..." matInput [(ngModel)]="answer.title" (focusout)="onQuestionChange()" #answerInput="ngModel"><br/>
        <mat-hint class="hint-error" *ngIf="answerInput.dirty && !answer.title">Answer title required</mat-hint>
      </mat-form-field>
    </div>
    <div class="flex-3"></div>
    <mat-form-field class="no-label-wrapper btr-form-field no-margin-bottom flex-20 score" appearance="outline">
      <input placeholder="Score..."
             matInput type="number" step="1"
             #scoreInput="ngModel"
             (focusout)="onQuestionChange()"
             autocomplete="off"
             [(ngModel)]="answer.score"
             pattern="^([+-]?[1-9]\d*|0)$"><br/>
      <mat-hint class="hint-error" *ngIf="(scoreInput.errors?.pattern) || (!answer.score && answer.score !== 0)">
        <span *ngIf="!scoreInput.errors?.pattern && (!answer.score && answer.score !== 0)">Answer Score required</span>
        <span *ngIf="scoreInput.errors?.pattern">Only positive and negative integer numbers allowed</span>
      </mat-hint>
    </mat-form-field>
    <mat-icon class="height-icon pointer green-text large-icon flex-2" cdkDragHandle>height</mat-icon>
    <span class="flex-2">
       <mat-icon *ngIf="question.answers.length > 2" class="red delete-icon"  (click)="deleteAnswer(question.answers, j)">delete</mat-icon>
    </span>
    <span class="add-sub-question-container flex-17">
      <app-styleguide-simple-button
        *ngIf="!answer.trigger?.questions?.length && level < 1"
        type="green-link"
        label="Add Sub-Questions"
        icon="icon-n-plus"
        (onClick)="addSubQuestion(answer)">
      </app-styleguide-simple-button>
      <app-styleguide-simple-button
        *ngIf="answer.trigger?.questions?.length && level < 1"
        type="red-link"
        label="Remove Sub-Questions"
        icon="icon-n-close"
        (onClick)="removeSubquestions(answer)">
      </app-styleguide-simple-button>
    </span>
    <div class="sub-questionnaire flex-100" *ngIf="answer.trigger?.questions?.length">
      <app-questionnaire-builder (valuesChanged)="onQuestionChange()" [level]="level + 1"
                                 [questions]="answer.trigger.questions"
                                 [mode]="mode"
      ></app-questionnaire-builder>
    </div>
  </div>
  <div class="flex-row items-center">
    <app-styleguide-simple-button
      type="green-link"
      icon="icon-n-plus"
      label="Add Answer"
      (onClick)="addAnswer(question.answers)">
    </app-styleguide-simple-button>
  </div>
</div>
