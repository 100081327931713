import { Injectable } from '@angular/core';
import { Company, CompanyConfigDTO, CompanyDTO } from '@core/models';
import { CompaniesService } from '@core/services';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { UserSelectors } from '../user/user.selectors';
import {
  ActivateCompany, CancelCompanyDeletion, ConvertCompanyToNonSSO, ConvertCompanyToSSO, DeactivateCompany, DeleteCompany,
  GetCurrentCompany, GetPermissionMatrixLite, PublishCompany, UpdateCompany, UpdateCompanyConfig, UpdateCompanyConfigReviewIdleTime, UpdateCompanyPoolConfig
} from './company.actions';
import { cloneDeep } from 'lodash';
import { throwError } from 'rxjs';
import { CompanyCustomDateFormatService } from '@core/services/company-custom-date-format.service';
import { PermissionMatrixService } from '@core/services/permission-matrix.service';

export interface CompanyStateModel {
  company: Company;
  loading: boolean;
  permissionMatrixLite: any;
}

const defaults = {
  company: new Company({}),
  loading: false,
  permissionMatrixLite: null
};

@State<CompanyStateModel>({
  name: 'CompanyState',
  defaults
})
@Injectable()
export class CompanyState {

  constructor(
    private companiesService: CompaniesService,
    private store: Store,
    private companyCustomDateFormatService: CompanyCustomDateFormatService,
    private permissionMatrixService: PermissionMatrixService) { }

  @Action(GetCurrentCompany, { cancelUncompleted: true })
  getCurrentCompany({ patchState, dispatch }: StateContext<CompanyStateModel>, { company }) {
    // stored from BTR admin
    if (company) {
      patchState({
        company
      });
    } else {
      patchState({
        loading: true
      });
      return this.companiesService.getCurrentCompany().pipe(tap((res: CompanyDTO) => {
        const cmp = new Company(res);
        this.companyCustomDateFormatService.updateDatepickerFormat(cmp.config.dateformat);
        patchState({
          loading: false,
          company: cmp
        });
      }));
    }
  }

  @Action(GetPermissionMatrixLite, { cancelUncompleted: true })
  getPermissionMatrixLite({ patchState }: StateContext<CompanyStateModel>) {
    return this.permissionMatrixService.getPermissionMatrixLite().pipe(
      tap(res => {
        patchState({
          permissionMatrixLite: res
        })
      })
    )
  }

  @Action(UpdateCompany, { cancelUncompleted: true })
  updateCompany({ patchState }: StateContext<CompanyStateModel>, { newCompany }: UpdateCompany) {
    patchState({ loading: true });
    const isBTRAdmin = this.store.selectSnapshot(UserSelectors.isBTRAdmin);
    const request = isBTRAdmin ? this.companiesService.updateCompanyById(newCompany) : this.companiesService.updateCompany(newCompany);
    return request.pipe(
      tap((res: CompanyDTO) => {
        patchState({
          company: new Company(res)
        });
      }),
      finalize(() => {
        patchState({
          loading: false,
        });
      })
    );
  }

  @Action(UpdateCompanyConfig, { cancelUncompleted: true })
  updateCompanyConfig({ patchState }: StateContext<CompanyStateModel>, { companyUuid, newCompany }: UpdateCompanyConfig) {
    patchState({
      loading: true
    });
    const user = this.store.selectSnapshot(UserSelectors.getCurrentUser);
    const request = user.is_btr_admin
      ? this.companiesService.updateAdminCompanyConfig(companyUuid, newCompany)
      : this.companiesService.updateCompanyConfig(newCompany);
    return request.pipe(
      tap(updatedCompany => {
        if (user.is_btr_admin) {
          this.companiesService.getCompanyById(updatedCompany.company_uuid).pipe(tap((res: CompanyDTO) => {
            const cmp = new Company(res);
            this.companyCustomDateFormatService.updateDatepickerFormat(cmp.config.dateformat);
            patchState({
              loading: false,
              company: cmp
            });
          })).subscribe();
        }
      }),
      catchError(err => {
        patchState({
          loading: false
        });
        return throwError(err);
      })
    );
  }

  @Action(DeleteCompany, { cancelUncompleted: true })
  deleteCompany({ patchState }: StateContext<CompanyStateModel>, { companyUuid }: DeleteCompany) {
    patchState({ loading: true });
    return this.companiesService.deleteCompany(companyUuid).pipe(tap(res => {
      patchState({
        company: new Company(res),
        loading: false
      });
    }),
      catchError(err => {
        patchState({ loading: false });
        return throwError(err);
      }));
  }

  @Action(CancelCompanyDeletion, { cancelUncompleted: true })
  cancelCompanyDeletion({ patchState }: StateContext<CompanyStateModel>, { companyUuid }: CancelCompanyDeletion) {
    patchState({ loading: true });
    return this.companiesService.cancelDeletionCompany(companyUuid).pipe(tap(res => {
      patchState({
        company: new Company(res),
        loading: false
      });
    }),
      catchError(err => {
        patchState({ loading: false });
        return throwError(err);
      }));
  }

  @Action(ActivateCompany, { cancelUncompleted: true })
  activateCompany({ patchState }: StateContext<CompanyStateModel>, { companyUuid }: ActivateCompany) {
    patchState({ loading: true });
    return this.companiesService.activateCompany(companyUuid).pipe(tap(res => {
      patchState({
        company: new Company(res),
        loading: false
      });
    }),
      catchError(err => {
        patchState({ loading: false });
        return throwError(err);
      }));
  }

  @Action(DeactivateCompany, { cancelUncompleted: true })
  deactivateCompany({ patchState }: StateContext<CompanyStateModel>, { companyUuid }: DeactivateCompany) {
    patchState({ loading: true });
    return this.companiesService.deactivateCompany(companyUuid).pipe(tap(res => {
      patchState({
        company: new Company(res),
        loading: false
      });
    }),
      catchError(err => {
        patchState({ loading: false });
        return throwError(err);
      }));
  }

  @Action(PublishCompany, { cancelUncompleted: true })
  publishCompany({ patchState }: StateContext<CompanyStateModel>, { company }: PublishCompany) {
    patchState({ loading: true });
    return this.store.dispatch(new UpdateCompany(company.toDTO())).pipe(
      switchMap(() => {
        return this.companiesService.publishCompany(company.uuid).pipe(tap(res => {
          patchState({
            company: new Company(res),
            loading: false
          });
        }),
          catchError(err => {
            patchState({ loading: false });
            return throwError(err);
          }));
      }));
  }

  @Action(UpdateCompanyPoolConfig, { cancelUncompleted: true })
  updateCompanyPoolConfig({ getState, patchState }: StateContext<CompanyStateModel>, { poolConfig }: UpdateCompanyPoolConfig) {
    const company: Company = cloneDeep(getState().company);
    company.pool_config = poolConfig;
    patchState({ company });
  }

  @Action(ConvertCompanyToSSO, { cancelUncompleted: true })
  ConvertCompanyToSSO({ patchState }: StateContext<CompanyStateModel>, { companyUuid }: ConvertCompanyToSSO) {
    patchState({ loading: true });
    return this.companiesService.convertCompanyToSSO(companyUuid).pipe(tap(res => {
      patchState({
        company: new Company(res),
        loading: false
      });
    }),
      catchError(err => {
        patchState({ loading: false });
        return throwError(err);
      }));
  }

  @Action(ConvertCompanyToNonSSO, { cancelUncompleted: true })
  ConvertCompanyToNonSSO({ patchState }: StateContext<CompanyStateModel>, { companyUuid }: ConvertCompanyToNonSSO) {
    patchState({ loading: true });
    return this.companiesService.convertCompanyToNonSSO(companyUuid).pipe(tap(res => {
      patchState({
        company: new Company(res),
        loading: false
      });
    }),
      catchError(err => {
        patchState({ loading: false });
        return throwError(err);
      }));
  }

  @Action(UpdateCompanyConfigReviewIdleTime, { cancelUncompleted: true })
  updateCompanyConfigReviewIdleTime({ getState, patchState }: StateContext<CompanyStateModel>, { companyUuid, minutes }: UpdateCompanyConfigReviewIdleTime) {
    patchState({
      loading: true
    });

    return this.companiesService.updateCompanyConfigReviewIdleTime(companyUuid, minutes).pipe(
      tap((res: CompanyConfigDTO) => {
        const company: Company = cloneDeep(getState().company);
        company.config.reviewIdleTime = res.review_idle_time;
        patchState({
          company: company,
          loading: false
        });
      }),
      catchError(err => {
        patchState({
          loading: false
        });
        return throwError(err);
      })
    );
  }
}
