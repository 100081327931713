import { NgModule } from "@angular/core";
import { WebViewerComponent } from "./webviewer.component";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "@core/modules/material.module";
import { CoreModule } from "@core/core.module";
import { ButtonsModule } from "@shared/components/buttons/buttons.module";
import { PageSelectorModule } from "@shared/components/page-selector/page-selector.module";

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    CoreModule,
    ButtonsModule,
    PageSelectorModule
  ],
  exports: [WebViewerComponent],
  declarations: [
    WebViewerComponent
  ],
  providers: []
})
export class WebViewerModule {
}
