import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { IBranding } from '@core/interfaces';
import { AuthenticationService, RoutingService } from '@core/services';
import { CmsService } from '@core/services/cms.service';
import { AutoUnsubscribe } from '@core/utilities/auto-unsub';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-login-container',
  templateUrl: './login-container.component.html',
  styleUrls: ['./login-container.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('2s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('1s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
@AutoUnsubscribe()
export class LoginContainerComponent implements OnInit {
  currentYear: number = new Date().getFullYear();
  cmsLoaded = false;
  brandingData: IBranding;

  private loggedIn$: Subscription;
  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;

  @Input() disableLoadingAnimation = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private cmsService: CmsService,
    public routingService: RoutingService,
    public authService: AuthenticationService,
  ) { }

  ngOnInit(): void {
    this.cmsService.getBrandingLogin().pipe(take(1)).subscribe(res => {
      this.cmsLoaded = true;
      this.brandingData = res;
      localStorage.setItem('pool_config', JSON.stringify(res.company.pool_config));
      this.authService.configureAmplify();
      this.cdr.markForCheck();
    }, err => {
      this.router.navigate([this.routingService.COMPANY_NOT_FOUND.url()]);
    });
  }
}
