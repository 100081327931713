import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DynamicModule } from 'ng-dynamic-component';
import { CoreModule } from '../../../../core/core.module';
import { MaterialModule } from '../../../../core/modules/material.module';
import { RequestedAccessPanelComponent } from './requested-access-panel.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { UserDetailsModule } from '../../user-details/user-details.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MaterialModule,
    CoreModule,
    DynamicModule,
    MatExpansionModule,
    UserDetailsModule,
  ],
    declarations: [
        RequestedAccessPanelComponent,
    ],
    exports: [
        RequestedAccessPanelComponent
    ],
    providers: []
})
export class RequestedAccessPanelModule {
}
