import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NotificationResponse } from '@core/interfaces';
import { NotificationMenuItemComponent } from '../../notification-menu-item/notification-menu-item.component';

@Component({
  selector: 'app-licence-company-request-rejected',
  templateUrl: './licence-company-request-rejected.component.html',
  styleUrls: ['./licence-company-request-rejected.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyLicenceRequestNotificationRejectedComponent extends NotificationMenuItemComponent {
  @Input() notification: NotificationResponse;
}
