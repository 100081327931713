import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PipesModule } from '@core/pipes/pipes.module';
import { ButtonsModule } from '../buttons/buttons.module';
import { LoadingAnimationModule } from '../loading-animation/loading-animation.module';
import { AsyncPdfBannerComponent } from './async-pdf-banner.component';
import { AsyncPdfFilesModalComponent } from './async-pdf-files-modal/async-pdf-files-modal.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    LoadingAnimationModule,
    ButtonsModule,
    PipesModule,
    MatProgressBarModule,
    MatTooltipModule
  ],
  declarations: [
    AsyncPdfBannerComponent,
    AsyncPdfFilesModalComponent
  ],
  exports: [
    AsyncPdfBannerComponent
  ],
  providers: []
})
export class AsyncPdfBannerModule {
}
