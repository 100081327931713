import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NotificationResponse } from '@core/interfaces';
import { NotificationMenuItemComponent } from '../../notification-menu-item/notification-menu-item.component';

@Component({
  selector: 'app-signature-request-completed',
  templateUrl: './signature-request-completed.component.html',
  styleUrls: ['./signature-request-completed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignatureRequestCompletedComponent extends NotificationMenuItemComponent {
  @Input() notification: NotificationResponse;
}
