import { Injectable } from '@angular/core';
import { RuntimeLockStatus } from '@core/interfaces/import-wizard';

import { GeneralFeService } from '@core/services/general-fe.service';
import { interval, Observable, Subject } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { CanvasFunctionalElementType } from '../../canvas/canvas.enum';
import {
  AttachmentThumbnail,
  IApiPaginatedDataBase,
  ICanvasFunctionalElement,
  ICanvasFunctionalElementConfig,
  ICanvasStep,
  ICanvasStepData,
  ICanvasStepDataRow,
  IFilterDataValues,
  IRowTestScriptFailWithoutDeviation,
  ITemplateListingDocumentSettings,
  IWorkflowRuntime,
  IWorkflowTemplate,
  IWorkflowTemplateFilterTag,
  IWorkflowType,
  LockStatus
} from '../interfaces';
import { ApiService } from './api.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class CanvasService {
  private workflowPath = '/api/canvas-user-workflow';
  private previewWorkflowPath = '/api/preview-user-workflow/';
  private fePath = '/api/canvas-user-fe/';
  private feConfigsPath = '/api/company-fe-config/';
  private rowPath = '/api/row/';
  private runtimePath = '/api/runtime-user-workflow';

  private lockStatus$: Subject<LockStatus>;
  private pollInterval = 3000;

  constructor(private apiService: ApiService, private generalService: GeneralFeService) {
  }

  public getDocumentSettings(id: string): Observable<ITemplateListingDocumentSettings> {
    return this.apiService.get(`${this.workflowPath}/document-settings/${id}`);
  }

  public getCanvasFes(): Observable<ICanvasFunctionalElement[]> {
    return this.apiService.get(this.fePath);
  }

  public getTemplates(offset: number, limit: number, filters: IFilterDataValues): Observable<IApiPaginatedDataBase<IWorkflowTemplate>> {
    return this.apiService.post(`${this.workflowPath}/listing/${offset}/${limit}`, filters);
  }

  public createTemplate(template: IWorkflowTemplate) {
    return this.apiService.post(`${this.workflowPath}`, template);
  }

  public deleteTemplate(id: string, versionId: string): Observable<any> {
    return this.apiService.delete(`${this.workflowPath}/${id}/version/${versionId}`);
  }

  public updateTemplate(templateId: string, versionId: string, template: IWorkflowTemplate): Observable<{ data: IWorkflowTemplate }> {
    const updatedTemplate: IWorkflowTemplate = _.cloneDeep(template);
    updatedTemplate.steps = this.generalService.prepareStepsForSave(template.steps);
    return this.apiService.put(`${this.workflowPath}/${templateId}/version/${versionId}`, updatedTemplate)
      .pipe(
        map((res) => {
          res.data = this.generalService.setDefaultGeneralImages(res.data);
          return res;
        }));
  }

  public cloneCanvasWorkflow(workflowUuid: string, workflow: IWorkflowTemplate): Observable<{ data: IWorkflowTemplate }> {
    return this.apiService.post(`${this.workflowPath}/${workflowUuid}/clone`, workflow);
  }

  public saveNewTemplateVersion(workflowUuid: string, workflow: IWorkflowTemplate): Observable<{ data: IWorkflowTemplate }> {
    workflow.steps = this.generalService.prepareStepsForSave(workflow.steps);
    return this.apiService.post(`${this.workflowPath}/${workflowUuid}/new-version`, workflow);
  }

  public getCanvasWorkflowVersions(id: string): Observable<IApiPaginatedDataBase<IWorkflowTemplate>> {
    return this.apiService.get(`${this.workflowPath}/${id}/versions`);
  }

  public getCanvasWorkflowVersion(id: string, versionId: string): Observable<{ data: IWorkflowTemplate }> {
    return this.apiService.get(`${this.workflowPath}/${id}/version/${versionId}`);
  }

  public validateAndPublishTemplate(
    id: string,
    versionId: string
  ): Observable<{ data: IWorkflowTemplate }> {
    return this.apiService.post(`${this.workflowPath}/${id}/version/${versionId}/validate-publish`, {});
  }

  public getConfigs(feType?: string): Observable<ICanvasFunctionalElementConfig[]> {
    return this.apiService.get(feType ? this.feConfigsPath + feType : this.feConfigsPath).pipe(
      map((res) => {
        return this.generalService.setDefaultGeneralImagesBySteps(res);
      }));
  }

  public deleteConfig(config: ICanvasFunctionalElementConfig) {
    return this.apiService.delete(this.feConfigsPath + config.fe_type + '/' + config.uuid);
  }

  public saveConfig(config: Partial<ICanvasFunctionalElementConfig>) {
    if (config.fe_type === CanvasFunctionalElementType.General_Table) {
      config.step_data = this.generalService.prepareGeneralDataForSave(config);
    }
    return this.apiService.post(this.feConfigsPath + config.fe_type, config);
  }

  public updateConfig(config: ICanvasFunctionalElementConfig) {
    if (config.fe_type === CanvasFunctionalElementType.General_Table) {
      config.step_data = this.generalService.prepareGeneralDataForSave(config);
    }
    return this.apiService.put(this.feConfigsPath + config.fe_type + '/' + config.uuid, config);
  }

  public getStep(
    workflowUuid: string,
    versionUuid: string,
    feType: CanvasFunctionalElementType,
    stepUuid: string
  ): Observable<{ data: ICanvasStep }> {
    return this.apiService.get(`${this.runtimePath}/${workflowUuid}/version/${versionUuid}/${feType}/${stepUuid}`);
  }

  public updateStep(
    workflowUuid: string,
    versionUuid: string,
    feType: CanvasFunctionalElementType,
    stepUuid: string,
    isTemplate: boolean,
    step: ICanvasStep
  ): Observable<{ data: ICanvasStep }> {
    return this.apiService.put(`${isTemplate ? this.workflowPath : this.runtimePath}/${workflowUuid}/version/${versionUuid}/${feType}/${stepUuid}`, step);
  }

  public getPreviewWorkflowVersion(id: string, versionId: string): Observable<{ data: IWorkflowRuntime }> {
    return this.apiService.get(`${this.previewWorkflowPath}${id}/version/${versionId}`);
  }

  public resetPreviewWorkflowVersion(id: string, versionId: string): Observable<{ data: IWorkflowRuntime }> {
    return this.apiService.post(`${this.previewWorkflowPath}${id}/version/${versionId}/reset-preview-data`, {});
  }

  public submitPreviewStep(
    workflowUuid: string,
    versionUuid: string,
    feType: CanvasFunctionalElementType,
    stepUuid: string
  ): Observable<{ data: ICanvasStep }> {
    return this.apiService.post(`${this.previewWorkflowPath}${workflowUuid}/version/${versionUuid}/${feType}/${stepUuid}/submit`, {});
  }

  public updatePreviewWorkflowStep(
    workflowUuid: string,
    versionUuid: string,
    feType: CanvasFunctionalElementType,
    stepUuid: string,
    step: ICanvasStep
  ): Observable<ICanvasStep> {
    return this.apiService.put(`${this.previewWorkflowPath}${workflowUuid}/version/${versionUuid}/${feType}/${stepUuid}`, step);
  }

  public getExternalFile(workflowUuid: string,
    versionUuid: string,
    feType: CanvasFunctionalElementType,
    stepUuid: string,
    fileUuid: string,
    isTemplate: boolean): Observable<any> {
    return this.apiService.getBuffer(`${isTemplate ? this.workflowPath : this.runtimePath}/${workflowUuid}/version/${versionUuid}/${feType}/${stepUuid}/external-upload/${fileUuid}`);
  }

  public getExternalDeliverable(url: string): Observable<any> {
    return this.apiService.getBuffer(url);
  }

  public uploadExternalFile(
    workflowUuid: string,
    versionUuid: string,
    feType: CanvasFunctionalElementType,
    stepUuid: string,
    formData: FormData,
    isTemplate: boolean
  ): Observable<{ uuid: string; mime_type?: string }> {
    // tslint:disable-next-line: max-line-length
    return this.apiService.postMultipartForm(`${isTemplate ? this.workflowPath : this.runtimePath}/${workflowUuid}/version/${versionUuid}/${feType}/${stepUuid}/external-upload`, formData);
  }

  public setMandatoryAttachment(
    workflowUuid: string,
    versionUuid: string,
    stepUuid: string,
    rowUuid: string,
    isTemplate = false,
    isMandatoryAttachment: boolean
  ): Observable<{ success: boolean }> {
    return this.apiService.post(
      `${isTemplate ? this.workflowPath : this.runtimePath}/${workflowUuid}/version/${versionUuid}/ts-execute/${stepUuid}/${rowUuid}/mandatory-attachment`,
      { mandatory_attachment: isMandatoryAttachment }
    );
  }

  public addAttachments(workflowUuid: string, versionUuid: string, stepUuid: string, rowUuid: string, body) {
    return this.apiService.postMultipartForm(`${this.runtimePath}/${workflowUuid}/version/${versionUuid}/ts-execute/${stepUuid}/${rowUuid}/sync-attachments`, body);
  }

  public getThumbnails(workflowUuid: string, versionUuid: string, stepUuid: string, rowUuid: string): Observable<AttachmentThumbnail> {
    return this.apiService.get(`${this.runtimePath}/${workflowUuid}/version/${versionUuid}/ts-execute/${stepUuid}/${rowUuid}/attachment-thumbnails`);
  }

  public getOriginalAttachment(workflowUuid: string, versionUuid: string, stepUuid: string, rowUuid: string, attachmentUuid: string) {
    return this.apiService.getImageFromFile(`${this.runtimePath}/${workflowUuid}/version/${versionUuid}/ts-execute/${stepUuid}/${rowUuid}/attachment-image/${attachmentUuid}`);
  }

  public failWithoutDeviation(
    workflowUuid: string,
    versionUuid: string,
    stepUuid: string,
    rowUuid: string,
    body: FormData,
  ): Observable<IRowTestScriptFailWithoutDeviation> {
    return this.apiService.postMultipartForm(
      `${this.runtimePath}/${workflowUuid}/version/${versionUuid}/ts-execute/${stepUuid}/${rowUuid}/fail-without-deviation`,
      body
    );
  }

  public updateTableRow(rowUuid: string, payload: {
    content: ICanvasStepDataRow
  }): Observable<{ success: true }> {
    return this.apiService.put(`${this.rowPath}${rowUuid}`, payload);
  }

  public addLinkDeliverable(
    stepUuid: string,
    body
  ): Observable<ICanvasStepData> {
    return this.apiService.post(`/api/functional-elements/Implementation/deliverable/${stepUuid}`, body);
  }

  public addFileDeliverable(
    stepUuid: string,
    body: FormData
  ): Observable<ICanvasStepData> {
    return this.apiService.postMultipartForm(`/api/functional-elements/Implementation/deliverable/${stepUuid}`, body);
  }

  public deleteDeliverable(stepUuid: string, body): Observable<ICanvasStepData> {
    return this.apiService.deleteWithBody(`/api/functional-elements/Implementation/deliverable/${stepUuid}`, body);
  }

  public saveStep(feType: CanvasFunctionalElementType, stepUuid: string, type: IWorkflowType, payload: any):
    Observable<{ success: boolean }> {
    const path = type === IWorkflowType.Template ? this.workflowPath : this.runtimePath;
    return this.apiService.put(`${path}/${feType}/${stepUuid}`, payload);
  }

  public getSaveStepLockStatus(versionUuid: string): Observable<RuntimeLockStatus> {
    return this.apiService.get(`/api/user-workflow/${versionUuid}/lock-status`);
  }

  public getFilterTags(workflowUuid: string, versionUuid: string): Observable<{ data: IWorkflowTemplateFilterTag[] }> {
    return this.apiService.get(`${this.workflowPath}/${workflowUuid}/version/${versionUuid}/filter-tags`);
  }
  public checkFilterTag(workflowUuid: string, versionUuid: string, filterTagCategoryUuid: string): Observable<{ success: boolean }> {
    return this.apiService.put(`${this.workflowPath}/${workflowUuid}/version/${versionUuid}/check/${filterTagCategoryUuid}`, {});
  }
  public uncheckFilterTag(workflowUuid: string, versionUuid: string, filterTagCategoryUuid: string): Observable<{ success: boolean }> {
    return this.apiService.put(`${this.workflowPath}/${workflowUuid}/version/${versionUuid}/uncheck/${filterTagCategoryUuid}`, {});
  }

  public startSaveStepLockStatusCheck(versionUuid: string): Observable<RuntimeLockStatus> {
    this.stopSaveStepLockStatusCheck();
    this.lockStatus$ = new Subject();
    return interval(this.pollInterval).pipe(
      startWith(0),
      switchMap(() => this.getSaveStepLockStatus(versionUuid)),
      takeUntil(this.lockStatus$)
    );
  }

  public stopSaveStepLockStatusCheck() {
    if (this.lockStatus$) {
      this.lockStatus$.next(null);
      this.lockStatus$.complete();
    }
  }
}
