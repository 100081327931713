import { Injectable } from '@angular/core';
import {
  IApiPaginatedDataBase, ICompanyLite, ICompanySystem,
  ISortQueryParameters,
  IImpactAssessmentFullWithProgress,
  IPackage,
  IPackageFileResponse, IPackageHistoryListingRequest, IPackageHistoryListingResponse, IPackageInitial,
  IPackageListingRequest, IPackageLite, IPackageVersion, IRequestPackageRequest, WorkflowLink
} from '@core/interfaces';
import { IPackageRuntimeImportRequest, IPackageTemplateImportRequest, RuntimeLockStatus } from '@core/interfaces/import-wizard';
import { interval, Observable, Subject } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { CanvasFunctionalElementType } from 'src/app/canvas/canvas.enum';
import { ApiService } from './api.service';
import { GeneralFeService } from './general-fe.service';
import { PLACEHOLDER_ROOT_ID } from '@core/constants/app-constants';
import { IUserLite } from '@core/models';




export interface LockStatus {
  is_review_locked: boolean;
  review_locked_by: IUserLite;
  is_review_session_active: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class MarketplaceService {
  private marketplaceLocalPath = '/api/package';
  private marketplaceGlobalPath = '/api/marketplace';
  private marketplaceSystemGlobalPath = '/api/marketplace/system';

  private pollInterval = 3000;
  private lockStatus$: Subject<LockStatus>;
  private downloadStatus$: Subject<boolean>;
  private publishingStatus$: Subject<boolean>;

  constructor(private apiService: ApiService, private generalService: GeneralFeService) { }

  public getPackages(offset: number, limit: number, body?: IPackageListingRequest): Observable<IApiPaginatedDataBase<IPackageLite>> {
    return this.apiService.post(`${this.marketplaceLocalPath}/listing/${offset}/${limit}`, body || {});
  }

  public getGlobalPackages(offset: number, limit: number, body?: IPackageListingRequest): Observable<IApiPaginatedDataBase<IPackageLite>> {
    return this.apiService.post(`${this.marketplaceGlobalPath}/package/listing/${offset}/${limit}`, body || {});
  }

  public removeLocalPackage(id: number, reason: string): Observable<{ success: true }> {
    return this.apiService.post(`${this.marketplaceLocalPath}/${id}/remove`, { reason });
  }

  public removeGlobalPackage(id: number, reason: string): Observable<{ success: true }> {
    return this.apiService.post(`${this.marketplaceGlobalPath}/package/${id}/remove`, { reason });
  }

  public savePackage(rootUuid: string, versionUuid: string, data: IPackage): Observable<IPackage> {
    return this.apiService.put(`${this.marketplaceLocalPath}/${rootUuid}/version/${versionUuid}`, data)
      .pipe(map(res => res.data));
  }

  public shareSettings(rootUuid: string, versionUuid: string, data: ICompanyLite[]): Observable<ICompanyLite[]> {
    return this.apiService.put(`${this.marketplaceLocalPath}/${rootUuid}/version/${versionUuid}/sharing-settings`, data)
      .pipe(map(res => res.data));
  }

  public publishPackage(rootUuid: string, versionUuid: string): Observable<IPackage> {
    return this.apiService.post(`${this.marketplaceLocalPath}/${rootUuid}/version/${versionUuid}/publish`, {})
      .pipe(map(res => res.data));
  }

  public getChildCompanies(): Observable<ICompanyLite[]> {
    return this.apiService.get(`${this.marketplaceGlobalPath}/child-companies`).pipe(map(res => res.data));
  }

  public getCompanies(hasPublishedPackages = 0, sortQueryParams?: ISortQueryParameters): Observable<ICompanyLite[]> {
    return this.apiService.get(`${this.marketplaceGlobalPath}/company?has_published_packages=${hasPublishedPackages}`,
     this.apiService.addSortQueryParams(sortQueryParams)).pipe(map(res => res.data));
  }

  public getGlobalSystems(offset: number, limit: number, sortQueryParams: ISortQueryParameters, hasPackage?: boolean, includeRetired?: boolean): Observable<IApiPaginatedDataBase<ICompanySystem>> {
    let queryParams = this.apiService.addSortQueryParams(sortQueryParams);
    if (hasPackage) {
      queryParams = queryParams.set('hasPackage', 1);
    }
    if (includeRetired) {
      queryParams = queryParams.set('includeRetired', 1);
    }
    return this.apiService.get(`${this.marketplaceSystemGlobalPath}/${offset}/${limit}`, queryParams);
  }

  public addPackage(packageData: IPackageInitial): Observable<IPackage> {
    return this.apiService.post(`${this.marketplaceLocalPath}`, packageData).pipe(map(res => res.data));
  }

  public getPackage(rootUuid: string, versionUuid: string): Observable<IPackage> {
    return this.apiService.get(`${this.marketplaceLocalPath}/${rootUuid}/version/${versionUuid}`).pipe(map(res => res.data));
  }

  public getGlobalPackage(rootUuid: string, versionUuid: string): Observable<IPackage> {
    return this.apiService.get(`${this.marketplaceGlobalPath}/package/${rootUuid}/version/${versionUuid}`).pipe(map(res => res.data));
  }

  public createNewPackageVersion(uuid: string): Observable<IPackage> {
    return this.apiService.post(`${this.marketplaceLocalPath}/${uuid}/new-version`, {}).pipe(map(res => res.data));
  }

  public getVersions(uuid: string): Observable<IPackageVersion[]> {
    return this.apiService.get(`${this.marketplaceLocalPath}/${uuid}/versions`).pipe(map(res => res.data));
  }

  public getGlobalVersions(uuid: string): Observable<IPackageVersion[]> {
    return this.apiService.get<{ data: WorkflowLink[] }>(`${this.marketplaceGlobalPath}/package/${uuid}/versions`).pipe(map(res => res.data));
  }

  public deletePackage(rootUuid: string, versionUuid: string): Observable<{ success: true }> {
    return this.apiService.delete(`${this.marketplaceLocalPath}/${rootUuid}/version/${versionUuid}`);
  }

  public rejectPackage(rootUuid: string, versionUuid: string, reason: string): Observable<{ success: true }> {
    return this.apiService.post(`${this.marketplaceGlobalPath}/package/${rootUuid}/version/${versionUuid}/reject`, { reason });
  }

  public approvePackage(rootUuid: string, versionUuid: string): Observable<IPackage> {
    return this.apiService.post(`${this.marketplaceGlobalPath}/package/${rootUuid}/version/${versionUuid}/approve`, {}).pipe(map(res => res.data));
  }

  public addSystemToGlobal(system: ICompanySystem): Observable<ICompanySystem> {
    return this.apiService.post(`${this.marketplaceSystemGlobalPath}`, system);
  }

  public updateGlobalSystem(uuid: string, system: ICompanySystem): Observable<ICompanySystem> {
    return this.apiService.put(`${this.marketplaceSystemGlobalPath}/${uuid}`, system);
  }

  public activateGlobalSystem(globalSystemUuid: string, system: ICompanySystem): Observable<ICompanySystem> {
    return this.apiService.post(`${this.marketplaceSystemGlobalPath}/${globalSystemUuid}/activate`, system);
  }

  public getGlobalSystem(globalSystemUuid: string): Observable<ICompanySystem> {
    return this.apiService.get(`${this.marketplaceSystemGlobalPath}/${globalSystemUuid}`);
  }

  public cancelPackagePublishing(rootUuid: string, versionUuid: string): Observable<{ success: true }> {
    return this.apiService.post(`${this.marketplaceLocalPath}/${rootUuid}/version/${versionUuid}/cancel-publish`, {});
  }

  public downloadPackage(rootUuid: string, versionsUuid: string): Observable<{ data: IPackage }> {
    return this.apiService.post(`${this.marketplaceGlobalPath}/package/${rootUuid}/version/${versionsUuid}/download`, {});
  }

  public requestPackage(rootUuid: string, versionsUuid: string, payload: IRequestPackageRequest): Observable<IRequestPackageRequest> {
    return this.apiService.post(`${this.marketplaceGlobalPath}/package/${rootUuid}/version/${versionsUuid}/request`, payload);
  }

  public getExternalFile(rootUuid: string, versionUuid: string, fileUuid: string, isLocal: boolean): Observable<any> {
    const apiPath = isLocal ? this.marketplaceLocalPath : `${this.marketplaceGlobalPath}/package`;
    return this.apiService.getBuffer(`${apiPath}/${rootUuid}/version/${versionUuid}/file/${fileUuid}`, null, 'response');
  }

  public deleteExternalFile(rootUuid: string, versionUuid: string, fileUuid: string): Observable<{ success: true }> {
    return this.apiService.delete(`${this.marketplaceLocalPath}/${rootUuid}/version/${versionUuid}/file/${fileUuid}`);
  }

  public createExternalFile(rootUuid: string, versionUuid: string, formData: FormData): Observable<{ data: IPackageFileResponse[] }> {
    return this.apiService.postMultipartForm(`${this.marketplaceLocalPath}/${rootUuid}/version/${versionUuid}/file`, formData);
  }

  public addTemplates(rootUuid: string, versionUuid: string, links: WorkflowLink[]): Observable<{ data: WorkflowLink[] }> {
    return this.apiService.put(`${this.marketplaceLocalPath}/${rootUuid}/version/${versionUuid}/canvas`, links);
  }

  public addWorkflows(rootUuid: string, versionUuid: string, links: WorkflowLink[]): Observable<{ data: WorkflowLink[] }> {
    return this.apiService.put(`${this.marketplaceLocalPath}/${rootUuid}/version/${versionUuid}/runtime`, links);
  }

  public createGlobalSystem(system: ICompanySystem): Observable<ICompanySystem> {
    return this.apiService.post(`${this.marketplaceGlobalPath}/system`, system);
  }

  public deleteGlobalSystem(systemUuid: ICompanySystem, deletePackages: number): Observable<{ success: true }> {
    return this.apiService.delete(`${this.marketplaceGlobalPath}/system/${systemUuid}?deletePackages=${deletePackages}`);
  }

  public importDataToRuntime(rootUuid: string, versionUuid: string, importRequest: IPackageRuntimeImportRequest) {
    return this.apiService.post(`${this.marketplaceGlobalPath}/package/${rootUuid}/version/${versionUuid}/import/runtime`, [importRequest]);
  }

  public getWorkflow(rootUuid: string, versionUuid: string, workflowRootUuid, workflowVersionUuid) {
    return this.apiService.get(`${this.marketplaceLocalPath}/${rootUuid}/version/${versionUuid}/runtime/${workflowRootUuid || PLACEHOLDER_ROOT_ID}/version/${workflowVersionUuid}`)
      .pipe(map(res => res.data));
  }

  public getPackagesHistory(offset: number, limit: number, body?: IPackageHistoryListingRequest): Observable<IApiPaginatedDataBase<IPackageHistoryListingResponse>> {
    return this.apiService.post(`${this.marketplaceGlobalPath}/package/history/${offset}/${limit}`, body || {});
  }

  public getPackagesHistorySystems(): Observable<string[]> {
    return this.apiService.get(`${this.marketplaceGlobalPath}/package/history/system`);
  }

  public getPackagesHistoryCompanies(): Observable<string[]> {
    return this.apiService.get(`${this.marketplaceGlobalPath}/package/history/company`);
  }

  public importDataToTemplates(rootUuid: string, versionUuid: string, importRequest: IPackageTemplateImportRequest[]) {
    return this.apiService.post(`${this.marketplaceGlobalPath}/package/${rootUuid}/version/${versionUuid}/import/template`, importRequest);
  }

  public getImportDataLockStatus(workflowUuid: string, versionUuid: string): Observable<RuntimeLockStatus> {
    return this.apiService.get(`/api/runtime-user-workflow/${workflowUuid}/version/${versionUuid}/lock-status`);
  }

  public startImportDataLockStatusCheck(workflowUuid: string, versionUuid: string): Observable<RuntimeLockStatus> {
    this.stopImportDataLockStatusCheck();
    this.lockStatus$ = new Subject();
    return interval(this.pollInterval).pipe(
      startWith(0),
      switchMap(() => this.getImportDataLockStatus(workflowUuid, versionUuid)),
      takeUntil(this.lockStatus$)
    );
  }

  public stopImportDataLockStatusCheck() {
    if (this.lockStatus$) {
      this.lockStatus$.next(null);
      this.lockStatus$.complete();
    }
  }

  public startDownloadStatusCheck(rootUuid: string, versionUuid: string): Observable<any> {
    this.stopDownloadStatusCheck();
    this.downloadStatus$ = new Subject();
    return interval(this.pollInterval).pipe(
      startWith(0),
      switchMap(() => this.getPackage(rootUuid, versionUuid)),
      takeUntil(this.downloadStatus$)
    );
  }

  public stopDownloadStatusCheck() {
    if (this.downloadStatus$) {
      this.downloadStatus$.next(null);
      this.downloadStatus$.complete();
    }
  }

  public startPublishingStatusCheck(rootUuid: string, versionUuid: string): Observable<any> {
    this.stopPublishingStatusCheck();
    this.publishingStatus$ = new Subject();
    return interval(this.pollInterval).pipe(
      startWith(0),
      switchMap(() => this.getPackage(rootUuid, versionUuid)),
      takeUntil(this.publishingStatus$)
    );
  }

  public stopPublishingStatusCheck() {
    if (this.publishingStatus$) {
      this.publishingStatus$.next(null);
      this.publishingStatus$.complete();
    }
  }

  public getPackageTemplate(packageRootUuid: string, packageVersionUuid: string, workflowVersionUuid: string, isLocal: boolean) {
    const apiPath = isLocal ? this.marketplaceLocalPath : `${this.marketplaceGlobalPath}/package`;
    return this.apiService.get(`${apiPath}/${packageRootUuid}/version/${packageVersionUuid}/canvas/${PLACEHOLDER_ROOT_ID}/version/${workflowVersionUuid}`);
  }

  public getPackageWorkFlow(packageRootUuid: string, packageVersionUuid: string, workflowVersionUuid: string, isLocal: boolean) {
    const apiPath = isLocal ? this.marketplaceLocalPath : `${this.marketplaceGlobalPath}/package`;
    return this.apiService.get(`${apiPath}/${packageRootUuid}/version/${packageVersionUuid}/runtime/${PLACEHOLDER_ROOT_ID}/version/${workflowVersionUuid}`)
      .pipe(map(res => this.generalService.setDefaultGeneralImages(res.data)));
  }

  public getRuntimeIAWithProgressForPackage(
    packageRootUuid: string,
    packageVersionUuid: string,
    workflowVersionUuid: string,
    stepUuid: string,
    sessionUuid: string,
    fe: CanvasFunctionalElementType,
    isLocalPackage: boolean
  ): Observable<IImpactAssessmentFullWithProgress> {
    const apiPath = isLocalPackage ? this.marketplaceLocalPath : `${this.marketplaceGlobalPath}/package`;
    return this.apiService
      .get(`${apiPath}/${packageRootUuid}/version/${packageVersionUuid}/runtime/${PLACEHOLDER_ROOT_ID}/version/${workflowVersionUuid}/${fe}/${stepUuid}/session/${sessionUuid}/play`);
  }

  public getMarketplaceFile(workflowUuid: string): Observable<any> {
    return this.apiService.getBuffer(`${this.marketplaceGlobalPath}/file/${workflowUuid}`);
  }

  public getExternalFilePublicPackage(globalPackageRoot: string, globalPackageVersion: string, globalWorkflowVersion: string, workflowStepUuid: string, fileUuid: string, withParams = true): Observable<any> {
    if (withParams) {
      return this.apiService.getBuffer(`${this.marketplaceGlobalPath}/package/${globalPackageRoot}/version/${globalPackageVersion}/runtime/${PLACEHOLDER_ROOT_ID}/version/${globalWorkflowVersion}/External/${workflowStepUuid}/external-upload/${fileUuid}`, null, 'response');
    } else {
      return this.apiService.getBuffer(`${this.marketplaceGlobalPath}/package/${globalPackageRoot}/version/${globalPackageVersion}/runtime/${PLACEHOLDER_ROOT_ID}/version/${globalWorkflowVersion}/External/${workflowStepUuid}/external-upload/${fileUuid}`);
    }
  }

  public getExternalFileLocalPackage(packageRoot: string, packageVersion: string, workflowVersion: string, workflowStepUuid: string, fileUuid: string, withParams = true): Observable<any> {
    if (withParams) {
      return this.apiService.getBuffer(`${this.marketplaceLocalPath}/${packageRoot}/version/${packageVersion}/runtime/${PLACEHOLDER_ROOT_ID}/version/${workflowVersion}/External/${workflowStepUuid}/external-upload/${fileUuid}`, null, 'response');
    } else {
      return this.apiService.getBuffer(`${this.marketplaceLocalPath}/${packageRoot}/version/${packageVersion}/runtime/${PLACEHOLDER_ROOT_ID}/version/${workflowVersion}/External/${workflowStepUuid}/external-upload/${fileUuid}`);
    }
  }
}
