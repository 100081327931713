import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
  selector: 'app-styleguide-simple-button',
  templateUrl: './styleguide-simple-button.component.html',
  styleUrls: ['./styleguide-simple-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StyleguideSimpleButtonComponent {
  @Input() label = '';
  @Input() icon: string;
  @Input() iconPosition: 'left' | 'right' = 'left';
  @Input() height = 'auto';
  @Input() disabled = false;
  @Input() type:
    'dark-link'
    | 'dark-icon'
    | 'green-link'
    | 'green-filled'
    | 'green-outline'
    | 'red-link'
    | 'red-filled'
    | 'blue-link'
    | 'red-outline'
    | 'white-filled';

  @Input() labelInfo;
  @Input() fluid = false;
  @Input() underline = false;
  @Input() tooltip: string;
  @Input() iconSize: string;
  @Input() customLabelClass = '';
  @Input() spinner = false;

  @Output() onClick = new EventEmitter<boolean>();
}
