import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component
} from '@angular/core';
import { Router } from '@angular/router';
import { AccessLevel } from '@core/constants';
import { FILTERS_LOCAL_STORAGE_KEY } from '@core/constants/app-constants';
import { PermissionList } from '@core/constants/permissions.enum';
import {
  ICompanySystem,
  IRuntimeLite,
  ITaskFilter,
  MyTaskInterface,
  TaskType
} from '@core/interfaces';
import { Company, IUser } from '@core/models';
import {
  AuthenticationService,
  HelperService, HomepageService, RoutingService, SystemService, TasksService
} from '@core/services';
import { AutoUnsubscribe } from '@core/utilities/auto-unsub';
import { Store } from '@ngxs/store';
import moment from 'moment';
import { StateReset } from 'ngxs-reset-plugin';
import { Subscription, forkJoin } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AddActiveFilterWorkspace } from 'src/app/documents/workspace/store/workspace-filters.actions';
import { WorkspaceFiltersState } from 'src/app/documents/workspace/store/workspace-filters.state';
import { CompanySelectors } from 'src/app/store/company/company.selectors';
import { UserSelectors } from 'src/app/store/user/user.selectors';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class HomeComponent {
  currentUser: IUser;
  company: Company;
  readonly AccessLevel = AccessLevel;
  workflowsLoading = true;
  systemsLoading = false;
  permissions: PermissionList;
  today = moment(new Date()).tz('Etc/GMT+0').valueOf();
  workflows: IRuntimeLite[];
  systems: ICompanySystem[];
  taskTypes: ITaskFilter[] = [
    {
      label: 'Overdue',
      value: 'overdue',
      items: []
    },
    {
      label: 'Upcoming',
      value: 'upcoming',
      items: []
    }
  ];
  tasks: MyTaskInterface[] = [];

  private currentUser$: Subscription;

  constructor(
    private cdr: ChangeDetectorRef,
    private homepageService: HomepageService,
    private systemService: SystemService,
    private helperService: HelperService,
    private router: Router,
    private routingService: RoutingService,
    private store: Store,
    private tasksService: TasksService,
    private auth: AuthenticationService,
  ) {
    this.currentUser$ = this.store.select(UserSelectors.getCurrentUser).pipe(
      filter(user => !!user),
      tap(user => this.currentUser = user),
      switchMap(res => this.store.selectOnce(UserSelectors.getUserPermissions)),
      take(1)
    ).subscribe(permissions => {
      this.permissions = permissions;
      const userAccessLevel = this.store.selectSnapshot(UserSelectors.getUserAccessLevel);
      if (userAccessLevel !== AccessLevel.BTRAdmin) {
        this.company = this.store.selectSnapshot(CompanySelectors.getCurrentCompany);
        this.getTasks();
        this.getSystems();
        this.getWorkflows();
      }
      this.setBlacklistedFileTypeExtensions();
      this.cdr.markForCheck();
    });
  }

  private getTasks() {
    forkJoin([
      this.tasksService.getTasks(0, 3, {sortBy: 'workflow_title', sortDirection: 'desc', filters: { target_date_status: 'overdue' }}),
      this.tasksService.getTasks(0, 3, {sortBy: 'workflow_title', sortDirection: 'desc', filters: { target_date_status: 'upcoming' }})
    ]).pipe(take(1)).subscribe(([overdue, upcoming]) => {
      this.taskTypes = this.taskTypes.map(taskType => {
        let items = [];
        switch (taskType.value as TaskType) {
          case 'overdue':
            items = overdue.data;
            break;
          case 'upcoming':
            items = upcoming.data;
            break;
        }
        return {
          ...taskType,
          items
        }
      });



      this.setWorkflowsOverdue();
      this.cdr.markForCheck();
    });
  }

  private getWorkflows() {
    if (!this.permissions.viewWorkspaceRuntimeListing) {
      return;
    }
    this.workflowsLoading = true;
    this.homepageService.getHomepageWorkflows()
      .pipe(take(1))
      .subscribe(workflows => {
        this.workflows = workflows.data;
        this.workflowsLoading = false;
        this.setWorkflowsOverdue();
        this.cdr.markForCheck();
      },
        (err) => {
          this.workflowsLoading = false;
          this.helperService.showErrorMessage(err);
          this.cdr.markForCheck();
        }
      );
  }

  private getSystems() {
    this.systemsLoading = true;
    this.systemService.getSystems(0, Number.MAX_SAFE_INTEGER)
      .pipe(take(1))
      .subscribe(res => {
        this.systems = res.data;
        this.systemsLoading = false;
        this.cdr.markForCheck();
      },
        (err) => {
          this.systemsLoading = false;
          this.helperService.showErrorMessage(err);
          this.cdr.markForCheck();
        });
  }

  goToWorkflows() {
    this.store.dispatch(
      new StateReset(WorkspaceFiltersState)
    ).pipe(
      take(1),
      map(() => {
        const key = "filters.owner_user_uuid";
        const value = [this.currentUser.uuid];
        localStorage.setItem(FILTERS_LOCAL_STORAGE_KEY.RUNTIME, JSON.stringify([{ key, value }]));
        return this.store.dispatch(new AddActiveFilterWorkspace(key, value))
      }),
      take(1)
    ).subscribe(() => this.router.navigate([this.routingService.DOCUMENTS.url()]));
  }

  private setBlacklistedFileTypeExtensions() {
    this.auth.getBlacklistedFileTypeExtensions().pipe(
      filter(res => !!res),
      take(1)
    ).subscribe(res => {
      localStorage.setItem('blacklistedFileTypeExtensions', JSON.stringify(res));
    });
  }

  private setWorkflowsOverdue() {
    const overdueTasks = this.taskTypes.find(task => task.value === 'overdue');
    if (!this.workflows || !overdueTasks?.items) {
      return;
    }
    this.workflows = this.workflows.map(workflow => {
      return {
        ...workflow,
        is_overdue: overdueTasks.items.findIndex(task => task.workflow_uuid === workflow.uuid) > -1
      };
    });
  }
}