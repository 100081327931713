

export interface ICompanyLite {
  name: string;
  domain: string;
  image_url: string;
  uuid?: string;
}

export interface ICompanyRelationshipRequest {
  target_company_uuid: string;
  type: 'parent' | 'child';
}

export enum UserPreferredMFA {
  TOTP = 'otp',
  SMS = 'sms'
}
export enum UserActionType {
  CONVERT_USERS_TO_NON_SSO = 'CONVERT_USERS_TO_NON_SSO',
  CONVERT_USERS_TO_SSO = 'CONVERT_USERS_TO_SSO',
  PRINT = 'PRINT'
}

export enum NewUserActionType {
  NON_SSO_USER = 'NON_SSO_USER',
  SSO_USER = 'SSO_USER',
}

export enum MfaTemplateView {
  CONFIGURE_MFA = 'configure-mfa',
  CONFIGURE_OTP = 'configure-otp',
  EDIT_QR_CODE = 'edit-qr',
  VIEW_QR = 'view-qr',
  CONFIGURE_SMS = 'configure-sms',
}
