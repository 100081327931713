import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DebounceClickDirective } from '@core/directives/debounce-click.directive';
import { DebounceKeyupDirective } from '@core/directives/debounce-keyup.directive';
import { DragAndDropDirective } from '@core/directives/drag-and-drop.directive';
import { TooltipSizeDirective } from '@core/directives/tooltip-size.directive';
import { LogicalOrConditionsGuard } from '@core/guards/logical-or-conditions.guard';
import { ButtonsModule } from '@shared/components/buttons/buttons.module';
import { TypeToConfirmModule } from '@shared/components/type-to-confirm/type-to-confirm.module';
import { BelowInMarginTooltipDirective } from './directives/below-left-tooltip.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { IntegersOnlyDirective } from './directives/integers-only.directive';
import { TooltipClampDirective } from './directives/tooltip-clamp.directive';
import { TooltipWhenEllipsisDirective } from './directives/tooltip-when-ellipsis.directive';
import { UnderscoreLineBreakDirective } from './directives/underscore-line-break.directive';
import { UserAccessLevelDirective } from './directives/user-access-level.directive';
import { UserDirective } from './directives/user.directive';
import {
  AccessLevelGuardService,
  AccessMarketplaceGuardService,
  AuthorizationGuardService, RequestAccessGuardService, ReviewAccessGuardService, SectionsVisibilityGuardService
} from './guards';
import { AuthenticationGuardService } from './guards/authentication.guard.service';
import { NotAuthenticatedGuardService } from './guards/not-authenticated.guard.service';
import {
  ConfirmationModalComponent,
} from './modals';
import { InformationModalComponent } from './modals/information-modal/information-modal.component';
import { MaterialModule } from './modules/material.module';
import { PipesModule } from './pipes/pipes.module';
import { SanitizeHtmlPipe } from './pipes/sanitize.pipe';
import {
  AdminService, AsyncPdfService, CompaniesService,
  DepartmentService,
  FilterTagService,
  LicensesService,
  SiteService,
  SystemService
} from './services';
import { ApiService } from './services/api.service';
import { AuthenticationService } from './services/authentication.service';
import { UserService } from './services/user.service';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    ButtonsModule,
    TypeToConfirmModule,
  ],
  exports: [
    ConfirmationModalComponent,
    MaterialModule,
    UserDirective,
    IntegersOnlyDirective,
    UserAccessLevelDirective,
    PipesModule,
    SanitizeHtmlPipe,
    DebounceClickDirective,
    DebounceKeyupDirective,
    DragAndDropDirective,
    TooltipClampDirective,
    TooltipWhenEllipsisDirective,
    UnderscoreLineBreakDirective,
    TooltipSizeDirective,
    BelowInMarginTooltipDirective,
    ClickOutsideDirective
  ],
  declarations: [
    ConfirmationModalComponent,
    UserDirective,
    IntegersOnlyDirective,
    UserAccessLevelDirective,
    SanitizeHtmlPipe,
    InformationModalComponent,
    DebounceClickDirective,
    DebounceKeyupDirective,
    DragAndDropDirective,
    TooltipClampDirective,
    TooltipWhenEllipsisDirective,
    UnderscoreLineBreakDirective,
    TooltipSizeDirective,
    BelowInMarginTooltipDirective,
    ClickOutsideDirective
  ],
  providers: [
    ApiService,
    UserService,
    AuthenticationGuardService,
    AccessLevelGuardService,
    AccessMarketplaceGuardService,
    AuthenticationService,
    AuthorizationGuardService,
    AdminService,
    CompaniesService,
    SystemService,
    SiteService,
    DepartmentService,
    FilterTagService,
    LicensesService,
    AsyncPdfService,
    NotAuthenticatedGuardService,
    LogicalOrConditionsGuard,
    SectionsVisibilityGuardService,
    RequestAccessGuardService,
    ReviewAccessGuardService,
  ]
})
export class CoreModule { }
