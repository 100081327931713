import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NotificationResponse } from '@core/interfaces';
import { NotificationMenuItemComponent } from '../../notification-menu-item/notification-menu-item.component';

@Component({
  selector: 'app-market-data-imported',
  templateUrl: './market-data-imported.component.html',
  styleUrls: ['./market-data-imported.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketDataImportedComponent extends NotificationMenuItemComponent {
  @Input() notification: NotificationResponse;
}
