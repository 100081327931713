<div class="item-data">
  <app-icon-two-images bigImg="unlocked" smallImg="close2" [isSuccess]="false">
  </app-icon-two-images>
  <div class="item-details" (click)="goToAction()">
      <div class="item-details-title">
        Your access request {{notification.data?.access_level === TemplateAccessRequestType.WORKSPACE_ACCESS ? 'to use ' : 'for '}} <strong>{{notification.data?.link.title}}</strong> has been rejected.
      </div>
      <div class="item-details-time" *ngIf="company$ | async as company">
          {{notification.created_at | dateFormat : null : company.config.timezone : company.config.timeformat}}
      </div>
  </div>
</div>
<app-notification-bullet [notification]="notification"></app-notification-bullet>
