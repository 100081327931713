import { Pipe, PipeTransform } from '@angular/core';
import { IApprovalOrReviewRequestSeriesUser } from '@core/interfaces';
import { IUser, IUserLite } from '../models';

@Pipe({
  name: 'displayName'
})
export class DisplayNamePipe implements PipeTransform {

  transform(user: IUser | IUserLite | IApprovalOrReviewRequestSeriesUser): string {
    return user ? (user.first_name + ' ' + user.last_name) : '';
  }

}
