import { Injectable } from '@angular/core';
import { IDepartment } from '@core/models';
import { Observable } from 'rxjs';
import { IApiPaginatedDataBase, ISortQueryParameters } from '../interfaces';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class DepartmentService {
  private path = '/api/department';

  constructor(private apiService: ApiService) {}

  public getDepartments(offset: number, limit: number, sortQueryParams?: ISortQueryParameters): Observable<IApiPaginatedDataBase<IDepartment>> {
      return this.apiService.get<IDepartment[]>(`${this.path}/${offset}/${limit}`, this.apiService.addSortQueryParams(sortQueryParams));
  }

  public getDepartmentsByCompany(companyId): Observable<IApiPaginatedDataBase<IDepartment>> {
    return this.apiService.get(`/api/company/${companyId}/departments`);
  }

  public addDepartment(department: IDepartment) {
    return this.apiService.post(this.path, department);
  }

  public updateDepartment(department: IDepartment) {
    return this.apiService.put(`${this.path}/${department.uuid}`, department);
  }

  public getDepartment(department: IDepartment, permissions = []): Observable<IDepartment> {
    return this.apiService.get(`${this.path}/${department.uuid}`,
      this.apiService.addExtraQueryParams(undefined, { searched_permissions: permissions }));
  }

  public deleteDepartment(department: IDepartment) {
    return this.apiService.delete(`${this.path}/${department.uuid}`);
  }
}
