import { IUserLite, RoleType } from '@core/models';

export interface RuntimeAccessListRequest {
  root_workflow_uuid: string;
  comment: string;
}

export interface TemplateAccessListRequest extends RuntimeAccessListRequest {
  access_level: string;
}

export interface TransferOwnershipRequest {
  root_workflow_uuid: string;
  new_owner_uuid: string;
}

export interface AttachUserToAccessListRequest {
  user_uuid: string;
  type?: TemplateAccessRequestType;
}

export interface AttachGroupToAccessListRequest {
  group_uuid: string;
  type?: TemplateAccessRequestType;
}

export interface DetachUserFromAccessListRequest {
  user_uuid: string;
  access_level?: TemplateAccessRequestType;
}

export interface DetachGroupFromAccessListRequest {
  group_uuid: string;
  access_level?: TemplateAccessRequestType;
}

export interface GroupAccessListResponse {
  workflow_access_list_uuid?: string;
  group_uuid: string;
  name: string;
  image_url?: string;
  uuid?: string;
}

export interface GroupMembersResponse extends IUserLite {
  has_access: boolean;
}

export interface BulkShareRequest {
  root_workflow_uuids: string[];
  user_uuids: string[];
  group_uuids: string[];
}

export interface BulkTransferOwnershipRequest {
  root_workflow_uuids: string[];
  new_owner_uuid: string[];
}

export interface BulkAssignRequest {
  approvers_series?: string[];
  reviewers_series?: string[];
  testers_series?: string[];
  documents: string[];
}

export interface BulkShareTransferStepperNavigationStep {
  isCompleted: boolean;
  label: string;
  index: number;
  selected: boolean;
  error: string;
  key: string;
}

export enum TemplateAccessRequestType {
  ACCESS = 'access',
  WORKSPACE_ACCESS = 'workspace_access',
  ALL = 'all',
  TEMPORARY = 'temporary',
}

export enum SelectedAccessType {
  ACCESS = 'ACCESS',
  NO_ACCESS = 'NO_ACCESS'
}

export enum SelectedAccessTypeLabel {
  ACCESS = 'Access',
  NO_ACCESS = 'No access'
}

export interface IWorkflowAccessUser extends IUserLite {
  type: TemplateAccessRequestType
}

export interface WorkflowAccessListResponse {
  owner: IWorkflowAccessUser;
  users: IWorkflowAccessUser[];
  groups: GroupAccessListResponse[];
}

export interface UserGroupsResource {
  uuid: string;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface WorkflowAccessListForUserResponse {
  uuid: string;
  version_uuid: string;
  display_label: string;
  document_title: string;
  type: string;
  owner: IUserLite;
  access_types: AccesGroup[];
}

interface AccesGroup {
  type: 'individual' | 'group';
  group: Group;
}

interface Group {
  uuid: string;
  name: string;
}

export interface IAccessListRequests {
  uuid: string;
  requested_at: string;
  access_level: TemplateAccessRequestType;
  user_uuid: string;
  first_name: string;
  last_name: string;
  image_url: string;
  role_name: RoleType;
  department_name: string;
  email?: string;
  is_active?: boolean;
}

export interface WorkflowRequestAccessDetailsResponse {
  uuid: string;
  current_version_uuid: string;
  document_title: string;
  display_label: string;
  version: number;
  status: string;
}

export enum WorkflowsAccessRequestSeries {
  APPROVERS_SERIES = 'approvers_series',
  REVIEWERS_SERIES = 'reviewers_series',
  TESTERS_SERIES = 'testers_series',
}