import { nanoid } from 'nanoid';

export class Util {

  /*
  * Generate a 10 char nanoid with a prefix. Ensures use as an HTML id
  * */
  static prefixedNanoid(): string {
    return 'n' + nanoid(9);
  }

  /*
  * Generate a random name
  * */
  static randomName(): string {
    return Math.random().toString(36).substr(2, 5);
  }

  static printPdf = (pdf) => {
    const blob = new Blob([pdf], {type: 'application/pdf'});
    const blobUrl = URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = blobUrl;
    document.body.appendChild(iframe);
    iframe.contentWindow.print();
  }

  static capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  static reduceSpacesToOnlyOne(str: string) {
    return str.replace(/\s+/g, ' ');
  }
}
