import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { CanvasFunctionalElementType } from '../../canvas/canvas.enum';
import { ICanvasStep, ICanvasWorkflowError, IWorkflowTemplate, TemplateType } from '../interfaces';

@Injectable({ providedIn: 'root' })
export class FlowValidationService {
  workflow: IWorkflowTemplate;
  workflowErrors: ICanvasWorkflowError[] = [];
  validationFunctions: string[];
  private isFlowValid: BehaviorSubject<boolean> = new BehaviorSubject(true);
  branchNumber = 0;
  branches: ICanvasStep[][] = [[]];
  stepsByGid: any;

  constructor() {
    const thisClass = FlowValidationService.prototype;
    this.validationFunctions = Object.getOwnPropertyNames(thisClass).filter(item => {
      return (typeof thisClass[item] === 'function') && item.startsWith('doValidate');
    });
  }

  init() {
    this.workflow = null;
    this.workflowErrors = [];
    this.isFlowValid.next(true);
    this.branchNumber = 0;
    this.branches = [[]];
    this.stepsByGid = null;
  }

  validate(workflow: IWorkflowTemplate) {
    this.workflowErrors = [];
    this.workflow = workflow;
    this.stepsByGid = this.workflow.steps.reduce((map: any, _step: ICanvasStep) => {
      map[_step.gid] = _step;
      return map;
    }, {});
    if (this.workflow.type !== TemplateType.SDOX) {
      this.extractBranches();
    }
    this.checkValidity();
  }

  private checkValidity() {
    let isValid = true;
    if (this.workflow && this.workflow.steps.length > 1) {
      let theFunc;
      let funcRes;
      this.validationFunctions.forEach(validationFunction => {
        if (validationFunction.includes(this.workflow.type) || validationFunction.includes('Generic')) {
          theFunc = this[validationFunction].bind(this);
          funcRes = theFunc();
          isValid = isValid && funcRes;
        }
      });
      this.isFlowValid.next(isValid);
    }
  }

  flowValidity() {
    return this.isFlowValid.asObservable();
  }

  getErrorMessage(): ICanvasWorkflowError[] {
    return this.workflowErrors;
  }

  /*
   * Generates all branches from the loaded workflow inside this.branches variable,
   * and the total number of branches in this.branchNumber
   */
  private extractBranches() {
    if (this.workflow.steps && this.workflow.transitions) {
      const currentNode = this.workflow.steps.find(step => step.fe_type === CanvasFunctionalElementType.Start);
      const visited = [];
      this.branches = [[]];
      this.branchNumber = 0;
      this.getBranchesFromNode(currentNode, visited);
    }
  }

  private getBranchesFromNode(currentNode: ICanvasStep, visited: ICanvasStep[]) {
    if (!visited.find(stp => stp.gid === currentNode.gid)) {
      visited.push(currentNode);
      this.branches[this.branchNumber].push(currentNode);
      const transitions = this.workflow.transitions.filter(tr => tr.from_gid === currentNode.gid);
      if (transitions.length) {
        const currentBranchSteps = _.cloneDeep(this.branches[this.branchNumber]);
        transitions.forEach((trans, index) => {
          const nextNode = this.stepsByGid[trans.to_gid];
          if (nextNode) {
            if (index > 0) {
              this.branches.push([...currentBranchSteps]);
              this.branchNumber++;
            }
            this.getBranchesFromNode(nextNode, visited);
          }
        });
      }
    }
  }

  /*
   *  All validation function names must start with doValidate followed by Generic or Workflow
   *  type (SDOX, ECM, etc)
   */


  /*
  * Generic Validations
  * */

  doValidateGenericMultipleConnections(): boolean {
    if (this.workflow.transitions.length < 2) {
      return;
    }
    let res = true;
    this.workflow.steps.forEach(step => {
      res = res && (this.workflow.transitions.filter(trans => {
        return trans.to_gid === step.gid;
      }).length < 2);
    });
    const errorIndex = this.workflowErrors.findIndex(err => err.errorType === 'MultipleConnections');
    if (res) {
      if (errorIndex > -1) {
        this.workflowErrors.splice(errorIndex, 1);
      }
    } else {
      if (errorIndex === -1) {
        const error: ICanvasWorkflowError = {
          errorType: 'MultipleConnections',
          message: 'Only one connection can enter each step'
        };
        this.workflowErrors.push(error);
      }
    }
    return res;
  }

  doValidateGenericUniqueTablePrefix(): boolean {
    const tables = this.workflow.steps.filter(step => step.fe_type === CanvasFunctionalElementType.Table);
    let prefixes = {};
    tables.forEach(table => {
      if (prefixes[table.config_data.sequence_prefix]) {
        prefixes[table.config_data.sequence_prefix]++;
      } else {
        prefixes = {
          ...prefixes,
          [table.config_data.sequence_prefix]: 1
        };
      }
    });
    const duplicatePrefixes = [];
    for (const [key, value] of Object.entries(prefixes)) {
      if (value > 1) {
        duplicatePrefixes.push(key);
      }
    }
    const res = !duplicatePrefixes.length;
    const errorIndex = this.workflowErrors.findIndex(err => err.errorType === 'UniqueTablePrefix');
    if (res) {
      if (errorIndex > -1) {
        this.workflowErrors.splice(errorIndex, 1);
      }
    } else {
      if (errorIndex === -1) {
        const error: ICanvasWorkflowError = {
          errorType: 'UniqueTablePrefix',
          message: 'Some tables have the same prefix.' +
            'Table prefixes should be unique throughout the document. Duplicate Prefixes: ' + duplicatePrefixes.join(', ')
        };
        this.workflowErrors.push(error);
      }
    }
    return res;
  }

  /*
  * SDOX Validations
  * */

  doValidateSDOXEnding(): boolean {
    if (!this.workflow.transitions.length) {
      return;
    }
    const firstStep = this.workflow.steps.find(step => {
      return step.fe_type === CanvasFunctionalElementType.Start;
    });
    let transition = this.workflow.transitions.find(trans => {
      return trans.from_gid === firstStep.gid;
    });
    let hasNext = !!transition;
    const visited = [];
    while (hasNext && !(visited.includes(transition.from_gid))) {
      visited.push(transition.from_gid);
      const nextTransition = this.workflow.transitions.find(trans => {
        return transition.to_gid === trans.from_gid;
      });
      if (nextTransition) {
        transition = nextTransition;
      }
      hasNext = !!nextTransition;
    }
    let lastElement;
    if (transition?.to_gid) {
      lastElement = this.stepsByGid[transition.to_gid];
    }

    const res = !lastElement || (lastElement.fe_type === CanvasFunctionalElementType.Completer);
    const errorIndex = this.workflowErrors.findIndex(err => err.errorType === 'Completer');
    if (res) {
      if (errorIndex > -1) {
        this.workflowErrors.splice(errorIndex, 1);
      }
    } else {
      if (errorIndex === -1) {
        const error: ICanvasWorkflowError = {
          errorType: 'Completer',
          message: 'The Approval must be the last step!'
        };
        this.workflowErrors.push(error);
      }
    }
    return res;
  }

  doValidateSDOXMissingElements(): boolean {
    const res = this.workflow.steps
      .findIndex(fe =>
        fe.fe_type === CanvasFunctionalElementType.Table ||
        fe.fe_type === CanvasFunctionalElementType.Test_Script ||
        fe.fe_type === CanvasFunctionalElementType.General_Table ||
        fe.fe_type === CanvasFunctionalElementType.External ||
        fe.fe_type === CanvasFunctionalElementType.SectionTitle
      ) > -1;
    const errorIndex = this.workflowErrors.findIndex(err => err.errorType === 'MissingElements');
    const missingComponentsByType = {
      general_document: 'Section or External',
      test_script: ', TestScript, Section or External',
      requirement_document: ', Table, Section or External',
    };
    if (res) {
      if (errorIndex > -1) {
        this.workflowErrors.splice(errorIndex, 1);
      }
    } else {
      if (errorIndex === -1) {
        const error: ICanvasWorkflowError = {
          errorType: 'MissingElements',
          message: `At least one General ${missingComponentsByType[this.workflow.sdox_document_category]} section required`
        };
        this.workflowErrors.push(error);
      }
    }
    return res;
  }

  doValidateSDOXMultipleCompleters(): boolean {
    if (this.workflow.steps.length < 3) {
      return;
    }
    const completerCount = this.workflow.steps.filter(fe => fe.fe_type === CanvasFunctionalElementType.Completer).length;
    const res = !(completerCount > 1);
    const errorIndex = this.workflowErrors.findIndex(err => err.errorType === 'MultipleCompleters');
    if (res) {
      if (errorIndex > -1) {
        this.workflowErrors.splice(errorIndex, 1);
      }
    } else {
      if (errorIndex === -1) {
        const error: ICanvasWorkflowError = {
          errorType: 'MultipleCompleters',
          message: 'Only one Approval is allowed'
        };
        this.workflowErrors.push(error);
      }
    }
    return res;
  }

  doValidateSDOXCompletePath(): boolean {
    if (this.workflow.steps.length < 3) {
      return;
    }
    const allStepsAreConnected = this.workflow.steps.reduce((acc, step) => {
      const fromTransition = this.workflow.transitions.find(tr => tr.from_gid === step.gid);
      const toTransition = this.workflow.transitions.find(tr => tr.to_gid === step.gid);
      // NRESQ-13048: All steps are connected IF:
      return acc &&
        !!(fromTransition && step.fe_type === CanvasFunctionalElementType.Start || // Start has a transition to the next step (fromTransition)
          toTransition && step.fe_type === CanvasFunctionalElementType.Completer || // Completed has a transition from the previous step (toTransition)
          fromTransition && toTransition); // For all other steps, both fromTransition and toTransition exist
    }, true);

    const errorIndex = this.workflowErrors.findIndex(err => err.errorType === 'CompletePath');
    if (allStepsAreConnected) {
      if (errorIndex > -1) {
        this.workflowErrors.splice(errorIndex, 1);
      }
    } else {
      if (errorIndex === -1) {
        const error: ICanvasWorkflowError = {
          errorType: 'CompletePath',
          message: 'Missing connection between sections'
        };
        this.workflowErrors.push(error);
      }
    }
    return allStepsAreConnected;
  }

  doValidateSDOXMultipleBranches(): boolean {
    if (this.workflow.transitions.length < 2) {
      return;
    }
    let res = true;
    this.workflow.steps.forEach(step => {
      res = res && (this.workflow.transitions.filter(trans => {
        return trans.from_gid === step.gid;
      }).length < 2);
    });
    const errorIndex = this.workflowErrors.findIndex(err => err.errorType === 'MultipleBranches');
    if (res) {
      if (errorIndex > -1) {
        this.workflowErrors.splice(errorIndex, 1);
      }
    } else {
      if (errorIndex === -1) {
        const error: ICanvasWorkflowError = {
          errorType: 'MultipleBranches',
          message: 'Multiple branches are not allowed!'
        };
        this.workflowErrors.push(error);
      }
    }
    return res;
  }

  /*
  * ECM / ILM Validations
  * */

  // VAL-WF-10002
  doValidateECMILMInitiation(): boolean {
    if (this.workflow.steps.length < 2) {
      return;
    }
    const res = this.workflow.steps.findIndex(fe => fe.fe_type === CanvasFunctionalElementType.Initiation) > -1;
    const errorIndex = this.workflowErrors.findIndex(err => err.errorType === 'MissingInitiation');
    if (res) {
      if (errorIndex > -1) {
        this.workflowErrors.splice(errorIndex, 1);
      }
    } else {
      if (errorIndex === -1) {
        const error: ICanvasWorkflowError = {
          errorType: 'MissingInitiation',
          message: `Initiation must be present`
        };
        this.workflowErrors.push(error);
      }
    }
    return res;
  }

  // VAL-WF-10003
  doValidateECMILMPosition(): boolean {
    const initiationPos = this.workflow.steps.findIndex(fe => fe.fe_type === CanvasFunctionalElementType.Initiation);
    if (initiationPos === -1) {
      return;
    }
    // check if initiation is the first FE after Start
    const startStep = this.workflow.steps.find(fe => fe.fe_type === CanvasFunctionalElementType.Start);
    const initiationStep = this.workflow.steps.find(fe => fe.fe_type === CanvasFunctionalElementType.Initiation);
    const fromStartTransition = this.workflow.transitions.find(tr => tr.from_gid === startStep.gid);
    const res = fromStartTransition?.to_gid === initiationStep.gid;
    const errorIndex = this.workflowErrors.findIndex(err => err.errorType === 'WrongInitiationPosition');
    if (res) {
      if (errorIndex > -1) {
        this.workflowErrors.splice(errorIndex, 1);
      }
    } else {
      if (errorIndex === -1) {
        const error: ICanvasWorkflowError = {
          errorType: 'WrongInitiationPosition',
          message: `Initiation must be after start`
        };
        this.workflowErrors.push(error);
      }
    }
    return res;
  }

  // VAL-WF-10004
  doValidateECMILMMultipleInitiations(): boolean {
    if (this.workflow.steps.length < 3) {
      return;
    }
    const initiationCount = this.workflow.steps.filter(fe => fe.fe_type === 'Initiation').length;
    const res = !(initiationCount > 1);
    const errorIndex = this.workflowErrors.findIndex(err => err.errorType === 'MultipleInitiations');
    if (res) {
      if (errorIndex > -1) {
        this.workflowErrors.splice(errorIndex, 1);
      }
    } else {
      if (errorIndex === -1) {
        const error: ICanvasWorkflowError = {
          errorType: 'MultipleInitiations',
          message: 'A workflow may only have one initiation'
        };
        this.workflowErrors.push(error);
      }
    }
    return res;
  }

  // VAL-WF-10101
  doValidateECMILMSelectedImpact(): boolean {
    let res = true;
    const stepsLength = this.workflow.steps.length;

    for (let i = 0; i < stepsLength; i++) {
      if (this.workflow.steps[i].fe_type === CanvasFunctionalElementType.Impact &&
        !this.workflow.steps[i].config_data.impact_assessment_uuid) {
        res = false;
        break;
      }
    }

    const errorIndex = this.workflowErrors.findIndex(err => err.errorType === 'MissingImpactConfig');
    if (res) {
      if (errorIndex > -1) {
        this.workflowErrors.splice(errorIndex, 1);
      }
    } else {
      if (errorIndex === -1) {
        const error: ICanvasWorkflowError = {
          errorType: 'MissingImpactConfig',
          message: `Impact Assessments must have a configuration selected`
        };
        this.workflowErrors.push(error);
      }
    }
    return res;
  }

  // VAL-WF-10001
  doValidateECMILMSFETypes(): boolean {
    let res = true;
    const validStepTypes = [
      CanvasFunctionalElementType.Start,
      CanvasFunctionalElementType.Completer,
      CanvasFunctionalElementType.Initiation,
      CanvasFunctionalElementType.Impact,
      CanvasFunctionalElementType.Switch,
      CanvasFunctionalElementType.Summary,
      CanvasFunctionalElementType.Implementation
    ];
    for (const step of this.workflow.steps) {
      if (!validStepTypes.includes(step.fe_type)) {
        res = false;
        break;
      }
    }
    const errorIndex = this.workflowErrors.findIndex(err => err.errorType === 'InvalidFE');
    if (res) {
      if (errorIndex > -1) {
        this.workflowErrors.splice(errorIndex, 1);
      }
    } else {
      if (errorIndex === -1) {
        const error: ICanvasWorkflowError = {
          errorType: 'InvalidFE',
          message: `Template contains invalid Steps`
        };
        this.workflowErrors.push(error);
      }
    }
    return res;
  }

  // VAL-WF-10404
  doValidateECMILMDuplicateCompleter(): boolean {
    if (this.workflow.steps.length < 3) {
      return;
    }
    let res = true;
    if (this.workflow.steps && this.workflow.transitions) {
      this.branches.forEach(branch => {
        let prevNodeType = '';
        branch.forEach((currentStep, index) => {
          if (prevNodeType === CanvasFunctionalElementType.Completer && currentStep.fe_type === prevNodeType) {
            res = false;
          }
          prevNodeType = currentStep.fe_type;
        });
      });
    }
    const errorIndex = this.workflowErrors.findIndex(err => err.errorType === 'DuplicateCompleter');
    if (res) {
      if (errorIndex > -1) {
        this.workflowErrors.splice(errorIndex, 1);
      }
    } else {
      if (errorIndex === -1) {
        const error: ICanvasWorkflowError = {
          errorType: 'DuplicateCompleter',
          message: 'The Approval should not be preceded by a Approval Step'
        };
        this.workflowErrors.push(error);
      }
    }
    return res;
  }

  // VAL-WF-10201
  doValidateECMILMIABeforeSummary(): boolean {
    if (this.workflow.steps.length < 3) {
      return;
    }
    let res = true;
    // NRESQ-13048: will begin from Start and move through all steps, to check if Impact Assessment is before Summary:
    if (this.workflow.steps && this.workflow.transitions) {
      let nextStep = this.workflow.steps.find(fe => fe.fe_type === CanvasFunctionalElementType.Start);
      let nextTransition;
      while (nextStep) {
        nextTransition = this.workflow.transitions.find(tr => tr.from_gid === nextStep.gid);
        if (!nextTransition) { // Break IF could not find next transition
          break;
        }
        nextStep = this.workflow.steps.find(step => step.gid === nextTransition?.to_gid);
        if (nextStep.fe_type === CanvasFunctionalElementType.Impact) {
          break; // Break IF it finds Impact first
        } else if (nextStep.fe_type === CanvasFunctionalElementType.Summary) {
          res = false; // Change the result to false and break if it finds Summary first
          break;
        }
      }
    }
    const errorIndex = this.workflowErrors.findIndex(err => err.errorType === 'IABeforeSummary');
    if (res) {
      if (errorIndex > -1) {
        this.workflowErrors.splice(errorIndex, 1);
      }
    } else {
      if (errorIndex === -1) {
        const error: ICanvasWorkflowError = {
          errorType: 'IABeforeSummary',
          message: 'Summary must be preceded by an Impact Assessment'
        };
        this.workflowErrors.push(error);
      }
    }
    return res;
  }

  // VAL-WF-00001 - All nodes must be connected
  doValidateECMILMAllNodesConnected(): boolean {
    let res = true;
    if (this.workflow.steps.length < 3) {
      return;
    }
    this.workflow.steps.filter(step => step.fe_type !== CanvasFunctionalElementType.Start).forEach(step => {
      const hasConnection = this.workflow.transitions.find(trans => trans.to_gid === step.gid);
      if (!hasConnection) {
        res = false;
      }
    });

    const errorIndex = this.workflowErrors.findIndex(err => err.errorType === 'AllNodesConnected');
    if (res) {
      if (errorIndex > -1) {
        this.workflowErrors.splice(errorIndex, 1);
      }
    } else {
      if (errorIndex === -1) {
        const error: ICanvasWorkflowError = {
          errorType: 'AllNodesConnected',
          message: 'Missing connection between sections'
        };
        this.workflowErrors.push(error);
      }
    }
    return res;
  }

  // VAL-WF-00002 - The environments declared in Implementation and Summary steps must be unique
  doValidateECMILMUniqueEnvironments(): boolean {
    let res = true;
    if (this.workflow.steps.length < 3) {
      return;
    }

    this.branches.forEach(branch => {
      const implementationEnvironments = [];
      branch
        .filter(step => step.fe_type === CanvasFunctionalElementType.Implementation || step.fe_type === CanvasFunctionalElementType.Summary)
        .forEach(step => {
          if (step.fe_type === CanvasFunctionalElementType.Implementation) {
            if (step.config_data.selected_environment && implementationEnvironments.includes(step.config_data.selected_environment)) {
              res = false;
            }
            implementationEnvironments.push(step.config_data.selected_environment);
          }
          if (step.fe_type === CanvasFunctionalElementType.Summary && step.step_data.environments?.length) {
            if (new Set(step.step_data.environments).size !== step.step_data.environments.length) {
              res = false;
            }
          }
        });
    });
    const errorIndex = this.workflowErrors.findIndex(err => err.errorType === 'UniqueEnvironments');
    if (res) {
      if (errorIndex > -1) {
        this.workflowErrors.splice(errorIndex, 1);
      }
    } else {
      if (errorIndex === -1) {
        const error: ICanvasWorkflowError = {
          errorType: 'UniqueEnvironments',
          message: 'The environments declared in Implementation and Summary steps must be unique'
        };
        this.workflowErrors.push(error);
      }
    }
    return res;
  }

  // Each Summary environment must have a corresponding Implementation FE
  doValidateECMILMCorrespondingEnvironments(): boolean {
    let res = true;
    if (this.workflow.steps.length < 3) {
      return;
    }

    const implementationEnvironments = [];
    const summaryEnvironments = [];

    let implementationIndex = 0;
    let summaryIndex = 0;
    let hasImplementationBeforeSummary = false;
    this.branches.forEach(branch => {
      branch
        .filter(step => step.fe_type === CanvasFunctionalElementType.Implementation || step.fe_type === CanvasFunctionalElementType.Summary)
        .forEach((step, index) => {
          if (step.fe_type === CanvasFunctionalElementType.Implementation) {
            implementationIndex = index;
          }

          if (step.fe_type === CanvasFunctionalElementType.Summary) {
            summaryIndex = index;
          }

          if (implementationIndex < summaryIndex) {
            hasImplementationBeforeSummary = true;
          }
          if (step.fe_type === CanvasFunctionalElementType.Summary && step.step_data.environments?.length) {
            summaryEnvironments.push(...step.step_data.environments.map(env => env.name));
          }

          if (step.fe_type === CanvasFunctionalElementType.Implementation && step.config_data.selected_environment) {
            implementationEnvironments.push(step.config_data.selected_environment);
          }
        });
    });

    summaryEnvironments.forEach(uniqueSummary => {
      if (!implementationEnvironments.includes(uniqueSummary) || hasImplementationBeforeSummary && ((summaryEnvironments.length === implementationEnvironments.length))) {
        res = false;
      }
    });

    const errorIndex = this.workflowErrors.findIndex(err => err.errorType === 'CorrespondingEnvironments');

    if (res) {
      if (errorIndex > -1) {
        this.workflowErrors.splice(errorIndex, 1);
      }
    } else {
      if (errorIndex === -1) {
        const error: ICanvasWorkflowError = {
          errorType: 'CorrespondingEnvironments',
          message: 'Each Summary environment must have a corresponding Implementation FE'
        };
        this.workflowErrors.push(error);
      }
    }
    return res;
  }

  // VAL-WF-10103 - None or only one IA per branch
  doValidateECMILMOneIAPerBranch(): boolean {
    if (this.workflow.steps.length < 3) {
      return;
    }
    let res = true;
    if (this.workflow.steps && this.workflow.transitions) {
      let prevTypes = [];
      this.branches.forEach(branch => {
        prevTypes = [];
        branch.forEach((currentStep, index) => {
          if (currentStep.fe_type === CanvasFunctionalElementType.Impact && prevTypes.includes(currentStep.fe_type)) {
            res = false;
          }
          prevTypes.push(currentStep.fe_type);
        });
      });
    }
    const errorIndex = this.workflowErrors.findIndex(err => err.errorType === 'OneIAPerBranch');
    if (res) {
      if (errorIndex > -1) {
        this.workflowErrors.splice(errorIndex, 1);
      }
    } else {
      if (errorIndex === -1) {
        const error: ICanvasWorkflowError = {
          errorType: 'OneIAPerBranch',
          message: 'None or only one IA per branch'
        };
        this.workflowErrors.push(error);
      }
    }
    return res;
  }

  // VAL-WF-10202 - None or only one Summary per branch
  doValidateECMILMIAOneSummaryPerBranch(): boolean {
    if (this.workflow.steps.length < 3) {
      return;
    }
    let res = true;
    if (this.workflow.steps && this.workflow.transitions) {
      let prevTypes = [];
      this.branches.forEach(branch => {
        prevTypes = [];
        branch.forEach((currentStep, index) => {
          if (currentStep.fe_type === CanvasFunctionalElementType.Summary && prevTypes.includes(currentStep.fe_type)) {
            res = false;
          }
          prevTypes.push(currentStep.fe_type);
        });
      });
    }
    const errorIndex = this.workflowErrors.findIndex(err => err.errorType === 'OneSummaryPerBranch');
    if (res) {
      if (errorIndex > -1) {
        this.workflowErrors.splice(errorIndex, 1);
      }
    } else {
      if (errorIndex === -1) {
        const error: ICanvasWorkflowError = {
          errorType: 'OneSummaryPerBranch',
          message: 'None or only one Summary per branch'
        };
        this.workflowErrors.push(error);
      }
    }
    return res;
  }

  // VAL-WF-10203 - Summary needs at least one environment and one deliverable
  doValidateECMILMSummaryWithEnvironmentAndDeliverable(): boolean {
    if (this.workflow.steps.length < 3) {
      return;
    }
    let res = true;
    if (this.workflow.steps) {
      this.workflow.steps
        .filter(step => step.fe_type === CanvasFunctionalElementType.Summary)
        .forEach(step => {
          if (!step.step_data.environments?.length) {
            res = false;
          } else {
            step.step_data.environments.forEach(env => {
              if (!env.document_classification.length || !env.name) {
                res = false;
              }
            });
          }
        });
    }
    const errorIndex = this.workflowErrors.findIndex(err => err.errorType === 'SummaryWithEnvironmentAndDeliverable');
    if (res) {
      if (errorIndex > -1) {
        this.workflowErrors.splice(errorIndex, 1);
      }
    } else {
      if (errorIndex === -1) {
        const error: ICanvasWorkflowError = {
          errorType: 'SummaryWithEnvironmentAndDeliverable',
          message: 'Summary needs at least one environment and one deliverable'
        };
        this.workflowErrors.push(error);
      }
    }
    return res;
  }

  // VAL-WF-10503 - Each Implementation step should have at least one environment
  doValidateECMILMImplementationWithEnvironment(): boolean {
    if (this.workflow.steps.length < 3) {
      return;
    }
    let res = true;
    if (this.workflow.steps) {
      this.workflow.steps
        .filter(step => step.fe_type === CanvasFunctionalElementType.Implementation)
        .forEach(step => {
          if (!step.config_data.selected_environment) {
            res = false;
          }
        });
    }
    const errorIndex = this.workflowErrors.findIndex(err => err.errorType === 'ImplementationWithEnvironment');
    if (res) {
      if (errorIndex > -1) {
        this.workflowErrors.splice(errorIndex, 1);
      }
    } else {
      if (errorIndex === -1) {
        const error: ICanvasWorkflowError = {
          errorType: 'ImplementationWithEnvironment',
          message: 'Each Implementation step should have at least one environment'
        };
        this.workflowErrors.push(error);
      }
    }
    return res;
  }

  // VAL-WF-10401 - Each final branch must end in a Approval
  doValidateECMILMCompleterOnEachBranch(): boolean {
    if (this.workflow.steps.length < 3) {
      return;
    }
    let res = true;
    if (this.workflow.steps && this.workflow.transitions) {
      this.branches.forEach(branch => {
        if (branch[branch.length - 1].fe_type !== CanvasFunctionalElementType.Completer) {
          res = false;
        }
      });
    }
    const errorIndex = this.workflowErrors.findIndex(err => err.errorType === 'CompleterOnEachBranch');
    if (res) {
      if (errorIndex > -1) {
        this.workflowErrors.splice(errorIndex, 1);
      }
    } else {
      if (errorIndex === -1) {
        const error: ICanvasWorkflowError = {
          errorType: 'CompleterOnEachBranch',
          message: 'Each final branch must end in an Approval'
        };
        this.workflowErrors.push(error);
      }
    }
    return res;
  }

  //  VAL-WF-10301 Must have a condition providing step selected
  doValidateECMILMSwitchWithCondition(): boolean {
    if (this.workflow.steps.length < 3) {
      return;
    }
    let res = true;
    if (this.workflow.steps && this.workflow.transitions) {
      this.branches.forEach(branch => {
        branch.forEach((currentStep, index) => {
          if (currentStep.fe_type === CanvasFunctionalElementType.Switch && !currentStep.config_data?.decisional_step_gid) {
            res = false;
          }
        });
      });
    }
    const errorIndex = this.workflowErrors.findIndex(err => err.errorType === 'SwitchWithCondition');
    if (res) {
      if (errorIndex > -1) {
        this.workflowErrors.splice(errorIndex, 1);
      }
    } else {
      if (errorIndex === -1) {
        const error: ICanvasWorkflowError = {
          errorType: 'SwitchWithCondition',
          message: 'Each Switch must have a condition providing step selected'
        };
        this.workflowErrors.push(error);
      }
    }
    return res;
  }

  //  VAL-WF-10302 the decisional step must be above (before) the affected switch step
  doValidateECMILMDecisionalStepBeforeSwitch(): boolean {
    if (this.workflow.steps.length < 3) {
      return;
    }
    let res = true;
    if (this.workflow.steps && this.workflow.transitions) {
      const prevStepIDs = [];
      this.branches.forEach(branch => {
        branch.forEach((currentStep, index) => {
          // save previous branch step GIDs
          prevStepIDs.push(currentStep.gid);
          // if Switch step then check to see if decisional_step_gid is included in prevStepIDs
          if (currentStep.fe_type === CanvasFunctionalElementType.Switch && currentStep.config_data?.decisional_step_gid) {
            if (!prevStepIDs.includes(currentStep.config_data?.decisional_step_gid)) {
              res = false;
            }
          }
        });
      });
    }
    const errorIndex = this.workflowErrors.findIndex(err => err.errorType === 'DecisionalStepBeforeSwitch');
    if (res) {
      if (errorIndex > -1) {
        this.workflowErrors.splice(errorIndex, 1);
      }
    } else {
      if (errorIndex === -1) {
        const error: ICanvasWorkflowError = {
          errorType: 'DecisionalStepBeforeSwitch',
          message: 'The decisional step must be above (before) the affected switch step'
        };
        this.workflowErrors.push(error);
      }
    }
    return res;
  }

  // VAL-WF-10307 each FE can be used only once as a switch condition
  doValidateECMILMDecisionalUsedOnlyOnce(): boolean {
    if (this.workflow.steps.length < 3) {
      return;
    }
    let res = true;
    if (this.workflow.steps && this.workflow.transitions) {
      this.branches.forEach(branch => {
        const usedStepIDs = [];
        branch.forEach((currentStep, index) => {
          if (currentStep.fe_type === CanvasFunctionalElementType.Switch && currentStep.config_data?.decisional_step_gid) {
            // check if switch decisional condition was used before
            if (usedStepIDs.includes(currentStep.config_data.decisional_step_gid)) {
              res = false;
            }
            usedStepIDs.push(currentStep.config_data.decisional_step_gid);
          }
        });
      });
    }
    const errorIndex = this.workflowErrors.findIndex(err => err.errorType === 'DecisionalUsedOnlyOnce');
    if (res) {
      if (errorIndex > -1) {
        this.workflowErrors.splice(errorIndex, 1);
      }
    } else {
      if (errorIndex === -1) {
        const error: ICanvasWorkflowError = {
          errorType: 'DecisionalUsedOnlyOnce',
          message: 'Each FE can be used only once as a switch condition'
        };
        this.workflowErrors.push(error);
      }
    }
    return res;
  }

  // VAL-WF-10303 must have the exact number of transitions as cases possible
  doValidateECMILMCasesEqualTransitions(): boolean {
    if (this.workflow.steps.length < 3) {
      return;
    }
    let res = true;
    if (this.workflow.steps && this.workflow.transitions) {
      this.branches.forEach(branch => {
        branch.forEach((currentStep, index) => {
          let activeCases = 0;
          if (currentStep.fe_type === CanvasFunctionalElementType.Switch && currentStep.config_data?.cases) {
            activeCases = Object.entries(currentStep.config_data.cases).map(([k, v]) => (v)).filter(val => !!val).length;
            const outTransitionsCount = this.workflow.transitions.filter(trans => trans.from_gid === currentStep.gid).length;
            if (activeCases !== outTransitionsCount) {
              res = false;
            }
          }
        });
      });
    }
    const errorIndex = this.workflowErrors.findIndex(err => err.errorType === 'CasesEqualTransitions');
    if (res) {
      if (errorIndex > -1) {
        this.workflowErrors.splice(errorIndex, 1);
      }
    } else {
      if (errorIndex === -1) {
        const error: ICanvasWorkflowError = {
          errorType: 'CasesEqualTransitions',
          message: 'Switch must have all priorities connected with a branch'
        };
        this.workflowErrors.push(error);
      }
    }
    return res;
  }

  // VAL-WF-10304 each case must be assigned to a position (left, right, etc.)
  doValidateECMILMEachCaseAssignedToPosition(): boolean {
    if (this.workflow.steps.length < 3) {
      return;
    }
    let res = true;
    if (this.workflow.steps && this.workflow.transitions) {
      this.branches.forEach(branch => {
        branch.forEach((currentStep, index) => {
          let activeCases = 0;
          if (currentStep.fe_type === CanvasFunctionalElementType.Switch && currentStep.config_data?.cases) {
            activeCases = Object.entries(currentStep.config_data.cases).map(([k, v]) => (v)).filter(val => !!val).length;

            let conditionalStep;
            if (currentStep.config_data?.decisional_step_gid) {
              conditionalStep = this.stepsByGid[currentStep.config_data.decisional_step_gid];
            }

            // we verify that the number of cases that exist on the conditional step
            // based on the type of step (initiation -> priority_list, impact/implementation -> question_answer_list
            // matches the number of cases on the switch
            if (conditionalStep) {
              if (conditionalStep.fe_type === CanvasFunctionalElementType.Initiation) {
                if (activeCases !== conditionalStep.config_data?.priority_list?.length) {
                  res = false;
                }
              } else {
                if (activeCases !== conditionalStep.config_data?.question_answer_list?.length) {
                  res = false;
                }
              }
            }
          }
        });
      });
    }
    const errorIndex = this.workflowErrors.findIndex(err => err.errorType === 'EachCaseAssignedToPosition');
    if (res) {
      if (errorIndex > -1) {
        this.workflowErrors.splice(errorIndex, 1);
      }
    } else {
      if (errorIndex === -1) {
        const error: ICanvasWorkflowError = {
          errorType: 'EachCaseAssignedToPosition',
          message: 'Each Switch case must be assigned to a position (left, right, etc.)'
        };
        this.workflowErrors.push(error);
      }
    }
    return res;
  }

  // VAL-WF-10306 Each FE can have only one “branch impacting“ variable
  doValidateECMILMOnlyOneBranchImpactingVariable(): boolean {
    if (this.workflow.steps.length < 3) {
      return;
    }
    let res = true;
    if (this.workflow.steps && this.workflow.transitions) {
      this.branches.forEach(branch => {
        const branchLastStep = branch[branch.length - 1];
        // check if the branch variables on the last step on each branch has only one branch
        // impacting variable for each FE
        const branchVariables = branchLastStep.cached_data.branch_variables;
        let branchImpactingVariablesByGID = {};
        if (branchVariables) {
          branchVariables.forEach(branchVariable => {
            // if we find a branch impacting variable we check if one already exists on that FE
            if (branchVariable.is_switchable) {
              if (!branchImpactingVariablesByGID[branchVariable.source_step_gid]) {
                branchImpactingVariablesByGID = {
                  ...branchImpactingVariablesByGID,
                  [branchVariable.source_step_gid]: 1
                };
              } else {
                res = false;
              }
            }
          });
        }
      });
    }
    const errorIndex = this.workflowErrors.findIndex(err => err.errorType === 'OnlyOneBranchImpactingVariable');
    if (res) {
      if (errorIndex > -1) {
        this.workflowErrors.splice(errorIndex, 1);
      }
    } else {
      if (errorIndex === -1) {
        const error: ICanvasWorkflowError = {
          errorType: 'OnlyOneBranchImpactingVariable',
          message: 'Each FE can have only one “branch impacting“ variable'
        };
        this.workflowErrors.push(error);
      }
    }
    return res;
  }

  // VAL-WF-10504 - Each Implementation and Impact step should have decisional question if the "question_enable" is true
  doValidateECMILMDecisionalQuestionRequired(): boolean {
    if (this.workflow.steps.length < 3) {
      return;
    }
    let res = true;
    const invalidStepNames = [];
    if (this.workflow.steps) {
      this.workflow.steps
        .filter(step => [CanvasFunctionalElementType.Implementation, CanvasFunctionalElementType.Impact].includes(step.fe_type))
        .forEach(step => {
          if (step.config_data.question_enable && !step.config_data.question_text) {
            invalidStepNames.push(step.display_title);
            res = false;
          }
        });
    }
    const errorIndex = this.workflowErrors.findIndex(err => err.errorType === 'DecisionalQuestionRequired');
    if (res) {
      if (errorIndex > -1) {
        this.workflowErrors.splice(errorIndex, 1);
      }
    } else {
      if (errorIndex === -1) {
        const error: ICanvasWorkflowError = {
          errorType: 'DecisionalQuestionRequired',
          message: 'Please add a question in ' + invalidStepNames.join(', ')
        };
        this.workflowErrors.push(error);
      }
    }
    return res;
  }
}
