import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreModule } from '../../../core/core.module';
import { MaterialModule } from '../../../core/modules/material.module';
import { WorkflowsListingModalComponent } from './workflows-listing-modal.component';
import { ButtonsModule } from '@shared/components/buttons/buttons.module';
import { PageSelectorModule } from '../page-selector/page-selector.module';
import { SearchInputModule } from '../search-input/search-input.module';
import { MatIconModule } from '@angular/material/icon';
import { FiltersDropdownModule } from '../filters-dropdown/filters-dropdown.module';
import { DatepickerModule } from '../datepicker/datepicker.module';
import { SelectModule } from '../select/select.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    CoreModule,
    ButtonsModule,
    PageSelectorModule,
    SearchInputModule,
    MatIconModule,
    FiltersDropdownModule,
    DatepickerModule,
    SelectModule
  ],
  declarations: [
    WorkflowsListingModalComponent
  ],
  exports: [
    WorkflowsListingModalComponent
  ],
  providers: []
})
export class WorkflowsListingModalModule {
}
