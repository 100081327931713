<div class="margin-top-15 flex-row flex-wrap flex-100">

  <div class="flex-100">
    <article class="section-header clearfix">
      <div class="section-name">
        <span class="icon-icc_projects"><span class="section-title">CMS</span></span>
        <p>Manage branding content</p>
      </div>
    </article>
  </div>

  <div class="section-container gray-text flex-wrap flex-100">
    <ng-container *ngIf="currentUser">
      <article class="section-header clearfix flex-100">
        <div class="section-name">
          <span class="icon-icn_help"><span class="section-title"> CMS Dashboard</span></span>
        </div>
      </article>
      <div class="form-container flex-100" *ngIf="loginPageData">
        <form [formGroup]="cmsForm" class="flex-wrap flex-100">
          <div class="margin-top-15 flex-row flex-wrap justify-start items-center flex-gap-20">

            <div class="image-preview-container flex-fixed-300"
                 *ngIf="imageSrc">
              <img class="image-preview"
                   [src]="imageSrc"
                   *ngIf="imageSrc">
            </div>

            <div class="image-preview-container image-preview-width">
              <app-styleguide-simple-button
                type="green-filled"
                label="Upload Image"
                icon="icon-n-upload"
                (onClick)="fileInput.click()">
              </app-styleguide-simple-button>

              <input
                #fileInput
                hidden
                formControlName="image"
                id="input-file"
                accept="image/*"
                type="file"
                (change)="onFileChange($event)">
            </div>
          </div>

          <div class="margin-top-15 flex-100">
            <app-styleguide-simple-button
              type="green-filled"
              label="Save"
              [disabled]="!cmsForm.valid || !cmsForm.dirty"
              (onClick)="saveCMSData()">
            </app-styleguide-simple-button>
          </div>
        </form>
      </div>
    </ng-container>
  </div>
</div>
