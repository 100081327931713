import { Injectable } from '@angular/core';
import { IApiPaginatedDataBase, ICompanyUserGroupDepartment, IExtraQueryParameters, ISortQueryParameters } from '@core/interfaces';
import { IUserLite } from '@core/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class CompanySearchService {
  private readonly companySearchPath = '/api/company-search';

  constructor(private apiService: ApiService) { }

  searchCompanyUser(offset: number, limit: number, sortQueryParams: ISortQueryParameters, extraQueryParams: IExtraQueryParameters): Observable<IUserLite[]> {
    const queryParams = this.apiService.addSortQueryParams(sortQueryParams);
    return this.apiService.get(`${this.companySearchPath}/user/${offset}/${limit}`, this.apiService.addExtraQueryParams(queryParams, extraQueryParams))
      .pipe(map((res: IApiPaginatedDataBase<IUserLite>) => res.data));
  }

  searchCompanyUserGroupDepartment(offset: number, limit: number, sortQueryParams: ISortQueryParameters, extraQueryParams: IExtraQueryParameters): Observable<ICompanyUserGroupDepartment> {
    const queryParams = this.apiService.addSortQueryParams(sortQueryParams);
    return this.apiService.get(`${this.companySearchPath}/user-group-department/${offset}/${limit}`, this.apiService.addExtraQueryParams(queryParams, extraQueryParams))
      .pipe(map(res => {
        return {
          departments: res.departments?.data || [],
          users: res.users?.data || [],
          groups: res.groups?.data || [],
          private_groups: res.private_groups?.data || [],
        }
      }));
  }
}
