import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UserDetailsModule } from '../user-details/user-details.module';
import { AccessEntitiesListComponent } from './access-entities-list.component';

@NgModule({
  imports: [
    CommonModule,
    UserDetailsModule
  ],
  declarations: [
    AccessEntitiesListComponent
  ],
  exports: [
    AccessEntitiesListComponent
  ],
  providers: []
})
export class AccessEntitiesListModule {
}
