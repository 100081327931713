import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { MaterialModule } from '@core/modules/material.module';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';
import { UserGroupDepartmentSearchModule } from '../user-group-department-search/user-group-department-search.module';
import { UsersListComponent } from './users-list.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    CoreModule,
    UserAvatarModule,
    UserGroupDepartmentSearchModule
  ],
  declarations: [
    UsersListComponent
  ],
  exports: [
    UsersListComponent
  ],
  providers: []
})
export class UsersListModule {
}
