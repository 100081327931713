export enum CompanyStatus {
  Activate = 'activate',
  Deactivate = 'deactivate',
  Delete = 'delete',
  CancelDelete = 'cancel deletion'
}


export enum MaintenanceStatus {
  Operational = 'operational',
  Deleting = 'deleting',
  DeleteRequest = 'delete-requested',
  Creating = 'creating'
}
