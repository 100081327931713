import { ICompanySystem, ISortQueryParameters } from '@core/interfaces';

export class SetLoading {
  static readonly type = '[System] SetLoading';
  constructor(public isLoading = true) {}
}

export class GetSystems {
  static readonly type = '[System] GetSystems';
  constructor(public offset: number, public limit: number, public sortQueryParams: ISortQueryParameters) { }
}

export class GetGlobalSystems {
  static readonly type = '[Marketplace Systems] Get';
  constructor(public offset: number, public limit: number, public sortQueryParams: ISortQueryParameters, public hasPackage?: boolean) { }
}

export class CreateGlobalSystem {
  static readonly type = '[Marketplace Systems] Create';
  constructor(public system: ICompanySystem) { }
}

export class EditGlobalSystem {
  static readonly type = '[Marketplace Systems] Edit';
  constructor(public uuid: string, public system: ICompanySystem) { }
}

export class DeleteGlobalSystem {
  static readonly type = '[Marketplace Systems] Delete';
  constructor(public systemUuid: string, public deletePackages: number) { }
}
