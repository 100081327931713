import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  private eliminateMinutes(timeZone: string): string {
    return timeZone.includes(':00') ? timeZone.replace(':00', '') : timeZone;
  }

  private transformTimeZones(timeZone: string): string {
    const zoneAbbr = moment().tz(timeZone).zoneAbbr();
    if (zoneAbbr.startsWith('-') || zoneAbbr.startsWith('+') || zoneAbbr.startsWith('GMT')) {
      const gmt = zoneAbbr !== 'GMT' ? `- (GMT${this.eliminateMinutes(moment().tz(timeZone).format('Z'))})` : '- GMT';
      return `${gmt}`;
    } else {
      return `${zoneAbbr} (GMT${this.eliminateMinutes(moment().tz(timeZone).format('Z'))})`;
    }
  }

  transform(date: string, dateFormat: string, timezone?: string, timeFormat?: string, isTimeZoneHidden?: boolean): string {
    if (!date) {
      return '-';
    }

    //  Used to display only the time in a day
    if (!dateFormat && !timezone && timeFormat) {
      return moment(date).format(timeFormat);
    }

    //  Used to display the time in a day with timezone
    if (!dateFormat && timezone && timeFormat) {
      return `${moment(date).format(timeFormat)} ${this.transformTimeZones(timezone)}`;
    }

    if (!dateFormat) {
      dateFormat = 'YYYY-MM-DD';
    }

    const format = timeFormat ? dateFormat + ' ' + timeFormat : dateFormat;
    if (timezone) {
      date = `${moment(date).tz(timezone).format(format)}${!!isTimeZoneHidden  ? '' : ' ' + this.transformTimeZones(timezone)}`;
    } else {
      date = moment(date).format(format);
    }

    return `${date}`;
  }
}
