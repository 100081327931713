import { Injectable } from '@angular/core';
import { NotificationResponse } from '@core/interfaces';
import { NotificationsService } from '@core/services';
import { Action, State, StateContext } from '@ngxs/store';
import * as _ from 'lodash';
import { take, tap } from 'rxjs/operators';
import {GetNotifications, MarkNotificationAsRead, PushNotification, ResetNotificationsCounter, UpdateNotificationsCounter} from './notification.actions';

const DEFAULT_ITEMS_PER_PAGE = 15;

export interface NotificationStateModel {
  loading: boolean;
  notifications: NotificationResponse[];
  offset: number;
  limit: number;
  total: number;
  total_unseen: number;
}

@State<NotificationStateModel>({
  name: 'NotificationState',
  defaults: {
    loading: false,
    notifications: [],
    offset: 0,
    limit: DEFAULT_ITEMS_PER_PAGE,
    total: 0,
    total_unseen: 0
  }
})
@Injectable()
export class NotificationState {

  constructor(private notificationsService: NotificationsService) { }

  @Action(GetNotifications, { cancelUncompleted: true })
  getNotifications({ getState, patchState, }: StateContext<NotificationStateModel>) {
    const state = getState();
    patchState({ loading: true });
    return this.notificationsService.getNotifications(state.offset, state.limit).pipe(
      tap(result => {
        patchState({
          loading: false,
          notifications: [...state.notifications, ...result.data],
          offset: state.offset + state.limit,
          total: result.total,
          total_unseen: result.meta.total_unseen
        });
      }));
  }

  @Action(MarkNotificationAsRead, { cancelUncompleted: true })
  markNotificationAsRead({ getState, patchState }: StateContext<NotificationStateModel>, { uuid }: MarkNotificationAsRead) {
    const notifications = _.cloneDeep(getState().notifications);
    const notification = notifications.find((n: NotificationResponse): boolean => n.uuid === uuid);
    notification.is_read = !notification.is_read;
    patchState({ notifications });
  }

  @Action(PushNotification, { cancelUncompleted: true })
  pushNotification({ getState, patchState }: StateContext<NotificationStateModel>, { notification }: PushNotification) {
    const notifications = _.cloneDeep(getState().notifications);
    notifications.unshift(notification);
    patchState({ notifications });
  }

  @Action(ResetNotificationsCounter, { cancelUncompleted: true })
  resetNotificationsCounter({ patchState }: StateContext<NotificationStateModel>) {
    this.notificationsService.resetCounter()
      .pipe(take(1))
      .subscribe(() => patchState({ total_unseen: 0 }));
  }

  @Action(UpdateNotificationsCounter, { cancelUncompleted: true })
  updateNotificationsCounter({ patchState }: StateContext<NotificationStateModel>, {counter}: UpdateNotificationsCounter) {
    patchState({ total_unseen: counter });
  }
}
