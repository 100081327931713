<app-login-container>
  <ng-template let-brandingData>
    <div class="flex-row justify-center items-center">
      <div class="section-container flex-col flex-fixed-380">
        <app-login-header *ngIf="!temporaryPasswordUsed" [companyName]="brandingData?.company?.name"></app-login-header>
        <div *ngIf="temporaryPasswordUsed" class="temporary-pass-warning">
          You logged in with a temporary password. To finish logging in, you must create a new password
        </div>

        <amplify-authenticator
          [services]="amplifyServices"
          [loginMechanisms]="['email']"
          [hideSignUp]="true">
        </amplify-authenticator>

        <app-styleguide-simple-button
          *ngIf="!temporaryPasswordUsed"
          type="green-link"
          fluid="true"
          label="Forgot password?"
          (onClick)="goToForgetPasswordPage()">
        </app-styleguide-simple-button>

        <app-styleguide-simple-button
          *ngIf="!temporaryPasswordUsed && brandingData.company.is_sso && brandingData.company.pool_config.idp_data.length && !isLoggedIn"
          type="green-outline"
          class="margin-top-30 sign-in-with-sso"
          label="Sign in with SSO"
          (onClick)="goToSignInWithSSO(brandingData)">
        </app-styleguide-simple-button>
      </div>
    </div>
  </ng-template>
</app-login-container>
