import { ImpactAssessmentEditorView } from '@core/constants/impact-assessment-editor-view.enum';
import { IImpactAssessmentLite, IImpactAssessmentStepData, ImpactAssessmentStep } from '@core/interfaces';

export class ClickAction {
  static readonly type = '[ImpactAssessment] ClickAction';
  constructor(public action: string) { }
}

export class ChangeStep {
  static readonly type = '[ImpactAssessment] ChangeStep';
  constructor(public step: IImpactAssessmentStepData) { }
}

export class ChangeToPreviousStep {
  static readonly type = '[ImpactAssessment] ChangeToPreviousStep';
}

export class ChangeEditorMode {
  static readonly type = '[ImpactAssessment] ChangeEditorMode';
  constructor(public mode: ImpactAssessmentEditorView) { }
}

export class SelectIAConfig {
  static readonly type = '[ImpactAssessment] SelectIAConfig';
  constructor(public iaConfig: IImpactAssessmentLite) { }
}

export class SelectIALink {
  static readonly type = '[ImpactAssessment] SelectIALink';
  constructor(public iaLink: IImpactAssessmentLite) { }
}
