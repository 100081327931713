import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@core/modules/material.module';
import { CoreModule } from '@core/core.module';
import {
  TraceMatrixLinksSummarySectionComponent
} from '@shared/components/trace-matrix-links/trace-matrix-links-summary/trace-matrix-links-summary-section/trace-matrix-links-summary-section.component';


@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    CoreModule,
  ],
  declarations: [
    TraceMatrixLinksSummarySectionComponent
  ],
  exports: [
    TraceMatrixLinksSummarySectionComponent
  ],
  providers: []
})
export class TraceMatrixLinksSummarySectionModule {
}
