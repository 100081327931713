import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IApiPaginatedDataBase, IRuntimeLite } from '../interfaces';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class HomepageService {
  constructor(private apiService: ApiService) {}

  public getHomepageWorkflows(): Observable<IApiPaginatedDataBase<IRuntimeLite>> {
    return this.apiService.get('/api/company-user-homepage-workflows?forceRetrieve=false');
  }
}
