import { Injectable } from '@angular/core';
import { ICanvasStep, IReviewData, ISignatureData } from '@core/interfaces';
import { TCActions } from 'src/app/canvas/shared/fe-components/completer/signature-modal/tc-actions.enum';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CompleterService {

  readonly path = '/api/runtime-user-workflow/';

  constructor(private apiService: ApiService) {
  }

  sendForSignature(stepUuid: string, seriesData: ISignatureData, workflowUuid, workflowVersionUuid, action = TCActions.SendForSignature.valueOf()) {
    const sendForSignaturePath = `${this.path}${workflowUuid}/version/${workflowVersionUuid}/Completer/${stepUuid}/${action}`;
    return this.apiService.post(sendForSignaturePath, seriesData);
  }

  answerTask(stepUuid: string, requestData, workflowUuid, workflowVersionUuid, action) {
    const answerPath = `${this.path}${workflowUuid}/version/${workflowVersionUuid}/Completer/${stepUuid}/${action}`;
    return this.apiService.post(answerPath, requestData);
  }

  sendForReviewUpdate(seriesData: IReviewData, workflowUuid: string, workflowVersionUuid: string, reviewSessionUuid: string) {
    const sendForSignaturePath = `${this.path}${workflowUuid}/version/${workflowVersionUuid}/review-session/${reviewSessionUuid}`;
    return this.apiService.put(sendForSignaturePath, seriesData);
  }
}
