import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { MaterialModule } from '@core/modules/material.module';
import { ButtonsModule } from '@shared/components/buttons/buttons.module';
import { SaveCancelButtonComponent } from './save-cancel-button.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    CoreModule,
    ButtonsModule,
  ],
  declarations: [
    SaveCancelButtonComponent
  ],
  exports: [
    SaveCancelButtonComponent
  ],
  providers: []
})
export class SaveCancelButtonModule {
}
