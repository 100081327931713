import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({selector: '[appIntegersOnly]'})
export class IntegersOnlyDirective {
  constructor(private el: ElementRef) { }

  @Input() number = 0;
  @Input() positiveOnly = true;
  @Input() min = 0;
  @Input() max = 100;
  @Input() allowStartingZero = false;
  @Output() textUpdate = new EventEmitter<string>();

  private check(value: string): boolean {
    if (!new RegExp('^[0-9]*$').test(value)) {
      return false;
    }

    if (!Number.isInteger(+value)) {
      return false;
    }

    if (this.positiveOnly) {
      if (Math.sign(+value) !== 1 && (!this.allowStartingZero || +value !== 0)) {
        return false;
      }
    }

    if (!(+value >= this.min && +value <= this.max)) {
      return false;
    }

    return true;
  }

  private run(oldValue): void {
    setTimeout(() => {
      const currentValue: string = this.el.nativeElement.value;
      if (currentValue !== '' && !this.check(currentValue)) {
        this.el.nativeElement.value = oldValue;
        this.textUpdate.emit(oldValue);
      } else {
        this.textUpdate.emit(currentValue);
      }
    });
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event) {
    this.run(this.el.nativeElement.value);
  }

  @HostListener('paste', ['$event'])
  onPaste(event) {
    this.run(this.el.nativeElement.value);
  }
}
