import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '@core/core.module';
import { MaterialModule } from '@core/modules/material.module';
import { PipesModule } from '@core/pipes/pipes.module';
import { ButtonsModule } from '@shared/components/buttons/buttons.module';
import { PinCodeInputComponent } from './pin-code-input.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    CoreModule,
    ButtonsModule,
    ReactiveFormsModule,
    PipesModule
  ],
  declarations: [
    PinCodeInputComponent
  ],
  exports: [
    PinCodeInputComponent
  ],
  providers: []
})
export class PinCodeInputModule {
}
