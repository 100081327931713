<div class="paginator flex-100 flex-col">
  <div class="progress-bar-container flex-100">
    <mat-progress-bar mode="query" *ngIf="showProgressBar && loadingNewRows"></mat-progress-bar>
  </div>
  <div class="flex-100 flex-row justify-end items-center">
    <mat-paginator #paginator
                   class="mat-paginator"
                   [length]="length"
                   [showFirstLastButtons]="true"
                   [pageSizeOptions]="[5, 10, 25, 50, 100]"
                   [pageSize]="pageSize"
                   [hidePageSize]="hidePageSize"
                   (page)="changePage($event)"
    >
    </mat-paginator>
    <div class="page-selector-container flex-row justify-center items-center">
      Page
      <input appIntegersOnly [class.has-error]="pageError" [max]="maxInt" #pageNum matInput [value]="numberOfPages ? pageIndex + 1 : 0" (blur)="onPageNumberChange(pageNum.value)" (keyup.enter)="onPageNumberChange(pageNum.value)" class="page"/>
      of {{numberOfPages}}
    </div>
  </div>
  <div *ngIf="pageError" class="page-error flex-row justify-end items-center">
    Please introduce a page number between 1 and the maximum number of pages
  </div>
</div>
