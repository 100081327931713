import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NotificationResponse, NotificationTemplate } from '@core/interfaces';
import { NotificationMenuItemComponent } from '../../notification-menu-item/notification-menu-item.component';


@Component({
  selector: 'app-market-package-downloaded',
  templateUrl: './market-package-downloaded.component.html',
  styleUrls: ['./market-package-downloaded.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketPackageDownloadedComponent extends NotificationMenuItemComponent {
  @Input() notification: NotificationResponse;
  readonly NotificationTemplate = NotificationTemplate;
}
