import { Injectable } from '@angular/core';
import { IApiPaginatedDataBase, ISortQueryParameters } from '@core/interfaces/api-response.interface';
import { Observable } from 'rxjs';
import { IPrefix, PrefixType } from '../interfaces/prefix.interface';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class PrefixService {
  private path = '/api/workflow-prefix';

  constructor(private apiService: ApiService) { }

  public getPrefixes(offset: number, limit: number, sortQueryParams?: ISortQueryParameters, filters?: { type: PrefixType }): Observable<IApiPaginatedDataBase<IPrefix>> {
    const queryParams = this.apiService.addSortQueryParams(sortQueryParams);
    return this.apiService.get(`${this.path}/${offset}/${limit}`, this.apiService.addFilterQueryParams(queryParams, filters));
  }

  public addPrefix(prefix: IPrefix) {
    return this.apiService.post(this.path, prefix);
  }

  public updatePrefix(prefix: IPrefix) {
    return this.apiService.patch(`${this.path}/${prefix.uuid}`, prefix);
  }

  public deletePrefix(prefix: IPrefix) {
    return this.apiService.delete(`${this.path}/${prefix.uuid}`);
  }
}
