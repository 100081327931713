import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../../../../core/modules/material.module';
import { SharingModalComponent } from './sharing-modal.component';
import { ButtonsModule } from '../../buttons/buttons.module';
import { RouterModule } from '@angular/router';
import { CoreModule } from '../../../../core/core.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MaterialModule,
        CoreModule,
        ButtonsModule,
        ReactiveFormsModule,
    ],
  declarations: [
    SharingModalComponent
  ],
  exports: [
    SharingModalComponent
  ],
  providers: []
})
export class SharingModalModule {
}
