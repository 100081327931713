export interface IDPForm {
  name: string;
  type: SSOIdentityProviderType;
  integrationType: SSOIntegrationType;
  metadataUrl?: string;
  metadataFile?: any;
  idpSignOut?: boolean;
  clientId?: string;
  clientSecret?: string;
  attributesRequestMethod?: SSOIdentityProviderRequestMethod;
  oidcIssuer?: string;
  authorizeScopes?: string;
  authorizeUrl?: string;
  tokenUrl?: string;
  attributesUrl?: string;
  jwksUri?: string;
  attributesUrlAddAttributes?: string;
  attributesMappingEmailValue?: string;
  attributesMappingFamilyNameValue?: string;
  attributesMappingGivenNameValue?: string;
  attributesMappingPhoneNumberValue?: string;
}

export interface IDPResponse {
  type: string;
  name: string;
  metadata_url: string;
  metadata_file: string;
  idp_signout: boolean;
  client_id: string;
  client_secret: string;
  attributes_request_method: SSOIdentityProviderRequestMethod;
  oidc_issuer: string;
  authorize_scopes: string;
  authorize_url: string;
  token_url: string;
  attributes_url: string;
  jwks_uri: string;
  attributes_url_add_attributes?: string;
  attribute_mapping: {
    [key: string]: string;
  };
  creation_date: string;
  integration_type: SSOIntegrationType;
}

export enum SSOIdentityProviderType {
  SAML = 'SAML',
  OIDC = 'OIDC',
}

export enum SSOIdentityProviderTypeLabel {
  SAML = 'SAML',
  OIDC = 'OpenID Connect (OIDC)',
}

export enum SSOIntegrationType {
  AZURE_AD = 'AzureAD',
  AD_FS = 'AD FS',
  OKTA = 'Okta',
}

export enum SSOIntegrationTypeEntityIdLabel {
  AzureAD = 'Identifier ID (Entity ID)',
  'AD FS' = 'Relying party SAML 2.0 SSO service URL',
  Okta = 'Single sign on URL',
}

export enum SSOIntegrationTypeReplyUrlLabel {
  AzureAD = 'Reply URL (Assertion Customer Service URL)',
  'AD FS' = 'Relying party trust identifier',
  Okta = 'Audience URI (SP Entity ID)',
}

export enum SSOIdentityProviderMetadataType {
  URL = 'URL',
  FILE = 'FILE',
}

export enum SSOIdentityProviderMetadataTypeLabel {
  URL = 'Enter metadata document endpoint URL',
  FILE = 'Upload metadata document',
}

export enum SSOIdentityProviderRequestMethod {
  GET = 'GET',
  POST = 'POST',
}

export enum SSOIdentityProviderMethodType {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
}

export enum SSOIdentityProviderMethodTypeLabel {
  AUTO = 'Auto fill through issuer URL',
  MANUAL = 'Manual input',
}

export enum SSOIdentityProviderAttributesMapping {
  EMAIL = 'email',
  FAMILY_NAME = 'family_name',
  GIVEN_NAME = 'given_name',
  PHONE_NUMBER = 'phone_number',
}

export enum SSOIdentityProviderAttributesMappingLabel {
  EMAIL = 'Email',
  FAMILY_NAME = 'Family Name',
  GIVEN_NAME = 'Given Name',
  PHONE_NUMBER = 'Phone Number',
}
