import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IApiPaginatedDataBase, ICompanyFilterTag,
  ICompanyFilterTagValue,
  ICompanyFilterTagWithObjectValues,
  ISortQueryParameters
} from '../interfaces';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class FilterTagService {
  private path = '/api/filter-tag';

  constructor(private apiService: ApiService) { }

  public getFilterTags(offset: number, limit: number, sortQueryParams?: ISortQueryParameters): Observable<IApiPaginatedDataBase<ICompanyFilterTag>> {
    return this.apiService.get<ICompanyFilterTag[]>(`${this.path}/${offset}/${limit}`, this.apiService.addSortQueryParams(sortQueryParams));
  }

  public addFilterTag(filterTag: ICompanyFilterTag) {
    return this.apiService.post(this.path, filterTag);
  }

  public renameFilterTagCategory(filterTagCategoryUuid: string, newValue: string) {
    return this.apiService.put(`${this.path}/${filterTagCategoryUuid}`, { name: newValue });
  }

  public deleteFilterTag(filterTag: ICompanyFilterTag) {
    return this.apiService.delete(`${this.path}/${filterTag.uuid}`);
  }

  public getFilterTag(filterTag: ICompanyFilterTag) {
    return this.apiService.get(`${this.path}/${filterTag.uuid}`);
  }

  public getFilterTagWithValues(offset: number, limit: number, filterTagsUuids: string[]): Observable<IApiPaginatedDataBase<ICompanyFilterTagWithObjectValues>> {
    return this.apiService.post(`${this.path}/get-with-values/${offset}/${limit}`,
      {
        uuids: filterTagsUuids
      }
    );
  }

  public addFilterTagValue(filterTagCategoryUuid: string, value: string) {
    return this.apiService.post(`${this.path}/${filterTagCategoryUuid}/values`, { value });
  }

  public updateFilterTagValue(filterTagCategoryUuid: string, filterTagUuid: string, value: string) {
    return this.apiService.put(`${this.path}/${filterTagCategoryUuid}/values/${filterTagUuid}`, { value });
  }

  public deleteFilterTagValue(filterTagCategoryUuid: string, filterTagUuid: string) {
    return this.apiService.delete(`${this.path}/${filterTagCategoryUuid}/values/${filterTagUuid}`);
  }
}
