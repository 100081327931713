import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DynamicModule } from 'ng-dynamic-component';
import { MaterialModule } from '../../../core/modules/material.module';
import { ActionInfoButtonComponent } from './action-info-button/action-info-button.component';
import { DropdownButtonComponent } from './dropdown-button/dropdown-button.component';
import { MultipleButtonComponent } from './multiple-button/multiple-button.component';
import { SplitterButtonComponent } from './splitter-button/splitter-button.component';
import { StepperButtonComponent } from './stepper-button/stepper-button.component';
import { StyleguideSimpleButtonComponent } from './styleguide-simple-button/styleguide-simple-button.component';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    DynamicModule
  ],
  declarations: [
    MultipleButtonComponent,
    SplitterButtonComponent,
    DropdownButtonComponent,
    ActionInfoButtonComponent,
    StepperButtonComponent,
    StyleguideSimpleButtonComponent,
    ToggleButtonComponent
  ],
  exports: [
    MultipleButtonComponent,
    SplitterButtonComponent,
    DropdownButtonComponent,
    ActionInfoButtonComponent,
    StepperButtonComponent,
    StyleguideSimpleButtonComponent,
    ToggleButtonComponent
  ],
  providers: []
})
export class ButtonsModule {
}
