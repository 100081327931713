import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NotificationResponse, TemplateAccessRequestType } from '@core/interfaces';
import { NotificationMenuItemComponent } from '../../notification-menu-item/notification-menu-item.component';

@Component({
  selector: 'app-acl-request-rejected',
  templateUrl: './acl-request-rejected.component.html',
  styleUrls: ['./acl-request-rejected.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AclRequestRejectedComponent extends NotificationMenuItemComponent {
  @Input() notification: NotificationResponse;

  readonly TemplateAccessRequestType = TemplateAccessRequestType;

  goToAction(): void {
    if (this.notification.data?.access_level === TemplateAccessRequestType.WORKSPACE_ACCESS ||
      (this.notification.data?.access_level === TemplateAccessRequestType.ACCESS && !this.notification.data?.link?.is_template)) {
      this.goToWorkflowsList();
    } else {
      this.goToTemplatesList();
    }
  }
}
