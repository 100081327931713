import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@core/modules/material.module';
import { DisableKPICardComponent } from './disable-kpi-card.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule
  ],
  declarations: [
    DisableKPICardComponent
  ],
  exports: [
    DisableKPICardComponent
  ],
  providers: []
})
export class DisableKPICardModule {
}
