import { Company, IUser } from '@core/models';
import { Selector, createSelector } from '@ngxs/store';
import { UserSelectors } from '../user/user.selectors';
import { CompanyState, CompanyStateModel } from './company.state';
import { Permissions } from '@core/constants/permissions.enum';

export class CompanySelectors {
  @Selector([UserSelectors.getCurrentUser, CompanyState])
  static getCurrentCompany(user: IUser, state: CompanyStateModel) {
    if (user) {
      return state.company;
    }
    return null;
  }

  @Selector([CompanyState])
  static isLoading(state: CompanyStateModel) {
    return state.loading;
  }

  @Selector([CompanyState])
  static getPermissionMatrixLite(state: CompanyStateModel) {
    return state.permissionMatrixLite;
  }
}
