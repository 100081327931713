import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { Logout } from '@store/user/user.actions';

@Component({
  selector: 'app-missing-account-login',
  templateUrl: './missing-account-login.component.html',
  styleUrls: ['./missing-account.component.scss']
})
export class MissingAccountLoginComponent {

  constructor(private store: Store) { }

  goToLogin() {
    // We need to logout here in order for the localStorage to also be cleaned
    this.store.dispatch(new Logout());
  }
}
