import { IDropdownButtonMenu, IWorkflowRuntime } from '../../core/interfaces';
import { IReviewSessionComment, IReviewSessionCommentLite, IWorkflowReviewLockResponse } from '../../core/interfaces/review.interface';

export class SaveWorkflow {
  static readonly type = '[Document] SaveWorkflow';
  constructor(public document?: IWorkflowRuntime) { }
}

export class ReloadDocument {
  static readonly type = '[Document] ReloadDocument';
}

export class UpdateDocument {
  static readonly type = '[Document] UpdateDocument';
  constructor(public document: IWorkflowRuntime) { }
}

export class GenerateStepUuid {
  static readonly type = '[Document] GenerateStepUuid';
}

export class SetLoadingDocument {
  static readonly type = '[Document] SetLoadingDocument';
  constructor(public isLoading = true) { }
}

export class SetLoadingHighlights {
  static readonly type = '[Document] SetLoadingHighlights';
  constructor(public isLoading = true) { }
}

export class GetCurrentDocument {
  static readonly type = '[Document] GetCurrentDocument';
  constructor(public addSubscriptions = false) { }
}

export class SetCurrentDocument {
  static readonly type = '[Document] SetDocument';
  constructor(public document: IWorkflowRuntime) { }
}

export class GetReviewSessions {
  static readonly type = '[Document] GetReviewSessions';
  constructor(public workflowUuid: string, public versionUuid: string, public loadComments = true) { }
}

export class UpdateCurrentReviewSession {
  static readonly type = '[Document] UpdateCurrentReviewSession';
}

export class GetSessionComments {
  static readonly type = '[Document] GetSessionComments';

  constructor(public sessionUuid: string) { }
}

export class SelectComment {
  static readonly type = '[Document] SelectComment';
  constructor(public selectedCommentGid: string) { }
}

export class SelectCommentChunk {
  static readonly type = '[Document] SelectCommentChunk';
  constructor(public selectedChunkGid: string) { }
}

export class AddComment {
  static readonly type = '[Document] AddComment';
  constructor(public newComment: IReviewSessionComment) { }
}

export class UpdateComment {
  static readonly type = '[Document] UpdateComment';
  constructor(public comment: IReviewSessionComment, public text?: string) { }
}

export class RemoveComment {
  static readonly type = '[Document] RemoveComment';
  constructor(public comment: IReviewSessionComment, public clearSelection = true) { }
}

export class PostComment {
  static readonly type = '[Document] PostComment';
  constructor(public comment: IReviewSessionComment, public retryOn404 = true) { }
}

export class DeleteComment {
  static readonly type = '[Document] DeleteComment';
  constructor(public comment: IReviewSessionComment, public clearSelection = true, public retryOn404 = true) { }
}

export class EditComment {
  static readonly type = '[Document] EditComment';
  constructor(public comment: IReviewSessionComment) { }
}

export class SolveComment {
  static readonly type = '[Document] SolveComment';

  constructor(public comment: IReviewSessionComment) {
  }
}

export class BulkUpdateComments {
  static readonly type = '[Document] BulkUpdateComments';
  constructor(public comments: IReviewSessionComment[], public stepUuid: string) { }
}

export class AddReply {
  static readonly type = '[Document] AddReply';
  constructor(public comment: IReviewSessionComment) {
  }
}

export class UpdateReply {
  static readonly type = '[Document] UpdateReply';
  constructor(public parentComment: IReviewSessionComment, public reply: IReviewSessionCommentLite, public text: string) { }
}

export class RemoveReply {
  static readonly type = '[Document] RemoveReply';

  constructor(public comment: IReviewSessionComment) { }
}

export class PostReply {
  static readonly type = '[Document] PostReply';

  constructor(public parentComment: IReviewSessionComment, public reply: IReviewSessionCommentLite) { }
}

export class DeleteReply {
  static readonly type = '[Document] DeleteReply';

  constructor(public parentComment: IReviewSessionComment, public reply: IReviewSessionCommentLite) { }
}

export class EditReply {
  static readonly type = '[Document] EditReply';
  constructor(public parentComment: IReviewSessionComment, public reply: IReviewSessionCommentLite) { }
}

export class SetReviewVersionsBtnLabelAndComments {
  static readonly type = '[Document] SetReviewVersionsBtnLabelAndComments';
  constructor(public label: string, public comments: string) { }
}

export class SetActionsReviewVersions {
  static readonly type = '[Document] SetActionsReviewVersions';
  constructor(public reviewVersions: IDropdownButtonMenu[]) { }
}

export class Unlock {
  static readonly type = '[Document] Unlock';
  constructor(public useKeepAlive = false) { }
}

export class Lock {
  static readonly type = '[Document] Lock';
  constructor(public lockedFromThisTab = false) { }
}
export class ExtendLock {
  static readonly type = '[Document] ExtendLock';
  constructor(public silentCall = false) { }
}

export class SetLockValue {
  static readonly type = '[Document] SetLockValue';
  constructor(public lockValue: IWorkflowReviewLockResponse | null) { }
}

export class FinalizeReview {
  static readonly type = '[Document] FinalizeReview';
  constructor(public originalUuid: string) { }
}

export class UpdateTablePageNumber {
  static readonly type = '[Document] UpdateTablePageNumber';
  constructor(public stepUuid: string, public pageNumber: number) { }
}

export class SetLinksLoading {
  static readonly type = '[Document] SetLinksLoading';
  constructor(public isLoading: boolean) { }
}

export class StopLinksStatusCheck {
  static readonly type = '[Document] StopLinksStatusCheck';
}

export class ConfirmPass {
  static readonly type = '[Document] ConfirmPass';
  constructor(public rowUuid: string) { }
}

export class SetGeneralFeEnableStatus {
  static readonly type = '[Document] SetGeneralFeEnableStatus';
  constructor(public stepUuid: string, public enable: boolean = true) { }
}
