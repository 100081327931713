import { NgModule } from '@angular/core';
import { PageSelectorComponent } from './page-selector.component';
import { MatInputModule } from '@angular/material/input';
import { CoreModule } from '../../../core/core.module';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        PageSelectorComponent,
    ],
    imports: [
        MatInputModule,
        CoreModule,
        CommonModule
    ],
    exports: [
        PageSelectorComponent
    ]
})
export class PageSelectorModule { }
