<div class="item-data">
  <app-icon-two-images bigImg="unlocked" smallImg="check2" [isSuccess]="true">
  </app-icon-two-images>
  <div class="item-details" (click)="goToAction()">
      <div class="item-details-title">
        You now have access to {{notification.data?.access_level === 'workspace_access' ? 'use ' : ''}}<strong>{{notification.data?.link.title}}</strong>.
      </div>
      <div class="item-details-time" *ngIf="company$ | async as company">
          {{notification.created_at | dateFormat : null : company.config.timezone : company.config.timeformat}}
      </div>
  </div>
</div>
<app-notification-bullet [notification]="notification"></app-notification-bullet>