<app-loading-animation [@inOutAnimation] *ngIf="!disableLoadingAnimation && !cmsLoaded"></app-loading-animation>
<div class="login-container flex-100 flex-wrap"
     [@inOutAnimation]
     *ngIf="cmsLoaded">
  <div class="left-side" [class.small-width]="brandingData && brandingData.cms_page">
    <div class="flex-col flex-100 justify-between items-center">
      <div class="header">
        <img *ngIf="!brandingData || !brandingData.company?.image_url" src="../../../../../assets/img/logo-new.png"/>
        <img *ngIf="brandingData && brandingData.company?.image_url" src="{{brandingData.company.image_url}}"/>
      </div>
      <ng-template [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{$implicit: brandingData}"></ng-template>
      <div class="footer copyright">
        <div>
          <a target="_blank" href="http://www.sware.com/">
            <img alt="btr logo" src="../../../../../assets/img/logo-new.png"></a><br/>
          <p class="caption-normal">Copyright © {{currentYear}} Sware, Inc. All rights reserved.</p>
        </div>
      </div>
    </div>
  </div>

  <div class="right-side flex-col"
       *ngIf="brandingData && brandingData.cms_page">
    <div class="image flex-row flex-wrap justify-center items-start height-100" [style.background-image]="'url(' + brandingData.cms_page.image_url + ')'"></div>
  </div>
</div>

