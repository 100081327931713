import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { IFilterDataValues, MyTasksResponseInterface } from '../interfaces';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class TasksService {

  constructor(private apiService: ApiService, private store: Store) { }

  getTasks(offset: number, limit: number, filters?: IFilterDataValues) : Observable<MyTasksResponseInterface> {
    return this.apiService.post(`/api/my-tasks/${offset}/${limit}`, filters);
  }

  getAssignments(offset: number, limit: number, filters?: IFilterDataValues) : Observable<MyTasksResponseInterface> {
    return this.apiService.post(`/api/my-assignments/${offset}/${limit}`, filters);
  }

  public printMyTasks(offset: number, limit: number, exportFormat: string, filters?: IFilterDataValues) {
    return this.apiService.postToBackendPdfService(`/api/my-tasks/${offset}/${limit}/export/${exportFormat}`, filters);
  }
  public printMyAssignments(offset: number, limit: number, exportFormat: string, filters?: IFilterDataValues) {
    return this.apiService.postToBackendPdfService(`/api/my-assignments/${offset}/${limit}/export/${exportFormat}`, filters);
  }
}
