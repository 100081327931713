<div class="item-data">
  <app-icon-two-images bigImg="unlocked" [isSuccess]="true">
  </app-icon-two-images>
  <div class="item-details" (click)="goToOriginalWorkflow(notification)">
      <div class="item-details-title">
        <strong>{{notification?.data?.action_user | displayName}}</strong> has requested access to {{notification.data?.access_level === 'workspace_access' ? 'use ' : ''}}<strong>{{notification.data?.link.title}}</strong>.
      </div>
      <ng-container *ngIf="notification.data?.comment">
        <div class="item-details-subtitle margin-top-10">With comment:</div>
          <div class="item-details-comment"
              matTooltip="{{notification.data?.comment}}"
              matTooltipClass="btr-basic"
              appTooltipSize
              matTooltipPosition="right"
              appTooltipWhenEllipsis>
          "{{notification.data?.comment}}"
        </div>
      </ng-container>
      <div class="item-details-time" *ngIf="company$ | async as company">
          {{notification.created_at | dateFormat : null : company.config.timezone : company.config.timeformat}}
      </div>
  </div>
</div>
<app-notification-bullet [notification]="notification"></app-notification-bullet>