import { MomentDateAdapter } from '@angular/material-moment-adapter';

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const DATEPICKER_DATE_FORMATS = {
    parse: {
      dateInput: 'LL',
    },
    display: {
      dateInput: 'LL',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
};

export class CustomDateAdapter extends MomentDateAdapter {
    getDayOfWeekNames() {
      return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(val => val.toUpperCase());
    }
}

export function updateDatepickerFormat(dateInput:string){
  DATEPICKER_DATE_FORMATS.display.dateInput = dateInput;
}