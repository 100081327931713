import { Selector } from '@ngxs/store';
import { AsyncFileDynamoDBResponse } from './async-pdf.model';
import { AsyncPdfState, AsyncPdfStateModel } from './async-pdf.state';

export class AsyncPdfSelectors {

  @Selector([AsyncPdfState])
  static getGeneratedFiles(state: AsyncPdfStateModel) {
    return state.files;
  }

  @Selector([AsyncPdfSelectors.getGeneratedFiles])
  static isBannerDisplayed(files: AsyncFileDynamoDBResponse[]) {
    return files.length > 0;
  }
}
