import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { MaterialModule } from '@core/modules/material.module';
import { CellHighlightComponent } from './cell-highlight.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
  ],
  declarations: [
    CellHighlightComponent,
  ],
  exports: [
    CellHighlightComponent,
  ],
  providers: []
})
export class CellHighlightModule {}
