<ng-container *ngIf="isBtrAdmin !== undefined">
  <ng-container *ngIf="isBtrAdmin || (company$ | async)?.uuid">
    <div class="notifications-menu-container" *ngIf="filteredNotifications$ | async as notifications">
      <div class="notifications-header flex-row justify-between items-center">
        <div class="notifications-title">Notifications</div>
        <span *ngIf="!isBtrAdmin" class="notifications-settings icon-n-gear" (click)="goToSettings()"></span>
      </div>
      <div class="no-tasks-container" *ngIf="!notifications.length">
        <span class="icon-n-notifications"></span>
        <div>There are no notifications</div>
      </div>
      <div class="notification-days" #notifRef>
        <div class="notification-day" *ngFor="let notification of notifications">
          <div class="notification-day-label">{{notification.date | dateToDay:true}}</div>
          <div class="notification-day-item" *ngFor="let item of notification.details">
            <ng-template [ngComponentOutlet]="components[item.template]" [ndcDynamicInputs]="{notification: item}">
            </ng-template>
          </div>
        </div>
        <div class="loading" *ngIf="isLoading$ | async">
          <app-loading-animation [withLogo]="false" [size]="24" [isLocal]="true">
          </app-loading-animation>
          <span>Loading notifications</span>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
