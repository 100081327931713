import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NotificationResponse } from '@core/interfaces';
import { NotificationMenuItemComponent } from '../../notification-menu-item/notification-menu-item.component';

@Component({
  selector: 'app-market-package-link-initiator',
  templateUrl: './market-package-link-initiator.component.html',
  styleUrls: ['./market-package-link-initiator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MarketPackageLinkInitiatorComponent extends NotificationMenuItemComponent {
  @Input() notification: NotificationResponse;
}
