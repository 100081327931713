<div class="item-data">
    <ng-container>
        <app-icon-two-images bigImg="parents" smallImg="close2" [isSuccess]="false">
        </app-icon-two-images>
    </ng-container>
    <div class="item-details" (click)="goToParentChildCompanies(notification)">
        <div class="item-details-title">

            Your 
            <ng-container *ngIf="notification.template === NotificationTemplate.RelationRemovedToChildCompany">
                <span> parent </span>
            </ng-container> 
            <ng-container *ngIf="notification.template === NotificationTemplate.RelationRemovedToParentCompany">
                <span> child </span>
            </ng-container>
            company <strong>{{notification.data?.company_name}}</strong> was removed by Res_Q Admin.
        </div>
        <div class="item-details-time" *ngIf="company$ | async as company">
            {{notification.created_at | dateFormat : null : company.config.timezone : company.config.timeformat}}
        </div>
    </div>
</div>
<app-notification-bullet [notification]="notification"></app-notification-bullet>