import { WorkflowBaseModel } from '@core/models/workflow-base.model';
import { ICanvasStep, IWorkflowRuntime } from '../interfaces';

export class WorkflowRuntimeModel extends WorkflowBaseModel<IWorkflowRuntime> {
  protected readonly _workflow: IWorkflowRuntime;

  static stripStep(step: ICanvasStep) {
    if (step.step_data) {
      delete step.step_data.executionRequiredMessage;
      delete step.step_data.summaryRequiredMessage;
    }
    return step;
  }

  constructor(workflow: IWorkflowRuntime) {
    super(workflow);
  }
}
