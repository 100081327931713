import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import moment from 'moment';
import { CompanySelectors } from 'src/app/store/company/company.selectors';
import { DateFormatPipe } from '.';

@Pipe({
  name: 'dateToDay',
})
export class DateToDayPipe implements PipeTransform {
  today = new Date();
  yesterday = moment().subtract(1, 'day');
  dateFormatPipe = new DateFormatPipe();

  constructor(private store: Store) { }

  transform(date: string, isCompanyformat?: boolean): string {
    //  Remove warning about the date not having a proper ISO format.
    //  If we change the format, the comparison function no longer works
    moment.suppressDeprecationWarnings = true;
    const companyConfig = this.store.selectSnapshot(CompanySelectors.getCurrentCompany)?.config;
    switch (true) {
      case moment(date).isSame(this.today, 'day'):
        return 'Today';
      case moment(date).isSame(this.yesterday, 'day'):
        return 'Yesterday';
      default:
        return isCompanyformat && companyConfig ?
          this.dateFormatPipe.transform(date, companyConfig.dateformat, companyConfig.timezone, companyConfig.timeformat) :
          date;
    }
  }
}
