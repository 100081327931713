export enum SignaturePasswordType {
  Create = 'Create',
  Change = 'Change',
  Reset = 'Reset'
}

export enum SignaturePasswordLabel {
  Create = 'Setup',
  Change = 'Change',
}

export enum PinCodeType {
  Create = 'Create',
  Change = 'Change',
  Reset = 'Reset',
}
