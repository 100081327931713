import { IFilterSelectValue } from './filter.interface';

export type TaskType = 'all' | 'upcoming' | 'overdue';

export interface ITaskFilter extends IFilterSelectValue {
  count?: number;
  items?: MyTaskInterface[];
}

interface MyTasksBase {
  assignation_date: string;
  system_name: string;
  system_uuid: string;
  type: string;
  type_label: string;
  uuid: string;
  workflow_display_label: string;
  workflow_document_category: string;
  workflow_root_uuid: string;
  workflow_status: string;
  workflow_status_label: string;
  workflow_title: string;
  workflow_uuid: string;
  workflow_version: number;
}

export interface MyTaskInterface extends MyTasksBase {
  target_date: string;
  target_date_status: string;
  target_date_status_label: string;
  comment?: string;
  status: string;
  status_label: string;
  original_workflow_root_uuid?: string;
  original_workflow_uuid?: string;
}

export interface MyTasksResponseInterface {
  data: MyTaskInterface[];
  total: number;
}


export enum ShortcutFilter {
  All = 'all',
  Overdue = 'overdue',
  Upcoming = 'upcoming',
}


export interface MyAssignmentsInterface extends MyTasksBase {
  assigner_email: string;
  assigner_first_name: string;
  assigner_has_mfa: boolean;
  assigner_is_active: boolean;
  assigner_is_sso: boolean;
  assigner_last_name: string;
  assigner_uuid: string;
}
export interface MyAssignmentsResponseInterface {
  data: MyAssignmentsInterface[];
  total: number;
}