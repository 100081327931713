import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-two-images',
  templateUrl: './icon-two-images.component.html',
  styleUrls: ['./icon-two-images.component.scss']
})
export class IconTwoImagesComponent {
  @Input() bigImg: string;
  @Input() smallImg: string;
  @Input() isSuccess: boolean;

}
