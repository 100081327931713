import { Selector } from "@ngxs/store";
import { ConfigState, ConfigStateModel } from "./config.state";

export class ConfigSelectors {

  @Selector([ConfigState])
  static syncfusionKey(state: ConfigStateModel) {
    return state.syncfusionKey;
  }

  @Selector([ConfigState])
  static webviewerKey(state: ConfigStateModel) {
    return state.webviewerKey;
  }

  @Selector([ConfigState])
  static videoRecordingSettings(state: ConfigStateModel) {
    return state.videoRecordingSettings;
  }
}
