import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NotificationResponse, NotificationTemplate } from '@core/interfaces';
import { NotificationMenuItemComponent } from '../../notification-menu-item/notification-menu-item.component';

@Component({
  selector: 'app-report-completed',
  templateUrl: './report-completed.component.html',
  styleUrls: ['./report-completed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportCompletedComponent extends NotificationMenuItemComponent {
  @Input() notification: NotificationResponse;
  readonly NotificationTemplate = NotificationTemplate;
}
