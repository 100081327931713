import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { combineLatest, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { UserSelectors } from 'src/app/store/user/user.selectors';

@Injectable()
export class AccessLevelGuardService  {
  constructor(private store: Store) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    return combineLatest([
      this.store.select(UserSelectors.getCurrentUser),
      this.store.select(UserSelectors.getUserAccessLevel)
    ]).pipe(
      filter(([user, accessLevel]) => !!user && !!accessLevel),
      map(([currentUser, accessLevel]) => {
        const isAuthenticated = this.store.selectSnapshot(UserSelectors.isLoggedIn);
        if (!isAuthenticated || !currentUser || route.data === null || route.data.accessLevels === null) {
          return false;
        }
        return route.data.accessLevels.some(r => accessLevel === r);
      }),
      catchError(error => {
        return of(false);
      }));
  }
}
