import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({
  name: 'dateGMTFormat'
})
export class DateGMTFormatPipe implements PipeTransform {
  transform(date: string): string {
    if (!date) {
      return '-';
    }

    date = moment(date).tz('gmt').format('YYYY-MM-DD HH:mm:ss');

    return date + ' GMT';
  }
}
