import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { RoutingService, AclService, HelperService } from '@core/services';
import { UserSelectors } from '@store/user/user.selectors';
import { Store } from '@ngxs/store';
import { IWorkflowType, TemplateAccessRequestType, WorkflowAccessListResponse } from '@core/interfaces';
import { forkJoin } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Injectable()
export class RequestAccessGuardService  {
  constructor(
    private routingService: RoutingService,
    private aclService: AclService,
    private router: Router,
    private store: Store,
    private helperService: HelperService
  ) {}

  accessList: WorkflowAccessListResponse;

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) {
    return new Promise<boolean>((resolve, reject) => {
      if (!route?.params?.workflowId) {
          resolve(false);
          this.router.navigate([this.routingService.BLANK.url()]);
      } else {
        return this.store.select(UserSelectors.getCurrentUser).pipe(
          filter(user => !!user),
          take(1)
        ).subscribe((currentUser) => {

          if (currentUser.is_btr_admin || route?.params?.workflowId === 'null') {
            resolve(true);
          } else {
            forkJoin([
              this.aclService.getAccessList(
                state.url.includes('templates') ? IWorkflowType.Template : IWorkflowType.Runtime,
                route.params.workflowId, TemplateAccessRequestType.ACCESS
              ),
              this.aclService.getCurrentUserGroup(),
            ]).subscribe(([accessList, userGroups]) => {
              this.accessList = accessList;
              let hasAccess = false;
              if (!accessList.users.length && !accessList.owner) {
                hasAccess = true;
                resolve(true);
              } else {
                hasAccess = this.helperService.userIsInAccessList(accessList, currentUser.uuid, userGroups);

                if (!hasAccess) {
                  resolve(false);
                  this.router.navigate([this.routingService.REQEURST_ACCESS.url()],
                    {
                      queryParams: {
                        type: state.url.includes('templates') || state.url.includes('canvas') ? 'Templates' : 'Documents',
                        workflowId: route?.params?.workflowId,
                        versionId: route?.params?.versionId,
                      }
                    });
                } else {
                  resolve(true);
                }
              }
            }, error => {
              this.accessList = null;
              resolve(false);
              this.router.navigate([this.routingService.BLANK.url()]);
            });
          }
        });
      }
    });
  }
}
