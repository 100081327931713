import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges, Output, SimpleChanges, ViewChild
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

export interface IPageChangeEvent {
  pageIndex: number;
  pageSize: number;
}

@Component({
  selector: 'app-page-selector',
  templateUrl: './page-selector.component.html',
  styleUrls: ['./page-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageSelectorComponent implements AfterViewInit, OnChanges {
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('pageNum') pageNum: ElementRef;
  _length = 0;
  @Input() set length(val) {
    this._length = val;
    this.cdr.markForCheck();
    setTimeout(() => {
      this.numberOfPages = this.paginator?.getNumberOfPages();
      // If this.numberOfPages < this.pageIndex (some rows were removed), make sure to select last page
      if (this.pageIndex > 0 && this.pageIndex + 1 > this.numberOfPages) {
        this.onPageNumberChange(this.numberOfPages);
      }
      this.cdr.markForCheck();
    }, 0);
  }

  get length() {
    return this._length;
  }
  @Input() showProgressBar: boolean;
  @Input() pageIndex = 0;
  @Input() disabled = false;
  @Input() hidePageSize = false;
  @Input() pageSize = 10;

  @Output() pageChange = new EventEmitter<IPageChangeEvent>();

  numberOfPages: number;
  maxInt = Number.MAX_SAFE_INTEGER;
  loadingNewRows: boolean;
  pageError: boolean;

  constructor(private cdr: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    this.numberOfPages = this.paginator.getNumberOfPages();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (Number.isSafeInteger(changes?.pageIndex?.currentValue) && this.paginator) {
      this.loadingNewRows = true;
      this.pageError = false;
      this.paginator.pageIndex = changes?.pageIndex?.currentValue;
      this.numberOfPages = this.paginator.getNumberOfPages();
      setTimeout(() => {
        this.loadingNewRows = false;
        this.cdr.markForCheck();
      }, 1000);
    }
  }

  onPageNumberChange(pageNumber: number): void {
    this.pageIndex = pageNumber - 1;
    if (this.pageIndex + 1 < 1 || this.pageIndex + 1 > this.numberOfPages) {
      this.pageError = true;
    } else {
      this.pageError = false;
      this.paginator.pageIndex = pageNumber - 1;
      this.pageChange.emit({
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      });
      this.numberOfPages = this.paginator.getNumberOfPages();
      this.paginator.pageIndex = this.pageIndex;
    }
  }

  changePage(event: PageEvent): void {
    const resetPageSize = this.pageSize !== event.pageSize;
    this.pageSize = event.pageSize;
    const difference = event.pageIndex - event.previousPageIndex;
    if (!this.pageError) {
      event.pageIndex = resetPageSize ? 0 : this.pageIndex + difference;
    } else {
      event.pageIndex = event.previousPageIndex;
      this.paginator.pageIndex = event.pageIndex;
      return;
    }
    if (!this.disabled) {
      this.loadingNewRows = true;
      this.pageError = false;
      this.pageIndex = event.pageIndex;
      this.paginator.pageIndex = event.pageIndex;
      this.pageChange.emit({
        pageIndex: this.pageIndex,
        pageSize: event.pageSize
      });
      this.numberOfPages = this.paginator.getNumberOfPages();
      setTimeout(() => {
        this.loadingNewRows = false;
        this.cdr.markForCheck();
      }, 1000);
    } else {
      this.pageIndex = event.previousPageIndex;
      this.paginator.pageIndex = event.previousPageIndex;
      this.pageChange.emit({
        pageIndex: this.pageIndex,
        pageSize: event.pageSize
      });
    }
  }

}
