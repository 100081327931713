import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@core/modules/material.module';
import { SharingInputComponent } from './sharing-input.component';
import { SharingModalModule } from './sharing-modal/sharing-modal.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharingModalModule,
  ],
  declarations: [
    SharingInputComponent
  ],
  exports: [
    SharingInputComponent
  ],
  providers: []
})
export class SharingInputModule {
}
