import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ButtonsModule } from '../buttons/buttons.module';
import { SignaturePasswordBannerComponent } from './signature-password-banner.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonsModule,
    MatProgressBarModule
  ],
  declarations: [
    SignaturePasswordBannerComponent
  ],
  exports: [
    SignaturePasswordBannerComponent
  ],
  providers: []
})
export class SignaturePasswordBannerModule {
}
