import { Injectable } from '@angular/core';
import { IExtraQueryParameters, IFilterDataValues, ISortQueryParameters } from '../interfaces';
import { ApiService } from './api.service';
import { ActiveSectionCategory } from '../../audit-trail/audit-trail.interface';

@Injectable({
  providedIn: 'root',
})
export class AuditTrailService {
  auditTrailPath = () => `/api/audit-trail/all`;
  auditTrailWorkflow = (category, entityId) => `/api/audit-trail/${category}/${entityId}`;
  auditTrailTypes = (category) => `/api/audit-trail-types/${category}`;

  constructor(private apiService: ApiService) {}

  public getAuditTrailTypes(category: string, sortQueryParams?: ISortQueryParameters, extraQueryParams?: IExtraQueryParameters) {
    const queryParams = this.apiService.addSortQueryParams(sortQueryParams);

    return this.apiService.get(this.auditTrailTypes(category), this.apiService.addExtraQueryParams(queryParams, extraQueryParams));
  }

  public getAuditTrail(offset: number, limit: number, filters: IFilterDataValues) {
    return this.apiService.post(`${this.auditTrailPath()}/${offset}/${limit}`, filters);
  }

  public getWorkflowAuditTrail(offset: number, limit: number, category: ActiveSectionCategory, entityID: string, filters: IFilterDataValues) {
    return this.apiService.post(`${this.auditTrailWorkflow(category, entityID)}/${offset}/${limit}`, filters);
  }
}
