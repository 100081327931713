import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileThumbnailListComponent } from './file-thumbnail-list.component';
import { FileThumbnailOrIconModule } from '../file-thumbnail-or-icon/file-thumbnail-or-icon.module';
import { ButtonsModule } from '../buttons/buttons.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FileThumbnailOrIconModule,
    ButtonsModule
  ],
  declarations: [
    FileThumbnailListComponent
  ],
  exports: [
    FileThumbnailListComponent
  ]
})
export class FileThumbnailListModule {
}
