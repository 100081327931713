import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NotificationResponse, NotificationTemplate } from '@core/interfaces';
import { NotificationMenuItemComponent } from '../../notification-menu-item/notification-menu-item.component';

@Component({
  selector: 'app-system-periodic-review',
  templateUrl: './system-periodic-review.component.html',
  styleUrls: ['./system-periodic-review.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SystemPeriodicReviewComponent extends NotificationMenuItemComponent {
  @Input() notification: NotificationResponse;
  readonly NotificationTemplate = NotificationTemplate;
}
