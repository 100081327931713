import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Company } from '@core/models';
import { CompaniesService } from '@core/services';
import { Store } from '@ngxs/store';
import { GetCurrentCompany } from '@store/company/company.actions';
import { Observable, of } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { CompanySelectors } from 'src/app/store/company/company.selectors';
import { UserSelectors } from '@store/user/user.selectors';
import { AccessLevel } from '@core/constants';

// get company data when a route with that company's uuid is accessed
@Injectable()
export class CompanyDetailsResolver  {
  constructor(
    private companiesService: CompaniesService,
    private store: Store
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Company> {
    let company$;
    // Access page from BTR Admin
    if (route.paramMap.get('id')) {
      company$ = this.companiesService
        .getCompanyById(route.paramMap.get('id'))
        .pipe(
          take(1),
          map(dto => new Company(dto)),
          tap(company => this.store.dispatch(new GetCurrentCompany(company)))
        );
    } else {
      this.store.select(UserSelectors.getCurrentUser).pipe(
        filter(user => !!user),
        take(1)
      ).subscribe((user) => {
        const userAccessLevel = this.store.selectSnapshot(UserSelectors.getUserAccessLevel);
        // Access canvas page from marketplace package from BTR Admin
        if (userAccessLevel === AccessLevel.BTRAdmin) {
          company$ = of(null)
        } else {
          // Access page from Company Admin
          const company = this.store.selectSnapshot(CompanySelectors.getCurrentCompany);
          if (!company.uuid) {
            return this.store.select(CompanySelectors.getCurrentCompany)
              .pipe(
                filter(c => !!c.uuid),
                take(1)
              );
          }
          return this.store.selectOnce(CompanySelectors.getCurrentCompany);
        }
      })
    }
    return company$;
  }
}
