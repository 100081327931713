import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  public videoPath = '/api/syncfusion-image-upload/';


  constructor(private apiService: ApiService) {
  }

  public getStreamingLink(image: string) {
    return this.apiService.get(`${this.videoPath}${image}`);
  }

  public canPlayType(type: string): boolean {
    const vid = document.createElement('video');
    return vid.canPlayType(type) ? true  : false;
  }
}
