import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { IApprovalOrReviewRequestSeriesUser } from '@core/interfaces';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentAssignmentsService {
  private path = '/api/document-assignments';

  constructor(private apiService: ApiService) {}

  public getAssignmentsList(workflowUuid: string, assignmentType: string) {
    return this.apiService.get(`${this.path}/list/${workflowUuid}/${assignmentType}`);
  }

  public getAssignmentsLists(workflowUuid: string, assignmentTypes: string[]) {
    const calls = assignmentTypes.map((assignmentType) => this.getAssignmentsList(workflowUuid, assignmentType));
    return forkJoin(calls); 
  }

  public saveAssignmentsList(workflowUuid: string, assignmentType: string, series: IApprovalOrReviewRequestSeriesUser[][]) {
    return this.apiService.post(`${this.path}/list/${workflowUuid}/${assignmentType}`, {series});
  }
}
