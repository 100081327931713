export enum AsyncFileResponseType {
  PDF_TO_IMAGE = 'pdf_to_image',
  ASYNC_PDF = 'async_pdf'
}

export enum AsyncFileResponseStatus {
  DONE = 'Done',
  FAILED = 'Failed',
  PENDING = 'Pending'
}

export interface AsyncFileResponse {
  company_uuid: string;
  workflow_uuid: string;
  workflow_version_uuid: string;
  step_uuid: string;
  display_label: string;
  version: number;
  images: string[];
  file_url: string;
  row_uuid?: string;
}
export interface AsyncFileDynamoDBResponse extends AsyncFileResponse {
  batch_id: string;
  batch_type: AsyncFileResponseType;
  batch_timestamp: string;
  batch_end: string;
  user_uuid: string;
  status: AsyncFileResponseStatus;
  error_message: string;
  document_title: string;
  document_type?: string;
  sdox_document_category?: string;
}

export interface AsyncFileDynamoDBResponseLocal extends AsyncFileDynamoDBResponse {
  isLoading?: boolean;
}

export enum AsyncFileExportType {
  Word = 'word',
  Pdf = 'pdf'
}
