import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NotificationResponse } from '@core/interfaces';
import { NotificationMenuItemComponent } from '../../notification-menu-item/notification-menu-item.component';

@Component({
  selector: 'app-signature-request-user-removed',
  templateUrl: './signature-request-user-removed.component.html',
  styleUrls: ['./signature-request-user-removed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignatureRequestUserRemovedComponent extends NotificationMenuItemComponent {
  @Input() notification: NotificationResponse;
}
