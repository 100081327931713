import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CognitoHostedIdentityProviderLabel, ICompanyPoolIdp } from '@core/interfaces';

@Component({
  selector: 'app-identity-provider-selection-modal',
  templateUrl: './identity-provider-selection-modal.component.html',
  styleUrls: ['./identity-provider-selection-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IdentityProviderSelectionModalComponent {
  public idpData: ICompanyPoolIdp[];
  readonly CognitoHostedIdentityProviderLabel = CognitoHostedIdentityProviderLabel;

  constructor(
    public dialogRef: MatDialogRef<IdentityProviderSelectionModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { idpData: ICompanyPoolIdp[] },
  ) {
    this.idpData = data.idpData;
  }

  close(): void {
    this.dialogRef.close();
  }

  selectIdp(provider: ICompanyPoolIdp) {
    this.dialogRef.close(provider);
  }
}
