import { Injectable } from '@angular/core';
import { IApiPaginatedDataBase } from '@core/interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICompanyGroup } from '../interfaces/company-group.interface';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyGroupsService {
  private readonly companyGroupsPath = '/api/company-group';

  constructor(private apiService: ApiService) { }

  getCompanyGroups(offset: number, limit: number): Observable<ICompanyGroup[]> {
    return this.apiService.get<ICompanyGroup>(`${this.companyGroupsPath}/index/${offset}/${limit}`).pipe(
      map((res: IApiPaginatedDataBase<ICompanyGroup>) => res.data)
    );
  }

  getCompanyGroup(groupUuid: string, permissions = []): Observable<ICompanyGroup> {
    return this.apiService.get(`${this.companyGroupsPath}/${groupUuid}`,
      this.apiService.addExtraQueryParams(undefined, { searched_permissions: permissions }));
  }

  createCompanyGroup(companyGroupData: ICompanyGroup) {
    return this.apiService.post(this.companyGroupsPath, companyGroupData);
  }

  updateCompanyGroup(companyGroupData: ICompanyGroup) {
    return this.apiService.put(`${this.companyGroupsPath}/${companyGroupData.uuid}`, companyGroupData);
  }

  deleteCompanyGroup(companyGroupData: ICompanyGroup) {
    return this.apiService.delete(`${this.companyGroupsPath}/${companyGroupData.uuid}`);
  }
}
