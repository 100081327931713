import { NodeModel } from '@syncfusion/ej2-angular-diagrams';
import { IOutputData } from 'angular-split';
import { ICanvasStep, IWorkflowTemplate } from '../../core/interfaces';
import { FeData } from '../../canvas/shared/models/fe-data.model';

export class SaveTemplate {
  static readonly type = '[Canvas] SaveTemplate';
  constructor(public template?: IWorkflowTemplate) { }
}

export class SetLoadedTemplate {
  static readonly type = '[Canvas] SetLoadedTemplate';
  constructor(public template: IWorkflowTemplate) { }
}
export class UpdateTemplate {
  static readonly type = '[Canvas] UpdateTemplate';
  constructor(public template: IWorkflowTemplate) { }
}
export class HighlightNode {
  static readonly type = '[Canvas] HighlightNode';
  constructor(public node: NodeModel) { }
}
export class SetLoading {
  static readonly type = '[Canvas] SetLoading';
  constructor(public loading: boolean) { }
}
export class ReloadTemplate {
  static readonly type = '[Canvas] ReloadTemplate';
}
export class PublishTemplateSuccess {
  static readonly type = '[Canvas] PublishTemplateSuccess';
}
export class OnGutterDblClick {
  static readonly type = '[Canvas] OnGutterDblClick';
  constructor(public event: IOutputData) { }
}
export class UpdateDiagramSwitchPorts {
  static readonly type = '[Canvas] UpdateDiagramSwitchPorts';
}

export class CollectionChanged {
  static readonly type = '[Canvas] CollectionChanged';
  constructor(public template: IWorkflowTemplate) { }
}
export class AddTemplateStep {
  static readonly type = '[Canvas] AddTemplateStep';
  constructor(public step: ICanvasStep) { }
}
export class SelectNodeGid {
  static readonly type = '[Canvas] SelectNodeGid';
  constructor(public gid: string) { }
}
export class UpdateStep {
  static readonly type = '[Canvas] UpdateStep';
  constructor(public wfStepIndex: number, public updatedStep1: ICanvasStep) { }
}

export class GenerateStepUuid {
  static readonly type = '[Canvas] GenerateStepUuid';
}
export class SelectedNodeData {
  static readonly type = '[Canvas] SelectedNodeData';
  constructor(public nodeData: FeData) { }
}

export class SetGeneralFeEnableStatusCanvas {
  static readonly type = '[Canvas] SetGeneralFeEnableStatusCanvas';
  constructor(public stepUuid: string, public enable: boolean = true) {}
}

export class ResetGeneralFeEnableStatusCanvas {
  static readonly type = '[Canvas] ResetGeneralFeEnableStatusCanvas';
}