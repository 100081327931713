import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { MaterialModule } from '@core/modules/material.module';
import { ButtonsModule } from '@shared/components/buttons/buttons.module';
import { PopupModule } from '../popup/popup.module';
import { UserAvatarComponent } from './user-avatar.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    CoreModule,
    OverlayModule,
    PopupModule,
    ButtonsModule,
  ],
  declarations: [
    UserAvatarComponent
  ],
  exports: [
    UserAvatarComponent
  ],
  providers: []
})
export class UserAvatarModule {
}
