import { Injectable } from '@angular/core';
import { ICompanySystem } from '@core/interfaces';
import { SystemService } from '@core/services';
import { MarketplaceService } from '@core/services/marketplace.service';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { CreateGlobalSystem, DeleteGlobalSystem, EditGlobalSystem, GetGlobalSystems, GetSystems, SetLoading } from './system.actions';
import { SystemStateModel } from './system.model';

@State<SystemStateModel>({
  name: 'SystemState',
  defaults: {
    loading: false,
    systems: [],
    globalSystems: [],
    total: 0,
    totalGlobalItems: 0
  }
})
@Injectable()
export class SystemState {

  constructor(private systemService: SystemService,
    private marketplaceService: MarketplaceService) { }

  @Action(GetSystems, { cancelUncompleted: true })
  getSystems({ patchState }: StateContext<SystemStateModel>, { offset, limit, sortQueryParams }: GetSystems) {
    patchState({
      loading: true
    });
    return this.systemService.getSystems(offset, limit, sortQueryParams).pipe(tap(res => {
      patchState({
        loading: false,
        systems: res.data,
        total: res.total
      });
    }));
  }

  @Action(GetGlobalSystems, { cancelUncompleted: true })
  getGlobalSystems({ patchState }: StateContext<SystemStateModel>, { offset, limit, sortQueryParams, hasPackage }: GetGlobalSystems) {
    patchState({ loading: true });
    return this.marketplaceService.getGlobalSystems(offset, limit, sortQueryParams, hasPackage)
      .pipe(tap(res => {
        patchState({
          globalSystems: res.data,
          loading: false,
          totalGlobalItems: res.total
        });
      }));
  }

  @Action(CreateGlobalSystem, { cancelUncompleted: true })
  createGlobalSystem({ patchState }: StateContext<SystemStateModel>, { system }) {
    patchState({ loading: true });
    return this.marketplaceService.createGlobalSystem(system)
      .pipe(tap((result: ICompanySystem) => {
        patchState({ loading: false });
      }, err => {
        patchState({ loading: false });
      }));
  }

  @Action(EditGlobalSystem, { cancelUncompleted: true })
  editGlobalSystem({ patchState }: StateContext<SystemStateModel>, { uuid, system }) {
    patchState({ loading: true });
    return this.marketplaceService.updateGlobalSystem(uuid, system)
      .pipe(tap((result: ICompanySystem) => {
        patchState({ loading: false });
      }, err => {
        patchState({ loading: false });
      }));
  }

  @Action(DeleteGlobalSystem, { cancelUncompleted: true })
  deleteGlobalSystem({ patchState }: StateContext<SystemStateModel>, { systemUuid, deletePackages }) {
    patchState({ loading: true });
    return this.marketplaceService.deleteGlobalSystem(systemUuid, deletePackages)
      .pipe(tap((result: { success: true }) => {
        patchState({ loading: false });
      }, err => {
        patchState({ loading: false });
      }));
  }

  @Action(SetLoading, { cancelUncompleted: true })
  setLoading({ patchState }: StateContext<SystemStateModel>, { isLoading }) {
    patchState({
      loading: isLoading
    });
  }
}
