import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonsModule } from '@shared/components/buttons/buttons.module';
import { DynamicModule } from 'ng-dynamic-component';
import { CoreModule } from '@core/core.module';
import { MaterialModule } from '@core/modules/material.module';
import { QuestionsPlayerComponent } from './questions-player.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    CoreModule,
    DynamicModule,
    ButtonsModule,
  ],
  declarations: [
    QuestionsPlayerComponent
  ],
  exports: [
    QuestionsPlayerComponent
  ],
  providers: []
})
export class QuestionsPlayerModule {
}
