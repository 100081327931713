import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@core/modules/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '@shared/components/buttons/buttons.module';
import { ComponentsModule } from '@shared/components/components.module';
import { CoreModule } from '@core/core.module';
import { CmsComponent } from './cms.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    CoreModule,
    ButtonsModule,
  ],
  exports: [
    CmsComponent
  ],
  declarations: [
    CmsComponent
  ],
  providers: []
})
export class CmsModule {
}
