import { ImpactAssessmentEditorView } from '@core/constants/impact-assessment-editor-view.enum';
import { IImpactAssessmentLite, IImpactAssessmentStepData, ImpactAssessmentStep } from '@core/interfaces';

export const impactAssessmentStateDefaults: ImpactAssessmentStateModel = {
  loading: false,
  currentStep: {
    step: ImpactAssessmentStep.Selector
  },
  stepHistory: [],
  editorMode: ImpactAssessmentEditorView.Edit,
  iaConfig: null,
  iaLink: null
};

export interface ImpactAssessmentStateModel {
  loading: boolean;
  currentStep: IImpactAssessmentStepData;
  stepHistory: ImpactAssessmentStep[];
  editorMode: ImpactAssessmentEditorView;
  iaConfig: IImpactAssessmentLite;
  iaLink: IImpactAssessmentLite;
}
