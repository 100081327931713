import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { HelperService, RoutingService } from '@core/services';
import { UserSelectors } from '@store/user/user.selectors';
import { filter, take } from 'rxjs/operators';
import { Store } from '@ngxs/store';

@Injectable()
export class AccessMarketplaceGuardService  {
  constructor(
    private helperService: HelperService,
    private routingService: RoutingService,
    private router: Router,
    private store: Store,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) {
    return new Promise<boolean>((resolve, reject) => {
      this.store.select(UserSelectors.getCurrentUser).pipe(
        filter(user => !!user),
        take(1)
      ).subscribe((user) => {
        if (state.url.indexOf('/uploads') > -1 &&
          (this.helperService.isActionDisabledByEnvironment() || this.helperService.canViewOnlyMyPackages(user))
        ) {
          this.router.navigate([this.routingService.MARKETPLACE_MY_PACKAGES_DOWNLOADS.url()]);
          resolve(false);
        }
        resolve(true);
      }, error => {
        reject();
      });
    });
  }
}
