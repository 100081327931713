import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CanvasFunctionalElementType } from '../../canvas/canvas.enum';
import { IWorkflowRuntime } from '../interfaces';
import { Company, IUser } from '../models';
import { forkJoin, Observable } from 'rxjs';
import { AsyncFileDynamoDBResponse, AsyncFileResponseStatus, AsyncFileExportType } from '@store/async-pdf/async-pdf.model';
import { finalize, take, tap } from 'rxjs/operators';
import { cloneDeep, some } from 'lodash';
import { CanvasService } from '@core/services/canvas.service';
import { PrintingService } from '@core/services/printing.service';

@Injectable({
  providedIn: 'root',
})
export class PrintingHelperService {

  constructor(
    private toastr: ToastrService,
    private canvasService: CanvasService
  ) {
  }

  hasPdfGenerationInProgress (generatedFiles: AsyncFileDynamoDBResponse[], workflow: IWorkflowRuntime): boolean {
    return !!generatedFiles.find(file => file.workflow_uuid === workflow.version_root_uuid
      && file.workflow_version_uuid === workflow.uuid
      && file.status === AsyncFileResponseStatus.PENDING
    );
  }

  /*
  * Returns "true" if the external files are uploaded
  * */
  checkAllExternalFilesAreUploaded(workflow: IWorkflowRuntime): Promise<boolean> {
    return new Promise<boolean>(((resolve, reject) => {
      const externalStepCalls: Observable<{ data }>[] = [];
      workflow.steps.forEach(step => {
        if (step.fe_type === CanvasFunctionalElementType.External) {
          externalStepCalls.push(this.canvasService.getStep(
            workflow.version_root_uuid,
            workflow.uuid,
            CanvasFunctionalElementType.External,
            step.uuid)
            .pipe(take(1),
              tap(item => {
                const wfStep = workflow.steps.find(workflowStep => workflowStep.uuid === item.data.uuid);
                wfStep.step_data = item.data.step_data;
              })),
          );
        }
      });
      if (externalStepCalls.length === 0) {
        resolve(true);
      }
      forkJoin(externalStepCalls).subscribe(res => {
        if (some(res, step => step.data.step_data.files.filter(doc => doc.mime_type === 'application/pdf').length &&
          step.data.step_data.images.length === 0 )) {
          resolve(false);
        }
        resolve(true);
      }, err => {
        reject(err);
      });
    }));
  }

  /*
   * Functionality for downloading SDOX
   * */
  sdoxToPdf(
    printingService: PrintingService,
    loadedWorkflow: IWorkflowRuntime,
    company: Company,
    user: IUser,
    filesAreUploaded: boolean,
    cbAfterDownload,
    cbOnFinalize,
    cbIfAsync,
    exportAction?,
    isAsync = true,
    hasMetaData = false,
    hasMissingFiles = false,
    exportType = AsyncFileExportType.Pdf,
    isArchiveOrPrinting = true,
  ) {
    if (filesAreUploaded) {
      const workflow = cloneDeep(loadedWorkflow);
      workflow.steps.forEach(step => {
        if (step.step_data.files?.length && step.fe_type === 'External') {
          step.fileUrl = `${window.location.protocol}//${window.location.hostname}/latest/api/runtime-user-workflow/${loadedWorkflow.version_root_uuid}/version/${loadedWorkflow.uuid}/External/${step.uuid}/external-upload/${step.step_data.files[0].file_uuid}`;
        }
      });
      workflow.hasMetadata = hasMetaData;
      workflow.hasMissingFiles = hasMissingFiles;
      (() => {
        if (exportAction) {
          return exportAction(workflow);
        } else {
          //  We get the ratio of the first PDF page and send it to the API to customize the print resolution
          const page = document.querySelector('div.page') as HTMLDivElement;
          if (page) {
            workflow.ratio = page.offsetWidth / page.offsetHeight;
          }

          return printingService.printSDOXDocument(
            workflow,
            company,
            user,
            isAsync,
            exportType,
            isArchiveOrPrinting
          ).pipe(take(1));
        }
      })()
        .pipe(
          tap(() => {
            if (isAsync) {
             cbIfAsync();
            }
          }),
          take(1),
          finalize(() => {
            cbOnFinalize();
          })
        )
        .subscribe(
          pdf => cbAfterDownload(pdf),
          (error) => {
            if (!isAsync && (error.status === 408 || error.status === 504)) {
              this.toastr.error(`We cannot generate a preview for this document. Please use the Download PDF option from the actions menu`,
                '',
                { disableTimeOut: true });
            } else {
              this.toastr.error('Error generating SDOX Document!');
            }
          }
        );
    } else {
      this.toastr.error('PDF upload is still processing, please try again later.');
      cbOnFinalize();
    }
  }
}
