import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IApiPaginatedDataBase, ICompanySite, ISortQueryParameters } from '../interfaces';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  private path = '/api/site';

  constructor(private apiService: ApiService) {
  }

  public getSites(offset: number, limit: number, sortQueryParams?: ISortQueryParameters): Observable<IApiPaginatedDataBase<ICompanySite>> {
    return this.apiService.get<ICompanySite[]>(`${this.path}/${offset}/${limit}`, this.apiService.addSortQueryParams(sortQueryParams));
  }

  public addSite(site: ICompanySite) {
    return this.apiService.post(this.path, site);
  }

  public updateSite(site: ICompanySite) {
    return this.apiService.put(`${this.path}/${site.uuid}`, site);
  }

  public deleteSite(site: ICompanySite) {
    return this.apiService.delete(`${this.path}/${site.uuid}`);
  }
}
