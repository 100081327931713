import { Company } from '@core/models';
import { Selector } from '@ngxs/store';
import { CompanySelectors } from '../company/company.selectors';
import { NotificationState, NotificationStateModel } from './notification.state';

export class NotificationSelectors {
  @Selector([NotificationState, CompanySelectors.getCurrentCompany])
  static getNotifications(state: NotificationStateModel, currentCompany: Company) {
    if (!currentCompany || currentCompany.config) {
      return state.notifications;
    }
    return [];
  }

  @Selector([NotificationState])
  static isLoading(state: NotificationStateModel) {
    return state.loading;
  }

  @Selector([NotificationState])
  static getTotalUnseen(state: NotificationStateModel) {
    return state.total_unseen;
  }

  @Selector([NotificationState])
  static canRetrieveNotifications(state: NotificationStateModel) {
    const startIndex = state.offset + 1;
    if (state.total === state.notifications.length) {
      return false;
    }
    return state.total === 0 || startIndex < state.total;
  }
}
