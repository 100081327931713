import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NotificationResponse, NotificationTemplate } from '@core/interfaces';
import { NotificationMenuItemComponent } from '../../notification-menu-item/notification-menu-item.component';

@Component({
  selector: 'app-signature-request-signed-or-cancelled',
  templateUrl: './signature-request-signed-or-cancelled.component.html',
  styleUrls: ['./signature-request-signed-or-cancelled.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignatureRequestApprovedOrCancelledComponent extends NotificationMenuItemComponent {
  @Input() notification: NotificationResponse;
  readonly NotificationTemplate = NotificationTemplate;

  openNotification(notification: NotificationResponse): void {
    switch (notification.template) {
      case NotificationTemplate.SignatureRequestApproved:
        this.goToOriginalWorkflow(notification);
        break;
      case NotificationTemplate.SignatureRequestCancelled:
        this.goToMyTasks(notification);
        break;
    }
  }
}
