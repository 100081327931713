import {
  ChangeDetectionStrategy,
  Component,
  Input
} from '@angular/core';
import { AccessLevel, WidgetType } from '@core/constants';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserSelectors } from 'src/app/store/user/user.selectors';

@Component({
  selector: 'app-dashboard-widget',
  templateUrl: './dashboard-widget.component.html',
  styleUrls: ['./dashboard-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardWidgetComponent {
  @Select(UserSelectors.getUserAccessLevel)
  userAccessLevel$: Observable<AccessLevel>;

  @Input() widget;
  @Input() resizeEvent;

  readonly WidgetType = WidgetType;
}
