import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { RoutingService } from '@core/services';
import { Store } from '@ngxs/store';
import { Auth } from 'aws-amplify';

@Injectable()
export class NotAuthenticatedGuardService  {
  constructor(private router: Router, private routingService: RoutingService) { }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const currentUser = await Auth.currentAuthenticatedUser().catch(e => false);
    if (currentUser) {
      this.router.navigate([this.routingService.HOME.url()]);
    }
    return !currentUser;
  }
}
