<div class="item-data">
  <app-icon-two-images bigImg="package" smallImg="download" [isSuccess]="true">
  </app-icon-two-images>
  <div class="item-details" (click)="goToPackage(notification)">
    <div class="item-details-title">The package
      <strong>{{notification.data?.link ? notification.data?.link?.title : notification.data?.package_title}} </strong> was
      <strong> downloaded </strong> in My Packages by <strong>{{notification.data?.action_user | displayName}}</strong>
    </div>
    <div class="item-details-time" *ngIf="company$ | async as company">
      {{notification.created_at | dateFormat : null : company.config.timezone : company.config.timeformat}}
    </div>
  </div>
</div>
<app-notification-bullet [notification]="notification"></app-notification-bullet>
