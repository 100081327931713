import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RoutingService } from '@core/services';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNotFoundComponent {

  constructor(public routingService: RoutingService) {}

}
