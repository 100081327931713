<mat-card appearance="outline" class="workflow-card {{workflow.type}} flex-100 flex-wrap" (click)="goToWorkflow()">
  <div class="card-header flex-100">
    <div class="workflow-name subtitle-2">
      {{workflow.document_title ? workflow.document_title : workflow.display_label}}
    </div>
  </div>
  <div class="content">
    <div class="workflow-card-container">
      <div class="overdue-task" matTooltip="Overdue Tasks" *ngIf="workflow.is_overdue">
        <mat-icon>alarm</mat-icon>
      </div>
      <div class=" workflow-info body-2 flex-row justify-start">
        <span class="bold-text">ID: &nbsp;</span>
          <div appTooltipWhenEllipsis
            [matTooltip]="workflow.display_label"
            matTooltipClass="btr-basic large"
            matTooltipPosition="below"
            appTooltipWhenEllipsis="horizontal"
            class="ellipsis-text">
              {{workflow.display_label}}
          </div>
      </div>

      <div class="ellipsis-text workflow-info body-2 flex-row justify-start">
        <span class="bold-text">Created on:&nbsp;</span>
        <div appTooltipWhenEllipsis
          [matTooltip]="workflow.created_at | dateFormat: company.config.dateformat : company.config.timezone : company.config.timeformat"
          matTooltipClass="btr-basic large"
          matTooltipPosition="below"
          appTooltipWhenEllipsis="horizontal"
          class="ellipsis-text">
          {{workflow.created_at | dateFormat: company.config.dateformat : company.config.timezone : company.config.timeformat}}
        </div>
      </div>

      <div class="ellipsis-text workflow-info body-2">
        <span class="bold-text">System: </span>
        <span>{{workflow?.system?.name}}</span>
      </div>

      <div class="workflow-progress body-2">
        <span class="bold-text">Progress </span>
        <span class="percentage-label bold-text">{{workflow.cached_data?.progress}}%</span>
      </div>

      <div class="progress-bar-container">
        <mat-progress-bar mode="determinate" [value]="workflow.cached_data?.progress" color="secondary"></mat-progress-bar>
      </div>

    </div>
  </div>
</mat-card>
