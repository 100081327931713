<form>
  <mat-label>Type <span>{{text}}</span> to confirm.</mat-label>
  <mat-form-field class="flex-100 no-x-padding no-subscript-wrapper"
                  appearance="outline"
                  [class.input-error]="!confirmFormControl.valid"
                  id="type-to-confirm-field">
    <input matInput
           placeholder="Type here"
           [formControl]="confirmFormControl"
           name="text-to-confirm"/>
  </mat-form-field>
  <div class="error-text" *ngIf="!confirmFormControl.valid && confirmFormControl.value.length">Please enter the text exactly as displayed above</div>
  <div class="error-text" *ngIf="confirmFormControl.touched && !confirmFormControl.value.length">This field is mandatory</div>
</form>