import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ChunkLoadingErrorHandler {
    handleError(error: any): void {
        const chunkFailedMessage = /Loading chunk (.*) failed/;
        if (chunkFailedMessage.test(error)) {
            window.location.reload();
        }
    }
}
