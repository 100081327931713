import { ErrorHandler, Injectable, InjectionToken, Injector } from '@angular/core';

export const ERROR_HANDLERS = new InjectionToken<ErrorHandler[]>('ERROR_HANDLERS');

@Injectable({
    providedIn: 'root'
})
export class CustomErrorHandler implements ErrorHandler {
   constructor(private injector: Injector){}
   handleError(error: any): any {
      const handlers = this.injector.get<ErrorHandler[]>(ERROR_HANDLERS, []);
      handlers.forEach((errorHandler: ErrorHandler) => {
         errorHandler.handleError(error);
      });
   }
}
