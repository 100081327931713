<div class="item-data">
    <app-icon-two-images
        bigImg="comment2"
        smallImg="close2"
        [isSuccess]="false">
    </app-icon-two-images>
    <div class="item-details" (click)="goToOriginalWorkflow(notification)">
        <div class="item-details-title">
            <strong>{{notification.data?.action_user | displayName}}</strong> canceled the execution and automatically the execution request on <strong>{{notification.data?.link?.title ? notification.data?.link?.title : '[deleted workflow]'}}</strong>.
        </div>
        <ng-container *ngIf="notification.data?.comment">
            <div class="item-details-subtitle">With comment:</div>
            <div class="item-details-comment"
                 matTooltip="{{notification.data?.comment}}"
                 matTooltipClass="btr-basic"
                 appTooltipSize
                 matTooltipPosition="right"
                 appTooltipWhenEllipsis
            >"{{notification.data?.comment}}"</div>
        </ng-container>
        <div class="item-details-time" *ngIf="company$ | async as company">
            {{notification.created_at | dateFormat : null : company.config.timezone : company.config.timeformat}}
        </div>
    </div>
</div>
<app-notification-bullet [notification]="notification"></app-notification-bullet>
