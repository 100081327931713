import { Injectable } from '@angular/core';
import {
  ICanvasFunctionalElementConfig,
  ICanvasStep,
  IWorkflowRuntime,
  IWorkflowTemplate
} from '@core/interfaces';
import { Observable } from 'rxjs';
import { CanvasFunctionalElementType } from 'src/app/canvas/canvas.enum';
import { ApiService } from './api.service';
import { RteImageUploadService } from './rte-image-upload.service';
import { cloneDeep } from 'lodash';


@Injectable({
  providedIn: 'root',
})
export class GeneralFeService {
  imageSettingsPath = `${this.apiService.apiUrl}${this.rteImageUploadService.uploadPath}`;

  constructor(
    private apiService: ApiService,
    private rteImageUploadService: RteImageUploadService) { }


  /*
  * Reset the 'src' for all images to the default (what we actually receive from the API) value
  */
  private resetImagesToDefault(content: string, imagesMap: any): string {
    if (!imagesMap) {
      return content;
    }
    const htmlDom = new DOMParser().parseFromString(content, 'text/html');
    const images = Array.from(htmlDom.querySelectorAll('img'));
    images.forEach(img => {
      const newValues: any = Object.values(imagesMap).find((x: any) => x.signed === img.src);
      if (newValues) {
        img.src = newValues.default;
      }
    });
    return htmlDom.body.innerHTML;
  }

  /*
  * Generate a map object to have a for each image's default and signed URLs
  */
  generateImagesMap(content: string): [any, HTMLImageElement[], Observable<any>[], any] {
    const htmlDom = new DOMParser().parseFromString(content, 'text/html');
    const allImages = Array.from(htmlDom.querySelectorAll('img'));
    const images = allImages.filter(img =>
      img.src.includes(this.rteImageUploadService.uploadPath) ||
      img.src.includes(this.rteImageUploadService.marketplaceUploadPath) ||
      img.src.includes('amazonaws.com/company') ||
      img.src.includes('/syncfusion-rte/')
    );
    const imagesMap = {};
    const calls: Observable<any>[] = [];
    images.forEach(img => {
      const isMarketplaceImg = img.src.includes('api/marketplace');
      const imgUuid = isMarketplaceImg
        ? img.src.split(this.rteImageUploadService.marketplaceUploadPath)[1]
        : img.src.split(this.rteImageUploadService.uploadPath)[1];
      if (imgUuid) {
        img.id = imgUuid;
        calls.push(isMarketplaceImg
          ? this.rteImageUploadService.getMarketplaceImage(imgUuid)
          : this.rteImageUploadService.getImage(imgUuid));
        imagesMap[imgUuid] = {
          default: img.src
        };
      } else {
        const imgId = img.id;
        if (imgId) {
          const getUrl = window.location;
          const url = new URL(getUrl.protocol + '/' + getUrl.host);
          const apiUrl = url.toString().substring(0, url.toString().length - 1);
          img.src = isMarketplaceImg
            ? `${apiUrl}/api/marketplace/syncfusion-image-upload/${imgId}`
            : `${apiUrl}/api/syncfusion-image-upload/${imgId}`;
          calls.push(isMarketplaceImg
            ? this.rteImageUploadService.getMarketplaceImage(imgId)
            : this.rteImageUploadService.getImage(imgId));
          imagesMap[imgId] = {
            default: img.src
          };
        }
      }
    });
    return [htmlDom, images, calls, imagesMap];
  }

  /*
  * Reset the image 'src' to the default value and delete 'imagesMap'
  */
  prepareStepsForSave(steps: ICanvasStep[]): ICanvasStep[] {
    if (!steps) {
      return;
    }
    steps = steps.map(step => {
      if (step.fe_type === CanvasFunctionalElementType.General_Table) {
        step = this.prepareGeneralDataForSave(step);
        return step;
      } 
      return step;
    });
    return steps;
  }

  /*
  * Reset the image 'src' to the default value and delete 'imagesMap'
  */
  prepareGeneralDataForSave(step: ICanvasStep): ICanvasStep {
    const stepData = { ...step.step_data }
    let stepObj = { ...step }
    stepObj = { ...stepObj, step_data: { ...stepData, content: this.resetImagesToDefault(step.step_data.content, step.step_data.imagesMap) } }
    step = cloneDeep(stepObj);
    delete step.step_data.imagesMap;
    return step;
  }

  /*
  * Set 'isDefaultImages' to true for all the images
  */
  setDefaultGeneralImages(workflow: IWorkflowTemplate | IWorkflowRuntime): IWorkflowTemplate | IWorkflowRuntime {
    const generalSteps = workflow.steps.filter(step => step.fe_type === CanvasFunctionalElementType.General_Table);
    if (generalSteps.length) {
      generalSteps.forEach(step => {
        step.step_data.isDefaultImages = true;
      });
    }
    return workflow;
  }

  /*
  * Set 'isDefaultImages' to true for all the images for General steps
  */
  setDefaultGeneralImagesBySteps(steps: ICanvasFunctionalElementConfig[]): ICanvasFunctionalElementConfig[] {
    if (steps.length) {
      steps.forEach(step => {
        if (step.fe_type === CanvasFunctionalElementType.General_Table) {
          step.step_data.isDefaultImages = true;
        }
      });
    }
    return steps;
  }

  /**
   * Called from Tiny mce when user uses any AI feature in the editor
   * @param prompt 
   * @returns 
   */
  invokeBedrockClaude(prompt: string): Observable<{ response: string }> {
    return this.apiService.post('/api/bedrock/invoke-claude', { prompt });
  }
}