import { ICompanyPoolConfig } from '@core/interfaces';
import { Company, CompanyConfigDTO, CompanyDTO } from '@core/models';

export class GetCurrentCompany {
  static readonly type = '[Company State] GetCurrentCompany';
  constructor(public company?: Company) { }
}

export class UpdateCompany {
  static readonly type = '[Company State] UpdateCompany';
  constructor(public newCompany: CompanyDTO) { }
}

export class UpdateCompanyConfig {
  static readonly type = '[Company State] UpdateCompanyConfig';
  constructor(public companyUuid: string, public newCompany: CompanyConfigDTO) { }
}

export class DeleteCompany {
  static readonly type = '[Company State] DeleteCompany';
  constructor(public companyUuid: string) { }
}

export class CancelCompanyDeletion {
  static readonly type = '[Company State] CancelCompanyDeletion';
  constructor(public companyUuid: string) { }
}

export class ActivateCompany {
  static readonly type = '[Company State] ActivateCompany';
  constructor(public companyUuid: string) { }
}

export class DeactivateCompany {
  static readonly type = '[Company State] DeactivateCompany';
  constructor(public companyUuid: string) { }
}

export class PublishCompany {
  static readonly type = '[Company State] PublishCompany';
  constructor(public company: Company) { }
}

export class UpdateCompanyPoolConfig {
  static readonly type = '[Company State] UpdateCompanyPoolConfig';
  constructor(public poolConfig: ICompanyPoolConfig) { }
}

export class ConvertCompanyToSSO {
  static readonly type = '[Company State] ConvertCompanyToSSO';
  constructor(public companyUuid: string) { }
}

export class ConvertCompanyToNonSSO {
  static readonly type = '[Company State] ConvertCompanyToNonSSO';
  constructor(public companyUuid: string) { }
}

export class GetPermissionMatrixLite {
  static readonly type = '[Company State] GetPermissionMatrixLite';
}

export class UpdateCompanyConfigReviewIdleTime {
  static readonly type = '[Company State] UpdateCompanyConfigReviewIdleTime';
  constructor(public companyUuid: string, public minutes: number) { }
}
