import { A11yModule } from '@angular/cdk/a11y';
import { BidiModule } from '@angular/cdk/bidi';
import { ObserversModule } from '@angular/cdk/observers';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { MaterialModule } from '@core/modules/material.module';
import { PopupComponent } from './popup.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    CoreModule,
    A11yModule,
    BidiModule,
    ObserversModule,
    OverlayModule,
    PortalModule,
    CdkStepperModule,
    CdkTableModule,
  ],
  declarations: [
    PopupComponent
  ],
  exports: [
    PopupComponent
  ],
  providers: []
})
export class PopupModule {
}
