<span
  [matTooltip]="tooltip"
  matTooltipPosition="below"
  matTooltipClass="btr-basic small text-center"
  class="simple-button-wrapper">
  <button
    *ngIf="type === 'dark-link'"
    class="dark-link"
    [class.fluid]="fluid"
    [class.mat-mdc-button-disabled]="disabled"
    [ngClass]="{'with-icon': !!icon, underline:underline, 'icon-right':iconPosition === 'right'}"
    (click)="!disabled && onClick.emit($event)"
    [disabled]="disabled"
    mat-button>
    <span *ngIf="icon" [class]="'icon left ' + icon"></span>
    <span [class]="customLabelClass">{{label}}</span>
  </button>

  <button
    *ngIf="type === 'dark-icon'"
    class="dark-icon"
    (click)="!disabled && onClick.emit($event)"
    mat-icon-button>
    {{label}}
  </button>

  <button
    *ngIf="type === 'green-link'"
    [class.fluid]="fluid"
    [class.mat-mdc-button-disabled]="disabled"
    class="green-link"
    [ngClass]="{'with-icon': !!icon, underline:underline, 'icon-right':iconPosition === 'right'}"
    (click)="!disabled && onClick.emit($event)"
    [disabled]="disabled"
    mat-button>
    <span *ngIf="icon && iconPosition === 'left'" [class]="'icon left ' + icon"></span>
    <span [class.label]="!!labelInfo" class="ellipsis-text label-wrapper">
      {{label}}
      <span *ngIf="labelInfo" class="label-info">
        {{labelInfo}}
      </span>
    </span>
    <!-- {{label}} -->
    <span *ngIf="icon && iconPosition === 'right'" [class]="'icon right ' + icon"></span>
  </button>

  <button
    *ngIf="type === 'green-filled'"
    class="green-filled"
    [class.fluid]="fluid"
    [class.mat-mdc-button-disabled]="disabled"
    [ngClass]="{'with-icon': !!icon, underline:underline, 'icon-right':iconPosition === 'right'}"
    (click)="!disabled && onClick.emit($event)"
    [disabled]="disabled"
    [style.height]="height"
    mat-flat-button>
    <span *ngIf="icon && iconPosition === 'left'" [class]="'icon left ' + icon" [style.fontSize]="iconSize"></span>
    <span [class.label]="!!labelInfo" [class]="customLabelClass">
      {{label}}
      <span *ngIf="labelInfo" class="label-info">
        {{labelInfo}}
      </span>
    </span>
    <!-- {{label}} -->
    <span *ngIf="icon && iconPosition === 'right'" [class]="'icon right ' + icon" [style.fontSize]="iconSize"></span>
  </button>

  <button
    *ngIf="type === 'white-filled'"
    class="white-filled"
    [class.fluid]="fluid"
    [class.mat-mdc-button-disabled]="disabled"
    [ngClass]="{'with-icon': !!icon, underline:underline, 'icon-right':iconPosition === 'right'}"
    (click)="!disabled && onClick.emit($event)"
    [disabled]="disabled"
    [style.height]="height"
    mat-flat-button>
    <span *ngIf="icon" [class]="'icon left ' + icon" [style.fontSize]="iconSize"></span>
    <span [class.label]="!!labelInfo">
      {{label}}
      <span *ngIf="labelInfo" class="label-info">
        {{labelInfo}}
      </span>
    </span>
  </button>

  <button
    *ngIf="type === 'green-outline'"
    [class.fluid]="fluid"
    [class.mat-mdc-button-disabled]="disabled"
    class="green-outline"
    [ngClass]="{'with-icon': !!icon, underline:underline, 'icon-right':iconPosition === 'right'}"
    (click)="!disabled && onClick.emit($event)"
    [disabled]="disabled"
    mat-stroked-button>
    <ng-container *ngIf="!spinner">
      <span *ngIf="icon && iconPosition === 'left'" [class]="'icon left ' + icon" [style.fontSize]="iconSize"></span>
      {{label}}
      <span *ngIf="icon && iconPosition === 'right'" [class]="'icon right ' + icon" [style.fontSize]="iconSize"></span>
    </ng-container>
    <div *ngIf="spinner" class="flex-row justify-start items-center">
      <img *ngIf="disabled" class="margin-left-5 margin-right-10 img-logo" [style.height]="'20px'" src="../../../../assets/img/loading.gif"/>
      <span *ngIf="!disabled && icon" class="icon left {{icon}}"></span>
      <span>{{label}}</span>
    </div>
  </button>

  <button
    *ngIf="type === 'red-outline'"
    [class.fluid]="fluid"
    [class.mat-mdc-button-disabled]="disabled"
    class="red-outline"
    [ngClass]="{'with-icon': !!icon, underline:underline, 'icon-right':iconPosition === 'right'}"
    (click)="!disabled && onClick.emit($event)"
    [disabled]="disabled"
    mat-stroked-button>
    <span *ngIf="icon" [class]="'icon left ' + icon"></span>
    {{label}}
  </button>

  <button
    *ngIf="type === 'red-link'"
    [class.fluid]="fluid"
    [class.mat-mdc-button-disabled]="disabled"
    class="red-link"
    [ngClass]="{'with-icon': !!icon, underline:underline, 'icon-right':iconPosition === 'right'}"
    (click)="!disabled && onClick.emit($event)"
    [disabled]="disabled"
    mat-button>
    <span *ngIf="icon" [class]="'icon left ' + icon"></span>
    {{label}}
  </button>

  <button
    *ngIf="type === 'red-filled'"
    [class.fluid]="fluid"
    [class.mat-mdc-button-disabled]="disabled"
    class="red-filled"
    [ngClass]="{'with-icon': !!icon, underline:underline, 'icon-right':iconPosition === 'right'}"
    (click)="!disabled && onClick.emit($event)"
    [disabled]="disabled"
    mat-flat-button>
    <span *ngIf="icon" [class]="'icon left ' + icon"></span>
    {{label}}
  </button>

  <button *ngIf="type === 'blue-link'" class="blue-link" mat-button>
    {{label}}
  </button>
</span>
