import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { MaterialModule } from '@core/modules/material.module';
import { ButtonsModule } from '@shared/components/buttons/buttons.module';
import { UserAvatarModule } from '../user-avatar/user-avatar.module';
import { UserGroupDepartmentSearchModule } from '../user-group-department-search/user-group-department-search.module';
import { EmailNotificationComponent } from './email-notification.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    CoreModule,
    UserAvatarModule,
    UserGroupDepartmentSearchModule,
    ButtonsModule,
  ],
  declarations: [
    EmailNotificationComponent
  ],
  exports: [
    EmailNotificationComponent
  ],
  providers: []
})
export class EmailNotificationModule {
}
