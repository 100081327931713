import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICompanyGroup } from '../interfaces/company-group.interface';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class PrivateGroupsService {
  private readonly companyUserGroupsPath = '/api/company-user-group';

  constructor(private apiService: ApiService) { }

  getCompanyGroups(isPrivate: boolean): Observable<ICompanyGroup[]> {
    let params = new HttpParams();
    params = params.set('type', isPrivate ? 'private' : 'public');
    return this.apiService.get(this.companyUserGroupsPath, params).pipe(map(res => res.data));
  }

  getCompanyGroup(group: ICompanyGroup): Observable<ICompanyGroup> {
    return this.apiService.get(`${this.companyUserGroupsPath}/${group.uuid}`);
  }

  createCompanyGroup(companyGroupData: ICompanyGroup) {
    return this.apiService.post(this.companyUserGroupsPath, companyGroupData);
  }

  updateCompanyGroup(companyGroupData: ICompanyGroup) {
    return this.apiService.put(`${this.companyUserGroupsPath}/${companyGroupData.uuid}`, companyGroupData);
  }

  deleteCompanyGroup(companyGroupData: ICompanyGroup) {
    return this.apiService.delete(`${this.companyUserGroupsPath}/${companyGroupData.uuid}`);
  }
}
