import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { interval, Observable, Subject } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AsyncFileDynamoDBResponse } from '../../store/async-pdf/async-pdf.model';

@Injectable({
  providedIn: 'root',
})
export class AsyncPdfService {
  private asyncPdfPath = '';
  private pollInterval = 3000;
  private checkStatus$: Subject<AsyncFileDynamoDBResponse[]>;

  constructor(private http: HttpClient) {
    const getUrl = window.location;
    const url = new URL(getUrl.protocol + '/' + getUrl.host);
    url.port = '';
    const port = environment.microservices_ports.printing;
    if (port) {
      url.port = port;
    }
    this.asyncPdfPath = url.toString().substring(0, url.toString().length - 1) + '/printing-api/print-sdox';
  }

  public startAsyncPdfCheck(userUuid: string): Observable<AsyncFileDynamoDBResponse[]> {
    this.stopAsyncPdfCheck();
    this.checkStatus$ = new Subject();
    return interval(this.pollInterval).pipe(
      startWith(0),
      switchMap(() => this.getGeneratedFiles(userUuid)),
      takeUntil(this.checkStatus$)
    );
  }

  public stopAsyncPdfCheck() {
    if (this.checkStatus$) {
      this.checkStatus$.next(null);
      this.checkStatus$.complete();
    }
  }

  public removeGeneratedFile(batchId: string) {
    return this.http.delete(`${this.asyncPdfPath}/${batchId}`);
  }

  public getGeneratedFiles(userUuid: string): Observable<AsyncFileDynamoDBResponse[]> {
    return this.http.get<AsyncFileDynamoDBResponse[]>(`${this.asyncPdfPath}/${userUuid}/status`);
  }
}
