import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { MaterialModule } from '@core/modules/material.module';
import { ButtonsModule } from '@shared/components/buttons/buttons.module';
import { ComponentsModule } from '@shared/components/components.module';
import { InputsModule } from '@shared/components/inputs/inputs.module';
import { LoginContainerComponent } from './login-container/login-container.component';
import { IdentityProviderSelectionModalComponent } from './identity-provider-selection-modal/identity-provider-selection-modal.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    CoreModule,
    ButtonsModule,
    InputsModule,
  ],
  exports: [
    LoginContainerComponent,
    IdentityProviderSelectionModalComponent
  ],
  declarations: [
    LoginContainerComponent,
    IdentityProviderSelectionModalComponent
  ],
  providers: []
})
export class SharedLoginModule {
}
