export interface ReportingStatusReponse {
    status: ReportingStatusData;
}

export interface ReportingCredentialsResponse {
    host: string;
    port: string;
    username: string;
    password: string;
    database: string;
}

export interface ReportingLockStatus {
    is_locked: boolean;
}

export interface ReportingLogsResponse {
    status: ReportingLogsStatus;
    created_at: string;
    updated_at: string;
}

export enum ReportingStatusData {
    Enabled = 'enabled',
    Disabled = 'disabled',
    InProgress = 'in_progress'
}

export enum ReportingLogsStatus {
    Success = 'success',
    Failed = 'failed',
    Pending = 'pending'
}

export enum ReportingLogsStatusLabel {
    'success' = 'Successful',
    'failed' = 'Failed',
    'pending' = 'In progress'
}
