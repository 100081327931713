import {
  ChangeDetectionStrategy,
  Component,
  Input
} from '@angular/core';
import { Router } from '@angular/router';
import { IRuntimeLite } from '@core/interfaces';
import { Company } from '@core/models/company.model';
import {
  HelperService
} from '@core/services';

@Component({
  selector: 'app-workflow-card',
  templateUrl: './workflow-card.component.html',
  styleUrls: ['./workflow-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowCardComponent {
  @Input() company: Company;
  @Input() workflow: IRuntimeLite;

  constructor(
    private router: Router,
    private helperService: HelperService
  ) { }

  goToWorkflow() {
    this.router.navigate([this.helperService.getWorkflowUrl(this.workflow.type, this.workflow.uuid, this.workflow.current_version_uuid)]);
  }
}
