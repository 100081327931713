import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AccessLevel } from '@core/constants';
import { RoutingService } from '@core/services';
import { Store } from '@ngxs/store';
import { CompanySelectors } from '@store/company/company.selectors';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { UserSelectors } from 'src/app/store/user/user.selectors';

@Injectable()
export class LogicalOrConditionsGuard  {
  constructor(private store: Store, private toastr: ToastrService, private router: Router, private routingService: RoutingService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    return combineLatest([
      this.store.select(UserSelectors.getCurrentUser),
      this.store.select(UserSelectors.getUserAccessLevel),
      this.store.select(CompanySelectors.getPermissionMatrixLite)
    ]).pipe(
      filter(([user, userAccessLevel, permissionsLite]) => !!user && !!userAccessLevel && !!permissionsLite),
      map(([user, userAccessLevel, permissionsLite]) => {
        const btrAdminHasAccess: boolean = route.data.btrAdminHasAccess;
        const isAuthenticated = this.store.selectSnapshot(UserSelectors.isLoggedIn);
        const isBTRAdmin = userAccessLevel === AccessLevel.BTRAdmin;
        if (!isAuthenticated || !user
          || route.data === null || route.data.conditions === null
          || (isBTRAdmin && !btrAdminHasAccess)) {
          this.toastr.warning('You are not allowed to access this page');
          this.router.navigate([this.routingService.BLANK.url()]);
          return false;
        }
        if (isBTRAdmin && btrAdminHasAccess) {
          return true;
        }

        const oneOfPermissions = route.data.conditions.oneOfPermissions;
        const requiredPermissions = route.data.conditions.requiredPermissions;

        if (requiredPermissions) {
          const hasRequiredPermissions = requiredPermissions
            .every(permission => user.permissions.includes(permission));
          if (hasRequiredPermissions) {
            return true;
          }
        }
        if (route.data.conditions.accessLevels) {
          const hasAccessLevel = route.data.conditions.accessLevels.some(r => userAccessLevel === r);
          if (hasAccessLevel) {
            return true;
          }
        }
        if (oneOfPermissions) {
          const hasAtLeastOnePermission = oneOfPermissions.some(permission => user.permissions.includes(permission));
          if (hasAtLeastOnePermission) {
            return true;
          }
        }

        const missingRequiredPermissions = requiredPermissions?.length
          ? requiredPermissions.filter(permission => !user.permissions.includes(permission)).map(p => permissionsLite[p])
          : [];
        const missingOneOfPermissions = oneOfPermissions?.length
          ? oneOfPermissions.filter(permission => !user.permissions.includes(permission)).map(p => permissionsLite[p])
          : [];

        if (missingRequiredPermissions.length) {
          this.toastr.warning(`You do not have the ${missingRequiredPermissions.join(' or ')} ${missingRequiredPermissions.length === 1 ? 'permission' : 'permissions'} for this action.`)
        } else if (missingOneOfPermissions.length) {
          this.toastr.warning(`You do not have the ${missingOneOfPermissions.join(' or ')} ${missingOneOfPermissions.length === 1 ? 'permission' : 'permissions'} for this action.`)
        } else {
          this.toastr.warning('You are not allowed to access this page');
        }

        this.router.navigate([this.routingService.BLANK.url()]);
        return false;
      }),
      catchError(error => {
        return of(false);
      }));
  }
}
