<div class="item-data">
    <app-icon-two-images
        bigImg="license"
        smallImg="thumbs-down"
        [isSuccess]="false">
    </app-icon-two-images>
    <div class="item-details" (click)="goToLicence(notification)">
        <div class="item-details-title">The license request <strong>#{{notification.data?.id}}</strong> was rejected by <strong>{{notification.data?.action_user | displayName}}</strong>.</div>
        <div>The following licenses were requested to be added to <strong>{{notification.data?.company?.name}}</strong>:</div>
        <div *ngIf="notification.data?.license_company_admin_count">
            Company Admin: <strong>{{notification.data?.license_company_admin_count | signWithLabel: 'license'}}</strong>
        </div>
        <div *ngIf="notification.data?.license_admin_count">
            Admin: <strong>{{notification.data?.license_admin_count | signWithLabel: 'license'}}</strong>
        </div>
        <div *ngIf="notification.data?.license_client_count">
            Client: <strong>{{notification.data?.license_client_count | signWithLabel: 'license'}}</strong>
        </div>
        <div *ngIf="notification.data?.license_editor_count">
            Editor: <strong>{{notification.data?.license_editor_count | signWithLabel: 'license'}}</strong>
        </div>
        <div *ngIf="notification.data?.license_viewer_count">
            Viewer: <strong>{{notification.data?.license_viewer_count | signWithLabel: 'license'}}</strong>
        </div>
        <div class="item-details-time" *ngIf="company$ | async as company">
            {{notification.created_at | dateFormat : null : company.config.timezone : company.config.timeformat}}
        </div>
    </div>
</div>
<app-notification-bullet [notification]="notification"></app-notification-bullet>
