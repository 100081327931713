<app-login-container [disableLoadingAnimation]="true">
  <ng-template let-brandingData>
    <div class="flex-row justify-center items-center" id="reset-password">
      <div class="section-container flex-col flex-fixed-380">
        <div class="login-header">
          <div class="welcome-message bold-text">
            We have sent a password reset code by email to {{user}}. Enter it below to reset your password
          </div>
        </div>

        <!-- Header For MFA Step (after Password Reset):  -->
        <app-login-header *ngIf="showMfaForm" [companyName]="brandingData?.company?.name"></app-login-header>

        <!-- Reset Password Form -->
        <form [formGroup]="resetForm" *ngIf="!showMfaForm" class="flex-col">
          <div class="reset-password-code">
            <mat-form-field appearance="outline" class="btr-form-field form-field-wrapper">
              <input
                type="text"
                matInput
                formControlName="code"
                placeholder="Reset Code*"
                autocomplete="off"
                required
              >
            </mat-form-field>
          </div>
          
          <mat-form-field appearance="outline" class="btr-form-field form-field-wrapper">
            <input matInput
                   formControlName="password"
                   type="password"
                   placeholder="Password*"
                   autocomplete="off"
                   required
            >
          </mat-form-field>

          <mat-form-field appearance="outline" class="btr-form-field form-field-wrapper reset-password-confirm">
            <input matInput
                   formControlName="confirmPassword"
                   type="password"
                   placeholder="Confirm your new password*"
                   autocomplete="off"
                   required
            >
            <mat-error *ngIf="resetForm.controls.confirmPassword.errors?.noMatch">
              Please make sure your passwords match
            </mat-error>
  
            <mat-error *ngIf="resetError">
              {{resetError}}
            </mat-error>
          </mat-form-field>


          <app-styleguide-simple-button
            type="green-filled"
            fluid="true"
            class="reset-password-btn"
            label="Reset password"
            (onClick)="onResetPasswordSubmit()">
          </app-styleguide-simple-button>

          <app-styleguide-simple-button
            type="green-link"
            fluid="true"
            label="Cancel"
            (onClick)="goToLoginPage()">
          </app-styleguide-simple-button>
        </form>

        <!-- MFA Form -->
        <form *ngIf="showMfaForm" [formGroup]="mfaFormGroup">
          <div class="reset-password-code">
            <mat-form-field appearance="outline" class="btr-form-field form-field-wrapper">
              <input type="text" matInput formControlName="mfaCode" placeholder="2FA Code" autocomplete="off" required>
              <mat-error *ngIf="mfaLoginError">
                {{mfaLoginError}}
              </mat-error>
            </mat-form-field>
          </div>
          <app-styleguide-simple-button 
            type="green-filled" 
            fluid="true" 
            class="reset-password-btn" 
            label="Confirm"
            (onClick)="loginMFA()">
          </app-styleguide-simple-button>
        </form>
      </div>
    </div>
  </ng-template>
</app-login-container>
