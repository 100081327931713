import { IUserLite } from "@core/models";
import { Attachment } from "./attachment-type.interface";

export interface ITestResultLink {
  is_passed: boolean | number;
  is_signed: boolean | number;
  is_na: boolean | number;
  signed_at: string;
  updated_at: string;
  deviation_uuid: string;
  deviation_status: string;
  fail_type: IFailType;
  comment: string;
  confirmed_at: string;
  confirmed_by: IUserLite;
  signed_by: IUserLite;
  uuid: string;
  attachments: Attachment[];
}

export interface ITestResultLinkChangeEvent {
  testResultLink: ITestResultLink;
  rowUuid: string;
  stepUuid: string;
}

export enum IFailType {
  FailWithDeviationAndNotProceed = 1,
  FailWithoutDeviation = 2,
  FailWithDeviationAndProceed = 3,
}
