import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TableLoadingOverlayComponent } from './table-loading-overlay.component';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    TableLoadingOverlayComponent
  ],
  exports: [
    TableLoadingOverlayComponent
  ],
  providers: []
})
export class TableLoadingOverlayModule {
}
