import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NotificationResponse } from '@core/interfaces';
import { NotificationMenuItemComponent } from '../../notification-menu-item/notification-menu-item.component';

@Component({
  selector: 'app-market-package-approved',
  templateUrl: './market-package-approved.component.html',
  styleUrls: ['./market-package-approved.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketPackageApprovedComponent extends NotificationMenuItemComponent {
  @Input() notification: NotificationResponse;
}
