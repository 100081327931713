import { HttpStatusCode } from "@angular/common/http";

export interface IValidationRequest {
  key: IValidationRequestKey;
  value: string | string[];
}

export enum IValidationRequestKey {
  TemplateDisplayLabelUnique = 'template_display_label_unique',
  HasAccessToDocumentUuids = 'has_access_to_document_uuids',
  HasAccessToDeviationUuids = 'has_access_to_deviation_uuids'
}

export interface IValidationResponse {
  status: HttpStatusCode; // 200 (Ok) | 422 (UnprocessableEntity)
  errors: string[];
}
