import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { PUSHER_EVENTS } from '@core/constants/app-constants';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  private webSocketConnection$: WebSocketSubject<any> = environment.production ? webSocket(`wss://${window.location.hostname}/app/resq_key`) : webSocket(`ws://${window.location.hostname}:6001/app/resq_key`);

  private connectionEstablished: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private newMessage: BehaviorSubject<string> = new BehaviorSubject<any>(null);

  get connectionEstablished$(): Observable<string> {
    return this.connectionEstablished.asObservable();
  }

  get newMessage$(): Observable<string> {
    return this.newMessage.asObservable();
  }

  constructor(private apiService: ApiService) {
    this.initWebsocket();
  }

  /**
   * Called to init, in constructor and for any connection interrupted / error
   */
  initWebsocket() {
    this.webSocketConnection$.subscribe({
      next: (message: any) => {
        if (message.event === PUSHER_EVENTS.CONNECTION_ESTABLISHED) {
          const messageData = JSON.parse(message?.data);
          const socketId = messageData?.socket_id;
          this.connectionEstablished.next(socketId);
        } else {
          this.newMessage.next(message);
        }
      },
      error: err => {
        if (err.type === 'close') {
          this.initWebsocket();
        }
        console.error(err);
      }
    });
  }

  /**
   * Called befor any subscribe to chanels
   * @param socketId
   * @param authChannelName
   * @returns
   */
  authWebsocket(socketId: string, authChannelName: string) {
    const data = {
      socket_id: socketId,
      channel_name: authChannelName,
    };
    return this.apiService.post(`/api/broadcasting/auth`, data);
  }

  /**
   * Called to subscribe to a specific channel
   * @param auth
   * @param channelName
   */
  subscribeToChannel(auth:string, channelName: string) {
    const eventData: any = {
      event: PUSHER_EVENTS.SUBSCRIBE,
      data: {
        channel: channelName
      },
    }
    if(auth){
      eventData.data.auth = auth;
    }
    this.webSocketConnection$.next(eventData);
  }
}
