import { Injectable } from '@angular/core';
import {
  IApiPaginatedDataBase, IBulkConvertResponse,
  ICompanyPoolConfig,
  ICompanyRelationshipRequest,
  IFilterDataValues,
  ISortQueryParameters,
  ISSOIdentityProvider,
  ISSOIdentityProviderSetup
} from '@core/interfaces';
import { ILicense } from '@core/interfaces/licenses.interface';
import { Company, CompanyDTO, ICompanyContact, IUser, IUserLite, IUserRole } from '@core/models';
import { HelperService } from '@core/services/helper.service';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { GetCurrentCompany } from 'src/app/store/company/company.actions';
import { IDPResponse } from '../../admin/shared/modules/company-details/sso-add-idp-modal/idp.interface';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService {
  readonly companyPath = '/api/company';
  readonly companyContactPath = '/api/company-contact';
  readonly companyIdpPath = '/api/idp';
  constructor(
    private apiService: ApiService,
    private store: Store,
    private helperService: HelperService,
  ) { }

  getCompanies(offset: number, limit: number, sortQueryParams: ISortQueryParameters): Observable<IApiPaginatedDataBase<Company & { licenses: ILicense }>> {
    return this.apiService.get<Company & { licenses: ILicense }[]>(`${this.companyPath}/${offset}/${limit}`, this.apiService.addSortQueryParams(sortQueryParams));
  }

  getAvailableTimezones() {
    return this.apiService.get('/api/timezones');
  }

  getCompanyById(id) {
    return this.apiService.get(`${this.companyPath}/${id}`);
  }

  createCompany(companyData) {
    return this.apiService.postMultipartForm(this.companyPath, this.helperService.generateFormDataForCompany(companyData));
  }

  updateCompany(companyData) {
    return this.apiService.putMultipartForm(this.companyPath, this.helperService.generateFormDataForCompany(companyData));
  }

  // if update is performed by the BTR admin is should be updated by id
  updateCompanyById(companyData) {
    return this.apiService.putMultipartForm(`${this.companyPath}/${companyData.uuid}`, this.helperService.generateFormDataForCompany(companyData));
  }

  addCompanyContact(companyContact: Partial<ICompanyContact>) {
    return this.apiService.post(this.companyContactPath, companyContact);
  }

  getCompanyContact(offset: number, limit: number, sortQueryParams?: ISortQueryParameters): Observable<IApiPaginatedDataBase<ICompanyContact>> {
    return this.apiService.get(`${this.companyContactPath}/${offset}/${limit}`, this.apiService.addSortQueryParams(sortQueryParams));
  }

  deleteCompanyContact(uuid) {
    return this.apiService.delete(`${this.companyContactPath}/${uuid}`);
  }

  publishCompany(companyID: string) {
    return this.apiService.post(`${this.companyPath}/${companyID}/publish`, {});
  }

  updateCompanyContact(contact: Partial<ICompanyContact>) {
    return this.apiService.put(`${this.companyContactPath}/${contact.uuid}`, contact);
  }

  updateCompanyConfig(companyConfig) {
    return this.apiService.put(`${this.companyPath}/config`, companyConfig).pipe(tap(result => {
      return this.store.dispatch(new GetCurrentCompany());
    }));
  }

  updateAdminCompanyConfig(companyId, companyConfig) {
    return this.apiService.put(`${this.companyPath}/${companyId}/config`, companyConfig);
  }

  resetMfa(companyUuid: string): Observable<{ success: true }> {
    return this.apiService.post(`/api/company-user/${companyUuid}/mfa/reset`, {});
  }

  resetMfaFromBTRA(companyUuid: string, userUuid: string): Observable<{ success: true }> {
    return this.apiService.post(`${this.companyPath}/${companyUuid}/user/${userUuid}/mfa/reset`, {});
  }

  getCompanyAdminUsers(offset: number, limit: number, filters: IFilterDataValues): Observable<IApiPaginatedDataBase<IUser>> {
    return this.apiService.post(`/api/company-user/listing/${offset}/${limit}`, filters)
      .pipe(catchError(err => of({ data: [], total: 0 })));
  }

  getCompanyAdminUsersListingLite(offset: number, limit: number, filters: IFilterDataValues): Observable<IApiPaginatedDataBase<IUser>> {
    return this.apiService.post(`/api/company-user/listing/lite/${offset}/${limit}`, filters)
      .pipe(catchError(err => of({ data: [], total: 0 })));
  }

  getCompanyUsersListingLite(offset: number, limit: number, filters: IFilterDataValues, companyUuid: string): Observable<IApiPaginatedDataBase<IUserLite>> {
    return this.apiService.post(`${this.companyPath}/${companyUuid}/company-user/listing/lite/${offset}/${limit}`, filters)
      .pipe(catchError(err => of({ data: [], total: 0 })));
  }

  getCompanyUsersListing(offset: number, limit: number, filters: IFilterDataValues, companyUuid: string): Observable<IApiPaginatedDataBase<IUser>> {
    return this.apiService.post(`${this.companyPath}/${companyUuid}/company-user/listing/${offset}/${limit}`, filters)
      .pipe(catchError(err => of({ data: [], total: 0 })));
  }

  updateCompanyUser(user: Partial<IUserLite>) {
    return this.apiService.put(`/api/company-user/${user.uuid}`, user);
  }

  updateBTRACompanyUser(user: Partial<IUserLite>, companyUuid: string) {
    return this.apiService.put(`/api/company-user/update/${companyUuid}/${user.uuid}`, user);
  }

  inviteCompanyUserBTRA(users: Partial<IUserLite>[], companyUuid: string): Observable<{ data: IBulkConvertResponse }> {
    return this.apiService.post(`/api/company-user/invite/${companyUuid}`, users);
  }

  inviteCompanyUser(users: (Partial<IUserLite> & { department_uuid: string })[]): Observable<{ data: IBulkConvertResponse }> {
    return this.apiService.post(`/api/company-user/invite`, users);
  }

  getCurrentCompany() {
    return this.apiService.get(`/api/current-company`);
  }

  getCompanyUsageData(companyId) {
    return this.apiService.get(`${this.companyPath}/${companyId}/usage`);
  }

  activateCompany(companyId) {
    return this.apiService.post(`${this.companyPath}/${companyId}/activate`, {});
  }

  deactivateCompany(companyId) {
    return this.apiService.post(`${this.companyPath}/${companyId}/inactivate`, {});
  }

  deleteCompany(companyId) {
    return this.apiService.delete(`${this.companyPath}/${companyId}`);
  }

  cancelDeletionCompany(companyId) {
    return this.apiService.post(`${this.companyPath}/${companyId}/cancel-deletion`, {});
  }

  convertCompanyToSSO(companyId) {
    return this.apiService.post(`${this.companyPath}/${companyId}/sso/enable`, {});
  }

  convertCompanyToNonSSO(companyId) {
    return this.apiService.post(`${this.companyPath}/${companyId}/sso/disable`, {});
  }

  public addCompanyRelationship(sourceUuid: string, payload: ICompanyRelationshipRequest[]): Observable<{ success: true }> {
    return this.apiService.post(`${this.companyPath}/${sourceUuid}/relationship`, payload);
  }

  public deleteCompanyRelationship(sourceUuid: string, relatedUuid: string): Observable<{ success: true }> {
    return this.apiService.delete(`${this.companyPath}/${sourceUuid}/relationship/${relatedUuid}`);
  }

  public convertUserToSso(companyId: string, userId: string): Observable<IUser> {
    return this.apiService.post(`${this.companyPath}/${companyId}/user/${userId}/sso/enable`, {});
  }

  public convertUserToNonSso(companyId: string, userId: string): Observable<IUser> {
    return this.apiService.post(`${this.companyPath}/${companyId}/user/${userId}/sso/disable`, {});
  }

  public updateCompanyCognitoData(companyId: string, poolConfig: ICompanyPoolConfig): Observable<CompanyDTO> {
    return this.apiService.patch(`${this.companyPath}/${companyId}/cognito`, poolConfig);
  }

  public enableBulkSso(companyId: string, payload: string[]): Observable<IBulkConvertResponse> {
    return this.apiService.post(`${this.companyPath}/${companyId}/user/bulk/sso/enable`, payload)
      .pipe(map(res => res.data));
  }

  public disableBulkSso(companyId: string, payload: string[]): Observable<IBulkConvertResponse> {
    return this.apiService.post(`${this.companyPath}/${companyId}/user/bulk/sso/disable`, payload)
      .pipe(map(res => res.data));
  }

  public getCompanySSOIdentityProviders(companyId: string): Observable<ISSOIdentityProvider[]> {
    return this.apiService.get(companyId ? `${this.companyPath}/${companyId}/idp` : this.companyIdpPath);
  }

  public getCompanySSOIdentityProvidersSetup(companyId: string): Observable<ISSOIdentityProviderSetup> {
    return this.apiService.get(companyId ? `${this.companyPath}/${companyId}/idp/setup` : `${this.companyIdpPath}/setup`);
  }

  public fetchCompanySSOIdentityProviders(companyId: string): Observable<{ success: true }> {
    return this.apiService.post(companyId ? `${this.companyPath}/${companyId}/idp/fetch` : `${this.companyIdpPath}/fetch`, {});
  }

  public getCompanySSOIdentityProviderDetail(companyId: string, idp: string): Observable<IDPResponse> {
    return this.apiService.get(companyId ? `${this.companyPath}/${companyId}/idp/details/${idp}` : `${this.companyIdpPath}/details/${idp}`);
  }

  public createCompanySSOIdentityProvider(companyId: string, body: FormData): Observable<IDPResponse> {
    return this.apiService.postMultipartForm(companyId ? `${this.companyPath}/${companyId}/idp` : `${this.companyIdpPath}`, body);
  }

  public updateCompanySSOIdentityProvider(companyId: string, idpName: string, body: FormData): Observable<IDPResponse> {
    return this.apiService.postMultipartForm(companyId ? `${this.companyPath}/${companyId}/idp/update/${idpName}` : `${this.companyIdpPath}/update/${idpName}`, body);
  }

  public deleteCompanySSOIdentityProvider(companyId: string, idpName: string): Observable<{ success: true }> {
    return this.apiService.delete(companyId ? `${this.companyPath}/${companyId}/idp/${idpName}` : `${this.companyIdpPath}/${idpName}`);
  }

  public resendTemporaryPassword(companyUuid: string, userUuid: string, isBTRAdmin: boolean): Observable<{ success: boolean }> {
    const apiUrl = isBTRAdmin
      ? `/api/company-user/${companyUuid}/password/resend/${userUuid}`
      : `/api/company-user/password/resend/${userUuid}`;
    return this.apiService.post(apiUrl, {});
  }

  public resetMfaSettings(companyUuid: string, userUuid: string, isBTRAdmin: boolean): Observable<{ success: boolean }> {
    const apiUrl = isBTRAdmin
      ? `/api/company/${companyUuid}/user/${userUuid}/mfa/reset`
      : `/api/company-user/${userUuid}/mfa/reset`;
    return this.apiService.post(apiUrl, {});
  }

  public getUserRolesForCompany(companyUuid: string, sortQueryParams?: ISortQueryParameters): Observable<IUserRole[]> {
    return this.apiService.get(`${this.companyPath}/${companyUuid}/roles`, this.apiService.addSortQueryParams(sortQueryParams)).pipe(
      map(res => res.data)
    );
  }

  public updateCompanyConfigReviewIdleTime(companyUuid: string, minutes: number) {
    return this.apiService.put(`${this.companyPath}/review-idle-time`, { review_idle_time: minutes });
  }
}
