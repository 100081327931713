import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ExtendSessionService {
  constructor(private apiService: ApiService) { }

  public extendSession(): Observable<{ success: true }> {
    return this.apiService.post('/api/session/extend', {});
  }
}
