import { SafeResourceUrl } from "@angular/platform-browser";
import { ITableAttachmentsSources } from "@core/services/table-attachments.service";

export interface Attachment {
  file_uuid?: string;
  original_attachment?: Blob;
  thumbnail?: SafeResourceUrl;
  name?: string;
  original_filename?: string;
  file_size?: number;
  extension?: string;
  index?: number;
  mime_type?: string;
  file_content?: string;
  full_path?: string;
  is_screen_recording?: boolean;
  dataUrl?: string;
  is_new_upload?: boolean;
  temporary_id_counter?: number;
  sequence_filename?: string;
}

export interface AttachmentThumbnail {
  data: Attachment[];
}

export enum UploadAttachmentType {
  UPLOAD = 'UPLOAD',
  RECORD = 'RECORD',
  SCREENSHOT = 'SCREENSHOT'
}

export enum ScreenCaptureMode {
  INIT,
  CAPTURE,
  GENERATE,
  PREVIEW
}

export interface TableAttachmentsInitDefaults {
  isViewMode: boolean;
  filesCount: number;
  documentTitle: string;
  sectionTitle: string;
  stepTitle: string;
  versionUuid: string;
  rowUuid: string;
  workflowUuid: string;
  stepUuid: string;
  source: ITableAttachmentsSources;
  attachmentDataFromDetailsDeviationsModal?: Attachment;
  attachmentDataFromPassFailRowThumbnails?: boolean;
  hideSelectAttachmentSection?: boolean;
  clickedThumbnailIndex?: number;
  downloadRequestUrl?: string;
  isSignStepOrSubmitDeviation?: boolean;
  attachments?: Attachment[];
  selectedAttachmentIndex?: number;
  isDeviation?: boolean;
  isReviewSessionActive?: boolean;
  cloneParentUuid?: string;
}
