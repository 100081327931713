<div class="item-bullet">
  <div class="item-bullet-clickable"
       [class.item-read]="notification.is_read"
       (click)="markAsRead($event)"
       [matTooltip]="notification.is_read ? 'Mark as unread' : 'Mark as read'"
       matTooltipClass="btr-basic small">
      <div class="item-bullet-visible"
           *ngIf="!notification.is_read"></div>
  </div>
</div>
